import React, { Component, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import { globalHistory } from "@reach/router";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import getLayersData from "../Common/NewMapDesign/API/layerDataGetApi";
import Select from "react-select";
import { SketchPicker } from "react-color";
import CreatableSelect from "react-select/creatable";

function TreeTiesAddProjectForms1WaterParameter(props) {
  const inputRefs = useRef({
    WaterPH: null,
    Nitrate: null,
    Electriccondcutivitywater: null,
    Rainfall: null,
    DissolvedOxygen: null,
    MoistureLevel: null,
    Ecoregion: null,
    Toxicity: null,
  });

  const [inputValues, setInputValues] = useState({
    WaterPH: "",
    MoistureLevel: "",
    Ecoregion: "",
    Toxicity: "",
  });

  const handleAddClick = () => {
    AddToGrid();
    Object.values(inputRefs.current).forEach((inputRef) => {
      inputRef.value = "";
    });
  };
  const [gridData, setGridData] = useState([]);
  const [layerData, setLayerData] = useState({
    isShowCount: false,
    isFilterApply: false,
    isOnlyTitle: false,
  });
  const [projectId, setProjectId] = useState("");
  const [rowLandParcelList, setRowLandParcelList] = useState([]);
  const fileExtensions = ["png", "geojson", "kml", "jpeg"];
  const [selectedExtension, setSelectedExtension] = useState("Select");
  const [guid, setGuid] = useState("");
  const [ProjectLayers, setProjectLayers] = useState([]);
  const [speciesData, SetSpeciesData] = useState([]);
  const [parentLayersData, setParentLayersData] = useState([]);
  const [uploadfile, setUploadfile] = useState([]);
  const [fileControl, setFileControl] = useState(null);
  const [fileNameControl, setFileNameControl] = useState("");
  const [showCount, setShowCount] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [LayerId, setLayerId] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const fileInputRef = useRef(null);
  const [layerNamedrpdwn, setLayerNamedrpdwn] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const [showNewLayerForm, setShowNewLayerForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [createSpecies, setCreateSpecies] = useState([]);
  const [isAddGridDisabled, setIsAddGridDisabled] = useState(false);
  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [errorMapbound, setErrorMapbound] = useState("");
  const [sampleFileData, setSampleFiledata] = useState("");
  const [commonMastersList, setCommonMastersList] = useState([]);
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };
  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    if (!layerData?.layerName) {
      ToastNotification.ShowError("Layer name is required.");
      isValid = false;
    }
    if (fileNameControl === "") {
      ToastNotification.ShowError("File is required.");
      isValid = false;
    }
    // if (layerData?.layerType === "LayerMarker") {
    //   if (!layerData?.speciesName) {
    //     ToastNotification.ShowError("Select Species Name.");
    //     isValid = false;
    //   }
    // }
    if (layerData?.fileType === "png") {
      if (
        layerData?.mapBounds === "" ||
        layerData?.mapBounds === null ||
        layerData?.mapBounds === undefined
      ) {
        ToastNotification.ShowError(
          "Enter map bounds value with correct format."
        );
        isValid = false;
      }
    }
    if (layerData?.sequence) {
      const isDuplicate = ProjectLayers.some(
        (item) =>
          Number(item?.sequence) === Number(layerData?.sequence) &&
          item?.layerGuid !== layerData.layerGuid
      );
      if (isDuplicate) {
        ToastNotification.ShowError("Sequence value is already in use.");
        isValid = false;
      }
    } else {
      // Find the maximum sequence number in ProjectLayers
      const maxSequence = ProjectLayers.reduce((max, item) => {
        const sequence = item?.sequence ?? 0;
        return sequence > max ? sequence : max;
      }, 0);

      // Assign the next sequence number
      layerData.sequence = maxSequence + 1;
    }
    return isValid;
  };

  const clearFile = () => {
    fileInputRef.current.value = "";
    setFileControl(null);
    setFileNameControl("");
    setFileSelected(false);
    setUploadfile([]);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files;
    const array = [];
    if (selectedFile && selectedFile?.[0]?.name) {
      const fileName = selectedFile?.[0]?.name;
      const fileExtension = fileName?.split(".").pop().toLowerCase();
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes

      if (layerData.fileType === fileExtension) {
        if (selectedFile?.[0]?.size > maxSize) {
          setError("File size exceeds 50MB limit.");
          clearFile();
        } else {
          setError("");
          setFileControl(selectedFile);
          setFileNameControl(selectedFile?.[0]?.name);
          setFileSelected(true);
          array.length = 0;
          if (selectedFile.length > 0) {
            array.push(selectedFile?.[0]);
          }
          setUploadfile(array);
          handleLayerTypeActions(selectedFile);
        }
      } else {
        setError("Invalid file type. Allowed selected file type only");
        clearFile();
      }
    }
    // else {
    //   fileInputRef.current.value = fileNameControl;
    //   //   clearFile();
    // }
  };

  const handleLayerTypeActions = (selectedFile) => {
    if (layerData.layerType === "LayerMarker") {
      fetchTreeData(selectedFile);
    } else if (layerData.layerType === "polygon") {
      fetchBoundaryData(selectedFile);
    }
  };

  const fetchTreeData = async (selectedFile) => {
    const treeDataRes = await SaveTreeData(
      projectId,
      selectedFile,
      layerData?.sampleFile
    );
    if (JSON.parse(treeDataRes?.success) === false) {
      setError(JSON.parse(treeDataRes?.message));
      clearFile();
    } else {
      setError("");
      updateLayerData(treeDataRes);
    }
  };
  const fetchBoundaryData = async (selectedFile) => {
    const boundarResp = await SaveProjectBoundaryValidation(
      projectId,
      selectedFile,
      layerData?.sampleFile
    );
    if (JSON.parse(boundarResp?.success) === false) {
      setError(JSON.parse(boundarResp?.message));
      clearFile();
    } else {
      setError("");
      updateLayerData(boundarResp);
    }
  };

  const updateLayerData = (responseData) => {
    setLayerData((prevData) => ({
      ...prevData,
      speciesName: responseData?.speciesNameList || prevData.speciesName,
    }));
  };

  const GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCommonMastersList(data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  useEffect(() => {
    GetCommonMasters();
  }, []);

  useEffect(() => {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    setProjectId(Id);
  }, []);

  useEffect(() => {
    const randomGuid = uuidv4();
    setGuid(randomGuid);
  }, []);

  useEffect(() => {
    // GetAllSpeciesData();
    GetLayerMaster();
  }, [projectId, editMode]);

  const GetLayerMaster = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetLayerMaster`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        if (responseData.length > 0) {
          setLayerNamedrpdwn(responseData);
          const fetchData = async () => {
            const projectLayerRes = await getLayersData(projectId);
            if (!editMode) {
              filterLayerDrpdwn(projectLayerRes?.data, responseData);
            }
          };
          if (projectId) {
            fetchData();
          }
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const filterLayerDrpdwn = (projectLayerRes, layerNameMst) => {
    const layerNameFilter = layerNameMst?.filter(
      (dropItem) =>
        !projectLayerRes.some(
          (gridItem) => dropItem.layerName === gridItem.layerName
        )
    );
    setLayerNamedrpdwn(layerNameFilter);
  };

  const handleMapBounds = (mapBounds) => {
    if (Array.isArray(mapBounds)) {
      return mapBounds;
    } else if (typeof mapBounds === "string") {
      return mapBounds?.split(",").map((coord) => parseFloat(coord));
    }
    return [];
  };

  const AddLayersDetails = async (e) => {
    if (fileSelected === true) {
      if (layerData.fileType === "png") {
        var stringMapBounds = layerData?.mapBounds;
        const mapBoundsArray = handleMapBounds(stringMapBounds);
        layerData.mapBounds = mapBoundsArray;
      }

      const fileResponse = await UploadLayerDocument(e);
      if (fileResponse?.success) {
        layerData.fileName = fileResponse?.UploadFileName;
        if (layerData.layerType === "LayerMarker") {
          await SaveAllTreeDetails(projectId, fileResponse?.UploadFileName);
        }
        if (layerData.layerType === "polygon") {
          await SaveProjectBoundary(projectId, fileResponse?.UploadFileName);
        }
      }
    } else {
      layerData.fileName = fileNameControl;
    }
    if (LayerId === "") {
      const randomGuid = uuidv4();
      layerData.layerGuid = randomGuid;
    } else {
      layerData.layerGuid = LayerId;
    }

    layerData.projectId = projectId;
    layerData.projectName = projectId;
    layerData.legend = rows;
    if (layerData?.fileType === "xlsx") {
      if (layerData.layerType === "polygon") {
        layerData.fileType = "xlsx";
      } else {
        layerData.fileType = "db";
      }
    }

    const isLayerNameExists = ProjectLayers.some(
      (item) =>
        item?.layerName === layerData?.layerName &&
        item.layerGuid !== layerData.layerGuid
    );
    if (layerData.layerGuid !== null && layerData.fileName !== null) {
      let data = layerData;
      let url = `${config.API_HOST_URL}/api/Ecosystem/AddLayerDetails`;
      fetch(url, {
        method: "POST",
        headers: {
          WebsiteGuid: props.websiteGUID,
          LanguageGuid: props.languageGUID,
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            clearAll();
            getLayerDetailList();
            GetLayerMaster();
            setIsAddGridDisabled(false);
            ToastNotification.ShowSuccess(resp.message);
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
  };

  const GetAllSpeciesData = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllSpeciesData`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        SetSpeciesData(responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const getLayerDetailList = async () => {
    const response = await getLayersData(projectId);
    setProjectLayers(response?.data);
    // setProjectLayers((prevLayers) => [...prevLayers, ...response?.data]);
  };

  useEffect(() => {
    const fetchData = async () => {
      getLayerDetailList();
      //let responseData = response?.data;
      // var parentLayerData = responseData
      //   .filter((x) => x.isOnlyTitle === true)
      //   .map((x) => ({
      //     layerName: x.layerName,
      //     layerGuid: x.layerGuid,
      //   }));
      // setParentLayersData(parentLayerData);
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const handleKeyDown = (event) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
    ];
    if (
      !allowedKeys.includes(event.key) &&
      !(event.key >= 0 && event.key <= 100)
    ) {
      event.preventDefault();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let newData = { ...layerData };
    newData[name] = value;
    setLayerData(newData);
    // Validate mapBounds value
    if (name === "mapBounds") {
      const validChars = /^[-\d.,]*$/;
      if (!validChars.test(value)) {
        setErrorMapbound(
          "Only numbers, commas, periods, and minus signs are allowed."
        );
        return;
      }
      const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      if (!regex.test(value)) {
        setErrorMapbound(
          "Invalid format. Please use the format: 15.250110379,74.077749917,15.257275915,74.082876954"
        );
      } else {
        setErrorMapbound("");
      }
    }
    setLayerData({
      ...layerData,
      [name]: value,
    });
  };
  const handleChangeRbtn = (e) => {
    const name = e.target.name;
    const value = e.target.value === "true";
    let newData = { ...layerData };
    newData[name] = value;
    setLayerData(newData);
  };
  const handleChangeForDrp = (selectedOption) => {
    setLayerData((prevData) => ({
      ...prevData,
      speciesName: selectedOption,
    }));
  };

  const handleCreate = (inputValue) => {
    const newOption = inputValue.toLowerCase().trim();
    var speciesExits = speciesData?.filter(
      (x) => x.speciesName?.toLowerCase() === newOption
    );
    if (newOption !== null && speciesExits.length !== 1) {
      AddNewTreeSpecies(newOption);
      setTimeout(() => {
        GetAllSpeciesData();
      }, 1000);
    }
  };

  const AddNewTreeSpecies = (newSpeciesData) => {
    const randomGuid = uuidv4();
    let SpeciesGuid = randomGuid;
    let SpeciesName = newSpeciesData;
    let data = {
      speciesGuid: SpeciesGuid,
      speciesName: SpeciesName,
    };
    let url = `${config.API_HOST_URL}/api/Ecosystem/AddNewTreeSpecies`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
        } else {
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  const Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1ImageUpload/:" + projectId, {
      state: { activeMenu: 11 },
    });
  };

  const Back = () => {
    navigate("/TreeTiesAddProjectForms1Cos/:" + projectId, {
      state: { activeMenu: 8 },
    });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({});

  const AddToGrid123 = (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      setIsAddGridDisabled(true);
      setSaveBtnDisabled(false);
      // if (LayerId === null || LayerId === "") {
      //   const isDuplicate = ProjectLayers.some(
      //     (item) => item.layerName === layerData?.layerName
      //   );
      //   if (!isDuplicate) {
      //     let newItem = {
      //       layerName: layerData?.layerName,
      //       layerType: layerData?.layerType,
      //       fileType: layerData?.fileType,
      //       isOnlyTitle: layerData?.isOnlyTitle,
      //       layerInfo: layerData?.layerInfo,
      //       sequence: layerData?.sequence,
      //     };
      //     //ProjectLayers.push(newItem);
      //     // const extractedList = ProjectLayers.map((item) => ({
      //     //   layerName: item.layerName,
      //     //   layerType: item.layerType,
      //     //   fileType: item.fileType,
      //     //   isOnlyTitle: item.isOnlyTitle,
      //     //   layerInfo: item?.layerInfo,
      //     //   sequence: item?.sequence,
      //     // }));
      //     // updateProjectLayers(extractedList);
      //     //ToastNotification.ShowSuccess("Layer added in grid, Please save");
      //   }
      // } else {
      //   const isDuplicate = ProjectLayers.some(
      //     (item) =>
      //       item.layerName === layerData?.layerName &&
      //       item.layerGuid !== LayerId
      //   );
      //   if (!isDuplicate) {
      //     const newState = ProjectLayers.map((obj) => {
      //       if (obj.layerGuid === LayerId) {
      //         return {
      //           ...obj,
      //           layerName: layerData?.layerName,
      //           layerType: layerData?.layerType,
      //           fileType: layerData?.fileType,
      //           isOnlyTitle: layerData?.isOnlyTitle,
      //           layerInfo: layerData?.layerInfo,
      //           sequence: layerData?.sequence,
      //         };
      //       }
      //       return obj;
      //     });
      //     setProjectLayers(newState);
      //   }
      //   // ToastNotification.ShowSuccess("Layer updated in Grid, Please save");
      // }
      AddLayersDetails();
    }
  };

  const AddToGrid = () => {
    const newRow = {
      WaterPH: inputValues.WaterPH,
      Nitrate: inputValues.Nitrate,
      Electriccondcutivitywater: inputValues.Electriccondcutivitywater,
      Rainfall: inputValues.Rainfall,
      DissolvedOxygen: inputValues.dissolvedoxygen,
      MoistureLevel: inputValues.MoistureLevel,
      Ecoregion: inputValues.Ecoregion,
      Toxicity: inputValues.Toxicity,
    };
    setGridData((prevData) => [...prevData, newRow]);
    setInputValues({
      WaterPH: "",
      Nitrate: "",
      Electriccondcutivitywater: "",
      Rainfall: "",
      DissolvedOxygen: "",
      MoistureLevel: "",
      Ecoregion: "",
      Toxicity: "",
    });
  };

  const clearAll = () => {
    setLayerData({
      isShowCount: false,
      isFilterApply: false,
      isOnlyTitle: false,
      layerName: "",
      layerType: "",
      sampleFile: "",
      fileType: "",
      layerInfo: "",
      sequence: "",
    });
    clearFile();
    setRows([]);
    setEditMode(false);
  };

  const updateProjectLayers = (newData) => {
    setProjectLayers(newData);
  };

  const SaveProjectBoundaryValidation = async (
    projectId,
    selectedFile,
    sampleFile
  ) => {
    const originalPath = sampleFile;
    const newSamplePath = originalPath.startsWith("/")
      ? originalPath.slice(1)
      : originalPath;
    let file = selectedFile;
    const array = [];
    array.length = 0;
    if (selectedFile.length > 0) {
      array.push(selectedFile[0]);
    }
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("formFiles", element);
      formData.append("projectId", projectId);
      formData.append("sampleFilePath", newSamplePath);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectBoundaryValidation/`;

    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };
  const SaveProjectBoundary = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectBoundary/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveAllTreeDetails = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveAllTreeDetails/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "Get",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveTreeData = async (projectId, selectedFile, sampleFile) => {
    const originalPath = sampleFile;
    const newSamplePath = originalPath?.startsWith("/")
      ? originalPath?.slice(1)
      : originalPath;
    let file = selectedFile;
    const array = [];
    array.length = 0;
    if (selectedFile.length > 0) {
      array.push(selectedFile[0]);
    }
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("formFiles", element);
      formData.append("projectId", projectId);
      formData.append("sampleFilePath", newSamplePath);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveTreeData`;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const UploadLayerDocument = async (e) => {
    let file = uploadfile;
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("FormFiles", element);
    }

    let uri =
      `${config.API_HOST_URL}/api/ProjectTreeTypes/UploadDocument/` + projectId;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const setFile = async (e) => {
    let filesArr = e.target.files;
    const array = [];
    // Clear the array if there's already a file
    if (filesArr.length > 0) {
      array.push(filesArr[0]);
    }
    setUploadfile(array);
  };

  // const setFile = async (e) => {
  //   let filesArr = e.target.files;
  //   const array = [...uploadfile];
  //   array.push(filesArr);
  //   setUploadfile(array);
  //   const filesize = array.length;
  //   if (filesize === 1) {
  //     for (let i = 0; i < filesArr.length; ++i) {
  //       const myFilesList = [...uploadfile];
  //       myFilesList.push(filesArr[i]);
  //       setUploadfile(myFilesList);
  //     }
  //   } else {
  //     if (e.target.id === "uploadjsonfile") {
  //       const index = array.indexOf(e.target.value, 1);
  //       array.splice(index, 1);
  //       setUploadfile(array);
  //       // ToastNotification.ShowWarning("Select only one file");
  //     }
  //   }
  //   // e.target.value = null;
  //   //let datares = await UploadLayerDocument();
  // };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      legendId: rows.length,
      name: "",
      colorCode: "",
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (e, idToRemove) => {
    e.preventDefault();
    const updatedRows = rows.filter((row) => row.legendId !== idToRemove);
    setRows(updatedRows);
  };

  const handleColorBoxClick = (e, id) => {
    e.preventDefault();
    setSelectedRowId(id);
  };

  const handleColorChange = (color) => {
    const updatedRows = rows?.map((row) => {
      if (row.legendId === selectedRowId) {
        return {
          ...row,
          colorCode: color.hex,
        };
      }
      return row;
    });
    setRows(updatedRows);
    setSelectedRowId(null);
  };
  const handleInputChange = (id, field, value) => {
    const updatedRows = rows?.map((row) => {
      if (row.legendId === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const layerNameHandleChange = (e) => {
    setIsAddGridDisabled(false);
    let layerFilter = layerNamedrpdwn.filter(
      (x) => x.layerName === e.target.value
    );
    setLayerData({
      layerName: e.target.value,
      fileType: layerFilter[0]?.fileType,
      layerType: layerFilter[0]?.layerType,
      sampleFile: layerFilter[0]?.sampleFile,
      isShowCount: layerFilter[0]?.showCount,
      isFilterApply: layerFilter[0]?.isFilterApply,
      layerInfo: layerFilter[0]?.layerInfo,
      // sequence: layerFilter[0]?.sequence,
    });
    setShowCount(layerFilter[0]?.showCount);
    setFileControl(null);
    setFileNameControl("");
    setFileSelected(false);
    setUploadfile([]);
  };

  const downloadSampleFile = (e) => {
    e.preventDefault();
    if (!layerData.layerName) {
      ToastNotification.ShowError("Please Select Layer Name");
    } else {
      if (layerData.sampleFile === null || layerData.sampleFile === undefined) {
        layerData.sampleFile = sampleFileData;
      }
      let FileName = layerData?.layerName + "." + layerData?.fileType;
      let url = config.Amazon_WebService_CDNUrl + layerData?.sampleFile;
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = FileName;
          a.click();
        });
      });
    }
  };

  const deleteLayer = (layerId, i) => {
    if (layerId !== null && layerId !== undefined) {
      let url =
        `${config.API_HOST_URL}/api/Ecosystem/DeleteLayerDetails/` +
        layerId +
        "/" +
        projectId;
      fetch(url, {
        method: "Post",
        headers: {
          WebsiteGuid: props.websiteGUID,
          LanguageGuid: props.languageGUID,
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            const fetchDataRefresh = async () => {
              clearAll();
              getLayerDetailList();
              GetLayerMaster();
            };
            fetchDataRefresh();
            ToastNotification.ShowSuccess(resp.message);
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    } else {
      setProjectLayers((prevList) =>
        prevList.filter((x) => x.layerName !== ProjectLayers[i].layerName)
      );
      const fetchDataRefresh = async () => {
        clearAll();
        getLayerDetailList();
        GetLayerMaster();
      };
      fetchDataRefresh();
      ToastNotification.ShowSuccess("Layer Detail Deleted Successfully");
    }
  };

  const editLayer = (i) => {
    setEditMode(true);
    const sampFile =
      layerNamedrpdwn.find((x) => x.layerName === ProjectLayers[i].layerName)
        ?.sampleFile ?? null; // or some other default value
    setSampleFiledata(sampFile);
    setLayerData({ sampleFile: sampFile });

    const url = getFileFromS3Path(
      ProjectLayers[i]?.projectId,
      ProjectLayers[i]?.fileName
    );
    setLayerId(ProjectLayers[i]?.layerGuid);
    if (ProjectLayers[i]?.fileType === "db") {
      ProjectLayers[i].fileType = "xlsx";
    }
    setLayerData(ProjectLayers[i]);
    setRows(ProjectLayers[i].legend);
    setFileControl(url);
    setFileNameControl(ProjectLayers[i]?.fileName);

    const selectedSpecies = ProjectLayers[i]?.speciesName?.map((species) => ({
      value: species?.speciesGuid,
      label: species?.speciesName,
    }));
    setLayerData({
      ...ProjectLayers[i],
      speciesName: selectedSpecies,
    });
  };

  const getFileFromS3Path = (ProjectId, FileName) => {
    if (ProjectId !== null && FileName !== null) {
      let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/${ProjectId}/${FileName}`;
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          const file = new File([blob], FileName, { type: blob.type });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          fileInputRef.current.files = dataTransfer.files;
          return fileInputRef.current.files;
        });
      });
    }
  };

  const SaveContinue = () => {
    Nextpage();
  };

  return (
    <div className="treeties-addproject-forms1-loc ecosyscom_cont ecosyscomlayers_cont">
      {/* <Header/> */}
      <div className="main-content-area6">
        {/* <LeftNavbar/>  */}
        <div className="middle-content-area6">
          <div className="container-18 ecoprosumry_cont">
            <ProjectSummary />
          </div>
          <div className="ecosys_middlecont exoloctab_cont">
            <LeftTabMenuContainer
              activeMenu={props.location.state?.activeMenu}
            />
            <div className="ecosys_rightcont addproformloc_cont">
              <form>
                <div className="container11 addlayesrfrom_cont addwaterparam_cont">
                  {/* Landparcel Start */}
                  <div className="land-parcels-container1">
                    <div className="land-parecel-content-area1">
                      <div className="close7">close</div>
                      <div className="left-content1">
                        <div className="hero-title13">
                          <b className="logo-a6">Add Water Parameter</b>
                        </div>
                        <div className="container-26">
                          <div className="column-118">
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Nitrate (ppm)
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Nitrate"
                                rules={{
                                  required: "Please Enter Nitrate (ppm)",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="Nitrate"
                                      placeholder="Nitrate (ppm)"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          Nitrate: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>

                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Electric condcutivity water
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Electriccondcutivitywater"
                                rules={{
                                  required:
                                    "Please Enter Water Electric Condcutivity",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="Electriccondcutivitywater"
                                      placeholder="Electriccondcutivitywater"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          Electriccondcutivitywater:
                                            e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>

                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Rainfall
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Rainfall"
                                rules={{
                                  required: "Please Enter Rainfall",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="Rainfall"
                                      placeholder="Rainfall"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          Rainfall: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>

                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Dissolved Oxygen
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="dissolvedoxygen"
                                rules={{
                                  required: "Please Enter Dissolved Oxygen",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="dissolvedoxygen"
                                      placeholder="Dissolved Oxygen"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          dissolvedoxygen: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>

                          <div className="column-118">
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Water PH
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="WaterPH"
                                rules={{
                                  required: "Please Enter WaterPH",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="number"
                                      name="WaterPH"
                                      placeholder="Water PH"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          WaterPH: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Moisture Level
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Moisturelevel"
                                rules={{
                                  required: "Please Enter Moisture Level",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="Moisturelevel"
                                      placeholder="Moisture Level"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          MoistureLevel: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Select Ecoregion
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Ecoregion"
                                rules={{
                                  required: "Please Select Ecoregion",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <select
                                      id="Ecoregion"
                                      name="Ecoregion"
                                      className="dropdown-130"
                                      value={value}
                                      onChange={(event) => {
                                        onChange(event.target.value);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          Ecoregion: event.target.value,
                                        }));
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {commonMastersList
                                        .filter((x) => x.type === "Eco Region")
                                        .map((item) => (
                                          <option
                                            key={item.mainGuId}
                                            value={item.description}
                                          >
                                            {item.description}
                                          </option>
                                        ))}
                                    </select>

                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>

                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Toxicity
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name="Toxicity"
                                rules={{
                                  required: "Please Enter Toxicity",
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      type="text"
                                      name="Toxicity"
                                      placeholder="Toxicity"
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                        setInputValues((prevValues) => ({
                                          ...prevValues,
                                          Toxicity: e.target.value,
                                        }));
                                      }}
                                    />
                                    {error && (
                                      <div className="mandatory has-error">
                                        {error.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cta-add-more4 pb-3">
                    <div className="logo-a6">plus</div>
                    <div className="add-more4">
                      <a
                        className="addbtnstyle"
                        onClick={handleAddClick}
                        disabled={isAddGridDisabled}
                        style={{
                          pointerEvents: isAddGridDisabled ? "none" : "auto",
                        }}
                      >
                        Add
                      </a>
                    </div>
                  </div>
                </div>
              </form>

              <div className="uploadedimagestbl_wrap">
                <table className="uploadedimgtbl waterparamtbl_cont">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Water PH</th>
                      <th>Nitrate</th>
                      <th>Moisture Level</th>
                      <th>Electric condcutivity</th>
                      <th>Ecoregion</th>
                      <th>Rainfall</th>
                      <th>Toxicity</th>
                      <th>Dissolved Oxygen</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.WaterPH}</td>
                        <td>{row.Nitrate}</td>
                        <td>{row.MoistureLevel}</td>
                        <td>{row.Electriccondcutivitywater}</td>
                        <td>{row.Ecoregion}</td>
                        <td>{row.Rainfall}</td>
                        <td>{row.Toxicity}</td>
                        <td>{row.DissolvedOxygen}</td>

                        <td>
                          <div className="upldimgactionswrap">
                            <a>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <mask
                                  id="mask0_3269_1355"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="18"
                                  height="19"
                                >
                                  <rect
                                    y="0.810791"
                                    width="18"
                                    height="18"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_3269_1355)">
                                  <path
                                    d="M9.00113 12.4935C9.85101 12.4935 10.5728 12.1961 11.1666 11.6012C11.7603 11.0064 12.0572 10.284 12.0572 9.4341C12.0572 8.58423 11.7598 7.86242 11.1649 7.26867C10.57 6.67492 9.84763 6.37804 8.99776 6.37804C8.14788 6.37804 7.42607 6.67548 6.83232 7.27035C6.23857 7.86523 5.9417 8.5876 5.9417 9.43748C5.9417 10.2874 6.23913 11.0092 6.83401 11.6029C7.42888 12.1967 8.15126 12.4935 9.00113 12.4935ZM8.99945 11.4608C8.43695 11.4608 7.95882 11.2639 7.56507 10.8702C7.17132 10.4764 6.97445 9.99829 6.97445 9.43579C6.97445 8.87329 7.17132 8.39517 7.56507 8.00142C7.95882 7.60767 8.43695 7.41079 8.99945 7.41079C9.56195 7.41079 10.0401 7.60767 10.4338 8.00142C10.8276 8.39517 11.0244 8.87329 11.0244 9.43579C11.0244 9.99829 10.8276 10.4764 10.4338 10.8702C10.0401 11.2639 9.56195 11.4608 8.99945 11.4608ZM9.00038 14.6858C7.27576 14.6858 5.70432 14.21 4.28607 13.2585C2.86782 12.3072 1.82357 11.0329 1.15332 9.43579C1.82357 7.83867 2.86745 6.56442 4.28495 5.61304C5.70257 4.66154 7.27376 4.18579 8.99851 4.18579C10.7231 4.18579 12.2946 4.66154 13.7128 5.61304C15.1311 6.56442 16.1753 7.83867 16.8456 9.43579C16.1753 11.0329 15.1314 12.3072 13.7139 13.2585C12.2963 14.21 10.7251 14.6858 9.00038 14.6858ZM8.99945 13.5608C10.4119 13.5608 11.7088 13.1889 12.8901 12.4452C14.0713 11.7014 14.9744 10.6983 15.5994 9.43579C14.9744 8.17329 14.0713 7.17017 12.8901 6.42642C11.7088 5.68267 10.4119 5.31079 8.99945 5.31079C7.58695 5.31079 6.29007 5.68267 5.10882 6.42642C3.92757 7.17017 3.02445 8.17329 2.39945 9.43579C3.02445 10.6983 3.92757 11.7014 5.10882 12.4452C6.29007 13.1889 7.58695 13.5608 8.99945 13.5608Z"
                                    fill="#405660"
                                  />
                                </g>
                              </svg>
                            </a>
                            <a className="upldimg_deletebtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                              >
                                <mask
                                  id="mask0_3269_1382"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="19"
                                  height="19"
                                >
                                  <rect
                                    x="0.958008"
                                    y="0.839844"
                                    width="18"
                                    height="18"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_3269_1382)">
                                  <path
                                    d="M6.43882 16.2149C6.06482 16.2149 5.74532 16.0825 5.48032 15.8176C5.21545 15.5526 5.08301 15.2331 5.08301 14.8591V5.33989H4.33301V4.21489H7.70801V3.55151H12.208V4.21489H15.583V5.33989H14.833V14.8591C14.833 15.238 14.7018 15.5586 14.4393 15.8211C14.1768 16.0836 13.8561 16.2149 13.4772 16.2149H6.43882ZM13.708 5.33989H6.20801V14.8591C6.20801 14.9265 6.22963 14.9818 6.27288 15.025C6.31613 15.0683 6.37145 15.0899 6.43882 15.0899H13.4772C13.5349 15.0899 13.5878 15.0658 13.6358 15.0177C13.6839 14.9697 13.708 14.9168 13.708 14.8591V5.33989ZM8.01101 13.5899H9.13582V6.83989H8.01101V13.5899ZM10.7802 13.5899H11.905V6.83989H10.7802V13.5899Z"
                                    fill="#405660"
                                  />
                                </g>
                              </svg>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Land parcel grid end    */}
              <div className="container11"></div>
              <div className="action-buttons5 pt-4">
                <button className="cta11">
                  <div className="back5" onClick={Back}>
                    Back
                  </div>
                </button>
                <button className="cta12" disabled={isSaveBtnDisabled}>
                  <div
                    className="save-continue6"
                    // onClick={() => AddLayersDetails()}
                    onClick={(e) => SaveContinue(e)}
                  >{`Save & Continue`}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1WaterParameter);
