import { Link } from "@reach/router";
import React, { Component } from "react";
import { connect } from 'react-redux';
import { globalHistory } from "@reach/router"
import config from "../utils/config";
import { navigate } from "@reach/router";
class ScheduleMeeting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: globalHistory.location.pathname.split(":")[1]
        };
        this.mounted = true;
    }

    isCalendlyScheduledEvent = (e) => {
        return e.data.event &&
            e.data.event.indexOf('calendly') === 0 &&
            e.data.event === 'calendly.event_scheduled'
    }

    componentDidMount() {
        this.mounted = true;
        this.ValidateTheUser(this.state.userId);

        window.addEventListener('message', (e) => {
            if (this.isCalendlyScheduledEvent(e)) {
                let apiUri = `${config.API_HOST_URL}/api/login/SaveUserMettingDetail?userId=${this.state.userId}&payload=${JSON.stringify(e.data.payload)}`;
                fetch(apiUri, { method: 'PUT' }).then((resp) => console.log(resp.status));
            }
        })
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    ValidateTheUser = (Id) => {
        if (this.mounted) {
            let UserDetail;
            UserDetail = {
                UserId: Id
            }
            let uri = `${config.API_HOST_URL}/api/login/ValidateUserForScheudleMeeting`;
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    WebsiteGuid: this.props.websiteGUID,
                    LanguageGuid: this.props.languageGUID,
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (!data.success) {
                                navigate(`/PageNotFound`);
                            }
                        });
                    } else {
                        return response.json().then(data => {
                            navigate(`/PageNotFound`);
                        });
                    }

                })
                .catch(error => {
                    if (this.mounted) {
                        console.log(error);
                    }
                });
        }

    }

    render() {
        return (
            < div className="calendly-inline-widget"
                data-url="https://calendly.com/growgreenpw?background_color=#8fbb77&text_color=ffffff&text_opacity=1&primary_color=333333"
            >
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleMeeting);