import React, { Component } from 'react';
class Iframewithcombinedtreeandboundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IframeGuid: props.IframeBaseUrl + "?showSatellite=true&overlayGeojson=" + props.IframeGuid//props.IframeGuid && props.IframeGuid !== null && props.IframeBaseUrl && props.IframeBaseUrl !== null ? props.IframeBaseUrl + "?showSatellite=true&overlayGeojson=" + props.IframeGuid : "https://vimana.vimanalabs.com/share/7e30f9a6b1174b62abbee00841a65c8f?showSatellite=true&overlayGeojson=ewoidHlwZSI6ICJGZWF0dXJlQ29sbGVjdGlvbiIsCiJuYW1lIjogIi50bXBtNHpkaDEiLAoiY3JzIjogeyAidHlwZSI6ICJuYW1lIiwgInByb3BlcnRpZXMiOiB7ICJuYW1lIjogInVybjpvZ2M6ZGVmOmNyczpPR0M6MS4zOkNSUzg0IiB9IH0sCiJmZWF0dXJlcyI6IFsKeyAidHlwZSI6ICJGZWF0dXJlIiwgImlkIjogMCwgInByb3BlcnRpZXMiOiB7ICJzdHJva2UiOiAiI2ZmMDAwMCIsICJzdHJva2Utb3BhY2l0eSI6IDEsICJmaWxsLW9wYWNpdHkiOiAwIH0sICJnZW9tZXRyeSI6IHsgInR5cGUiOiAiUG9seWdvbiIsICJjb29yZGluYXRlcyI6IFsgWyBbIDc4LjAwNTAxNDg5OTk5MDMsIDE3Ljg4NTAzMTU3OTAwODk5OSBdLCBbIDc4LjAwNTI2NzIxMDk4NzU5NywgMTcuODg1MTM5NzQxMjc4IF0sIFsgNzguMDA1OTE0MzMzOTI1NDA0LCAxNy44ODQwNTA5Njg5NjYxIF0sIFsgNzguMDA1NjczMjcwNTc1MzAxLCAxNy44ODM4NzM3NjM0MTQzIF0sIFsgNzguMDA1MDE0ODk5OTkwMywgMTcuODg1MDMxNTc5MDA4OTk5IF0gXSBdIH0gfQpdCn0K",
        }
    };
    render() {
        return (
            <div>
                <iframe
                    src={this.state.IframeGuid}
                ></iframe>
            </div>
        )
    }
}
export default Iframewithcombinedtreeandboundary