import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { ChartDonut, ChartThemeColor, ChartThemeVariant, ChartLine, Chart, ChartArea, ChartAxis, ChartBar, ChartGroup, ChartStack, ChartVoronoiContainer, ChartLegendTooltip, createContainer } from '@patternfly/react-charts';
import "@patternfly/react-core/dist/styles/base.css";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { Button } from '@patternfly/react-core';
import Searchcomp from '../Common/Search/Search';
import config from "../../utils/config";
import { navigate } from "@reach/router";
import { duration } from "moment";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
class NGODashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AllProjectSubtitle: "",
            VerifiedProjectCounts: 0,
            UnverifiedProjectCounts: 0,
            RejectedProjectCounts: 0,
            TreePlantedCounts: [],
            ListOfLegends: [],
            TotalTreePlantCount: 0,
            TotalTreePlantSubtitle: "",
            AmountRaisedCounts: [],
            TotalAmountRaised: 0,
            ProjectLocations: [],
            SelectedItem: "",
            PlannedGrowData: [],
            ActualGrowData: [],
            DropdownProjectList: [],
            SelectedProjectId: "",
            Co2NutrilizedChartData: [],
            SelectedProjectIdSGRate:""
        };
        this.mounted = true;
        this.isDataLoaded = false;
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleProjectDropdownChange = this.handleProjectDropdownChange.bind(this);
        this.handleProjectDropdownChange1 = this.handleProjectDropdownChange1.bind(this);

    }
    componentWillUnmount() {
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;

        if (this.props.userData !== null) {
            this.GetProjectDashboard();
            this.GetProjectLocations();
            this.GetProjectDropdown();
           // this.GetCo2NutrilizedChart();
        }

    }


    GetConvertedInr = (value) => () => {
        var val = Math.abs(value)
        if (val >= 1000) {
            val = (val / 1000).toFixed(2) + ' k';
        } else if (val >= 10000) {
            val = (val / 10000).toFixed(1) + ' m';
        }
        else if (val >= 100000) {
            val = (val / 100000).toFixed(1) + ' b';
        }
        return val;
    }

    GetProjectDashboard = () => {
        if (this.mounted && this.props.userData != null) {
            let UserDetail = {
                UserId: this.props.userData.userId,
            }
            let uri = `${config.API_HOST_URL}/api/NgoDashboard/GetProjectDashboardCounts`;
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    WebsiteGuid: this.props.websiteGUID,
                    LanguageGuid: this.props.languageGUID,
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (this.mounted) {
                                let responseData = data;
                                let treePlantedCounts = JSON.parse(data.treePlantedCounts);
                                let amountRaisedCounts = JSON.parse(data.amountRaisedCounts);
                                let ListOfLegends = [];
                                let TotalTreePlantCount = 0;
                                treePlantedCounts.forEach(element => {
                                    ListOfLegends.push({ name: element.x + " :" + element.y });
                                    TotalTreePlantCount += element.y
                                });
                                let TotalAmountRaised = 0;
                                amountRaisedCounts.forEach(element => {
                                    TotalAmountRaised += element.y
                                });
                                let TotalTreePlantSubtitle = "";
                                if (TotalTreePlantCount > 1000 && TotalTreePlantCount < 10000) {
                                    TotalTreePlantSubtitle = "k";
                                }
                                else if (TotalTreePlantCount > 10001 && TotalTreePlantCount > 100000) {
                                    TotalTreePlantSubtitle = "m";
                                }
                                let AllProjectSubtitle = "";
                                if (TotalTreePlantCount > 1000 && TotalTreePlantCount < 10000) {
                                    AllProjectSubtitle = "k";
                                }
                                else if (TotalTreePlantCount > 10001 && TotalTreePlantCount > 100000) {
                                    AllProjectSubtitle = "m";
                                }
                                if (TotalAmountRaised >= 10000000) {
                                    TotalAmountRaised = (TotalAmountRaised / 10000000).toFixed(2) + ' Cr';
                                } else if (TotalAmountRaised >= 100000) {
                                    TotalAmountRaised = (TotalAmountRaised / 100000).toFixed(1) + ' Lac';
                                }
                                this.setState({
                                    All: responseData.All,
                                    AllProjectSubtitle: AllProjectSubtitle,
                                    VerifiedProjectCounts: responseData.Verified,
                                    UnverifiedProjectCounts: responseData.Unverified,
                                    RejectedProjectCounts: responseData.Rejected,
                                    LastUpdatedDateTime: response.LastUpdatedDate,
                                    TreePlantedCounts: treePlantedCounts,
                                    ListOfLegends: ListOfLegends,
                                    TotalTreePlantCount: TotalTreePlantCount,
                                    TotalTreePlantSubtitle: TotalTreePlantSubtitle,
                                    AmountRaisedCounts: amountRaisedCounts,
                                    TotalAmountRaised: TotalAmountRaised
                                });
                            } else {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors
                                    });
                                }
                            }
                        });
                    } else {
                        return response.json().then(data => {
                            let errors = {};
                            errors["message"] = data.message;
                            if (this.mounted) {
                                this.setState({
                                    errors: errors
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    if (this.mounted) {
                        console.log(error);
                        this.setState({
                            errorMsg: error,
                            IsLoader: false
                        })
                    }
                });
        }
    }

    MoveToProjectPage = () => {
        navigate("projects");
    };
    // GetCalcuationCount = (param) => {
    //     let podClass = ''
    //     switch (param) {
    //         case param > 1000 && param < 99999:
    //             podClass = param.substring(1, 1) +'K'
    //             break;
    //         case param > 100000 && param < 999999:
    //             podClass = 'M'
    //             break;
    //         case param > 1000000:
    //             podClass = 'B'
    //             break;
    //     }
    //     return podClass
    // }

    handleStateChange = (e) => {
        this.setState({ SelectedItem: e.target.value }, () => this.GetSurvivalGrowth());
    };

    GetProjectLocations = () => {
        if (this.mounted) {

            let uri = `${config.API_HOST_URL}/api/projects/GetProjectLocations`;
            let params = {
                UserId: this.props.userData.userId,
                RoleId: this.props.userData.roleId
            }
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (this.mounted) {
                                let responseData = JSON.parse(data.LocationList);
                                this.setState({
                                    ProjectLocations: responseData,
                                    SelectedItem: responseData[0].Location
                                }, () => this.GetSurvivalGrowth());
                            }

                        });
                    } else {
                        return response.json().then(data => {
                            if (this.mounted) {

                            }
                        });
                    }
                })
                .catch(error => {
                });
        }
    }

    GetSurvivalGrowth = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/NgoDashboard/GetSurvivalGrowthChart`;
            let UserDetail = {
                UserId: this.props.userData.userId,
                RoleId: this.props.userData.roleId,
                // Location: this.state.SelectedItem
                ProjectId : this.state.SelectedProjectIdSGRate
            }
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    return response.json().then(data => {
                        if (this.mounted) {
                            let responseData = JSON.parse(data.SurvivalGrowth);
                            if (responseData.length > 0) {
                                this.setState({
                                    SurvivalGrowth: responseData
                                });
                            } else {
                                this.setState({
                                    SurvivalGrowth: [
                                        { x: '2017', y: 0 },
                                        { x: '2018', y: 0 },
                                        { x: '2019', y: 0 },
                                        { x: '2020', y: 0 },
                                        { x: '2021', y: 0 }
                                    ]
                                });
                            }
                        }
                    });

                })
                .catch(error => {
                    if (this.mounted) {
                        this.setState({
                            SurvivalGrowth: []
                        });
                    }
                });
        }
    }

    handleProjectDropdownChange = (e) => {
        this.setState({ SelectedProjectId: e.target.value }, () => this.GetPlannedVsActualTressGrow());
    };
    handleProjectDropdownChange1 = (e) => {
        this.setState({ SelectedProjectIdSGRate: e.target.value }, () => this.GetSurvivalGrowth());
    };
    GetProjectDropdown = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/projects/GetProjectDropdown`;
            let params = {
                UserId: this.props.userData.userId,
                RoleId: this.props.userData.roleId
            }
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (this.mounted) {
                                let responseData = JSON.parse(data.ProjectList);
                                this.setState({
                                    DropdownProjectList: responseData
                                }, () => this.GetPlannedVsActualTressGrow());
                            }

                        });
                    } else {
                        return response.json().then(data => {
                            if (this.mounted) {

                            }
                        });
                    }
                })
                .catch(error => {
                });
        }
    }

    GetPlannedVsActualTressGrow = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/NgoDashboard/PlannedVsActualTressGrow`;
            let UserDetail = {
                UserId: this.props.userData.userId,
                RoleId: this.props.userData.roleId,
                ProjectId: this.state.SelectedProjectId == "" ? "All Projects" : this.state.SelectedProjectId
            }
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    return response.json().then(data => {
                        if (this.mounted) {
                            if (data) {
                                let PlannedBarChartList = JSON.parse(data.PlannedBarChartList);
                                let ActualBarChartList = JSON.parse(data.ActualBarChartList);
                                this.setState({
                                    PlannedGrowData: PlannedBarChartList,
                                    ActualGrowData: ActualBarChartList,
                                });
                            } else {
                                this.setState({

                                });
                            }
                        }
                    });

                })
                .catch(error => {
                    if (this.mounted) {
                        this.setState({
                            SurvivalGrowth: []
                        });
                    }
                });
        }
    }

    GetCo2NutrilizedChart = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/NgoDashboard/GetCo2NutrilizedChart`;
            let params = {
                UserId: this.props.userData.userId,
                RoleId: this.props.userData.roleId,
            }
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    return response.json().then(data => {
                        if (this.mounted) {
                            let responseData = JSON.parse(data.Co2NutrilizedData);
                            this.setState({
                                Co2NutrilizedChartData: responseData
                            });
                            this.GetCo2NutrilizedamChartValue(responseData);
                        }

                    });
                })
                .catch(error => {
                });
        }
    }
    GetCo2NutrilizedamChartValue = (chartdata) => {
        let root = am5.Root.new("CO2neutralizedchartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();
        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                // panX: false,
                // panY: false,
                // wheelX: "panX",
                // wheelY: "zoomY",
                height: 195,
            })
        );
        chart.getDateFormatter("MMM dd,yyyy");
        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",


        }));
        cursor.lineX.set("visible", false);
        function generateData(chartdata) {
            let date = new Date(chartdata[0].x);
            date.setHours(0, 0, 0, 0);
            let date1 = am5.time.add(date, "day", 1);
            return { date: date1.getTime(), value: chartdata[0].y };
        }
        function generateDatas(chartdata) {
            let data = [];
            var i = 0;
            for (i = 0; i < chartdata.length; ++i) {
                data.push(generateData([chartdata[i]]));
            }
            return data;
        }
        // Create axes
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                gridIntervals: [
                    { timeUnit: "year", count: 3 }
                ],
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );
        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12,
        });
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                max: 100,
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );
        yAxis.get("renderer").labels.template.setAll({ fontSize: 12 });
        yAxis.children.moveValue(am5.Label.new(root, { text: "CO2 IN KG", rotation: -90, y: am5.p50, centerX: am5.p50, fontSize: 12 }), 0);
        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            autoTextColor: false,
            getLabelFillFromSprite: true,
            labelText: "[/] [fontSize:12px]{valueX.formatDate()} \n    {valueY} kg[/]",
            height: 38,
            paddingTop: -10

        });
        tooltip.get("background").setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.8,

        });
        //Add series
        let series = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: tooltip,
            })

        );
        series.fills.template.setAll({ fillOpacity: 0.3, visible: true });
        let data = generateDatas(chartdata);
        series.data.setAll(data);
        // Make stuff animate on load
        series.appear(1000);
        chart.appear(1000, 100);
    }
    render() {
        const CursorVoronoiContainer = createContainer("voronoi", "cursor");
        const legendData = [{ childName: 'Survival Rate', name: 'Survival Rate' }, { childName: 'Growth Rate', name: 'Growth Rate' }];
        return (
            <>
                <div className="headbottom_cont">
                    <ul className="headbottom_content">
                        <li className="heading_cont">Dashboard</li>
                        {/* <li className="updatetxt_cont">Last updated 3 May, 2021 9:00:00 AM IST<i className="fa fa-repeat"></i></li> */}
                        <li className="search_container">
                            <Searchcomp />
                        </li>
                        {/* <li className="export_btn">
                            <Button>Export Report <i className="fa fa-plus"></i></Button>
                        </li> */}
                    </ul>
                </div>
                <div className="ngodashboard_cont">
                    <Container>
                        <Row className="row-1 rowmarginbot">
                            <Col lg="6">
                                <div className="card_box">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">
                                            Project Status
                                        </div>
                                        <a className="viewall_btn" onClick={this.MoveToProjectPage}>View All</a>
                                    </div>
                                    <div className="donutwrap">
                                        <ChartDonut
                                            ariaDesc="Average number of pets"
                                            ariaTitle={"Total Project : " + this.state.All}
                                            constrainToVisibleArea={true}
                                            data={[{ x: 'Active Projects', y: this.state.VerifiedProjectCounts }, { x: 'Inactive Projects ', y: this.state.UnverifiedProjectCounts }, { x: 'Rejected Projects ', y: this.state.RejectedProjectCounts }]}
                                            height={180}
                                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                                            legendData={[{ name: `No. of Active Projects: ${this.state.VerifiedProjectCounts}` }, { name: `No. of Inactive Projects : ${this.state.UnverifiedProjectCounts}` }, { name: `No. of Rejected Projects : ${this.state.RejectedProjectCounts}` }]}
                                            legendOrientation="vertical"
                                            legendRoundedCorners="10"
                                            legendPosition="left"
                                            responsive="true"
                                            padding={{
                                                bottom: 60, // Adjusted to accommodate subTitle
                                                left: 350,
                                                right: 40, // Adjusted to accommodate legend
                                                top: 10
                                            }}
                                            subTitle={this.state.AllProjectSubtitle}
                                            subTitlePosition="center"
                                            title={this.state.All}
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={500}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="card_box">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">
                                            Total Trees Planted
                                        </div>
                                        <a className="viewall_btn" onClick={this.MoveToProjectPage}>View All</a>
                                    </div>

                                    <div className="donutwrap">
                                        <ChartDonut
                                            ariaDesc="Average number of pets"
                                            ariaTitle={"Total Tree Planted : " + this.state.TotalTreePlantCount}
                                            constrainToVisibleArea={true}
                                            data={this.state.TreePlantedCounts} //[{ x: 'Ladakh', y: 55 }, { x: 'Mumbai', y: 5 }, { x: 'Telangana', y: 15 }, { x: 'Munnar', y: 25 }]
                                            height={180}
                                            labels={({ datum }) => `${datum.x}: ${datum.y}`}
                                            legendData={this.state.ListOfLegends}  //{[{ name: 'Ladakh: 55' }, { name: 'Mumbai: 5' }, { name: 'Telangana: 15' }, { name: 'Munnar: 25' }]}
                                            legendOrientation="vertical"
                                            legendRoundedCorners="10"
                                            legendPosition="left"
                                            padding={{
                                                bottom: 60, // Adjusted to accommodate subTitle
                                                left: 350,
                                                right: 20, // Adjusted to accommodate legend
                                                top: 10
                                            }}
                                            subTitle={this.state.TotalTreePlantSubtitle}
                                            subTitlePosition="center"
                                            title={this.state.TotalTreePlantCount}
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={500}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='row-2 rowmarginbot'>
                            <Col lg="4">
                                <div className="card_box survival_cont">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">Amount Raised</div>

                                    </div>

                                    <div className="donutwrap">
                                        <ChartDonut
                                            ariaDesc="Average number of pets"
                                            ariaTitle={"Total Amount Raised : " + this.state.TotalAmountRaised}
                                            constrainToVisibleArea={true}
                                            data={this.state.AmountRaisedCounts}//{[{ x: 'Ladakh', y: 55 }, { x: 'Mumbai', y: 5 }, { x: 'Telangana', y: 15 }, { x: 'Munnar', y: 25 }]}
                                            height={180}
                                            labels={({ datum }) => `${datum.x}: ${datum.y}%`}
                                            legendData={this.state.AmountRaisedCounts} //{[{ name: 'Ladakh: 55' }, { name: 'Mumbai: 5' }, { name: 'Telangana: 15' }, { name: 'Munnar: 25' }]}
                                            legendOrientation="vertical"
                                            legendPosition="left"
                                            padding={{
                                                bottom: 20, // Adjusted to accommodate subTitle
                                                left: 250,
                                                right: 20, // Adjusted to accommodate legend
                                                top: 10
                                            }}
                                            subTitlePosition="center"
                                            title={this.state.TotalAmountRaised}
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={380}
                                        />
                                    </div>

                                </div>
                            </Col>

                            {/* <Col lg="4">
                                <div className="card_box">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">
                                            Total CO2 neutralized
                                        </div>
                                    </div>
                                    <div className="donutwrap">
                                        <div id="CO2neutralizedchartdiv" style={{ width: "100%", height: "200px" }}>

                                        </div>
                                        <Chart
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}\n \n CO2 - ${datum.y} Kg`} constrainToVisibleArea />}
                                            minDomain={{ y: 0 }}
                                            maxDomain={{ y: 100 }}
                                            padding={{
                                                bottom: 40,
                                                left: 50,
                                                right: 30, // Adjusted to accommodate legend
                                                top: 20
                                            }}

                                        >
                                            <ChartAxis />
                                            <ChartAxis dependentAxis />
                                            <ChartGroup>
                                                <ChartArea
                                                    data={this.state.Co2NutrilizedChartData}
                                                    interpolation="monotoneX"
                                                />

                                            </ChartGroup>

                                        </Chart>
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col lg="4">
                                <div className="card_box">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">No. Of Customers</div>
                                        <ul className="filterlist">
                                            <li><a>Brands</a></li>
                                            <li><a>NGOs</a></li>
                                            <li><a>Consumers</a></li>
                                        </ul>
                                    </div>


                                    <div className="donutwrap">
                                        <ChartDonut
                                            ariaDesc="Average number of pets"
                                            ariaTitle="Donut chart example"
                                            constrainToVisibleArea={true}
                                            data={[{ x: 'Myntra', y: 60 }, { x: 'Swiggy', y: 4 }, { x: 'Flipkart', y: 15 }, { x: 'Amazon', y: 21 }]}
                                            height={180}
                                            labels={({ datum }) => `${datum.x}: ${datum.y}%`}
                                            legendData={[{ name: 'Myntra: 55' }, { name: 'Swiggy: 4' }, { name: 'Flipkart: 15' }, { name: 'Amazon: 21' }]}
                                            legendOrientation="vertical"
                                            legendPosition="left"
                                            padding={{
                                                bottom: 60, // Adjusted to accommodate subTitle
                                                left: 140,
                                                right: 20, // Adjusted to accommodate legend
                                                top: 10
                                            }}
                                            subTitlePosition="center"
                                            title="4"
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={290}
                                        />
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg="4">
                                <div className="card_box survival_cont">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">
                                            Survival & Growth Rate
                                        </div>
                                        <div className="card_dropdown">
                                            {/* <select className="form-control selectdropdown" value={this.state.SelectedItem} onChange={this.handleStateChange}>
                                               
                                                {
                                                    this.state.ProjectLocations.map(function (item) {
                                                        return <option value={item.Location} >{item.Location}</option>;
                                                    })
                                                }
                                            </select> */}
                                             <select className="form-control selectdropdown" value={this.state.SelectedProjectIdSGRate} onChange={this.handleProjectDropdownChange1}>
                                                <option value="All Projects">All Projects</option>
                                                {
                                                    this.state.DropdownProjectList.map(function (item) {
                                                        return <option value={item.ProjectId} >{item.ProjectName}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="donutwrap">
                                        <Chart
                                            ariaDesc="Average number of pets"
                                            ariaTitle="Line chart example"
                                            containerComponent={
                                                <CursorVoronoiContainer
                                                    cursorDimension="x"
                                                    labels={({ datum }) => `${datum.y}`}
                                                    labelComponent={<ChartLegendTooltip legendData={legendData} title={(datum) => datum.x} />}
                                                    mouseFollowTooltips
                                                    voronoiDimension="x"
                                                    voronoiPadding={10}
                                                />
                                            }
                                            legendData={legendData}
                                            legendPosition="bottom"
                                            height={250}
                                            maxDomain={{ y: 100 }}
                                            minDomain={{ y: 0 }}
                                            padding={{
                                                bottom: 75, // Adjusted to accommodate legend
                                                left: 20,
                                                right: 20,
                                                top: 20
                                            }}
                                            themeColor={ChartThemeColor.green}
                                            width={380}
                                        >
                                            <ChartAxis />
                                            <ChartAxis dependentAxis showGrid />
                                            <ChartGroup>
                                                <ChartLine
                                                    data={this.state.SurvivalGrowth}
                                                    name="Survival Rate"
                                                />
                                                {/* <ChartLine
                                                    data={[
                                                        { x: '2017', y: 50 },
                                                        { x: '2018', y: 45 },
                                                        { x: '2019', y: 58 },
                                                        { x: '2020', y: 70 },
                                                        { x: '2021', y: 90 }
                                                    ]}
                                                    name="Growth Rate"

                                                /> */}

                                            </ChartGroup>
                                        </Chart>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="card_box planned_cont">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">Planned vs Actual Tress Grown</div>
                                        <div className="card_dropdown">
                                            <select className="form-control selectdropdown" value={this.state.SelectedProjectId} onChange={this.handleProjectDropdownChange}>
                                                <option value="All Projects">All Projects</option>
                                                {
                                                    this.state.DropdownProjectList.map(function (item) {
                                                        return <option value={item.ProjectId} >{item.ProjectName}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="donutwrap">
                                        <Chart
                                            //ariaDesc="Average number of pets"
                                            ariaTitle="Planned vs Actual Trees Grown"
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
                                            domainPadding={{ x: [30, 25] }}
                                            legendData={[{ name: 'Planned' }, { name: 'Actual' }]}
                                            legendPosition="bottom"
                                            height={300}
                                            minDomain={{ y: 0 }}
                                            maxDomain={{ y: 2000 }}
                                            padding={{
                                                bottom: 75, // Adjusted to accommodate legend
                                                left: 50,
                                                right: 50,
                                                top: 50
                                            }}
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={500}
                                        >
                                            <ChartAxis />
                                            <ChartAxis dependentAxis showGrid />
                                            <ChartGroup offset={28}>
                                                <ChartBar barWidth={25} data={this.state.PlannedGrowData} />
                                                <ChartBar barWidth={25} data={this.state.ActualGrowData} />
                                            </ChartGroup>
                                        </Chart>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        {/* <Row className="row-3 rowmarginbot">
                            
                            <Col lg="4">
                                <div className="card_box survival_cont">
                                    <div className="cardboxinner_wrap">
                                        <div className="section_heading">Grow - Contribution</div>
                                        <div className="card_dropdown">
                                            <select className="form-control selectdropdown">
                                                <option value="select_dropdown_one">Growth Ladakh</option>
                                                <option value="select_dropdown_two">Growth Mumbai</option>
                                                <option value="select_dropdown_three">Growth Manali</option>
                                                <option value="select_dropdown_three">Growth Telangana</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="donutwrap">
                                        <Chart
                                            ariaDesc="Average number of pets"
                                            ariaTitle="Stack chart example"
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
                                            domainPadding={{ x: [30, 25] }}
                                            legendData={[{ name: 'Individuals' }, { name: 'Corporates' }, { name: 'Philanthropist' }, { name: 'Others' }]}
                                            legendPosition="bottom"
                                            height={275}
                                            padding={{
                                                bottom: 75, // Adjusted to accommodate legend
                                                left: 0,
                                                right: 20,
                                                top: 50
                                            }}
                                            themeColor={ChartThemeColor.multiOrdered}
                                            width={380}
                                            minDomain={{ y: 0 }}
                                            maxDomain={{ y: 80 }}
                                        >
                                            <ChartAxis />
                                            <ChartAxis dependentAxis />
                                            <ChartStack>
                                                <ChartBar barWidth={25} data={[{ name: 'Individuals', x: '2017', y: 38 }, { name: 'Individuals', x: '2018', y: 20 }, { name: 'Individuals', x: '2019', y: 40 }, { name: 'Individuals', x: '2020', y: 17 }, { name: 'Individuals', x: '2021', y: 38 }]} />
                                                <ChartBar barWidth={25} data={[{ name: 'Corporates', x: '2017', y: 7 }, { name: 'Corporates', x: '2018', y: 2 }, { name: 'Corporates', x: '2019', y: 2 }, { name: 'Corporates', x: '2020', y: 3 }, { name: 'Corporates', x: '2021', y: 2 }]} />
                                                <ChartBar barWidth={25} data={[{ name: 'Philanthropist', x: '2017', y: 15 }, { name: 'Philanthropist', x: '2018', y: 10 }, { name: 'Philanthropist', x: '2019', y: 18 }, { name: 'Philanthropist', x: '2020', y: 5 }, { name: 'Philanthropist', x: '2021', y: 15 }]} />
                                                <ChartBar barWidth={25} data={[{ name: 'Others', x: '2017', y: 10 }, { name: 'Others', x: '2018', y: 5 }, { name: 'Others', x: '2019', y: 10 }, { name: 'Others', x: '2020', y: 3 }, { name: 'Others', x: '2021', y: 10 }]} />
                                            </ChartStack>
                                        </Chart>
                                    </div>

                                </div>
                            </Col>
                            

                        </Row>
                        <Row className="row-4 rowmarginbot">
                            <Col lg="8">
                                <Container>
                                    <Row>
                                        <Col lg="6">
                                            <div className="card_box cdboxnotify_cont">
                                                <div className="cardboxinner_wrap">
                                                    <div className="section_heading">
                                                        Grow Alarms
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="card_box">
                                                <div className="section_heading">Top Tree Heroes</div>
                                                <ul className="toptreeheroes_list">
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>RS</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Rajveer Singh</span>
                                                            <span className="hrs_location">Mumbai</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>VP</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Varun Pillai</span>
                                                            <span className="hrs_location">Mumbai</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>AS</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Aditi Shah</span>
                                                            <span className="hrs_location">Delhi</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>KI</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Kartik Iyer</span>
                                                            <span className="hrs_location">Kochi</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>AS</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Arun Singh</span>
                                                            <span className="hrs_location">Mumbai</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span>VR</span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Vikram Rathod</span>
                                                            <span className="hrs_location">Jaipur</span>
                                                            <span className="hrs_treedonate">1.5k Trees donated</span>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col lg="6">
                                            <div className="card_box">
                                                <div className="section_heading">Top Trees Specimens - 10</div>
                                                <ul className="toptreeheroes_list">
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Mango</span>
                                                            <span className="hrs_location">12000trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.2 lakh raised</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Coconut</span>
                                                            <span className="hrs_location">1000 trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.1 lakh raised</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Banyan</span>
                                                            <span className="hrs_location">100 trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.1 lakh raised</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Pine</span>
                                                            <span className="hrs_location">200 trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.6 lakh raised</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Apple</span>
                                                            <span className="hrs_location">1000 trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.2 lakh raised</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="toptreeheroes_left">
                                                            <span></span>
                                                        </div>
                                                        <div className="toptreeheroes_right">
                                                            <span className="hrs_name">Papaya</span>
                                                            <span className="hrs_location">500 trees</span>
                                                            <span className="hrs_treedonate">Rs. 1.1 lakh raised</span>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col lg="4">
                                <div className="card_box homemap_cont">
                                    <div className="section_heading">Grow Reach</div>
                                    <div className="cardboxmap_cont">
                                        <SVGMap map={India} />
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                    </Container>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NGODashboard);