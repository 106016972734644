import React, { useEffect, useState } from "react";
import ModalTreeImg from "./mediaImages/actionmodal_tree.jpg";
import config from "../../../utils/config";
import TreeListingDefaultImg from "../../../assets/TreeActionModal_default.png";

export default function ProjectListActionModal(props) {
  const [activeTab, setActiveTab] = useState("Tree Image");
  const [treeData, setTreeData] = useState({});
  const [treeImage, setTreeImageData] = useState({});
  useEffect(() => {
    setTreeData(props?.eachTreeData);
    console.log("eachTreeData:", props?.eachTreeData);
  }, [props?.eachTreeData]);
  useEffect(() => {
    setTreeImageData(props?.speceiestreedata);
    console.log("speceiestreedata:", props?.speceiestreedata);
  }, [props?.speceiestreedata]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="row prosumcordtabscont_wrap">
      <div className="col-5">
        <div className="tab-buttons">
          <button
            className={activeTab === "Tree Image" ? "active" : ""}
            onClick={() => handleTabChange("Tree Image")}
          >
            Tree Image
          </button>
          <button
            className={activeTab === "Tree Details" ? "active" : ""}
            onClick={() => handleTabChange("Tree Details")}
          >
            Tree Details
          </button>
          {treeData?.source === "Ground Sampling" && (
            <button
              className={activeTab === "Observation Details" ? "active" : ""}
              onClick={() => handleTabChange("Observation Details")}
            >
              Observation Details
            </button>
          )}
        </div>
      </div>
      <div className="col-7">
        {activeTab === "Tree Image" && (
          <div className="tabs-content">
            <div className="actionmodal_imgcont">
              {treeData?.photos?.length > 0 ? (
                <img
                  className="actionmodal_tree"
                  src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${treeData?.projectID}/${treeData?.ec5_uuid}/${treeData?.photos?.[0]}`}
                  alt="ModalTreeImg"
                />
              ) : (
                <img
                  src={TreeListingDefaultImg}
                  alt="defaultimg_treelistimg"
                  className="defaultimg_treelist"
                />
                // <img
                //   className="actionmodal_tree"
                //   src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/AllTreeSpecies/${treeImage?.[0]?.imageName}`}
                //   alt="ModalTreeImg"
                // />
              )}
            </div>
          </div>
        )}
        {activeTab === "Tree Details" && (
          <div className="tabs-content">
            <div class="executivepartnerstrust_wrap">
              <div class="executivepatners_trust">
                <div class="exectveprtnetrst_right">
                  <div class="institutionaltabs_content">
                    <div class="institutetabs_item">
                      <span>
                        Plant ID <abbr>:&nbsp;</abbr>
                      </span>
                      <span>{treeData?.tree_id}</span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Specie Name<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData?.common_name ? treeData?.common_name : "-"}
                      </span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Height<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData?.tree_height ? treeData?.tree_height : "-"}
                      </span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Planting Date<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData?.planted_date ? treeData?.planted_date : "-"}
                      </span>
                    </div>
                    {/* <div class="institutetabs_item">
                <span>
                  Last Updated on<abbr>:&nbsp;</abbr>
                </span>
                <span>9 May, 2022 10:00 am IST</span>
              </div> */}
                    <div class="institutetabs_item">
                      <span>
                        Health Status<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData?.tree_health ? treeData?.tree_health : "-"}
                      </span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Girth GBH (cm)<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData?.tree_girth ? treeData?.tree_girth : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "Observation Details" && (
          <div className="tabs-content">
            <div class="executivepartnerstrust_wrap">
              <div class="executivepatners_trust">
                <div class="exectveprtnetrst_right">
                  <div class="institutionaltabs_content">
                    <div class="institutetabs_item">
                      <span>
                        Title <abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData.common_name ? treeData.common_name : "-"}{" "}
                        <br />
                        {treeData.lifeForm ? treeData.lifeForm : ""}
                      </span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Name Of Observer<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData.nameOfObserver
                          ? treeData.nameOfObserver
                          : "-"}
                      </span>
                    </div>
                    <div class="institutetabs_item">
                      <span>
                        Observation Notes<abbr>:&nbsp;</abbr>
                      </span>
                      <span>
                        {treeData.notesObservation
                          ? treeData.notesObservation
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
