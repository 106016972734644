import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { globalHistory } from "@reach/router";

// var id = this.props.id.replace(":", "");
// alert(id);

class TreeTiesAddProjectForms1Cos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [{ costType: "", cost: "", remark: "", costDetailsListId: "" }],
      projectId: "",
      CostDetailsId: "",
      TotalCost: 0,
      //CostDetailslist: [],
      // ProjectIndex: 1,
    };
    this.mounted = true;
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    // console.log("currentPath", currentPath);
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ projectId: Id });
    this.GetCostDetails(Id);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  GetCostDetails = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCostDetails`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "Post",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        //  this.setState({ CostDetailslist: responseData });
        console.log("responseData", responseData);
        console.log("GetCostDetails");
        this.setState({
          TotalCost: responseData.totalCost,
          // CostDetailsList: responseData.costDetailsList,
          projectId: responseData.projectId,
          CostDetailsId: responseData.costDetailsId,
          rowList: responseData.costDetailsList,
        });

        console.log("rowList", this.state.rowList);
        // this.setState({
        //   CostDetailslist: data,
        // });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  Save = () => {
    // debugger;
    let data = {
      TotalCost: this.state.TotalCost,
      CostDetailsList: this.state.rowList,
      projectId: this.state.projectId,
      CostDetailsId: this.state.CostDetailsId,
    };
    let url = `${config.API_HOST_URL}/api/Ecosystem/AddCostDetails`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
          //this.clearFields();
          ToastNotification.ShowSuccess(resp.message);
          this.Nextpage();
        } else {
          ToastNotification.ShowError(resp.message);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  add = () => {
    const lastRow = this.state.rowList[this.state.rowList.length - 1];
    if (!lastRow || !lastRow.costType || !lastRow.cost || !lastRow.remark) {
      ToastNotification.ShowError("Enter Cost Details");
    } else {
      this.setState({
        rowList: [
          ...this.state.rowList,
          { costType: "", cost: "", remark: "", costDetailsListId: "" },
        ],
      });
    }
  };

  remove = (i) => {
    let rowList = [...this.state.rowList];
    const cost = rowList[i].cost;
    if (cost !== null && !isNaN(parseFloat(cost))) {
      this.setState({
        TotalCost: this.state.TotalCost - parseFloat(cost),
      });
    }
    rowList.splice(i, 1);
    this.setState({ rowList: rowList });
  };
  // console.log(this.state.TotalCost - parseInt(rowList[i].cost));

  Back = () => {
    navigate("/TreeTiesAddProjectForms1Ris/:" + this.state.projectId, {
      state: { activeMenu: 8 },
    });
  };

  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Lay/:" + this.state.projectId, {
      state: { activeMenu: 10 },
    });
  };

  handleInput = (e, i) => {
    // debugger;
    const { name, value } = e.target;
    const onchangeVal = [...this.state.rowList];

    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (value === "" || alphanumericRegex.test(value)) {
      onchangeVal[i][name] = value;
      this.setState({ onchangeVal });

      // if (name === "cost") {
      //   let totalCost = 0;

      //   //recalculate
      //   this.state.rowList.forEach((element) => {
      //     if (element.cost) {
      //       totalCost = totalCost + parseFloat(element.cost);
      //     }
      //   });
      //   this.setState({ TotalCost: totalCost });
      // }
    }
  };

  handleInput1 = (e, i) => {
    // debugger;
    const { name, value } = e.target;
    const onchangeVal = [...this.state.rowList];

    //const regex = /^[0-9\b]+$/;
    const regex =
      /^(?:\d{1,10}(?:\.\d{1,4})?|(?:\d{0,7}(?:,\d{1,3})?(?:\.\d{1,4})?))$/;

    if (value === "" || regex.test(value)) {
      onchangeVal[i][name] = value;
      this.setState({ rowList: onchangeVal });

      if (name === "cost") {
        let totalCost = 0;

        //recalculate
        this.state.rowList.forEach((element) => {
          if (element.cost) {
            totalCost = totalCost + parseFloat(element.cost);
          }
        });
        this.setState({ TotalCost: totalCost });
      }
    }
  };

  render() {
    return (
      <div className="treeties-addproject-forms1-cos ecosyscom_cont">
        {/* <Header /> */}
        <div className="main-content-area1">
          {/* <LeftNavbar/> */}
          <div className="middle-content-area1">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont ecocostmid_cont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont">
                <div className="hero-title3">
                  <b className="project-summary3">Costs</b>
                </div>
                <div className="table-container pt-4">
                  {this.state.rowList.map((x, i) => {
                    return (
                      <div className="container3" key={i}>
                        <div className="column-content">
                          <div className="input-field-text2">
                            <div className="content-title-text21">
                              <div className="hero-title2">
                                <div className="project-summary2">
                                  Cost Type
                                </div>
                              </div>
                              {/* <select className="dropdown-13">
                                <option value="text">Select</option>
                                <option value="Lorem ipsum">Lorem ipsum</option>
                                <option value="Lorem ipsum">Lorem ipsum</option>
                                <option value="Lorem ipsum">Lorem ipsum</option>
                              </select> */}
                              <input
                                className="text-input-field-3"
                                name="costType"
                                value={x.costType}
                                type="text"
                                required
                                pattern="[a-zA-Z0-9]+"
                                onChange={(e) => this.handleInput(e, i)}
                                placeholder="Enter CostType"
                              />
                            </div>
                          </div>
                          <div className="input-field-text2">
                            <div className="content-title-text22">
                              <div className="hero-title2">
                                <div className="project-summary2">
                                  Cost (in Rs.)
                                </div>
                              </div>
                              <input
                                className="text-input-field-3"
                                name="cost"
                                value={x.cost}
                                type="number"
                                required
                                min="0"
                                onChange={(e) => this.handleInput1(e, i)}
                                placeholder="Enter Cost (in INR)"
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                          <div className="input-field-text2">
                            <div className="content-title-text22">
                              <div className="hero-title2">
                                <div className="project-summary2">Remarks</div>
                              </div>
                              <input
                                className="text-input-field-3"
                                name="remark"
                                value={x.remark}
                                type="text"
                                required
                                pattern="[a-zA-Z0-9]+"
                                onChange={(e) => this.handleInput(e, i)}
                                placeholder=" Enter Remark"
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.rowList.length !== 1 && (
                          // <div className="close" onClick={() => this.remove(i)}>
                          //   close
                          // </div>

                          <div className="costclosetabbtn">
                            <i
                              class="fa fa-remove"
                              onClick={() => this.remove(i)}
                            ></i>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {/* <div className="container3">
                          <div className="column-content">
                            <div className="column-13">
                              <div className="content-title-text21">
                                <div className="hero-title2">
                                  <div className="project-summary2">Cost Type</div>
                                </div>
                                <select className="dropdown-13">
                                  <option value="text">Select</option>
                                  <option value="Lorem ipsum">Lorem ipsum</option>
                                  <option value="Lorem ipsum">Lorem ipsum</option>
                                  <option value="Lorem ipsum">Lorem ipsum</option>
                                </select>
                              </div>
                              <div className="content-title-text22">
                                <div className="hero-title2">
                                  <div className="project-summary2">Total Cost</div>
                                </div>
                                <input className="text-input-field-3" type="text" />
                              </div>
                            </div>
                            <div className="input-field-text2">
                              <div className="content-title-text22">
                                <div className="hero-title2">
                                  <div className="project-summary2">Cost (in Rs.)</div>
                                </div>
                                <input className="text-input-field-3" type="text" />
                              </div>
                            </div>
                            <div className="input-field-text2">
                              <div className="content-title-text22">
                                <div className="hero-title2">
                                  <div className="project-summary2">Remarks</div>
                                </div>
                                <input className="text-input-field-3" type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="close">close</div>
                        </div> */}
                  {/* <div className="cta-add-more">
                    <div className="plus">plus</div>
                    <div className="add-more" onClick={this.add}>
                      Add more
                    </div>
                  </div> */}
                </div>
                <div className="cta-add-more pt-4 pb-4">
                  <div className="plus">plus</div>
                  <div className="add-more addbtnstyle" onClick={this.add}>
                    Add
                  </div>
                </div>
                <div className="content-title-text22 costtotalcont">
                  <div className="hero-title2">
                    <div className="project-summary2">Total Cost</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="TotalCost"
                    id="TotalCost"
                    value={this.state.TotalCost}
                    placeholder="Total Cost"
                  />
                </div>
                <div className="action-buttons pt-4">
                  <button className="cta1">
                    <div className="back" on onClick={this.Back}>
                      Back
                    </div>
                  </button>
                  <button className="cta2">
                    <div className="save-continue1" onClick={this.Save}>
                      Save & Continue
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar1">
            <button className="plus-circle1">plus-circle</button>
            <button className="notifications-bell1">notifications-bell</button>
            <button className="notifications-bell1">email</button>
          </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Cos);
