import React from "react";
import config from "../../../utils/config";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import { Modal, Button } from "@patternfly/react-core";
import growdashlogo from "../../../assets/growsmallogo.png";
import growmobileogo from "../../../assets/grow-logo.png";
import profileimg from "../../../assets/profile_img.jpg";

class FrontEndHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = {
      isModalOpen: false,
      IsShowTreeTiesLogo: "",
    };

    this.state = {
      initialStep: props.initialStep,
    };
    this.mounted = true;
    if (this.props.userData.roleId == 1) {
      this.handleSetting = () => navigate("/Settings");
    } else {
      this.handleSetting = () => navigate("");
    }
    if (this.props.userData.roleId == 2) {
      this.handleProfile = () => navigate("/Profile");
    } else {
      this.handleProfile = () => navigate("");
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.GetGlobalSettings("Header");
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleOutsideClick(e) {
    e.preventDefault();
  }

  AcountClickHandler = () => {
    document.getElementById("dropcontainer").classList.toggle("menu-hide");
    //document.querySelector(".dropdowncontaner").classList.toggle("menu-hide");
    let box = document.querySelector(".dropdown-toggle");
    // Detect all clicks on the document
    document.addEventListener("click", function (event) {
      // If user clicks inside the element, do nothing
      if (
        event.target.parentElement !== null &&
        event.target.parentElement !== undefined
      )
        if (event.target.parentElement.nextElementSibling) return;
      // If user clicks outside the element, hide it!
      if (navigator.userAgent.indexOf("Chrome") != -1) {
        box.nextElementSibling.classList.add("menu-hide");
      }
    });
  };

  mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
    //e.stopPropagation();
  };

  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };
  GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            if (this.mounted) {
              this.setState({
                IsShowTreeTiesLogo: this.MapKeyInArray(
                  responseData,
                  "IsShowTreeTiesLogo"
                ),
              });

              console.log("logohello", this.state.IsShowTreeTiesLogo);
            }
          } else {
            let errors = {};
            errors["message"] = data.message;
            if (this.mounted) {
              this.setState({ errors: errors });
            }
          }
        });
      })
      .catch((error) => {
        if (this.mounted) {
          console.log(error);
          this.setState({
            errorMsg: error,
            IsLoader: false,
          });
        }
      });
  };
  goBack = (e) => {
    window.history.back();
  };

  render() {
    return (
      <header>
        <div className="top_header">
          <div className="nav-icon">
            <a onClick={this.mobileSidebarToggle}>
              <i className="icon icon-menu-of-three-lines"></i>
            </a>
          </div>
          <ul className="navbar_right">
            {this.state.IsShowTreeTiesLogo == "Yes" ? (
              <li className="logo_cont">
                <div className="growlogo">
                  {/* <a href={this.props.userData.roleId == 2 ? "/NGODashboard" : "/GrowHead"}><img src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/treetieslogo_grow.png`} alt="growdashlogo" className="growlogo_img" /></a> */}
                  <a
                    href={
                      this.props.userData.roleId == 2
                        ? "/NGODashboardNew"
                        : "/NGODashboardNew"
                    }
                  >
                    <img
                      src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/treeties.png`}
                      alt="growdashlogo"
                      className="treetieslogo_img"
                    />
                  </a>
                </div>
              </li>
            ) : (
              <li className="logo_cont">
                <div className="growlogo">
                  <a
                    href={
                      this.props.userData.roleId == 2
                        ? "/NGODashboardNew"
                        : "/NGODashboardNew"
                    }
                  >
                    <img
                      src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/treeties.png`}
                      alt="growdashlogo"
                      className="growlogo_img"
                    />
                  </a>
                </div>
              </li>
            )}
            <li className="main_heading">
              <span>
                {this.props.userData.roleId == 2
                  ? this.props.userData.nameOfNGO
                  : ""}
              </span>
            </li>
            {/* <li className="notification">
                            <a href="#"><i className="icon icon-2"></i></a>
                            <ul className="submenu notification_list">
                                <li>Munnar Forest Reserve - 100 Trees needs care as per the latest Data shared by the officer.
                                    <span className="ntfntime">12 min ago</span>
                                </li>
                                <li>ID 13420 - Prakash singh just donated a full tree on Myntra on purchase  of Rs.10000
                                    <span className="ntfntime">1 day ago</span>
                                </li>
                                <li>Uago - A new brand just got registred
                                    <span className="ntfntime">3 May 2021</span>
                                </li>
                                <li>Munnar Forest Reserve - 100 Trees needs care as per the latest Data shared by the officer.
                                    <span className="ntfntime">12 min ago</span>
                                </li>
                                <li>ID 13420 - Prakash singh just donated a full tree on Myntra on purchase  of Rs.10000
                                    <span className="ntfntime">1 day ago</span>
                                </li>
                            </ul>
                        </li> */}

            <li className="user_cont">
              <a href="#">
                <div className="profiledata">
                  <span className="profile_name">
                    {this.props.userData.roleId == 2
                      ? this.props.userData.ngoHeadName
                      : this.props.userData.firstName +
                        " " +
                        this.props.userData.lastName}
                    <i className="fa fa-angle-down"></i>
                  </span>
                  <span className="profile_company">
                    {this.props.userData.roleId == 2 ? "NGO Manager" : ""}
                  </span>
                </div>
                {/* <img src={profileimg} alt="profile_img" /> */}
              </a>
              <ul className="submenu">
                {this.props.userData.roleId == 2 ? (
                  <li>
                    <a onClick={() => this.handleProfile()}>Profile</a>
                  </li>
                ) : (
                  ""
                )}
                {/* <li><a href="#">Setting</a></li> */}
                <li>
                  <a onClick={this.props.logout}>Logout</a>
                </li>
              </ul>
            </li>
            <li className="mobilelogocont">
              <div className="mobilegrowlogo">
                {/* companyLogo.png */}
                <img
                  src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/treetieslogo.png`}
                  alt="growmobileogo"
                />
              </div>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
    token: state.configuration.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReducer: (data, type) =>
      dispatch({
        type: type,
        data: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontEndHeader);
