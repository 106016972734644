import { useState, useRef } from "react";
import Select from "react-select";
import verfiedicon from "./icons/projectverified_icon.png";
import { ReactComponent as SelectDropdown } from "./icons/Select_Dropdown.svg";
import { ReactComponent as ProjectReport } from "./icons/Project_Report.svg";
import { ReactComponent as RegistryReport } from "./icons/Registry_Report.svg";
import { ReactComponent as DynamicProjectReport } from "./icons/Project_Report.svg";
import ProjectSummaryNew from "./ProjectSummaryNew";
import ProjectListNew from "./ProjectListNew";
import { Modal, ModalVariant } from "@patternfly/react-core";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RegistryReportUrl from "./Reports/RegistryReport";
import ProjectReportUrl from "./Reports/ProjectReport";
import OtherReportUlr from "./Reports/OtherReports";
import { useDispatch, useSelector } from "react-redux";
import project from "../../../Reducers/Project";
import ProjectNewReport from "./Reports/ProjectNewReport";
import NewProjectSummaryReport from "./Reports/NewProjectSummaryReport";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { DirectionsService } from "@react-google-maps/api";
import CloseIcon from "./icons/close.png";
import ProjectSummaryIcon from "./icons/projectsummary_icon.png";
import config from "../../../utils/config";
import { globalHistory } from "@reach/router";
import { useEffect } from "react";
import { navigate } from "@reach/router";
import { store } from "../../../App";

export default function ProjectDetailHeader(props) {
  const currentPath = globalHistory.location.pathname;
  const projectIndex = currentPath.split(":")[2].replace("/", "");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalMoreDetailsOpen, setisModalMoreDetailsOpen] = useState(false);
  const [isModalProListViewOpen, setisModalProListViewOpen] = useState(false);
  const reduxState = useSelector((state) => state.project);
  const dispatch = useDispatch();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const customSelectRef = useRef(null);
  const userInfo = store.getState().user.userData;
  const userMenu = store.getState().user.userMenuData;

  const handleModalMoreDetails = (open) => {
    setisModalMoreDetailsOpen(open);
    // if (!isModalMoreDetailsOpen) {
    //   document.body.classList.add("nobackdrop_cont");
    // } else {
    //   document.body.classList.remove("nobackdrop_cont");
    // }
    // project(
    //   reduxState?.landParcelData,
    //   dispatch({ type: "SET_LANDPARCEL_DETAILS", data: [] })
    // );
    // project(
    //   reduxState?.waterBodiesData,
    //   dispatch({ type: "SET_WATERBODIES_DETAILS", data: [] })
    // );
  };

  useEffect(() => {
    if (reduxState?.treeData) {
      setisModalProListViewOpen(false);
    }
  }, [reduxState?.treeData]);

  useEffect(() => {
    project(
      reduxState?.waterBodiesData,
      dispatch({ type: "SET_TREEDATA_DETAILS", data: {} })
    );
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        customSelectRef.current &&
        !customSelectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleModalProjectListView = (open) => {
    setisModalProListViewOpen(open);
    // if (!isModalProListViewOpen) {
    //   document.body.classList.add("prolistviewbackdrop_cont");
    // } else {
    //   document.body.classList.remove("prolistviewbackdrop_cont");
    // }
  };

  const options = [
    // {
    //   value: "Sample Project Report",
    //   label: "Sample Project Report",
    //   icon: <ProjectReport />,
    // },
    {
      value: "Tree Registry Report",
      label: "Tree Registry Report",
      icon: <RegistryReport />,
    },
    {
      value: "Dynamic Sample Project Report",
      label: "Project Report",
      icon: <DynamicProjectReport />,
      href: true,
    },
    {
      value: "Other Reports",
      label: "Other Reports",
      icon: <DynamicProjectReport />,
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const GetProjectReport = async (projectName, fileName) => {
    let data = await ProjectReportUrl(projectName, fileName);
    return data;
  };

  const GetRegistryReport = async (
    projectId,
    projectName,
    location,
    isForestHealthy,
    forestHealthyPercentage
  ) => {
    let data = await RegistryReportUrl(
      projectId,
      projectName,
      location,
      isForestHealthy,
      forestHealthyPercentage
    );
    return data;
  };

  const GetOtherReports = async (projectId) => {
    //e.preventDefault();
    let FileName = "OtherReports.pdf";
    let url =
      config.Amazon_WebService_CDNUrl +
      "/Websites/Uploads/UserFiles/SysAdmin/ProjectReports/" +
      projectId +
      "/" +
      FileName;
    console.log("URL", url);
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
    // let data = await OtherReportUlr(projectId);
    // return data;
  };

  // const GetDynamicProjectReport = async () => {
  //   let data = await ProjectNewReport(props);
  //   //return data;
  // };

  const handleOptionClick = (option) => {
    let projectId = props.projectDetail.projectId;
    let projectName = props.projectDetail.projectName;
    let fileName = props.projectDetail.projectReportFile;
    let location = props.projectDetail.location;
    let isForestHealthy = props.projectDetail.isForestHealthy;
    let forestHealthyPercentage = props.projectDetail.forestHealthyPercentage;

    // if (option.value === "Sample Project Report") {
    //   GetProjectReport(projectName, fileName);
    // }
    if (option.value === "Tree Registry Report") {
      GetRegistryReport(
        projectId,
        projectName,
        location,
        isForestHealthy,
        forestHealthyPercentage
      );
    }
    if (option.value === "Dynamic Sample Project Report") {
      //GetDynamicProjectReport();
      // debugger;
      // const div = document.getElementById("content");
      // // if (div.style.display === "none") {
      // const pdf = new jsPDF("p", "pt", "a4");
      // html2pdf()
      //   .from(div)
      //   .set({
      //     margin: 0.5,
      //     filename: "ProjectReport.pdf",
      //     image: { type: "jpeg", quality: 0.98 },
      //     html2canvas: { scale: 2 },
      //     jsPDF: pdf,
      //   })
      //   .save()
      //   .catch(function (error) {
      //     console.error("Error generating PDF:", error);
      //   });
      // // }
    }
    if (option.value === "Other Reports") {
      GetOtherReports(projectId);
    }
    //setSelectedOption(option);
    setIsOpen(false);
  };
  const percentage = 95;

  const handleMapViewClick = () => {
    // Close the modal if it's open
    if (isModalProListViewOpen) {
      handleModalProjectListView();
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="Pojectdetail_headernew">
        <div className="gobackbtn">
          <a
            href={
              userInfo?.roleId === 1
                ? userMenu
                    ?.filter(
                      (menu) => menu?.mainMenu?.menuName === "Approval"
                    )[0]
                    ?.subMenus?.filter(
                      (submenu) => submenu?.menuName === "Project List"
                    )[0]?.route
                : "/projects"
            }
          >
            <i className="fa fa-angle-left"></i>
          </a>
        </div>

        <div className="prodetheader_proheading">
          <h1>
            <span className="proheadnew_count">{props.projectIndex}</span>
            {props.projectDetail.projectName}
            <img className="verifyicon" src={verfiedicon} alt="verified_icon" />
          </h1>
          {/* <div className="projectviewhead_carbonseq">
            <h3>Carbon Sequestered - Till Now</h3>
            <span>
              {props?.projectDetail?.co2Nutrilized
                ? (props?.projectDetail?.co2Nutrilized * 1000).toFixed(2)
                : 0}{" "}
              kg
            </span>
          </div> */}
          <a onClick={handleModalMoreDetails} className="morebtn">
            More Details
          </a>
        </div>
        <div class="prolisttime_cont logilangval">
          <div class="polycord">
            <div class="polycordlocationab">
              <span class="cordone">
                A){props.projectDetail?.locationCords?.[0]},
              </span>
              <span class="cordtwo">
                {props.projectDetail?.locationCords?.[1]}
              </span>
              <span class="cordthree">
                B){props.projectDetail?.locationCords?.[2]},
              </span>
              <span class="cordfour">
                {" "}
                {props.projectDetail?.locationCords?.[3]}
              </span>
            </div>
            <div class="polycordlocationcd">
              <span class="cordfive">
                C){props.projectDetail?.locationCords?.[4]},
              </span>
              <span class="cordsix">
                {props.projectDetail?.locationCords?.[5]}
              </span>
              <span class="cordseven">
                D){props.projectDetail?.locationCords?.[6]}
              </span>
              <span class="cordeight">
                {props.projectDetail?.locationCords?.[7]}
              </span>
            </div>
          </div>
          <div className="spocshover_content">
            <div class="polycordlocationab">
              <span class="cordone">
                A){props.projectDetail?.locationCords?.[0]},{" "}
              </span>
              <span class="cordtwo">
                {" "}
                {props.projectDetail?.locationCords?.[1]}
              </span>
              <span class="cordthree">
                B){props.projectDetail?.locationCords?.[2]},{" "}
              </span>
              <span class="cordfour">
                {" "}
                {props.projectDetail?.locationCords?.[3]}
              </span>
            </div>
            <div class="polycordlocationcd">
              <span class="cordfive">
                C){props.projectDetail?.locationCords?.[4]},{" "}
              </span>
              <span class="cordsix">
                {" "}
                {props.projectDetail?.locationCords?.[5]}
              </span>
              <span class="cordseven">
                D){props.projectDetail?.locationCords?.[6]},{" "}
              </span>
              <span class="cordeight">
                {props.projectDetail?.locationCords?.[7]}
              </span>
            </div>
          </div>
        </div>

        <div class="prodetheader_verifycont">
          {/* <div className="prodheadverify_heading">
          <span>{props.projectDetail.status}</span>
        </div> */}
          <div className="verified_capsule">
            {/* {props.projectDetail.forestHealthyPercentage.toFixed(2)}% */}
            {/* <div className="verifiedpercent">
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              background={true}
              color="#444"
              styles={{
                textSize: "22px",
                textcolor: "#444",
                path: {
                  stroke: `rgba(80, 176, 65, 1)`,
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#fff",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                background: {
                  fill: "#fff",
                },
              }}
            />
          </div> */}
            {props.projectDetail.isForestHealthy === true ? (
              <div className="typeofverify_forest hlthyforbtn">
                Forest is Healthy
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M13.5 15.75H6V6L11.25 0.75L12.1875 1.6875C12.275 1.775 12.347 1.89375 12.4035 2.04375C12.46 2.19375 12.488 2.3375 12.4875 2.475V2.7375L11.6625 6H15.75C16.15 6 16.5 6.15 16.8 6.45C17.1 6.75 17.25 7.1 17.25 7.5V9C17.25 9.0875 17.2407 9.18125 17.2222 9.28125C17.2037 9.38125 17.1755 9.475 17.1375 9.5625L14.8875 14.85C14.775 15.1 14.5875 15.3125 14.325 15.4875C14.0625 15.6625 13.7875 15.75 13.5 15.75ZM4.5 6V15.75H1.5V6H4.5Z"
                    fill="white"
                  />
                </svg>
              </div>
            ) : (
              <div className="typeofverify_forest unhlthyforbtn">
                Forest is UnHealthy
              </div>
            )}
          </div>
        </div>
        {/* <div id="content" style={{ display: "none" }}> */}
        {/* <div id="content">
        <NewProjectSummaryReport projectDetail={props.projectDetail} />
      </div> */}

        <div className="projectview_cont">
          <div className="custom-select-container">
            <div
              className={`custom-select ${isOpen ? "open" : ""}`}
              ref={customSelectRef}
              onClick={toggleDropdown}
            >
              <div className="selected-option">
                {selectedOption ? (
                  <>
                    <span className="option-icon">{selectedOption.icon}</span>
                    <span className="option-label">{selectedOption.label}</span>
                  </>
                ) : (
                  <span className="placeholder">Report</span>
                )}
              </div>
              <div className="dropdown-icon">
                <SelectDropdown />
              </div>
            </div>
            {isOpen && (
              <div className="options">
                {options.map((option) =>
                  option.href ? (
                    <a
                      href={
                        config.BASE_URL +
                        "/projectsummaryreport/:" +
                        props.projectDetail.projectId +
                        "/:" +
                        projectIndex
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        key={option.value}
                        className={`option ${
                          option === selectedOption ? "selected" : ""
                        }`}
                        // onClick={() => handleOptionClick(option)}
                      >
                        <span className="option-icon">{option.icon}</span>
                        <span className="option-label">{option.label}</span>
                      </div>
                    </a>
                  ) : (
                    <div
                      key={option.value}
                      className={`option ${
                        option === selectedOption ? "selected" : ""
                      }`}
                      onClick={() => handleOptionClick(option)}
                    >
                      <span className="option-icon">{option.icon}</span>
                      <span className="option-label">{option.label}</span>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="projectviews_wrap">
            <button
              className={`projview_map ${
                !isModalProListViewOpen
                  ? "active_viewbtns"
                  : "inactive_viewbtns"
              }`}
              onClick={handleMapViewClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="#B7B7B7"
              >
                <path
                  d="M8 10C8.55 10 9.02083 9.80417 9.4125 9.4125C9.80417 9.02083 10 8.55 10 8C10 7.45 9.80417 6.97917 9.4125 6.5875C9.02083 6.19583 8.55 6 8 6C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8C6 8.55 6.19583 9.02083 6.5875 9.4125C6.97917 9.80417 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7875 12.525 12.2625C13.5083 10.7375 14 9.38333 14 8.2C14 6.38333 13.4208 4.89583 12.2625 3.7375C11.1042 2.57917 9.68333 2 8 2C6.31667 2 4.89583 2.57917 3.7375 3.7375C2.57917 4.89583 2 6.38333 2 8.2C2 9.38333 2.49167 10.7375 3.475 12.2625C4.45833 13.7875 5.96667 15.4833 8 17.35ZM8 20C5.31667 17.7167 3.3125 15.5958 1.9875 13.6375C0.6625 11.6792 0 9.86667 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.86667 15.3375 11.6792 14.0125 13.6375C12.6875 15.5958 10.6833 17.7167 8 20Z"
                  fill="#B7B7B7"
                />
              </svg>
            </button>
            <button
              className={`projview_list ${
                isModalProListViewOpen ? "active_viewbtns" : ""
              }`}
              onClick={handleModalProjectListView}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
              >
                <path
                  d="M0 14V12H18V14H0ZM0 10V8H18V10H0ZM0 6V4H18V6H0ZM0 2V0H18V2H0Z"
                  fill="#B7B7B7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`prosumrymodal_cont ${
          isModalMoreDetailsOpen ? "toggleprosumrymodal" : ""
        }`}
      >
        <div className="prosumrymodal_header">
          <div className="prosumrymodal_heading">
            <img src={ProjectSummaryIcon} alt="projectsummary_icon" />
            <span>Project Summary</span>
          </div>
          <a
            className="prosumrymodal_close"
            onClick={() => handleModalMoreDetails(false)}
          >
            <img src={CloseIcon} alt="close_icon" />
          </a>
        </div>

        <div className="projectmodal_body">
          <ProjectSummaryNew projectDetail={props.projectDetail} />
        </div>
      </div>
      <div
        className={`prosumrymodal_cont prolistmodal ${
          isModalProListViewOpen ? "toggleprosumrymodal" : ""
        }`}
      >
        <div className="prosumrymodal_header">
          <div className="prosumrymodal_heading">
            {/* <img src={ProjectSummaryIcon} alt="projectsummary_icon" /> */}
            <span>Tree Registry Details</span>
          </div>
          <a
            className="prosumrymodal_close"
            onClick={() => handleModalProjectListView(false)}
          >
            <img src={CloseIcon} alt="close_icon" />
          </a>
        </div>
        {/* <a className="prosumrymodal_close" onClick={() => false}></a> */}
        <div className="projectmodal_body">
          <ProjectListNew
            projectDetail={props.projectDetail}
            userInfo={userInfo}
          />
        </div>
      </div>
    </>
  );
}
