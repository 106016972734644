import config from "../../../utils/config";

 async function s3TreeDetailsData (data) {
  try {
    //For re-deployment 
    // Modifiled by shobana
    let response = await fetch(`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/`+ data);
    // let response = await fetch(`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/curated_trees_converted.geojson`);

    let responseJson = await response.json();
    return responseJson;
   } catch(error) {
    console.error(error);
  }

}
export default s3TreeDetailsData