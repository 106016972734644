import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import Loader from "../../Common/Loader/Loader";
import { globalHistory } from "@reach/router"
import Moment from 'react-moment';
import * as moment from 'moment';

class AddMeetingNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.IsOpen,
            errors: {},
            MeetingNote: "",
            MeetingDate: "",
            MeetingTime: "",
            UserId: props.UserId,
            IsLoader: false,
            MeetingScheduled: this.props.MeetingScheduled
        }

        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };

    }
    componentDidMount() {
        this.mounted = true;
        if (this.state.MeetingScheduled !== null && this.state.MeetingScheduled !== undefined) {
            let NewDate = moment(this.state.MeetingScheduled.start_time).format('YYYY-MM-DD');
            let NewTime = moment(this.state.MeetingScheduled.start_time).format('hh:mm A');
            this.setState({ MeetingDate: NewDate, MeetingTime: NewTime });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    mobileSidebarToggle = (e) => {
        document.documentElement.classList.toggle("nav-open");
        e.stopPropagation();
    };


    // This function do validate the Registration Form
    validateForm = (e) => {
        let fields;
        fields = {
            MeetingNote: this.state.MeetingNote
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["MeetingNote"]) {
            formIsValid = false;
            errors["MeetingNote"] = "Enter MeetingNote.";
        }
        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }

    AddMeetingNote = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            if (this.mounted) {
                let params = {
                    UserId: this.state.UserId,
                    MeetingNote: this.state.MeetingNote,
                    MeetingDate: this.state.MeetingDate,
                    MeetingTime: this.state.MeetingTime
                }
                let uri = `${config.API_HOST_URL}/api/Login/AddMeetingNote`;
                let fetchOptions = {
                    method: "POST",
                    body: JSON.stringify(params),
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }
                };
                this.setState({
                    IsLoader: true
                });
                fetch(uri, fetchOptions)
                    .then(response => {
                        return response.json().then(data => {
                            if (data.success) {
                                this.setState({
                                    IsLoader: false,
                                    IsOpen: false,
                                    MeetingNote: ""
                                });
                                this.handleModalToggle();
                                setTimeout(() => {
                                    this.setState({
                                    }, this.props.parentMethod)
                                }, 800);
                            } else {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors,
                                        IsLoader: false
                                    });
                                }
                            }
                        });
                    })
                    .catch(error => {
                        if (this.mounted) {
                            console.log(error);
                            this.setState({
                                errorMsg: error,
                                IsLoader: false
                            })
                        }
                    });
            }
        }
    }

    // This function will set the state of input fields and validate them when user enters the value in input boxes
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (this.mounted) {
            this.setState({ [name]: value });
            this.validateForm();
        }
    }


    render() {
        const { isModalOpen } = this.state;
        return (
            <div>
                {this.state.IsLoader ? <Loader /> :
                    <div className="addpro_btn">
                        <Modal title="Add Meeting Notes"
                            isOpen={isModalOpen}
                            onClose={this.handleModalToggle}
                            className="addproject_modal addmeetingnotepop"
                            actions={[
                                <Button key="submit" className="modal_btn submit_btn" onClick={this.AddMeetingNote}>Submit</Button>,
                                <Button key="cancel" className="modal_btn cancel_btn" onClick={this.handleModalToggle}>Cancel</Button>
                            ]}>
                            <div>
                                <ul className="addproject_form">
                                    <li className="form-group field">
                                        <textarea id="MeetingNote" placeholder="Enter Meeting Notes" name="MeetingNote" value={this.state.MeetingNote} onChange={(event) => this.handleUserInput(event)} />
                                        <label htmlFor="treetype">
                                            <sup className="mandatory">*</sup>Enter Meeting Note</label>
                                        <div className="mandatory has-error">{this.state.errors.MeetingNote}</div>
                                    </li>
                                    {this.state.MeetingScheduled !== null &&
                                        <>
                                            <li className="form-group field">
                                                <input type="date" id="MeetingDate" placeholder="Enter Meeting Date" name="MeetingDate" value={this.state.MeetingDate} onChange={(event) => this.handleUserInput(event)}
                                                />
                                                {/* <Moment className="infotag" format="DD MMMM, YYYY">{this.state.MeetingScheduled.start_time}</Moment> */}
                                                <label htmlFor="treetype">
                                                    Meeting Date</label>
                                            </li>
                                            <li className="form-group field">
                                                <input type="text" id="MeetingTime" placeholder="Enter Meeting Time" name="MeetingTime" onChange={(event) => this.handleUserInput(event)}
                                                    value={this.state.MeetingTime}
                                                />
                                                <label htmlFor="treetype">
                                                    Meeting Time</label>
                                            </li>
                                        </>
                                    }

                                </ul>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData
    };
}

export default connect(
    mapStateToProps
)(AddMeetingNote);