import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import Routes from "../../Routes";
import FrontEndLayout from "../Layout/FrontEndLayout";

import "bootstrap/dist/css/bootstrap.min.css";
import HomePageLayout from "./HomePageLayout";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    this.props.clearUserData();
    navigate("/login");
  };

  render() {
    let co2 = window.location.pathname.includes("/Co2EmissionCalculator");
    let SuccessMessage = window.location.pathname.includes("/SuccessMessage");
    let FailureMessage = window.location.pathname.includes("/FailureMessage");
    let home = window.location.pathname.includes("/home");
    let homeblank = window.location.pathname === "/" ? true : false;
    let registration = window.location.pathname.includes("/Registration");
    let aboutus = window.location.pathname.includes("/aboutus");
    let contactus = window.location.pathname.includes("/contactus");
    let partners = window.location.pathname.includes("/partners");
    let repository = window.location.pathname.includes("/repository");
    let platform = window.location.pathname.includes("/platform");
    let privacypolicy = window.location.pathname.includes("/privacypolicy");
    let termsconditions = window.location.pathname.includes("/termsconditions");
    let refundcancellationpolicy = window.location.pathname.includes(
      "/refundcancellationpolicy"
    );
    let newDynamicReport = window.location.pathname.includes(
      "/projectsummaryreport"
    );
    let ourexperience = window.location.pathname.includes("/ourexperience");
    let ourapproach = window.location.pathname.includes("/ourapproach");
    let ngoRegsiter = window.location.pathname.includes("/register");
    return (
      <div>
        {home ||
        homeblank ||
        aboutus ||
        ourexperience ||
        contactus ||
        partners ||
        repository ||
        platform ||
        ourapproach ||
        privacypolicy ||
        termsconditions ||
        refundcancellationpolicy ? (
          <div>
            <HomePageLayout />
          </div>
        ) : this.props.userData !== null &&
          !co2 &&
          !SuccessMessage &&
          !FailureMessage &&
          !registration &&
          !newDynamicReport &&
          !ngoRegsiter ? (
          <div className="layout">
            {
              <div className="FrontEndLayout">
                <FrontEndLayout />
              </div>
            }
          </div>
        ) : (
          <div className="layout">
            <Routes />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserData: () => dispatch({ type: "CLEAR_USER_DETAILS" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
