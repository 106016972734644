import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { globalHistory } from "@reach/router";

class TreeTiesAddProjectForms1Ris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      commonMastersList: [],
      ComplexityLevelSocialRelated: "",
      ComplexityLevelProjectGoalRelated: "",
      OverallRiskLevel: "",
      selectedComplexityLevelSocialRelated: "",
      selectedComplexityLevelProjectGoalRelated: "",
      selectedOverallRiskLevel: "",
      projectId: "",
      ProjectRiskList: [],
    };
    this.handleComplexityLevelSocialRelated =
      this.handleComplexityLevelSocialRelated.bind(this);
    this.handleComplexityLevelProjectGoalRelated =
      this.handleComplexityLevelProjectGoalRelated.bind(this);
    this.handleOverallRiskLevel = this.handleOverallRiskLevel.bind(this);
    this.mounted = true;
  }
  // componentDidMount() {
  //   this.GetCommonMasters();
  //   this.GetProjectRisk();
  // }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    //console.log("currentPath", currentPath);
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ projectId: Id });
    this.GetProjectRisk(Id);
    this.GetCommonMasters();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  // GetProjectRisk = (ProjectId) => {
  //   let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectRisk`;
  //   let proj = {
  //     ProjectId: ProjectId,
  //   };
  //   fetch(url, {
  //     method: "Post",
  //     body: JSON.stringify(proj),
  //     headers: {
  //       WebsiteGuid: this.props.websiteGUID,
  //       LanguageGuid: this.props.languageGUID,
  //       Authorization: `Bearer ${this.props.token}`,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       let responseData = data.data;
  //       this.setState({ ProjectRiskList: responseData });
  //      // console.log("responseData", responseData);
  //       this.setState({
  //         selectedComplexityLevelSocialRelated:
  //           responseData.complexityLevelSocialRelated,
  //         selectedComplexityLevelProjectGoalRelated:
  //           responseData.complexityLevelProjectGoalRelated,
  //         projectId: responseData.projectId,
  //         selectedOverallRiskLevel: responseData.overallRiskLevel,
  //       });

  //     })
  //     .catch((error) => {
  //       console.log("Error in request", error);
  //     });

  // };

  GetProjectRisk = (ProjectId) => {
    debugger;
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectRisk`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "Post",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        this.setState({ ProjectRiskList: responseData });
        // console.log("responseData", responseData);
        this.setState({
          selectedComplexityLevelSocialRelated:
            responseData.complexityLevelSocialRelated,
          selectedComplexityLevelProjectGoalRelated:
            responseData.complexityLevelProjectGoalRelated,
          projectId: responseData.projectId,
          selectedOverallRiskLevel: responseData.overallRiskLevel,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/ProjectRisk`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          commonMastersList: data,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  handleComplexityLevelSocialRelated = (event) => {
    const value = event.target.value;
    if (event.target.name == "ddltype")
      this.setState({
        selectedComplexityLevelSocialRelated: event.target.value,
      });
  };
  handleComplexityLevelProjectGoalRelated = (event) => {
    const value = event.target.value;
    if (event.target.name == "ddltype1")
      this.setState({
        selectedComplexityLevelProjectGoalRelated: event.target.value,
      });
  };
  handleOverallRiskLevel = (event) => {
    const value = event.target.value;
    if (event.target.name == "ddltype2")
      this.setState({
        selectedOverallRiskLevel: event.target.value,
      });
  };
  Save = () => {
    //debugger;
    if (!this.handleValidation()) return;
    const data = {
      ComplexityLevelSocialRelated:
        this.state.selectedComplexityLevelSocialRelated,
      ComplexityLevelProjectGoalRelated:
        this.state.selectedComplexityLevelProjectGoalRelated,
      OverallRiskLevel: this.state.selectedOverallRiskLevel,
      ProjectId: this.state.projectId,
    };
    const url = `${config.API_HOST_URL}/api/Ecosystem/AddProjectRisk`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success == "true") {
          // alert("Save Successfully");
          ToastNotification.ShowSuccess("Risks Saved Successfully");
          //this.clearFields();
          this.Nextpage();
        } else {
          //alert("Failed");
          ToastNotification.ShowError("Risks Not Saved Successfully");
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };
  handleValidation = (e) => {
    let fields;
    fields = {
      ComplexityLevelSocialRelated:
        this.state.selectedComplexityLevelSocialRelated,
      ComplexityLevelProjectGoalRelated:
        this.state.selectedComplexityLevelProjectGoalRelated,
      OverallRiskLevel: this.state.selectedOverallRiskLevel,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["ComplexityLevelSocialRelated"]) {
      formIsValid = false;
      errors["selectedComplexityLevelSocialRelated"] =
        "Enter Complexity Level Social Related.";
    }
    if (!fields["ComplexityLevelProjectGoalRelated"]) {
      formIsValid = false;
      errors["selectedComplexityLevelProjectGoalRelated"] =
        "Enter Complexity Level Project Goal Related.";
    }
    if (!fields["OverallRiskLevel"]) {
      formIsValid = false;
      errors["selectedOverallRiskLevel"] = "Enter  Overall Risk Level.";
    }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };

  // clearFields = (e) => {
  //   if (this.mounted) {
  //     this.setState({
  //       selectedComplexityLevelSocialRelated: "",
  //       selectedComplexityLevelProjectGoalRelated: "",
  //       selectedOverallRiskLevel: "",
  //     });
  //   }
  // };
  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Cos/:" + this.state.projectId, {
      state: { activeMenu: 9 },
    });
  };
  Back = () => {
    navigate("/TreeTiesAddProjectForms1Obj/:" + this.state.projectId, {
      state: { activeMenu: 7 },
    });
  };

  render() {
    return (
      <div className="treeties-addproject-forms1-ris ecosyscom_cont">
        {/* <Header /> */}
        <div className="main-content-area2">
          {/* <LeftNavbar/> */}
          <div className="middle-content-area2">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont">
                <div className="hero-title5">
                  <b className="project-summary5">Risks</b>
                </div>
                <div className="input-field-text7 pt-4">
                  <div className="content-title-text38">
                    <div className="hero-title4">
                      <div className="project-summary4">
                        Complexity Level - Social Related
                      </div>
                    </div>
                    <select
                      className="dropdown-15"
                      value={this.state.selectedComplexityLevelSocialRelated}
                      onChange={this.handleComplexityLevelSocialRelated}
                      ref="selectedGroup"
                      name="ddltype"
                    >
                      <option value="" selected="selected">
                        Select{" "}
                      </option>
                      {this.state.commonMastersList
                        .filter(
                          (x) => x.type === "Complexity Level - Social Related"
                        )
                        .map((item) => (
                          <option value={item.description}>
                            {item.description}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="content-title-text38">
                    <div className="hero-title4">
                      <div className="project-summary4">
                        Complexity Level - Project Goal Related
                      </div>
                    </div>
                    <select
                      className="dropdown-15"
                      value={
                        this.state.selectedComplexityLevelProjectGoalRelated
                      }
                      onChange={this.handleComplexityLevelProjectGoalRelated}
                      ref="selectedGroup"
                      name="ddltype1"
                    >
                      <option value="" selected="selected">
                        Select{" "}
                      </option>
                      {this.state.commonMastersList
                        .filter(
                          (x) =>
                            x.type === "Complexity Level - Project Goal Related"
                        )
                        .map((item) => (
                          <option value={item.description}>
                            {item.description}{" "}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="content-title-text38">
                    <div className="hero-title4">
                      <div className="project-summary4">Overall Risk Level</div>
                    </div>
                    <select
                      className="dropdown-15"
                      value={this.state.selectedOverallRiskLevel}
                      onChange={this.handleOverallRiskLevel}
                      ref="selectedGroup"
                      name="ddltype2"
                    >
                      <option value="" selected="selected">
                        Select{" "}
                      </option>
                      {this.state.commonMastersList
                        .filter((x) => x.type === "Overall Risk Level")
                        .map((item) => (
                          <option value={item.description}>
                            {item.description}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="action-buttons1 pt-4">
                  <button className="cta3">
                    <div className="back1" onClick={this.Back}>
                      Back
                    </div>
                  </button>
                  <button className="cta4">
                    <div
                      className="save-continue2"
                      onClick={this.Save}
                    >{`Save & Continue`}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar2">
            <button className="plus-circle2">plus-circle</button>
            <button className="notifications-bell2">notifications-bell</button>
            <button className="notifications-bell2">email</button>
          </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Ris);
