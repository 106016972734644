import { Color, Cartesian3, Cartographic, Math, Ion } from 'cesium';
import { Viewer, CameraFlyTo } from 'resium';
import React, { Component } from 'react';
import EntityViewer from './EntityViewer';
import { Modal } from '@patternfly/react-core';
import Iframewithcombinedtreeandboundary from "../Iframewithcombinedtreeandboundary.js";

class MainViewer extends Component {
    constructor(props) {
        super(props);
        Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiMDU1N2JmNS01OTkwLTQ2MmUtOTM0OS02N2M2NjVjODgwNzMiLCJpZCI6NjUxMTcsImlhdCI6MTYzNDEyNDMxNn0.XGchLm0deQK1Azv172v5YQhB1rquKNa9XIs3si7sf9Q";
        this.state = {
            posn: [0, 0],
            polyLocation: props.locationCords && props.locationCords.length > 0 ? props.locationCords : [0, 0, 0, 0, 0, 0, 0, 0],
            viewer: null,
            isDrawEnable: false,
            plotPlanted: props.plotPlanted ? props.plotPlanted : [],
            isModalOpen: false
        }
        this.handleModalToggle = (e) => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };
    }

    setViewerFromChild = (data) => {
        if (!this.state.viewer && data) {
            this.setState({ viewer: data }, () => {
                if (this.props.isPlantPlot) {
                    this.toggleMapMovement();
                }
            })
        }
    }

    getAreaPosn = (event) => {
        // Screen coordinates to world coordinates key points
        let ray = this.state.viewer.camera.getPickRay(event.position);
        let cartesian = this.state.viewer.scene.globe.pick(ray, this.state.viewer.scene);
        //Converting Cartesian coordinates to geographic coordinates
        let cartographic = Cartographic.fromCartesian(cartesian);
        //Decimal representation of radians to degrees
        let camPosn = this.state.viewer.scene.camera.position;
        return {
            lon: Math.toDegrees(cartographic.longitude), lat: Math.toDegrees(cartographic.latitude), cameraPosition: [camPosn.x, camPosn.y, camPosn.z]
        }
    }
    setArrayValue = (posnAry, idx, vlu) => {
        let rtnAry = [...posnAry];
        rtnAry[idx] = parseFloat(vlu);
        return rtnAry;
    }
    handleMouseDown = (event) => {
        let posnVar = this.getAreaPosn(event);
        this.setState({ posn: [posnVar.lon, posnVar.lat] });
    }
    handleMouseUp = (event) => {
        let posnVar = this.getAreaPosn(event);
        if (this.props.isPlantPlot) {
            let plotObj = {};
            plotObj.plotId = this.props.plotId;
            plotObj.isNew = true;
            plotObj.location = [...this.state.posn, this.state.posn[0], posnVar.lat, posnVar.lon, posnVar.lat, posnVar.lon, this.state.posn[1]];
            let plots = this.state.plotPlanted.filter((fl) => fl.plotId != this.props.plotId);
            plots.push(plotObj);
            this.state.viewer.selectedEntity = null;
            this.setState({ plotPlanted: plots }, () => this.props.onparentCallbackPlot(this.state.plotPlanted));
        }
        else {
            this.setState({
                polyLocation: [...this.state.posn, this.state.posn[0], posnVar.lat, posnVar.lon, posnVar.lat, posnVar.lon, this.state.posn[1]]
            }, () => {
                this.props.onparentCallback({ polyLocation: this.state.polyLocation, cameraPosition: posnVar.cameraPosition });
            });
        }
    }

    toggleMapMovement = () => {
        this.state.viewer.scene.screenSpaceCameraController.enableRotate = !this.state.viewer.scene.screenSpaceCameraController.enableRotate;
        this.state.viewer.scene.screenSpaceCameraController.enableTranslate = !this.state.viewer.scene.screenSpaceCameraController.enableTranslate;
        this.state.viewer.scene.screenSpaceCameraController.enableZoom = !this.state.viewer.scene.screenSpaceCameraController.enableZoom;
        this.state.viewer.scene.screenSpaceCameraController.enableTilt = !this.state.viewer.scene.screenSpaceCameraController.enableTilt;
        this.state.viewer.scene.screenSpaceCameraController.enableLook = !this.state.viewer.scene.screenSpaceCameraController.enableLook;
        this.setState({ isDrawEnable: !this.state.isDrawEnable });
    }

    PlotReverse = () => {
        if (this.props.isPlantPlot) {
            let plots = this.state.plotPlanted.filter((fl) => fl.plotId != this.props.plotId);
            this.setState({ plotPlanted: plots }, () => this.props.onparentCallbackPlot(this.state.plotPlanted));
        }
        else
            this.setState({ polyLocation: [0, 0, 0, 0, 0, 0, 0, 0] });
    }


    render() {
        const { isModalOpen } = this.state;
        const viewOnlyMap = this.props.locationCords && this.props.locationCords.length > 0;
        const cameraFocuse = viewOnlyMap ? Cartesian3.fromArray(this.props.cameraPosition) : Cartesian3.fromDegrees(78.964416504, 20.073694229, 7000000);
        return (
            <div className="mapMainDiv" >
                {viewOnlyMap &&
                    <Modal
                        title=""
                        isOpen={isModalOpen}
                        className="mapmodalviewpopup" 
                        onClose={this.handleModalToggle}
                    >
                        <div className="mapmodalview_cont">
                            <span className='plotTitle'><i className="fas fa-arrow-left" onClick={this.handleModalToggle}></i>&nbsp; Plot Details</span>
                            <Iframewithcombinedtreeandboundary IframeGuid={this.props.IframeGuid} IframeBaseUrl={this.props.IframeBaseUrl} />
                        </div>
                    </Modal>}
                <div className="polyControls">
                    <div className="posnOneDic">
                        {!viewOnlyMap && <div className="posnOneDiccontent">
                            <p>Draw Plot</p>
                            <input name="polyDraw" type="checkbox" value={this.state.isDrawEnable} onChange={this.toggleMapMovement} />
                        </div>}
                        {(!viewOnlyMap || this.props.isPlantPlot) && <div className="mapresetwrap">
                            <button key="Reset" className="modal_btn cancel_btn" onClick={this.PlotReverse}>
                                <i className="fas fa-undo-alt"></i>
                                <div className="tooltipdata">Reset</div>
                            </button>
                        </div>}
                    </div>
                </div>
                <div className="mapViewer">
                    <div className="satmapcont" >
                        <Viewer
                            onMouseDown={this.state.isDrawEnable ? this.handleMouseDown : null}
                            onMouseUp={this.state.isDrawEnable ? this.handleMouseUp : null}
                            homeButton={false}
                            timeline={false}
                            scene3DOnly={true}
                            fullscreenButton={false}>
                            <CameraFlyTo once={true} destination={cameraFocuse} duration={1} />
                            <EntityViewer polyArray={this.state.polyLocation} setViewerToParent={this.setViewerFromChild} Colormaterial={Color.BLUE.withAlpha(0.3)} Coloroutline={Color.BLUE} Click={this.handleModalToggle}></EntityViewer>
                            {this.state.plotPlanted.map((plot, idx) => <EntityViewer polyArray={plot.location} setViewerToParent={this.setViewerFromChild} Colormaterial={Color.RED.withAlpha(0.3)} Coloroutline={Color.RED} key={idx} isPlantPlot={this.props.isPlantPlot}></EntityViewer>)}
                        </Viewer>
                    </div>
                </div>
            </div>

        )
    }
}
export default MainViewer