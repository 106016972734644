import React, { useState } from "react";
import { useEffect } from "react";
import { GroundOverlay } from "@react-google-maps/api";
import moment from "moment";
import config from "../../../utils/config";
import TimeSeriesIcon from "./icons/TimeSeries_Icon.svg";
import TimeSeriesIconClose from "./icons/TimeSeriesClose_icon.svg";
import TimeSeriesBuletsIconPrev from "./icons/Timeseries_circlelefticon.svg";
import TimeSeriesBuletsIconNext from "./icons/Timeseries_circlerighticon.svg";

export default function TimeSeries(props) {
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isNextPrev, setIsNextPrev] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [isTimeSeriesOpen, setisTimeSeriesOpen] = useState(false);

  useEffect(() => {
    setTimeSeriesData(props.projectDetail?.timeSeriesData);
  }, [props.projectDetail.timeSeriesData]);

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(() => {
        let nextYear = moment().year();
        setCurrentYear((prevYear) => {
          nextYear =
            prevYear === moment().year()
              ? timeSeriesData[0]?.year
              : prevYear + 1;
          return nextYear;
        });
        let lastIndex = timeSeriesData.length - 1;
        if (timeSeriesData[lastIndex].year === nextYear) {
          setTimeout(() => {
            setIsPlaying(false);
          }, 3000);
        }
      }, 3000);
      setTimerId(timer);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [isPlaying]);

  const handlePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
  };

  const handleNext = () => {
    setCurrentYear((n) => n + 1);
    setIsNextPrev((prevState) => !prevState);
  };

  const handlePrevious = () => {
    setCurrentYear((n) => n - 1);
    setIsNextPrev((prevState) => !prevState);
  };
  const showYearData = (year) => {
    setCurrentYear(year);
    setIsNextPrev(true);
  };

  const handleTimeSeries = () => {
    setisTimeSeriesOpen(!isTimeSeriesOpen);
  };
  return (
    <>
      <a
        className={`timeseriesbtn_cont ${isTimeSeriesOpen ? "open" : ""}`}
        onClick={handleTimeSeries}
      >
        {isTimeSeriesOpen ? (
          <>
            <img src={TimeSeriesIconClose} alt="Close time series" />
            <span>Exit</span>
          </>
        ) : (
          <img src={TimeSeriesIcon} alt="Open time series" />
        )}
      </a>
      <div
        className={`timeseries_wrap ${
          isTimeSeriesOpen ? "toggletimeseries" : ""
        }`}
      >
        <div className="timeseriesbtns_cont">
          <button
            title="Previous"
            data-gtm-loc="timelapse"
            data-gtm-content="previous"
            onClick={handlePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <mask
                id="mask0_2896_3526"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="21"
              >
                <rect x="0.5" y="0.375" width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2896_3526)">
                <path
                  d="M5.0835 15.375V5.375H6.75016V15.375H5.0835ZM15.9168 15.375L8.41683 10.375L15.9168 5.375V15.375Z"
                  fill="white"
                />
              </g>
            </svg>
          </button>
          <button
            title="Play"
            data-gtm-loc="timelapse"
            data-gtm-content="play"
            onClick={handlePlayPause}
            className="playpausebtn_cont"
          >
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
              >
                <path
                  d="M7.16667 12.2096V0.542969H10.5V12.2096H7.16667ZM0.5 12.2096V0.542969H3.83333V12.2096H0.5Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <mask
                  id="mask0_2896_3433"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="21"
                  height="21"
                >
                  <rect
                    x="0.5"
                    y="0.375"
                    width="20"
                    height="20"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_2896_3433)">
                  <path
                    d="M7.16602 16.2096V4.54297L16.3327 10.3763L7.16602 16.2096Z"
                    fill="white"
                  />
                </g>
              </svg>
            )}
          </button>
          <button
            title="Next"
            data-gtm-loc="timelapse"
            data-gtm-content="next"
            onClick={handleNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <mask
                id="mask0_2896_3529"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="21"
              >
                <rect x="0.5" y="0.375" width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2896_3529)">
                <path
                  d="M14.2502 15.375V5.375H15.9168V15.375H14.2502ZM5.0835 15.375V5.375L12.5835 10.375L5.0835 15.375Z"
                  fill="white"
                />
              </g>
            </svg>
          </button>
        </div>
        <div className="timesersbuletsarrs_cont timesersbuletsarrs_Prev">
          <img src={TimeSeriesBuletsIconPrev} alt="timesereies_prevarrow" />
        </div>
        <ul>
          {timeSeriesData &&
            timeSeriesData?.map((eachyear, index) => {
              return (
                <>
                  <li
                    className={
                      currentYear === eachyear?.year ? "active_timeseries" : ""
                    }
                    key={index}
                  >
                    <div
                      className="timeseries_btn"
                      onClick={() => showYearData(eachyear?.year)}
                    >
                      <div className="timeseries_bullets"></div>
                      <div className="timeseries_Year">{eachyear?.year}</div>
                    </div>
                  </li>
                </>
              );
            })}
        </ul>
        <div className="timesersbuletsarrs_cont timesersbuletsarrs_Next">
          <img src={TimeSeriesBuletsIconNext} alt="timesereies_nextarrow" />
        </div>
        {isPlaying &&
          timeSeriesData?.map((data, index) => {
            return (
              <>
                {currentYear == data?.year && (
                  <GroundOverlay
                    key={index}
                    url={
                      `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/` +
                      props.projectDetail?.projectId +
                      "/TimeSeries/" +
                      data?.imageName
                    }
                    // url={require("./TimeSeriesImages/" + data.imageName)}
                    bounds={
                      new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(
                          data?.bounds?.[0],
                          data?.bounds?.[1]
                        ),
                        new window.google.maps.LatLng(
                          data?.bounds?.[2],
                          data?.bounds?.[3]
                        )
                      )
                    }
                    defaultOpacity={0.5}
                  />
                )}
              </>
            );
          })}
        {isNextPrev &&
          timeSeriesData?.map((data, index) => {
            return (
              <>
                {currentYear == data?.year && (
                  <GroundOverlay
                    key={index}
                    url={
                      `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/` +
                      props.projectDetail?.projectId +
                      "/TimeSeries/" +
                      data?.imageName
                    }
                    // url={require("./TimeSeriesImages/" + data.imageName)}
                    bounds={
                      new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(
                          data?.bounds?.[0],
                          data?.bounds?.[1]
                        ),
                        new window.google.maps.LatLng(
                          data?.bounds?.[2],
                          data?.bounds?.[3]
                        )
                      )
                    }
                    defaultOpacity={0.5}
                  />
                )}
              </>
            );
          })}
      </div>
    </>
  );
}
