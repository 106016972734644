import React, { useEffect, useState, useCallback } from "react";
import { Modal, ModalVariant } from "@patternfly/react-core";
import detailMediaListvView from "./mediaImages/detailMediaListvView.jpg";
import ProjectListMediaPopup from "./ProjectListMedia";
import ProjectListVideosPopup from "./ProjectListVideos";
import ProjectListActionPopup from "./ProjectListActionModal";
import Pagination from "react-js-pagination";
import config from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import project from "../../../Reducers/Project";
import moment from "moment";
import TreeDetails from "./TreeDetails";
import TreeListingDefaultImg from "../../../assets/TreeListing_DefaultImage.png";
import Loader from "../Loader/NewLoader";

export default function ProjectListNew(props) {
  const [isModalProListViewAction, setisModalProListViewAction] =
    useState(false);
  const [isModalProListViewMedia, setisModalProListViewMedia] = useState(false);
  const [isModalProListViewVideos, setisModalProListViewVideos] =
    useState(false);
  const [treeDataList, setTreeDataList] = useState([]);
  const [treeImageList, setTreeImageList] = useState([]);
  const [treeImageNewList, setTreeImageNewList] = useState([]);
  const [selectedTree, setSelectedTree] = useState({});
  const [healthyCount, setHealthyCount] = useState(0);
  const [unHealthyCount, setUnHealthyCount] = useState(0);
  const [droneLiDARCount, setDroneLidarCount] = useState(0);
  const [groundCount, setGroundCount] = useState(0);
  const [rgbCount, setRgbCount] = useState(0);
  const [allTreeCount, setAllTreeCount] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [treeTypeImage, SetTreeTypeImage] = useState([]);
  const [activeProjectTreeTypesGrid, SetActiveProjectTreeTypesGrid] = useState(
    []
  );
  const [selectedTreeData, setSelectedTreeData] = useState([]);
  const reduxState = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [sortType, setSortType] = useState(true);
  const [up, setUp] = useState("active");
  const [down, setDown] = useState("");
  const [pageRange, setPageRange] = useState(10);
  const [status, setStatus] = useState(null);
  const [sortBy, setSortBy] = useState("tree_id");
  const [selectedSource, setSelectedSource] = useState("Ground Sampling");
  const [selectedTreeHealth, setSelectedTreeHealth] = useState("All");
  const [totalCount, setTotalCount] = useState(0);
  const [totalAllCount, setTotalAllCount] = useState(0);
  const [activeMediaitem1, setActiveMediaitem1] = useState();
  const [modelvalues, setModel] = useState();
  const [IsPopUpStatus, setIsPopUpStatus] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   if (reduxState.treeData) {
  //     setSelectedTreeData(reduxState.treeData);
  //   }
  // }, [reduxState.treeData]);

  useEffect(() => {
    setActivePage(1);
    setPagesize(10);
    setPageRange(10);
    setSelectedSource("Ground Sampling");
    setSelectedTreeHealth("All");
    GetAllTreeDetails(props.projectDetail.projectId, searchText);
  }, [props.projectDetail.projectId]);

  useEffect(() => {
    GetAllTreeDetails(props.projectDetail.projectId, searchText);
  }, [selectedSource, activePage, pagesize, pageRange, selectedTreeHealth]);

  const handleChangeSource = (event) => {
    setSelectedSource(event.target.value);
    // GetAllTreeDetails(props.projectDetail.projectId);
  };

  const handleChangeTreeHealth = (event) => {
    setSelectedTreeHealth(event.target.value);
    //GetAllTreeDetails(props.projectDetail.projectId);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    GetAllTreeDetails(props.projectDetail.projectId, searchText);
  };

  const handleSortClick = (SortBy) => {
    let up = "";
    let down = "active";
    let sortTypes = !sortType;

    if (sortTypes) {
      up = "active";
      down = "";
    }
    setSortBy(SortBy);
    setSortType(sortTypes);
    setUp(up);
    setDown(down);
    GetAllTreeDetails(props.projectDetail.projectId, searchText);
  };

  const handleModalProjectListAction = (eachTree) => {
    let speciesdata = treeImageList?.filter(
      (x) => x.speciesName === eachTree.common_name
    );
    setTreeImageNewList(speciesdata);
    setSelectedTree(eachTree);
    setisModalProListViewAction(!isModalProListViewAction);
    if (!isModalProListViewAction) {
      document.body.classList.remove("prolistviewbackdrop_cont");
      document.body.classList.add("prolistviewkmediabg_cont");
    } else {
      document.body.classList.add("prolistviewbackdrop_cont");
      document.body.classList.remove("prolistviewkmediabg_cont");
    }
  };

  const handleModalProjectListMedia = (eachTree) => {
    setSelectedTree(eachTree);
    setisModalProListViewMedia(!isModalProListViewMedia);
    if (!isModalProListViewMedia) {
      document.body.classList.remove("prolistviewbackdrop_cont");
      document.body.classList.add("prolistviewkmediabg_cont");
    } else {
      document.body.classList.add("prolistviewbackdrop_cont");
      document.body.classList.remove("prolistviewkmediabg_cont");
    }
  };

  const handleModalProjectListVideos = (eachTree) => {
    setSelectedTree(eachTree);
    setisModalProListViewVideos(!isModalProListViewVideos);
    if (!isModalProListViewVideos) {
      document.body.classList.remove("prolistviewbackdrop_cont");
      document.body.classList.add("prolistviewkmediabg_cont");
    } else {
      document.body.classList.add("prolistviewbackdrop_cont");
      document.body.classList.remove("prolistviewkmediabg_cont");
    }
  };

  const GetAllTreeDetails = (ProjectId, searchValue) => {
    setLoading(true);
    let url = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetAllTreeDetails/${activePage}/${pagesize}/${searchValue}/${sortType}/${sortBy}/${ProjectId}/${selectedTreeHealth}/${selectedSource}`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data?.list;
        let totalCount = data?.count;
        let totalAllCount = data?.count;
        let healthyCount = data?.healthyCount;
        let unHealthyCount = data?.unHealthyCount;
        let groundCount = data?.groundSamplingCount;
        let lidarCount = data?.droneLiDARCount;
        let rgbCount = data?.droneRGBCount;
        let TreeImage = data?.allImage;
        if (responseData !== null) {
          setTreeDataList(responseData);
          if (selectedTreeHealth == "Healthy") {
            setTotalCount(healthyCount);
            setTotalAllCount(totalAllCount);
          } else if (selectedTreeHealth == "UnHealthy") {
            setTotalCount(unHealthyCount);
            setTotalAllCount(totalAllCount);
          } else if (selectedTreeHealth == "All") {
            setTotalAllCount(totalAllCount);
            setTotalCount(totalAllCount);
          }
          setHealthyCount(healthyCount);
          setUnHealthyCount(unHealthyCount);
          setGroundCount(groundCount);
          setDroneLidarCount(lidarCount);
          setRgbCount(rgbCount);
          setTreeImageList(TreeImage);
          //setTotalAllCount(totalAllCount);
          setLoading(false);
        } else {
          setGroundCount(groundCount);
          setDroneLidarCount(lidarCount);
          setRgbCount(rgbCount);
          setTreeDataList(responseData);
          setTreeImageList(TreeImage);
          if (selectedTreeHealth == "Healthy") {
            setTotalCount(healthyCount);
            setTotalAllCount(healthyCount);
          } else if (selectedTreeHealth == "UnHealthy") {
            setTotalCount(unHealthyCount);
            setTotalAllCount(healthyCount);
          } else if (selectedTreeHealth == "All") {
            setTotalAllCount(healthyCount);
            setTotalCount(totalAllCount);
          }
          //setTotalCount(totalCount);
          setHealthyCount(healthyCount);
          setUnHealthyCount(unHealthyCount);
          //setTotalAllCount(totalAllCount);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error in request", error);
      });
  };

  const GetTreeTypeImage = (TreeTypeName) => {
    let UserDetail = {
      TreeTypeName: TreeTypeName,
    };
    let uri = `${config.API_HOST_URL}/api/TreeType/GetTreeTypeImage`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((resp) => resp.json())
      .then((data) => {
        let records = JSON.parse(data.List);
        if (records.length > 0) {
          SetTreeTypeImage(
            `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreeTypes/${records[0].TreeTypeImgPath}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetProjectTreeTypesGridDetail = (gridGuid) => {
    let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetProjectTreeTypesGridDetail/${gridGuid}`;
    let fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    fetch(uri, fetchOptions)
      .then((resp) => resp.json())
      .then((result) => {
        let records = result.data;
        if (records !== null) {
          SetActiveProjectTreeTypesGrid(records);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showTreeOnMap = (eachTree) => {
    // console.log("eachTree", eachTree);
    project(
      reduxState?.treeData,
      dispatch({ type: "SET_TREEDATA_DETAILS", data: eachTree })
    );
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchByEnter = (event) => {
    if (event.key === "Enter") {
      executeSearch(event.target.value);
    }
  };

  const handleSearchClick = () => {
    executeSearch(searchText);
  };

  const executeSearch = (query) => {
    setSearchText(query);
    setActivePage(1);
    GetAllTreeDetails(props.projectDetail.projectId, query);
  };

  const handleBackspaces = (event) => {
    if (
      (event.key === "Delete" && event.code === "Delete") ||
      (event.key === "Backspace" && event.code === "Backspace")
    ) {
      setSearchText(null);
      setActivePage(1);
      GetAllTreeDetails(props.projectDetail.projectId, null);
    }
  };

  const GetmultipleImage = (item) => {
    if (this.mounted) {
      let UserDetail = {
        ProjectId: item.projectId,
        Ec5_uuid: item.ec5_uuid,
      };
      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetProjectTreeTypeImage/${item.projectId}/${item.ec5_uuid}`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          let records = JSON.parse(data.List);
          if (this.mounted && records.length > 0) {
            this.setState({
              multipleImages: records,
            });
          } else {
            this.setState({
              multipleImages: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const SetVideoValues = (item) => (e) => {
    setActiveMediaitem1({ activeMediaitem1: item }, () =>
      this.GetmultipleImage(item)
    );
    this.handleModalToggle2(e);
  };

  const handleModalToggle2 = (e) => {
    setModel(({ isModalOpen2 }) => ({
      isModalOpen2: !isModalOpen2,
    }));
  };

  //const handleModalToggle = useCallback(() => {
  const handleModalToggle = (eachTree) => {
    //debugger;
    setSelectedTree(eachTree);
    setIsPopUpStatus(!IsPopUpStatus);
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}

      <div className="selectsearch_wrap">
        <select
          className="prolstvewpop_select"
          onChange={handleChangeSource}
          value={selectedSource}
        >
          <option value="Ground Sampling" selected="">
            Ground Sampling ({groundCount}){" "}
          </option>
          <option value="Drone LiDAR" selected="">
            Drone LiDAR ({droneLiDARCount}){" "}
          </option>
          <option value="Drone RGB" selected="">
            Drone RGB ({rgbCount}){" "}
          </option>
        </select>
        <select
          className="prolstvewpop_select"
          onChange={handleChangeTreeHealth}
          value={selectedTreeHealth}
        >
          <option value="All" selected="">
            All ({totalAllCount}){" "}
          </option>
          <option value="Healthy" selected="">
            Healthy ({healthyCount}){" "}
          </option>
          <option value="UnHealthy" selected="">
            Unhealthy ({unHealthyCount}){" "}
          </option>
        </select>
        <div className="projlistview_search">
          <input
            type="text"
            id="SearchText"
            autoFocus
            placeholder="Search for tree type, tree Id, species..."
            value={searchText}
            onChange={handleInputChange}
            onKeyPress={handleSearchByEnter}
            onKeyDown={handleBackspaces}
          />
          <div className="projlistsrchbtn" onClick={handleSearchClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6583 12.0086L10.6967 9.04701C11.3086 8.12475 11.6668 7.02049 11.6668 5.83339C11.6668 2.61686 9.04993 0 5.83339 0C2.61686 0 0 2.61686 0 5.83339C0 9.04993 2.61686 11.6668 5.83339 11.6668C7.02049 11.6668 8.12475 11.3086 9.04701 10.6967L12.0086 13.6583C12.4636 14.1139 13.2033 14.1139 13.6583 13.6583C14.1139 13.2027 14.1139 12.4642 13.6583 12.0086ZM1.75002 5.83339C1.75002 3.5817 3.5817 1.75002 5.83339 1.75002C8.08508 1.75002 9.91677 3.5817 9.91677 5.83339C9.91677 8.08508 8.08508 9.91677 5.83339 9.91677C3.5817 9.91677 1.75002 8.08508 1.75002 5.83339Z"
                fill="#858585"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="Pojectsummary_cont">
        <table className="prolistviewtbl">
          <thead>
            {treeDataList ? (
              <tr>
                <th className="thGridNo">SN</th>
                <th className="treeididth">
                  Tree Id
                  <div
                    className="sortArrow"
                    onClick={() => handleSortClick("tree_id")}
                  >
                    <i
                      className={
                        sortBy === "tree_id"
                          ? `fas fa-sort-up ${up}`
                          : "fas fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        sortBy === "tree_id"
                          ? `fas fa-sort-down ${down}`
                          : "fas fa-sort-down"
                      }
                    ></i>
                  </div>
                </th>
                <th className="treespeciesth">
                  Species Name{" "}
                  <div
                    className="sortArrow"
                    onClick={() => handleSortClick("species_name")}
                  >
                    <i
                      className={
                        sortBy === "species_name"
                          ? `fas fa-sort-up ${up}`
                          : "fas fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        sortBy === "species_name"
                          ? `fas fa-sort-down ${down}`
                          : "fas fa-sort-down"
                      }
                    ></i>
                  </div>
                </th>
                {selectedSource === "Ground Sampling" && (
                  <th className="treeplantdtth">
                    Tree Planted Date
                    <div
                      className="sortArrow"
                      onClick={() => handleSortClick("planted_date")}
                    >
                      <i
                        className={
                          sortBy === "planted_date"
                            ? `fas fa-sort-up ${up}`
                            : "fas fa-sort-up"
                        }
                      ></i>
                      <i
                        className={
                          sortBy === "planted_date"
                            ? `fas fa-sort-down ${down}`
                            : "fas fa-sort-down"
                        }
                      ></i>
                    </div>
                  </th>
                )}
                <th className="treesstatusth">
                  Status
                  <div
                    className="sortArrow"
                    onClick={() => handleSortClick("tree_Status")}
                  >
                    <i
                      className={
                        sortBy === "tree_Status"
                          ? `fas fa-sort-up ${up}`
                          : "fas fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        sortBy === "tree_Status"
                          ? `fas fa-sort-down ${down}`
                          : "fas fa-sort-down"
                      }
                    ></i>
                  </div>
                </th>
                {selectedSource === "Ground Sampling" && (
                  <th className="treesplantbyth">
                    Tree Planted By
                    <div
                      className="sortArrow"
                      onClick={() => handleSortClick("treePlantedby")}
                    >
                      <i
                        className={
                          sortBy === "treePlantedby"
                            ? `fas fa-sort-up ${up}`
                            : "fas fa-sort-up"
                        }
                      ></i>
                      <i
                        className={
                          sortBy === "treePlantedby"
                            ? `fas fa-sort-down ${down}`
                            : "fas fa-sort-down"
                        }
                      ></i>
                    </div>
                  </th>
                )}
                {selectedSource === "Ground Sampling" && (
                  <th className="treeheightth">
                    Tree Height (m)
                    <div
                      className="sortArrow"
                      onClick={() => handleSortClick("tree_height")}
                    >
                      <i
                        className={
                          sortBy === "tree_height"
                            ? `fas fa-sort-up ${up}`
                            : "fas fa-sort-up"
                        }
                      ></i>
                      <i
                        className={
                          sortBy === "tree_height"
                            ? `fas fa-sort-down ${down}`
                            : "fas fa-sort-down"
                        }
                      ></i>
                    </div>
                  </th>
                )}
                {/* <th className="treewealthth">
              Tree Health
              <div
                className="sortArrow"
                onClick={() => handleSortClick("tree_health")}
              >
                <i
                  className={
                    sortBy === "tree_health"
                      ? `fas fa-sort-up ${up}`
                      : "fas fa-sort-up"
                  }
                ></i>
                <i
                  className={
                    sortBy === "tree_health"
                      ? `fas fa-sort-down ${down}`
                      : "fas fa-sort-down"
                  }
                ></i>
              </div>
            </th> */}
                <th className="treesourceth">
                  Source
                  <div
                    className="sortArrow"
                    onClick={() => handleSortClick("source")}
                  >
                    <i
                      className={
                        sortBy === "source"
                          ? `fas fa-sort-up ${up}`
                          : "fas fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        sortBy === "source"
                          ? `fas fa-sort-down ${down}`
                          : "fas fa-sort-down"
                      }
                    ></i>
                  </div>
                </th>
                {selectedSource === "Ground Sampling" && (
                  <th className="treemediath"> Media </th>
                )}
                <th className="treeactionsth">Actions</th>
              </tr>
            ) : (
              ""
            )}
          </thead>
          <tbody>
            {treeDataList ? (
              treeDataList.map((eachTree, index) => {
                return (
                  <tr
                    key={eachTree?.id ? eachTree?.id : index}
                    className={
                      reduxState?.treeData?.tree_id === eachTree?.tree_id
                        ? "listTrActive"
                        : ""
                    }
                  >
                    <td className="tdGridNo">{index + 1}</td>
                    <td className="idtd treeidtd">
                      <a onClick={() => showTreeOnMap(eachTree)}>
                        {eachTree?.tree_id ? eachTree?.tree_id : "-"}
                      </a>
                    </td>
                    <td className="treetypetd">
                      <span style={{ fontWeight: "bold" }}>
                        {eachTree?.common_name
                          ? eachTree?.common_name
                          : eachTree?.latin_name
                          ? eachTree?.latin_name
                          : "-"}
                      </span>
                    </td>
                    {selectedSource === "Ground Sampling" && (
                      <td className="idtd">
                        {eachTree?.planted_date
                          ? moment(eachTree?.planted_date)
                              .format("DD MMM YYYY")
                              .toUpperCase()
                          : "-"}
                      </td>
                    )}
                    <td className="idtd alivetd treesstatustd">
                      <span>
                        {eachTree?.tree_Status ? eachTree?.tree_Status : "-"}
                      </span>
                    </td>
                    {selectedSource === "Ground Sampling" && (
                      <td className="idtd treesplantbytd">
                        {eachTree?.treePlantedby
                          ? eachTree?.treePlantedby
                          : "-"}
                      </td>
                    )}
                    {selectedSource === "Ground Sampling" && (
                      <td className="idtd treeheighttd">
                        {eachTree?.tree_height ? eachTree?.tree_height : "-"}
                      </td>
                    )}
                    {/* <td className="idtd treewealthtd">
            {eachTree?.tree_health ? eachTree?.tree_health : "-"}
          </td> */}
                    <td className="idtd treesourcetd">
                      {eachTree?.source ? eachTree?.source : "-"}
                    </td>
                    {selectedSource === "Ground Sampling" && (
                      <td className="idtd listviewmediatd">
                        {eachTree.photos && eachTree.photos.length > 0 ? (
                          <a
                            className="listviewmedia_popup"
                            onClick={() =>
                              handleModalProjectListMedia(eachTree)
                            }
                          >
                            <img
                              src={
                                `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${eachTree?.projectID}/${eachTree?.ec5_uuid}/` +
                                eachTree?.photos?.[0]
                              }
                              alt="trees plant"
                            />
                          </a>
                        ) : (
                          // <a
                          //   className="listviewmedia_popup"
                          //   onClick={() =>
                          //     handleModalProjectListMedia(eachTree)
                          //   }
                          // >
                          <img
                            src={TreeListingDefaultImg}
                            alt="defaultimg_treelistimg"
                            className="defaultimg_treelist"
                          />
                          // </a>
                        )}
                        {eachTree.videos && eachTree.videos.length > 0 ? (
                          <a
                            className="listviewmedia_popup listviewvideos_cont"
                            onClick={() =>
                              handleModalProjectListVideos(eachTree)
                            }
                          >
                            <i className="fa fa-video-camera"></i>
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    )}
                    <td className="action_cont">
                      <a
                        title="Details"
                        onClick={() => handleModalProjectListAction(eachTree)}
                      >
                        <i className="fas fa-satellite"></i>
                      </a>
                      {/* {selectedSource === "Ground Sampling" &&
                      eachTree.approvalStatus &&
                      eachTree.approvalStatus.toLowerCase() === "approved" ? (
                        <a className="thmbsupdwn_apprvestas">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M13.5 15.75H6V6L11.25 0.75L12.1875 1.6875C12.275 1.775 12.347 1.89375 12.4035 2.04375C12.46 2.19375 12.488 2.3375 12.4875 2.475V2.7375L11.6625 6H15.75C16.15 6 16.5 6.15 16.8 6.45C17.1 6.75 17.25 7.1 17.25 7.5V9C17.25 9.0875 17.2407 9.18125 17.2222 9.28125C17.2037 9.38125 17.1755 9.475 17.1375 9.5625L14.8875 14.85C14.775 15.1 14.5875 15.3125 14.325 15.4875C14.0625 15.6625 13.7875 15.75 13.5 15.75ZM4.5 6V15.75H1.5V6H4.5Z"
                              fill="#50b041"
                            ></path>
                          </svg>
                        </a>
                      ) : selectedSource === "Ground Sampling" &&
                        eachTree.approvalStatus &&
                        (eachTree.approvalStatus.toLowerCase() === "pending" ||
                          eachTree.approvalStatus.toLowerCase() ===
                            "rejected") ? (
                        <a className="thmbsupdwn_apprvestas">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M4.5 2.25H12V12L6.75 17.25L5.8125 16.3125C5.725 16.225 5.653 16.1062 5.5965 15.9562C5.54 15.8062 5.512 15.6625 5.5125 15.525V15.2625L6.3375 12H2.25C1.85 12 1.5 11.85 1.2 11.55C0.9 11.25 0.75 10.9 0.75 10.5V9C0.75 8.9125 0.75925 8.81875 0.77775 8.71875C0.79625 8.61875 0.8245 8.525 0.8625 8.4375L3.1125 3.15C3.225 2.9 3.4125 2.6875 3.675 2.5125C3.9375 2.3375 4.2125 2.25 4.5 2.25ZM13.5 12V2.25H16.5V12H13.5Z"
                              fill="#b54e56"
                            ></path>
                          </svg>
                        </a>
                      ) : (
                        ""
                      )} */}
                      {props?.userInfo?.roleId === 1 &&
                        selectedSource === "Ground Sampling" && (
                          <a
                            className="editicon_btn"
                            onClick={() => handleModalToggle(eachTree)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M4.09942 15.6C3.62017 15.6 3.21718 15.4367 2.89046 15.1099C2.56375 14.7832 2.40039 14.3802 2.40039 13.901V4.09905C2.40039 3.6198 2.56375 3.21681 2.89046 2.89009C3.21718 2.56337 3.62017 2.40002 4.09942 2.40002H9.25856C9.46481 2.40002 9.6195 2.46525 9.72262 2.59571C9.82575 2.72617 9.87731 2.86776 9.87731 3.02047C9.87731 3.17318 9.82613 3.3142 9.72377 3.44353C9.62142 3.57285 9.47115 3.63752 9.27298 3.63752H4.09942C3.98405 3.63752 3.87828 3.68559 3.78212 3.78174C3.68597 3.8779 3.63789 3.98367 3.63789 4.09905V13.901C3.63789 14.0164 3.68597 14.1221 3.78212 14.2183C3.87828 14.3144 3.98405 14.3625 4.09942 14.3625H13.9014C14.0167 14.3625 14.1225 14.3144 14.2187 14.2183C14.3148 14.1221 14.3629 14.0164 14.3629 13.901V8.66974C14.3629 8.46349 14.4281 8.3088 14.5586 8.20567C14.689 8.10255 14.8306 8.05099 14.9833 8.05099C15.1361 8.05099 15.2771 8.10255 15.4064 8.20567C15.5357 8.3088 15.6004 8.46349 15.6004 8.66974V13.901C15.6004 14.3802 15.437 14.7832 15.1103 15.1099C14.7836 15.4367 14.3806 15.6 13.9014 15.6H4.09942ZM7.25664 9.89426V9.1356C7.25664 8.9062 7.30238 8.68455 7.39385 8.47065C7.48532 8.25675 7.60929 8.07215 7.76578 7.91685L13.8898 1.7928C14.0225 1.66011 14.1606 1.56852 14.3043 1.51803C14.4479 1.46756 14.5963 1.44232 14.7495 1.44232C14.8994 1.44232 15.0525 1.47068 15.2088 1.52741C15.365 1.58415 15.5071 1.67405 15.635 1.79713L16.1499 2.28752C16.2797 2.4202 16.3747 2.56549 16.4348 2.72338C16.4949 2.88127 16.5249 3.04088 16.5249 3.20223C16.5249 3.36357 16.4978 3.51793 16.4436 3.6653C16.3894 3.81268 16.2949 3.9515 16.16 4.08176L10.0114 10.2491C9.8528 10.4077 9.66706 10.5298 9.45423 10.6154C9.24142 10.701 9.02091 10.7438 8.7927 10.7438H8.10615C7.86961 10.7438 7.66889 10.6613 7.50399 10.4964C7.33909 10.3315 7.25664 10.1308 7.25664 9.89426ZM8.49414 9.50627H8.97587L13.7499 4.75099L13.509 4.49137L13.2206 4.22165L8.49414 8.9481V9.50627Z"
                                fill="#333"
                              ></path>
                            </svg>
                          </a>
                        )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Records not found</td>
              </tr>
            )}
          </tbody>
        </table>
        <Modal
          title="listViewTitle"
          ariaLabel="listView"
          ariaLabelledby="list-view-title"
          isOpen={isModalProListViewMedia}
          onClose={handleModalProjectListMedia}
          className="projdetailpage_modal projdetimgcarusel_modal"
        >
          <ProjectListMediaPopup eachTreeData={selectedTree} />
        </Modal>

        <Modal
          title="listViewTitle"
          ariaLabel="listView"
          ariaLabelledby="list-view-title"
          isOpen={isModalProListViewVideos}
          onClose={handleModalProjectListVideos}
          className="projdetailpage_modal projdetimgcarusel_modal"
        >
          <ProjectListVideosPopup eachTreeData={selectedTree} />
        </Modal>

        <Modal
          title="listViewActionModal"
          ariaLabel="listView"
          ariaLabelledby="list-view-title"
          isOpen={isModalProListViewAction}
          onClose={handleModalProjectListAction}
          className="projdetailpage_modal projdetaction_modal"
        >
          <ProjectListActionPopup
            eachTreeData={selectedTree}
            speceiestreedata={treeImageNewList}
          />
        </Modal>

        <div className="paginationDiv">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={pagesize}
            totalItemsCount={totalCount}
            pageRangeDisplayed={pageRange}
            nextPageText={<i className="fa fa-angle-right"></i>}
            prevPageText={<i className="fa fa-angle-left"></i>}
            onChange={(pageNumber) => handlePageChange(pageNumber)}
          />
        </div>
      </div>
      {IsPopUpStatus && (
        <TreeDetails
          IsOpen={true}
          editMode={editMode}
          setEditMode={setEditMode}
          eachTreeData={selectedTree}
          GetAllTreeDetails={GetAllTreeDetails}
          searchText={searchText}
        />
      )}
    </>
  );
}
