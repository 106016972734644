import React, { Component, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

export default function ManualPlotting({ markerDroneArray, center }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <>
      {markerDroneArray?.length > 0 &&
        markerDroneArray?.map((eachMarker, index) => {
          let cord = {
            lat: eachMarker.geometry?.coordinates?.[1],
            lng: eachMarker.geometry?.coordinates?.[0],
          };
          return (
            <div key={index}>
              {
                <Marker
                  position={cord}
                  icon={
                    cord.lat === center.lat && cord.lng === center.lng
                      ? require("./markerPin.png")
                      : require("./yellow.svg").default
                  }
                  onMouseOver={() => {
                    setSelectedIndex(index);
                  }}
                  onMouseOut={() => setSelectedIndex(null)}
                >
                  {selectedIndex && selectedIndex == index && (
                    <InfoWindow position={cord}>
                      <div>
                        <p>
                          Tree Name:{" "}
                          {eachMarker?.properties?.Comm_name
                            ? eachMarker?.properties?.Comm_name
                            : "NA"}
                        </p>
                        <p>Latitude: {cord.lat}</p>
                        <p>Longitude: {cord.lng}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              }
            </div>
          );
        })}
    </>
  );
}
