import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import config from "../../../utils/config";
import { globalHistory } from "@reach/router";

export default function ProjectListMediaPopup({ images }) {
  const [projectId, setProjectId] = useState("");
  useEffect(() => {
    let currentPath = globalHistory.location?.pathname;
    let Id = currentPath?.split(":")[1]?.replace("/", "");
    setProjectId(Id);
    console.log("id", Id);
  }, []);
  return (
    <div className="treedetails_cont">
      <div className="treedetimg">
        <Carousel>
          {images?.map((imageName, index) => (
            <img
              src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/ProjectLevelImages/${projectId}/${imageName}`}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
