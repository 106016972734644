import React, { useEffect } from "react";
import "./CertificateAO.css";
import treetieslogo from "./Images/treeties.png";
import goldenmilelogo from "./Images/goldenmile.png";
import Aologo from "./Images/adventuresoverland-logo.png";
import AOSign from "./Images/ao.png";
import PwSign from "./Images/pw.png";

export default function Co2CertificateAO(props) {
  return (
    <div id="content">
      <div className="wrapper certificationcont">
        <div className="certificationinner_cont">
          <ul className="topheadcont">
            <li className="complogo himalayaslogo">
              <img src={treetieslogo} alt="treeties" />
              <img src={goldenmilelogo} alt="goldmine" />
            </li>
            <li className="complogo adventureslogo">
              <img src={Aologo} alt="adventuresoverland" />
            </li>
          </ul>
          <div className="certificationcontent">
            <h1>
              Certification
              <br /> <abbr>of eco support</abbr>{" "}
            </h1>
            <span>Congratulations to</span>
            <h2>{props.Name}</h2>
            <p>
              {props.Treecount} trees will be planted by Treeties, a grass root
              eco movement which has already planted more than 1 million trees
              at altitudes of 15,000 ft. Your contribution will help remove CO2
              and other pollutants from the air we breathe. These plantations
              are helping biodiversity to flourish by creating verdant habitats
              for migratory birds and other wildlife. Every tree planted enables
              an entire community to sustain itself while providing for a better
              planet. You are now a part of a legacy project. We, at Adventures
              Overland appreciate your contribution to a more environmentally
              friendly world
            </p>
          </div>
          <div className="footercont">
            <div className="footcontcontent cofounder_ao">
              <img
                className="founder_sign"
                src={AOSign}
                alt="cofounder-AO-sign"
              />
              <h3>Tushar Agarwal</h3>
              <span>CO-FOUNDER ADVENTURES OVERLAND</span>
            </div>
            <div className="footcontcontent cofounder_golden">
              <img className="founder_sign" src={PwSign} />
              <h3>Rajesh Patel</h3>
              <span>CO-FOUNDER GOLDEN MILE TRUST</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
