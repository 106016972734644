import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import { Button } from '@patternfly/react-core';
import config from "../../../utils/config";
import moment from "moment";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            List: [],
            count: 0,
            activePage: 1,
            pagesize: 10,
            SearchText: '',
            PageName: "Payments",
            SortBy: 'created_at',
            SortType: false,
            Up: "active",
            Down: "",
            PageRange: 10,
            isDataLoaded: false,
            errors: [],
            FromDate: "",
            ToDate: "",
            date: "",
            IsLoader: false,
            Message: "",
            defaultMonth:11,
        };
        this.state = this.initialState;
        this.mounted = true;
    }
    handleUserInputproperty = (date, name) => {
        if (name === "FromDate") {
            this.setState({ FromDate: date }, () => { this.Get() });
        }
        if (name === "ToDate") { 
            this.setState({ FromDate: new Date(new Date(date).setMonth(new Date(date).getMonth() - this.state.defaultMonth)), ToDate: date }, () => { this.Get() });
            // this.setState({ FromDate: new Date(new Date(date).setMonth(new Date(date).getMonth() - 3)), ToDate: date }, () => { this.Get() });
        }
    }
    onExportDate = () => {
        this.GetDataToExcel()
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;
        this.GetGlobalSettings("Date Configuration"); 
        this.setState({ FromDate: new Date(new Date().setMonth(new Date().getMonth() - this.state.defaultMonth)), ToDate: new Date() }, () => { this.Get() });
         //this.setState({ FromDate: new Date(new Date().setMonth(new Date().getMonth() - 3)), ToDate: new Date() }, () => { this.Get() });
    }
    MapKeyInArray = (responseData, keyname) => {
        let myArray = responseData.find(obj => obj.key === keyname)
        if (myArray) {
            return myArray.value;
        }
    }
    GetGlobalSettings = (GroupName) => {
        let UserDetail = {
            GroupName: GroupName
        }
        let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
        let fetchOptions = {
            method: "POST",
            body: JSON.stringify(UserDetail),
            headers: {
                WebsiteGuid: this.props.websiteGUID,
                LanguageGuid: this.props.languageGUID,
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        };

        fetch(uri, fetchOptions)
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        if (data.success) {
                            let responseData = data.data;
                            if (this.mounted) { console.log("dataPayment",data.data)
                                this.setState({ defaultMonth: this.MapKeyInArray(responseData, "Payment List Default Months") }, function () { console.log(this.state.defaultMonth) });
                            }
                           console.log(this.state.defaultMonth)
                        } else {
                            let errors = {};
                            errors["message"] = data.message;
                            if (this.mounted) {
                                this.setState({ errors: errors });
                            }
                        }
                    });
                } else {
                    return response.json().then(data => {
                        let errors = {};
                        errors["message"] = data.message;
                        if (this.mounted) {
                            this.setState({
                                errors: errors
                            });
                        }
                    });
                }

            })
            .catch(error => {
                if (this.mounted) {
                    console.log(error);
                    this.setState({
                        errorMsg: error,
                        IsLoader: false
                    })
                }
            });
    }
    GetDataToExcel = () => {
        if (this.mounted) {
            this.setState({
                IsLoader: true
            });
            let SearchText = 'null'
            if (this.state.SearchText !== '') {
                SearchText = this.state.SearchText
            }
            window.location = `${config.API_HOST_URL}/api/Payement/ExportPaymentDetails/${SearchText}/${moment(this.state.FromDate).format("YYYY-MM-DD")}/${moment(this.state.ToDate).format("YYYY-MM-DD")}`;
            setTimeout(() => this.setState({ IsLoader: false }), 300);
        }
    }
    Get = () => {
        if (this.mounted) {
            let SearchText = 'null'
            if (this.state.SearchText !== '')
                SearchText = this.state.SearchText
            let uri = `${config.API_HOST_URL}/api/Payement/GetPayments/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}/${moment(this.state.FromDate).format("YYYY-MM-DD")}/${moment(this.state.ToDate).format("YYYY-MM-DD")}`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions)
                .then((resp) => resp.json())
                .then(data => {
                    if (this.mounted) {
                        if (data.Count !== 0) {
                            this.setState({
                                List: JSON.parse(data.List),
                                count: data.Count,
                                Message: "",
                                isDataLoaded: true
                            });
                        } else {
                            this.setState({
                                List: [],
                                Message: data.Message,
                                count: 0,
                                pagesize: 10,
                                isDataLoaded: false
                            });
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    handlePageChange = pageNumber => {
        if (this.mounted) {
            this.setState({ activePage: pageNumber }, function () {
                this.Get();
            });
        }
    };
    handleSearchBySubmit = () => {
        if (this.mounted) {
            this.setState(
                {
                    activePage: 1
                },
                function () {
                    this.Get();
                }
            );
        }
    }
    handleChange = (e) => {
        if (this.mounted) {
            this.setState({
                SearchText: e.target.value
            });
        }
    }
    handleSortClick = (SortBy) => {
        let up = ""
        let down = "active"
        let sortType = this.state.SortType

        if (SortBy === this.state.SortBy)
            sortType = !this.state.SortType

        if (sortType) {
            up = "active"
            down = ""
        }
        this.setState({
            SortBy: SortBy,
            SortType: sortType,
            Up: up,
            Down: down
        }, function () { this.Get(); })
    }
    handleSearchByEnter = e => {
        if (e.key === "Enter") {
            if (this.mounted) {
                this.setState(
                    {
                        SearchText: e.target.value,
                        activePage: 1
                    },
                    function () {
                        this.Get();
                    }
                );
            }
        }
    };
    render() {
        const renderThumb = ({ style, ...props }) => {
            const thumbStyle = {
                borderRadius: 6,
                innerWidth: 4,
                backgroundColor: 'rgba(51, 51, 51, 1)'
            };
            return <div style={{ ...style, ...thumbStyle }} {...props} />;
        };
        const CustomScrollbars = props => (
            <Scrollbars
                renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                {...props}
            />
        );
        const isDataAvailable = this.state.List && this.state.List.length > 0;
        return (
            <>
                <div className="headbottom_cont">
                    <ul className="headbottom_content">
                        <li className="heading_cont">Payment List</li>
                        {isDataAvailable &&
                            <li className="export_btn">
                                <Button onClick={() => { this.onExportDate() }}>Export Report <i className="fa fa-plus"></i></Button>
                            </li>}
                    </ul>
                </div>
                <div className="growalarm_container applist_cont">
                    <Container>
                        <div className="growalarm_content">
                            <div className="commontblfltr_wrap">
                                <div className="showtxtnbtns_wrap paymentlistcont_wrap">
                                    <span className="showingtxt totalReport">Showing&nbsp;<strong> {this.state.count > 10 && <>{(this.state.activePage - 1) * 10 + 1} - {this.state.count < this.state.activePage * 10 ? this.state.count : this.state.activePage * 10} of </>}{this.state.count} </strong>&nbsp;{this.state.PageName}</span>
                                    <label htmlFor="fromdate">From Date :</label>
                                    <span className="showingtxt totalReport form-group field">
                                        <DatePicker
                                            className="DP_FromDate"
                                            name="FromDate"
                                            selected={this.state.FromDate}
                                            onSelect={(date) => { this.handleUserInputproperty(date, 'FromDate') }}
                                            minDate={new Date(new Date(this.state.ToDate).setMonth(new Date(this.state.ToDate).getMonth() - this.state.defaultMonth))}
                                            maxDate={this.state.ToDate}
                                        />
                                    </span>
                                    <label htmlFor="todate">To Date :</label>
                                    <span className=" showingtxt totalReport form-group field">
                                        <DatePicker
                                            className="DP_ToDate"
                                            name="ToDate"
                                            selected={this.state.ToDate}
                                            onChange={(date) => { this.handleUserInputproperty(date, 'ToDate') }}
                                            selectsEnd
                                            maxDate={new Date()}
                                            value={this.state.ToDate}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="commontbl_wrap">
                                <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                                    <table className="commontbl_cont">
                                        <thead>
                                            <tr>
                                                <th>Website
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("WebsiteName")} >
                                                        <i className={this.state.SortBy === "WebsiteName" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "WebsiteName" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>NGO
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("NgoName")} >
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>Email Id
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("email")} >
                                                        <i className={this.state.SortBy === "email" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "email" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>Project Name(s)
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("projectname")} >
                                                        <i className={this.state.SortBy === "projectname" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "projectname" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th className="status">Amount (INR)
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("amount")} >
                                                        <i className={this.state.SortBy === "amount" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "amount" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>Payment Date
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("created_at")} >
                                                        <i className={this.state.SortBy === "created_at" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "created_at" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th className="status statusth">Co2 In Kg
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("CarbonEmissionInTons")} >
                                                        <i className={this.state.SortBy === "CarbonEmissionInTons" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "CarbonEmissionInTons" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="tblsearch_cont">
                                                <td colSpan="9">
                                                    <div className="tblsearch">
                                                        <i className="fa fa-search"></i><input autoFocus type="text" placeholder="Search by website, ngo, email…" onKeyPress={this.handleSearchByEnter} value={this.state.SearchText} onChange={this.handleChange} />
                                                    </div>
                                                </td>
                                            </tr>
                                            {isDataAvailable ? this.state.List.map((item, index) => (
                                                <tr key={item.email}>
                                                    <td className="datetd">
                                                        {item.WebsiteName}
                                                    </td>
                                                    <td className="nametd">{item.NgoName}</td>
                                                    <td >{item.email}</td>
                                                    <td >{item.ProjectList}</td>
                                                    <td className="treeplantedtd">{item.amount / 100}</td>
                                                    <td className="locationtd">
                                                        <span>{moment(item.created_at * 1000).utcOffset("+05:30").format("DD MMM YYYY")}</span>
                                                    </td>
                                                    <td className="treeplantedtd">
                                                        {item.CarbonEmissionInTons}
                                                    </td>

                                                </tr>
                                            )) : <tr><td colSpan="6">{this.state.Message}</td></tr>}
                                        </tbody>
                                    </table>
                                </CustomScrollbars>
                            </div>
                            <div className="wrapper">
                                {this.state.isDataLoaded ? this.state.count > 0 ? (this.state.count > this.state.pagesize &&
                                    <div className="paginationDiv">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.pagesize}
                                            totalItemsCount={this.state.count}
                                            pageRangeDisplayed={this.state.PageRange}
                                            onChange={this.handlePageChange}
                                            nextPageText={<i className='fa fa-angle-right'></i>}
                                            prevPageText={<i className='fa fa-angle-left'></i>}
                                            lastPageText={<i className="fa fa-angle-double-right"></i>}
                                            firstPageText={<i className="fa fa-angle-double-left"></i>}
                                        />
                                    </div>) : <div>No Results Found</div> : <div></div>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        //  ApplicationInfo: state.user.ApplicationInfo,
        calendlyToken: state.configuration.calendlyToken
    };
}
function mapDispatchToProps(dispatch) {
    return {
        // setApplicationInfo: data => dispatch({ type: "SET_APPLICATION_DETAILS", data: data }),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentList);