import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import growlogo from "../../assets/logo.png";
import loginbg from "../../assets/loginbg.jpg";
import FormErrors from "../Login/FormErrors";
import { sha512 } from "js-sha512";
import CloseIcon from "../../assets/close.svg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      formErrors: { email: "", password: "", error: "" },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      fields: {},
      errors: {},
    };
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    this.mounted = true;
    document.getElementById("year").innerHTML = new Date().getFullYear();
  }

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.login(e);
    }
  };
  // This function will show the Forgot Password Page
  ForgetClick(e) {
    navigate("/Forgetpassword");
  }

  // This function will show the Registration Page
  Registerclick(e) {
    navigate("/register");
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  // This function will set the state of input fields and validate them when user enters the value in input boxes
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (this.mounted) {
      this.setState({ [name]: value });
    }
  }

  // This function will enable or disable the login button based on form validation
  validateForm() {
    let fields;
    fields = {
      Email: this.state.email,
      Password: this.state.password,
    };
    let errors = {};
    let formIsValid = true;

    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Enter your email address";
    }

    if (typeof fields["Email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["Email"])) {
        formIsValid = false;
        errors["Email"] = "Enter valid email address";
      }
    }
    if (!fields["Password"]) {
      formIsValid = false;
      errors["Password"] = "Enter your password";
    }
    if (this.mounted) {
      this.setState({
        formValid: this.state.emailValid && this.state.passwordValid,
      });
      this.setState({
        errors: errors,
      });
    }
    return formIsValid;
  }

  login = (e) => {
    debugger;
    e.preventDefault();
    if (this.validateForm()) {
      if (this.mounted) this.setState({ IsLoader: true });
      let pwd = this.state.password;
      let cryptedp = sha512(pwd);
      let UserDetail;
      UserDetail = {
        Email: this.state.email,
        Password: cryptedp,
      };
      let uri = `${config.API_HOST_URL}/api/login/AuthenticateUser`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          let fieldValidationErrors = this.state.formErrors;
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                let UserInfo = data.userData;
                let Userexists = data.projectexists;
                this.props.setUserMenuData(data.userMenuData);
                this.props.setUserData(data.userData);
                debugger;
                if (UserInfo.roleId == 1) {
                  // Grow Master Admin
                  navigate("/AdminDashboard");
                } else if (Userexists > 0) {
                  // NGO Admin
                  navigate("/NGODashboardNew");
                } else if (Userexists == 0) {
                  // NGO Admin no project add
                  navigate("/projects");
                } else if (UserInfo.roleId == 3) {
                  // Consumer
                  navigate("/NGODashboardNew");
                }
              } else {
                fieldValidationErrors.error = data.message;
                if (this.mounted) {
                  this.setState(
                    {
                      formErrors: fieldValidationErrors,
                    },
                    this.validateForm
                  );
                }
              }
            });
          } else {
            fieldValidationErrors.error = "something went wrong!";
            if (this.mounted) {
              this.setState(
                {
                  formErrors: fieldValidationErrors,
                },
                this.validateForm
              );
            }
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
          }
        });
    }
  };

  goBack = () => {
    window.history.go(-2);
  };

  render() {
    return (
      <div className="login_container">
        <div className="loginleft_cont">
          <div className="loginform_cont">
            {/* <div
              className="close-icon bg-clr border-rounded loginpge_backbtn"
              onClick={this.goBack}
            >
              <img src={CloseIcon} />
            </div> */}
            <div className="growlogocont">
              <div className="growlogo">
                {/* <img src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/companyLogo.png`}  alt="growlogo" /> */}
                <a href="/">
                  <img
                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/companyLogoWhiteBG.png`}
                    alt="growdashlogo"
                  />
                </a>
              </div>
            </div>

            <form className="loginfrm_wrap">
              <div>
                <h1 className="section_heading">Sign in</h1>
                <div className="panel panel-default">
                  <FormErrors formErrors={this.state.formErrors} />
                </div>
                <ul className="login_form">
                  <li className="field">
                    <input
                      type="text"
                      id="emailidlogin"
                      placeholder="Enter your email id"
                      name="email"
                      value={this.state.email}
                      onChange={(event) => this.handleUserInput(event)}
                      onKeyPress={this.handleEnter}
                    />
                    <label for="emailidlogin">Email Id</label>
                    <div className="mandatory has-error">
                      {this.state.errors.Email}
                    </div>
                  </li>
                  <li className="field">
                    <input
                      type="password"
                      id="passwordlogin"
                      placeholder="Enter your password"
                      name="password"
                      value={this.state.password}
                      onChange={(event) => this.handleUserInput(event)}
                      onKeyPress={this.handleEnter}
                    />
                    <label for="passwordlogin">Password</label>
                    <div className="mandatory has-error">
                      {this.state.errors.Password}
                    </div>
                  </li>
                  <li>
                    <button
                      id="ForgotPassword"
                      type="button"
                      className="forget_btn"
                      onClick={this.ForgetClick}
                    >
                      Forgot password?
                    </button>
                  </li>
                  <li>
                    <button
                      type="submit"
                      className="signin_btn"
                      onClick={this.login}
                    >
                      Sign In
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="register_btn"
                      onClick={this.Registerclick}
                    >
                      Not Registered? Sign Up Here
                    </button>
                  </li>
                </ul>
              </div>
            </form>
            <div className="footertext">
              Copyright © <span id="year"></span> TreeTies. All rights reserved.
              Powered by Powerweave
            </div>
          </div>
        </div>
        <div className="loginright_cont">
          <img
            src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/login-bg-image.png`}
            alt="loginbg"
          />
          <div className="logrghtimgtxt">
            46% of the world’s forest are already destroyed
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    userMenuData: state.user.userMenuData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => dispatch({ type: "SET_USER_DETAILS", data: data }),
    setUserMenuData: (data) =>
      dispatch({ type: "SET_USERMENU_DETAILS", data: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
