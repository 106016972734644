import React from "react";
import { Polygon, Marker, InfoWindow } from "@react-google-maps/api";
import { useEffect } from "react";
import { useState } from "react";

export default function GridPolygons({ multiPolygonData, layerName }) {
  const [selectedGrid, setSelectedGrid] = useState({});

  const options = {
    strokeWeight: 2,
    strokeOpacity: 1,
    strokeColor: "#ffffff",
    fillOpacity: 0,
    fillColor: "#85b66f",
  };

  //   useEffect(() => {
  //     console.log("multipolygon data", multiPolygonData);
  //   }, [multiPolygonData]);

  const onGridHover = (grid) => {
    setSelectedGrid(grid);
  };

  const getPolyCenter = (cords) => {
    if (cords && cords.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      cords.forEach((cord) =>
        bounds.extend(new window.google.maps.LatLng(cord.lat, cord.lng))
      );
      const centerCoord = bounds.getCenter();
      return centerCoord;
    }
  };
  const getColorCode = (grid) => {
    let LabelColorCode = "#fff";
    if (
      grid?.crop ||
      grid?.siteDescription ||
      grid?.irrigationPattern ||
      grid?.soilNitrate ||
      grid?.soilPhosphate ||
      grid?.soilPotassium ||
      grid?.soilpH ||
      grid?.soilElectricConductivity ||
      grid?.waterpH ||
      grid?.waterElectricConductivity
    ) {
      LabelColorCode = "#ff0";
    }
    return LabelColorCode;
  };
  return (
    <>
      {multiPolygonData?.length > 0 &&
        multiPolygonData?.map((eachFeature, index) => {
          let multiPoly = eachFeature.geometry?.coordinates?.[0]?.[0] || [];
          let newMultiCord = [];
          let label = eachFeature?.gridNo;
          label = label?.replace("Grid ", "");
          let color = getColorCode(eachFeature);
          {
            multiPoly?.map((cord) => {
              let obj = {};
              obj["lat"] = parseFloat(cord?.[1]);
              obj["lng"] = parseFloat(cord?.[0]);
              newMultiCord.push(obj);
            });
          }
          let center = getPolyCenter(newMultiCord);

          return (
            <>
              <Polygon
                key={index}
                paths={newMultiCord}
                options={options}
                className="gridPoly"
              ></Polygon>
              <Marker
                label={{
                  text: label.toString(),
                  color: color,
                  fontWeight: "bold",
                }}
                position={{
                  lat: center?.toJSON()?.lat,
                  lng: center?.toJSON()?.lng,
                }}
                icon={require("./transperantSVG.svg").default}
                onMouseOver={() => onGridHover(eachFeature)}
                // onMouseOut={() => setSelectedGrid(null)}
                className="samplinggridnum_cont"
              >
                {selectedGrid?.gridNo === eachFeature?.gridNo ? (
                  <InfoWindow
                    position={{
                      lat: center?.toJSON()?.lat,
                      lng: center?.toJSON()?.lng,
                    }}
                    className="treesinfowindow_cont"
                    onCloseClick={() => setSelectedGrid(null)}
                  >
                    <div>
                      <div className="infwdtree_head">
                        <span>
                          {selectedGrid?.gridNo ? selectedGrid?.gridNo : "-"}
                        </span>
                      </div>
                      <div className="treesinfowindowitem_wrap samplegridinfowindow_wrap">
                        <div className="treesinfowindow_item">
                          <span>
                            Crop <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.crop ? selectedGrid?.crop : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Site Description <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.siteDescription
                              ? selectedGrid?.siteDescription
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Irrigation Pattern <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.irrigationPattern
                              ? selectedGrid?.irrigationPattern
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Soil Nitrate <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.soilNitrate
                              ? selectedGrid?.soilNitrate
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Soil Phosphate <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.soilPhosphate
                              ? selectedGrid?.soilPhosphate
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Soil Potassium <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.soilPotassium
                              ? selectedGrid?.soilPotassium
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Soil pH <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.soilpH ? selectedGrid?.soilpH : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Soil Electric Conductivity <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.soilElectricConductivity
                              ? selectedGrid?.soilElectricConductivity
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Water pH <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.waterpH
                              ? selectedGrid?.waterpH
                              : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>
                            Water Electric Conductivity <abbr>:&nbsp;</abbr>
                          </span>
                          <span>
                            {selectedGrid?.waterElectricConductivity
                              ? selectedGrid?.waterElectricConductivity
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                ) : (
                  ""
                )}
              </Marker>
            </>
          );
        })}
    </>
  );
}
