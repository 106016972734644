import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import { navigate } from "@reach/router";
import { globalHistory } from "@reach/router";
import { Controller, useForm } from "react-hook-form";
import config from "../../utils/config";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import GetCollabarationData from "../Common/NewMapDesign/API/CollaborationGetApi";
import { v4 as uuidv4 } from "uuid";
import ToastNotification from "../Common/ToastNotification/ToastNotification";

function TreeTiesAddProjectForms1Colla(props) {
  const [projectId, setProjectId] = useState("");
  const [CollaborationsData, setCollaborationsData] = useState({});
  const [Collaborations, setCollaborations] = useState([]);
  const [collaborationId, setCollaborationId] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    setProjectId(Id);
  }, []);

  const AddcollaborationDetails = async (e) => {
    console.log("collaborationID", collaborationId);
    let proIdAlready = Collaborations.filter((x) => x.projectId === projectId);
    if (
      collaborationId === "" ||
      collaborationId === null ||
      (collaborationId === undefined && proIdAlready.length === 0)
    ) {
      const randomGuid = uuidv4();
      CollaborationsData.CollaborationId = randomGuid;
      CollaborationsData.projectId = projectId;
      setCollaborationId(randomGuid);
    } else {
      CollaborationsData.CollaborationId = collaborationId;
    }
    CollaborationsData.projectId = projectId;
    CollaborationsData.ProjectName = props.ProjectName;
    let data = CollaborationsData;
    let url = `${config.API_HOST_URL}/api/Projects/AddCollaborationsDetails`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
          ToastNotification.ShowSuccess(resp.message);
          clearAll();
          Nextpage();
        } else {
          ToastNotification.ShowError(resp.message);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  const AddToGrid = () => {
    if (!CollaborationsData?.Advisors?.toLowerCase().trim()) {
      ToastNotification.ShowError("Enter advisors name");
      setIsFormOpen(true);
    }
    if (!CollaborationsData?.Implementedby?.toLowerCase().trim()) {
      ToastNotification.ShowError("Enter implemented by name");
      setIsFormOpen(true);
    }
    if (!CollaborationsData?.Fundedby?.toLowerCase().trim()) {
      ToastNotification.ShowError("Enter funded by name");
      setIsFormOpen(true);
    }
    if (!CollaborationsData?.TreeTiesPOC?.toLowerCase().trim()) {
      ToastNotification.ShowError("Enter treeTies POC name");
      setIsFormOpen(true);
    } else {
      if (collaborationId == "") {
        let newItem = {
          advisors: CollaborationsData?.Advisors,
          implementedby: CollaborationsData?.Implementedby,
          fundedby: CollaborationsData?.Fundedby,
          treeTiesPOC: CollaborationsData?.TreeTiesPOC,
        };
        Collaborations.push(newItem);
        const extractedList = Collaborations.map((item) => ({
          advisors: item.advisors,
          implementedby: item.implementedby,
          fundedby: item.fundedby,
          treeTiesPOC: item.treeTiesPOC,
        }));
        const updateCollaborations = (newData) => {
          setCollaborations(newData);
        };
        updateCollaborations(extractedList);
      } else {
        const newState = Collaborations.map((obj) => {
          if (obj.collaborationId === collaborationId) {
            return {
              ...obj,
              advisors: CollaborationsData?.Advisors,
              implementedby: CollaborationsData?.Implementedby,
              fundedby: CollaborationsData?.Fundedby,
              treeTiesPOC: CollaborationsData?.TreeTiesPOC,
            };
          }
          console.log("obj", obj);
          return obj;
        });
        setCollaborations(newState);
      }
      setIsFormOpen(false);

      // setTimeout(() => {
      //   clearFields();
      // }, 5000);
    }
  };

  const Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Spo/:" + projectId, {
      state: { activeMenu: 3 },
    });
  };

  const Back = () => {
    navigate("/TreeTiesAddProjectForms1Par1/:" + projectId, {
      state: { activeMenu: 1 },
    });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let newData = { ...CollaborationsData };
    newData[name] = value;
    setCollaborationsData(newData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetCollabarationData(projectId);
      if (response.data.length !== 0) {
        setCollaborations((prevData) => [...prevData, ...response.data]);
        setIsFormOpen(false);
      } else {
        setIsFormOpen(true);
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const editCollaboration = (i) => {
    let selectedItem = {};
    setIsFormOpen(true);
    setCollaborationId(Collaborations[i]?.collaborationId);
    selectedItem["Advisors"] = Collaborations[i]?.advisors;
    selectedItem["Implementedby"] = Collaborations[i]?.implementedby;
    selectedItem["Fundedby"] = Collaborations[i]?.fundedby;
    selectedItem["TreeTiesPOC"] = Collaborations[i]?.treeTiesPOC;
    setCollaborationsData(selectedItem);
  };

  const clearAll = () => {
    setCollaborations([]);
    setCollaborationsData({
      Advisors: "",
      Implementedby: "",
      Fundedby: "",
      TreeTiesPOC: "",
    });
  };

  const removeCollaboration = (collaborationId) => {
    deleteCollaboration(collaborationId);
  };

  const deleteCollaboration = (collaborationId) => {
    if (window.confirm("Are you sure you want to delete this collaboration?")) {
      let url =
        `${config.API_HOST_URL}/api/Projects/DeleteCollaborationDetails/` +
        collaborationId;
      fetch(url, {
        method: "Delete",
        headers: {
          WebsiteGuid: props.websiteGUID,
          LanguageGuid: props.languageGUID,
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            const fetchDataRefresh = async () => {
              const response = await GetCollabarationData(projectId);
              console.log("res", response);
              if (response.data.length !== 0) {
                setCollaborations(response.data);
                setIsFormOpen(false);
                ToastNotification.ShowSuccess(
                  "Collaboration Deleted Successfully"
                );
              } else {
                setCollaborations(response.data);
                setIsFormOpen(true);
              }
              clearAll();
            };
            fetchDataRefresh();
            // ToastNotification.ShowSuccess(resp.message);
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
  };

  // Function to clear all fields
  // const clearFields = () => {
  //   setCollaborationsData({
  //     Advisors: "",
  //     Implementedby: "",
  //     Fundedby: "",
  //     TreeTiesPOC: "",
  //   });
  // };

  return (
    <div className="treeties-addproject-forms1-loc ecosyscom_cont">
      {/* <Header/> */}
      <div className="main-content-area6">
        {/* <LeftNavbar/>  */}
        <div className="middle-content-area6">
          <div className="container-18 ecoprosumry_cont">
            <ProjectSummary />
          </div>
          <div className="ecosys_middlecont exoloctab_cont exocolabtab_cont">
            <LeftTabMenuContainer
              activeMenu={props.location.state?.activeMenu}
            />
            <div className="ecosys_rightcont addproformloc_cont">
              <div className="container-17 pb-4">
                <div className="input-field-text20"></div>
              </div>
              {isFormOpen && (
                <form>
                  <div className="container11">
                    {/* Landparcel Start */}
                    <div className="land-parcels-container1">
                      <div className="land-parecel-content-area1">
                        <div className="close7">close</div>
                        <div className="left-content1">
                          <div className="hero-title13">
                            <b className="logo-a6">Add Collaborations</b>
                          </div>
                          <div className="container-26">
                            <div className="column-118">
                              <div className="content-title-text110">
                                <div className="hero-title13">
                                  <div className="project-summary13">
                                    Advisors
                                  </div>
                                </div>
                                <Controller
                                  control={control}
                                  name={"Advisors"}
                                  rules={{
                                    validate: {
                                      required: (value) => {
                                        if (!value)
                                          return "Advisors is required!";
                                      },
                                    },
                                  }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { error },
                                  }) => (
                                    <>
                                      <input
                                        className="enter-input-text-1"
                                        required
                                        pattern="[a-zA-Z0-9]+"
                                        type="text"
                                        name="Advisors"
                                        value={CollaborationsData?.Advisors}
                                        placeholder="Enter Advisors Name"
                                        onChange={(event) =>
                                          handleChange(event)
                                        }
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === "Backspace" ||
                                            event.key === "Delete"
                                          ) {
                                            return;
                                          }
                                          if (event.key === " ") {
                                            if (
                                              event.target.value.endsWith(" ")
                                            ) {
                                              event.preventDefault();
                                            }
                                          } else if (
                                            !/^[a-zA-Z]$/.test(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <p style={{ color: "red" }}>
                                        {error?.message ? error?.message : ""}
                                      </p>
                                    </>
                                  )}
                                />{" "}
                              </div>
                              <div className="content-title-text110">
                                <div className="hero-title13">
                                  <div className="project-summary13">
                                    Implemented by
                                  </div>
                                </div>
                                <Controller
                                  control={control}
                                  name={"Implementedby"}
                                  rules={{
                                    validate: {
                                      required: (value) => {
                                        if (!value)
                                          return "Implemented by is required!";
                                      },
                                    },
                                  }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { error },
                                  }) => (
                                    <>
                                      <input
                                        className="enter-input-text-1"
                                        required
                                        pattern="[a-zA-Z0-9]+"
                                        type="text"
                                        name="Implementedby"
                                        value={
                                          CollaborationsData?.Implementedby
                                        }
                                        placeholder="Enter Implemented by Name"
                                        onChange={(event) =>
                                          handleChange(event)
                                        }
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === "Backspace" ||
                                            event.key === "Delete"
                                          ) {
                                            return;
                                          }
                                          if (event.key === " ") {
                                            if (
                                              event.target.value.endsWith(" ")
                                            ) {
                                              event.preventDefault();
                                            }
                                          } else if (
                                            !/^[a-zA-Z]$/.test(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <p style={{ color: "red" }}>
                                        {error?.message ? error?.message : ""}
                                      </p>
                                    </>
                                  )}
                                />{" "}
                              </div>
                            </div>

                            <div className="column-118">
                              {" "}
                              <div className="content-title-text110">
                                <div className="hero-title13">
                                  <div className="project-summary13">
                                    Funded by
                                  </div>
                                </div>
                                <Controller
                                  control={control}
                                  name={"Fundedby"}
                                  rules={{
                                    validate: {
                                      required: (value) => {
                                        if (!value)
                                          return "Funded by is required!";
                                      },
                                    },
                                  }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { error },
                                  }) => (
                                    <>
                                      <input
                                        className="enter-input-text-1"
                                        required
                                        pattern="[a-zA-Z0-9]+"
                                        type="text"
                                        name="Fundedby"
                                        value={CollaborationsData?.Fundedby}
                                        placeholder="Enter Funded by Name"
                                        onChange={(event) =>
                                          handleChange(event)
                                        }
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === "Backspace" ||
                                            event.key === "Delete"
                                          ) {
                                            return;
                                          }
                                          if (event.key === " ") {
                                            if (
                                              event.target.value.endsWith(" ")
                                            ) {
                                              event.preventDefault();
                                            }
                                          } else if (
                                            !/^[a-zA-Z]$/.test(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <p style={{ color: "red" }}>
                                        {error?.message ? error?.message : ""}
                                      </p>
                                    </>
                                  )}
                                />{" "}
                              </div>
                              <div className="content-title-text110">
                                <div className="hero-title13">
                                  <div className="project-summary13">
                                    TreeTies POC
                                  </div>
                                </div>
                                <Controller
                                  control={control}
                                  name={"TreeTiesPOC"}
                                  rules={{
                                    validate: {
                                      required: (value) => {
                                        if (!value)
                                          return "TreeTies POC is required!";
                                      },
                                    },
                                  }}
                                  render={({
                                    field: { onChange, onBlur, value },
                                    fieldState: { error },
                                  }) => (
                                    <>
                                      <input
                                        className="enter-input-text-1"
                                        required
                                        pattern="[a-zA-Z0-9]+"
                                        type="text"
                                        name="TreeTiesPOC"
                                        value={CollaborationsData?.TreeTiesPOC}
                                        placeholder="Enter TreeTies POC Name"
                                        onChange={(event) =>
                                          handleChange(event)
                                        }
                                        onKeyDown={(event) => {
                                          if (
                                            event.key === "Backspace" ||
                                            event.key === "Delete"
                                          ) {
                                            return;
                                          }
                                          if (event.key === " ") {
                                            if (
                                              event.target.value.endsWith(" ")
                                            ) {
                                              event.preventDefault();
                                            }
                                          } else if (
                                            !/^[a-zA-Z]$/.test(event.key)
                                          ) {
                                            event.preventDefault();
                                          }
                                        }}
                                      />
                                      <p style={{ color: "red" }}>
                                        {error?.message ? error?.message : ""}
                                      </p>
                                    </>
                                  )}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Collaborations && Collaborations.length <= 1 && (
                    <div className="cta-add-more4 pt-3 pb-3">
                      <div className="logo-a6"></div>
                      <div className="add-more4">
                        <a className="addbtnstyle" onClick={AddToGrid}>
                          Add
                        </a>
                      </div>
                    </div>
                  )}
                </form>
              )}

              {/* Land parcel grid start    */}
              <div className="table4 pb-4">
                <div className="hero-title19">
                  {/* <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Sr No.</div>
                    </div>
                  </div> */}
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Advisors</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Implemented by</div>
                    </div>
                  </div>

                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Funded by</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">TreeTies POC</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Action</div>
                    </div>
                  </div>
                </div>

                {Collaborations &&
                  Collaborations.length > 0 &&
                  Collaborations.map((x, i) => {
                    return (
                      <div key={i}>
                        <div className="hero-title19">
                          {/* <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {i + 1}
                              </div>
                            </div>
                          </div> */}
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.advisors}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.implementedby}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.fundedby}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.treeTiesPOC}
                              </div>
                            </div>
                          </div>

                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => editCollaboration(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() =>
                                      removeCollaboration(x.collaborationId)
                                    }
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* Land parcel grid end    */}

              <div className="container11"></div>
              <div className="action-buttons5 pt-4">
                <button className="cta11">
                  <div className="back5" onClick={Back}>
                    Back
                  </div>
                </button>
                <button className="cta12">
                  <div
                    className="save-continue6"
                    onClick={() => AddcollaborationDetails()}
                  >{`Save & Continue`}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Colla);
