import { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  KmlLayer,
  GroundOverlay,
} from "@react-google-maps/api";
//import "./googlemap.css";
import s3TreeDetailsData from "./s3TreeDetailsData";
import s3KmlData from "./s3KmlData";
import ManualPlotting from "./ManualPlotting";
import TreeCountDroneBased from "./TreeCountDroneBased";
import GroundTruthSampling from "./GroundTruthSampling";
import BasinLayer from "./BasinLayer";
import StreamLayer from "./StreamLayer";

export default function GoogleMapViewer(props) {
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: 'AIzaSyALfkhscuzAI4JBrQxUKzrjRDkNb6tXlvk'
    // googleMapsApiKey: 'AIzaSyC4FYOWLg6zN9o9BCi8Kc3kb5FreGJXqgg'
    googleMapsApiKey: props.GoogleMapApiKey,
  });

  if (isLoaded == false) return <div>Loading...</div>;
  return (
    <Map
      projectDetail={props.projectDetail}
      locationCords={props.locationCords}
      projectTreeType={props.projectTreeType}
    ></Map>
  );
}

function Map(props) {
  const [polygon, setPolygon] = useState([]);
  const [showPolygon, setShowPolygon] = useState(false);
  //const [center, setCenter] = useState({lat: 15.2501627762245, lng: 74.0782077379068});
  // const [center, setCenter] = useState({lat: 15.2510123793, lng: 74.0806496143});
  const [center, setCenter] = useState({ lat: "", lng: "" });
  const [markerArray, setMarkerArray] = useState([]);
  const [markerDroneArray, setMarkerDroneArray] = useState([]);
  const [markerSetelliteArray, setMarkerSetelliteArray] = useState([]);
  const [isBoundarySelected, setIsBoundarySelected] = useState(true);
  const [isTreeSelected, setIsTreeSelected] = useState(false);
  const [isDroneTreeSelected, setIsDroneTreeSelected] = useState(false);
  const [isSetelliteSelected, setIsSetelliteSelected] = useState(false);
  const [treeCountArray, setTreeCountArray] = useState([]);
  const [isTreeCountSelected, setIsTreeCountSelected] = useState(false);
  const [canopyArray, setCanopyArray] = useState([]);
  const [isCanopySelected, setIsCanopySelected] = useState(false);
  const [isLidarNdviSelected, setIsLidarNdviSelected] = useState(false);
  const [isLidarElevationSelected, setIsLidarElevationSelected] =
    useState(false);
  const [isLidarOrthoSelected, setIsLidarOrthoSelected] = useState(false);
  const [isSatelliteLulc, setIsSatelliteLulc] = useState(false);
  const [isBasinSelected, setIsBasinSelected] = useState(false);
  const [isStreamSelected, setIsStreamSelected] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(17);
  const [selectedTreeData, setSelectedTreeData] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [ndviImage, setNdviImage] = useState("");
  const [satelliteLULCImage, setSatelliteLULCImage] = useState("");
  const [elevation, setElevationImage] = useState("");
  const [orthoImage, setOrthoImage] = useState("");
  const [streamData, setStreamData] = useState({});
  const [basinData, setBasinData] = useState({});

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (props.locationCords !== null && props.locationCords.length > 0) {
      setPolygon({
        paths: ConvertToLatLong(),
        options: {
          strokeWeight: 2,
          strokeOpacity: 1,
          strokeColor: "#0000ff", //"#0cc618",
          fillOpacity: 0,
          fillColor: "#333d72", //"#0cc618",
        },
      });
      setShowPolygon(true);
    }

    if (props.projectDetail.isShowLayersOnMap === true) {
      const GetMarkerArray = async () => {
        let data = await s3TreeDetailsData(
          props.projectDetail.projectTreeLocationFile
        );
        setMarkerArray(data?.features);
      };

      const GetMarkerDroneArray = async () => {
        let data = await s3TreeDetailsData(
          props.projectDetail.projectDroneTreeLocationFile
        );
        setMarkerDroneArray(data?.features);
      };

      const GetMarkerSetelliteArray = async () => {
        let data = await s3KmlData(props.projectDetail.projectGridLocationFile);
        setMarkerSetelliteArray(data?.url);
      };

      GetMarkerDroneArray();
      GetMarkerArray();
      GetMarkerSetelliteArray();

      const GetTreeCountData = async () => {
        let countGeo = props?.projectDetail?.lidarCountTree;
        let geoData = await s3TreeDetailsData(countGeo);
        let data = geoData?.features;
        setTreeCountArray(data);
      };

      const getCanopyData = async () => {
        let canopyGeo = props?.projectDetail?.lidarCanopyCover;
        let geoData = await s3TreeDetailsData(canopyGeo);
        let data = geoData?.features;
        setCanopyArray(data);
      };

      GetTreeCountData();
      getCanopyData();

      const GetLidarDtm = async () => {
        let data = await s3KmlData(props?.projectDetail?.lidarDtm);
        if (data?.url) setElevationImage(data?.url);
      };

      const GetLidarNdvi = async () => {
        let data = await s3KmlData(props?.projectDetail?.lidarNdvi);
        if (data?.url) setNdviImage(data?.url);
      };

      const GetLidarOrtho = async () => {
        let data = await s3KmlData(props?.projectDetail?.lidarOrtho);
        if (data?.url) setOrthoImage(data?.url);
      };

      GetLidarDtm();
      GetLidarNdvi();
      GetLidarOrtho();

      const GetStreamData = async () => {
        let data = await s3TreeDetailsData(props?.projectDetail?.streamLidar);
        if (data) setStreamData(data?.features);
      };

      const GetBasinData = async () => {
        let data = await s3TreeDetailsData(props?.projectDetail?.basinLidar);
        if (data) setBasinData(data?.features);
      };

      const GetSatelliteLULCImage = async () => {
        let data = await s3KmlData(props?.projectDetail?.satelliteLulc);

        if (data?.url) setSatelliteLULCImage(data?.url);
      };

      GetStreamData();
      GetBasinData();
      GetSatelliteLULCImage();
    }
  }, []);

  const mapOption = {
    mapTypeControl: false,
    mapTypeId: window.google.maps.MapTypeId.HYBRID,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      mapTypeIds: [
        window.google.maps.MapTypeId.ROADMAP,
        window.google.maps.MapTypeId.SATELLITE,
        window.google.maps.MapTypeId.HYBRID,
      ],
    },
  };

  const calculateAverage = (array) => {
    var total = 0;
    array.forEach(function (item, index) {
      total += item;
    });
    return total / array.length;
  };

  const ConvertToLatLong = () => {
    let latLongArray = [];
    let eachlatLong = {};
    let latArray = [];
    let lngArray = [];

    for (var i = 0; i < props.locationCords.length; i++) {
      if (i % 2 == 0) {
        eachlatLong.lng = props.locationCords[i];
        lngArray.push(props.locationCords[i]);
      } else {
        eachlatLong.lat = props.locationCords[i];
        latArray.push(props.locationCords[i]);
        latLongArray.push(eachlatLong);
        eachlatLong = {};
      }
    }

    if (latArray.length !== lngArray.length) {
      console.log("Invalid Polygon Location Cords in database.");
    }

    return latLongArray;
  };
  useEffect(() => {
    if (props.projectTreeType != null) {
      let treeCord = {
        lat: props.projectTreeType.locationCords[0],
        lng: props.projectTreeType.locationCords[1],
      }; //to be chnaged

      console.log("map", treeCord);
      setIsTreeSelected(true);
      // setIsDroneTreeSelected(true);
      setCenter(treeCord);
      setSelectedTreeData(props.projectTreeType);
      setZoomLevel(21); // google map max zoom
    } else {
      setIsTreeSelected(false);
      //  setIsDroneTreeSelected(false);
      if (props?.locationCords?.length > 1) {
        let cord = {
          lat: props?.locationCords?.[props?.locationCords?.length / 2 - 1],
          lng: props?.locationCords?.[props?.locationCords?.length / 2 - 2],
        };
        setCenter(cord);
      }
      setSelectedTreeData({});
      setZoomLevel(16);
    }
  }, [props.projectTreeType]);

  const handleBoundarySelected = (isSelected) => {
    setIsBoundarySelected(isSelected);
  };

  const handleTreeSelected = (isSelected) => {
    setIsTreeSelected(isSelected);
  };
  const handleDroneData = (isSelected) => {
    setIsDroneTreeSelected(isSelected);
  };

  const handleSatelliteData = (isSelected) => {
    setIsSetelliteSelected(isSelected);
  };

  const handleTreeCountSelected = (isSelected) => {
    setIsTreeCountSelected(isSelected);
  };

  const handleCanopySelected = (isSelected) => {
    setIsCanopySelected(isSelected);
  };

  const handleLidarNdvi = (isSelected) => {
    setIsLidarNdviSelected(isSelected);
  };

  const handleLidarElevation = (isSelected) => {
    setIsLidarElevationSelected(isSelected);
  };

  const handleLidarOrtho = (isSelected) => {
    setIsLidarOrthoSelected(isSelected);
  };

  const handleSatelliteLuLC = (isSelected) => {
    setIsSatelliteLulc(isSelected);
  };

  const handleBasinLayer = (isSelected) => {
    setIsBasinSelected(isSelected);
  };

  const handleStreamLayer = (isSelected) => {
    setIsStreamSelected(isSelected);
  };

  let POLYGON = {
    paths: [],
    options: {
      strokeWeight: 1,
      strokeOpacity: 1,
      strokeColor: "black",
      fillOpacity: 0.5,
      fillColor: "#85b66f",
    },
  };
  const bounds = props.projectDetail?.mapBounds;

  return (
    <div className="mapClass">
      <GoogleMap
        options={mapOption}
        defaultZoom={zoomLevel}
        zoom={zoomLevel}
        defaultCenter={center}
        center={center}
        mapContainerStyle={mapStyle.map_container}
      >
        {isBoundarySelected && showPolygon && (
          <Polygon paths={polygon?.paths} options={polygon?.options} />
        )}
        {isSetelliteSelected && (
          <KmlLayer
            url={markerSetelliteArray}
            options={{ preserveViewport: true }}
          />
        )}
        {isTreeSelected && (
          <GroundTruthSampling
            markerArray={markerArray}
            center={center}
            selectedTree={selectedTreeData}
          />
        )}
        {isDroneTreeSelected && (
          <ManualPlotting markerDroneArray={markerDroneArray} center={center} />
        )}
        {isTreeCountSelected && (
          <TreeCountDroneBased
            treeCountArray={treeCountArray}
            center={center}
          ></TreeCountDroneBased>
        )}

        {isCanopySelected &&
          canopyArray?.length > 0 &&
          canopyArray?.map((eachMarker, index) => {
            let polygonCords = eachMarker.geometry?.coordinates?.[0]?.[0] || [];
            let newCords = [];
            polygonCords?.map((cord) => {
              let obj = {};
              obj["lat"] = parseFloat(cord?.[1]);
              obj["lng"] = parseFloat(cord?.[0]);
              newCords.push(obj);
            });

            POLYGON["paths"] = [newCords];
            return (
              <Polygon
                key={index}
                paths={POLYGON?.paths}
                options={POLYGON?.options}
              />
            );
          })}

        {isLidarNdviSelected && (
          <GroundOverlay
            key={ndviImage}
            url={ndviImage}
            bounds={
              new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(bounds?.[0], bounds?.[1]),
                new window.google.maps.LatLng(bounds?.[2], bounds?.[3])
              )
            }
            defaultOpacity={0.5}
          />
        )}
        {isLidarElevationSelected && (
          <GroundOverlay
            key={elevation}
            url={elevation}
            bounds={
              new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(bounds?.[0], bounds?.[1]),
                new window.google.maps.LatLng(bounds?.[2], bounds?.[3])
              )
            }
            defaultOpacity={0.5}
          />
        )}

        {isLidarOrthoSelected && (
          <GroundOverlay
            key={orthoImage}
            url={orthoImage}
            bounds={
              new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(bounds?.[0], bounds?.[1]),
                new window.google.maps.LatLng(bounds?.[2], bounds?.[3])
              )
            }
            defaultOpacity={0.5}
          />
        )}

        {isSatelliteLulc && (
          <>
            <GroundOverlay
              key={require("./Satellite-LULC.png")}
              url={require("./Satellite-LULC.png")}
              bounds={
                new window.google.maps.LatLngBounds(
                  new window.google.maps.LatLng(bounds?.[0], bounds?.[1]),
                  new window.google.maps.LatLng(bounds?.[2], bounds?.[3])
                )
              }
              defaultOpacity={0.5}
            />
          </>
        )}
        <div className="layerinfo_wrap">
          {isSatelliteLulc && (
            <div class="chartsrightbars_cont landcoverlayer_info">
              <h3>LULC</h3>
              <ul>
                <li>
                  <span style={{ backgroundColor: "#20ce23" }}></span>Tree
                </li>
                <li>
                  <span style={{ backgroundColor: "#4f2600" }}></span>
                  Built-up/Barren
                </li>
                <li>
                  <span style={{ backgroundColor: "#6e9ce1" }}></span>Water
                </li>
                <li>
                  <span style={{ backgroundColor: "#ff7f00" }}></span>Non-veg
                  crops
                </li>
                <li>
                  <span style={{ backgroundColor: "#9ef315" }}></span>Other
                  vegeterian
                </li>
              </ul>
            </div>
          )}

          {isLidarNdviSelected && (
            <div class="chartsrightbars_cont basinlayer_info">
              <h3>Vegetation Index</h3>
              <ul>
                <li>
                  <span style={{ backgroundColor: "#2f2f2f" }}></span>Stressed
                  vegetation
                </li>
                <li>
                  <span style={{ backgroundColor: "#848484" }}></span>Moderate
                  vegetation
                </li>
                <li>
                  <span style={{ backgroundColor: "#f0f0f0" }}></span>Dense
                  vegetation
                </li>
              </ul>
            </div>
          )}

          {isBasinSelected && false && (
            <div class="chartsrightbars_cont basinlayer_info">
              <h3>LULC Basen layer</h3>
              <ul>
                <li>
                  <span class="chtrs_green"></span>Tree
                </li>
                <li>
                  <span class="chtrs_gray"></span>Built-up/Barren
                </li>
                <li>
                  <span class="chtrs_blue"></span>Water
                </li>
                <li>
                  <span class="chtrs_orange"></span>Non-veg crops
                </li>
                <li>
                  <span class="chtrs_lightgrn"></span>Other vegeterian
                </li>
              </ul>
            </div>
          )}
        </div>

        {isBasinSelected && <BasinLayer basinData={basinData} />}

        {isStreamSelected && <StreamLayer streamData={streamData} />}

        <div className="root-hamburger-menu">
          <button
            className={`hamburger-menu ${
              isSidebarOpen ? "iconmapsidebar" : ""
            }`}
            onClick={toggleSidebar}
          >
            <span></span>
          </button>
          <aside
            className={`hamburger-menu-sidebar ${
              isSidebarOpen ? "togglemapsidebar" : ""
            }`}
          >
            <nav>
              <div className="hamburger-menu-item">
                <input
                  checked={isBoundarySelected}
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleBoundarySelected(e.target.checked)}
                />
                <span className="checkboxLabel">Project Boundary</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleSatelliteData(e.target.checked)}
                />
                <span className="checkboxLabel">Sampling Grid</span>
              </div>
              <div className="subhambmenuitem_wrap">
                <div className="hamburger-menu-item heading_hambmenu">
                  <span>Tree Census</span>
                </div>
                <div className="hamburger-menu-item">
                  <input
                    type="checkbox"
                    className="largerCheckbox"
                    onChange={(e) => handleDroneData(e.target.checked)}
                  />
                  <span className="checkboxLabel">Tree count - Drone RGB</span>
                </div>
                <div className="hamburger-menu-item">
                  <input
                    type="checkbox"
                    className="largerCheckbox"
                    onChange={(e) => handleTreeSelected(e.target.checked)}
                  />
                  <span className="checkboxLabel">
                    Tree count - Ground Sampling
                  </span>
                </div>
                {/* <h4 className='hamburger-menu-heading'>Lidar Layers</h4> */}
                <div className="hamburger-menu-item">
                  <input
                    type="checkbox"
                    className="largerCheckbox"
                    onChange={(e) => handleTreeCountSelected(e.target.checked)}
                  />
                  <span className="checkboxLabel">
                    Tree count - Drone LiDAR
                  </span>
                </div>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleCanopySelected(e.target.checked)}
                />
                <span className="checkboxLabel">Tree Canopy Cover - LIDAR</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleLidarNdvi(e.target.checked)}
                />
                <span className="checkboxLabel">Vegetation Index (NDVI)</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleLidarElevation(e.target.checked)}
                />
                <span className="checkboxLabel">Elevation model</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleLidarOrtho(e.target.checked)}
                />
                <span className="checkboxLabel">LIDAR Orthomosaic</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleSatelliteLuLC(e.target.checked)}
                />
                <span className="checkboxLabel">Land Cover Land Use</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleBasinLayer(e.target.checked)}
                />
                <span className="checkboxLabel">Basin</span>
              </div>
              <div className="hamburger-menu-item">
                <input
                  type="checkbox"
                  className="largerCheckbox"
                  onChange={(e) => handleStreamLayer(e.target.checked)}
                />
                <span className="checkboxLabel">Stream Network</span>
              </div>

              <div
                style={{ marginLeft: 20, display: "none" }}
                className="hamburger-menu-item"
              >
                <input type="checkbox" className="largerCheckbox" />
                <span className="checkboxLabel">COMBINE</span>
              </div>
              <div className="hamburger-menu-item" style={{ display: "none" }}>
                <input type="checkbox" className="largerCheckbox" />
                <span className="checkboxLabel">SOIL</span>
              </div>
              <div className="hamburger-menu-item" style={{ display: "none" }}>
                <input type="checkbox" className="largerCheckbox" />
                <span className="checkboxLabel">WATER</span>
              </div>
            </nav>
          </aside>
        </div>
      </GoogleMap>
    </div>
  );
}

const mapStyle = {
  map_container: {
    width: "100%",
    height: "100vh",
  },
};
