import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import AppContainer from './Components/AppContainer';
import reducer from './Reducers/RootReducer';
import ReduxStateManagement from './utils/ReduxStateManagement';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


// A middleware for saving/retrieving the redux state to the local storage.
const PersistentState = new ReduxStateManagement();

export const store = createStore(reducer, PersistentState.loadState());
store.subscribe(() => {
    PersistentState.saveState(store.getState());
});

export class App extends Component {
    state = {
        loading: true
    };

    componentDidMount() {
        this.fakeRequest().then(() => {
            const el = document.querySelector(".preloader");
            if (el) {
                el.remove();  // removing the spinner element
                this.setState({ loading: false }); // showing the app
            }
        });
    }

    fakeRequest = () => {
        return new Promise(resolve => setTimeout(() => resolve(), 2500));
    };

    render() {
        return (
            <Provider store={store}>
                <AppContainer />
            </Provider>
        );
    }
}