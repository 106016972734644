import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "@patternfly/react-core";
import Searchcomp from "../../Common/Search/Search";
import VerifyStatusPopup from "./VerifyStatusPopup";
import config from "../../../utils/config";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import { navigate } from "@reach/router";

import "font-awesome/css/font-awesome.min.css";
import Loader from "../../Common/Loader/NewLoader";

class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isDataLoaded: false,
      activePage: 1,
      pagesize: 10,
      count: 0,
      PageRange: 10,
      SortBy: "DisplayOrder",
      SortType: true,
      ProjectList: [],
      SearchText: "",
      Status: "All",
      LastUpdatedOn: null,
      VerifiedCount: 0,
      UnverifiedCount: 0,
      VerifiedCount1: 0,
      RejectedCount: 0,
      UnverifiedCount1: 0,
      RejectedCount1: 0,
      commonMastersList: [],
      loading: true,
    };
    this.state = this.initialState;
    this.mounted = true;
  }
  componentDidMount() {
    this.GetCommonMasters();
    this.Get();
  }
  GetCommonMasters = () => {
    this.setState({ loading: true });
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ commonMastersList: data });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log("Error in request", error);
        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  Get = () => {
    let SearchText = "null";
    if (this.state.SearchText !== "") {
      SearchText = this.state.SearchText;
    }

    let url = `${config.API_HOST_URL}/api/projects/GetProjectDetails/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}/${this.state.Status}/true`;
    this.setState({ loading: true }, () => {
      //console.log("Loading state set to true");
    });
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        if (this.mounted) {
          this.setState({
            ProjectList: JSON.parse(resp.List),
            count: resp.Count,
            LastUpdatedOn: resp.LastUpdatedOn,
            isDataLoaded: true,
            VerifiedCount: resp.VerifiedCount,
            UnverifiedCount: resp.UnverifiedCount,
            RejectedCount: resp.RejectedCount,
          });
        }
        let VerifiedCount1 = 0;
        let UnverifiedCount1 = 0;
        let RejectedCount1 = 0;
        if (
          this.state.activePage == 1 &&
          this.state.Status == "All" &&
          SearchText == "null"
        ) {
          if (this.mounted)
            this.setState({
              VerifiedCount1: this.state.VerifiedCount,
              UnverifiedCount1: this.state.UnverifiedCount,
              RejectedCount1: this.state.RejectedCount,
            });
        }
        this.setState({ loading: false }, () => {
          //console.log("Loading state set to false");
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
        this.setState({ loading: false }, () => {
          //console.log("Loading state set to false");
        });
      });
  };
  handlePageChange = (pageNumber) => {
    if (this.mounted) {
      this.setState({ activePage: pageNumber }, function () {
        this.Get();
      });
    }
  };
  handleChange = (e) => {
    if (this.mounted) {
      this.setState({
        SearchText: e.target.value,
      });
    }
  };
  handleSearchByEnter = (e) => {
    if (e.key === "Enter") {
      if (this.mounted) {
        this.setState(
          {
            SearchText: e.target.value,
            activePage: 1,
          },
          function () {
            this.Get();
          }
        );
      }
    }
  };
  handleOptionChange = (Type) => {
    if (this.mounted)
      this.setState(
        {
          Status: Type,
          activePage: 1,
        },
        function () {
          this.Get();
        }
      );
  };
  handleBack = () => {
    this.setState(this.initialState, function () {
      this.Get();
    });
  };

  handleSortClick = (SortBy) => {
    let up = "";
    let down = "active";
    let sortType = this.state.SortType;

    if (SortBy === this.state.SortBy) sortType = !this.state.SortType;

    if (sortType) {
      up = "active";
      down = "";
    }

    this.setState(
      {
        SortBy: SortBy,
        SortType: sortType,
        Up: up,
        Down: down,
      },
      function () {
        this.Get();
      }
    );
  };
  projectdetailclick = (item, index) => {
    if (item.Status === "Verified") {
      navigate("/projectdetailsnew/:" + item.ProjectId + "/:" + (index + 1));
      // if (
      //   item.ProjectId == "bf33b663-d37f-4be7-b886-a0c06f120eac" ||
      //   item?.IsShowLayersOnMap
      // ) {

      // } else {
      //   navigate("/projectdetails/:" + item.ProjectId + "/:" + (index + 1));
      // }
    }
  };
  render() {
    const { commonMastersList } = this.state;
    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        innerWidth: 4,
        backgroundColor: "#50b041",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const CustomScrollbars = (props) => (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
      />
    );
    const { loading } = this.state;
    return (
      <>
        {loading && (
          <div className="loader-overlay listspages_cont">
            <Loader />
          </div>
        )}
        <div className="headbottom_cont">
          <ul className="headbottom_content">
            <li className="heading_cont">Project List</li>
            {/* <li className="updatetxt_cont">Last updated : {this.state.LastUpdatedOn && <Moment format={config.DateFormat}>{this.state.LastUpdatedOn}</Moment>}<i className="fa fa-repeat"></i></li> */}
            <li className="search_container">
              <Searchcomp />
            </li>
            <li className="export_btn"></li>
          </ul>
        </div>
        <div className="growalarm_container projectlist_cont">
          <Container>
            <div className="growalarm_content">
              <div className="commontblfltr_wrap">
                <ul className="filterlist">
                  <li
                    className={
                      this.state.Status === "All" ? "All_btn active" : "All_btn"
                    }
                  >
                    <a onClick={() => this.handleOptionChange("All")}>
                      All (
                      {this.state.VerifiedCount1 +
                        this.state.UnverifiedCount1 +
                        this.state.RejectedCount1}
                      )
                    </a>
                  </li>
                  <li
                    className={
                      this.state.Status === "Verified"
                        ? "Verified_btn active"
                        : "Verified_btn"
                    }
                  >
                    <a onClick={() => this.handleOptionChange("Verified")}>
                      Verified ({this.state.VerifiedCount1})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.Status === "Rejected"
                        ? "Verified_btn active"
                        : "Verified_btn"
                    }
                  >
                    <a onClick={() => this.handleOptionChange("Rejected")}>
                      Rejected ({this.state.RejectedCount1})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.Status === "Unverified"
                        ? "Unverified_btn active"
                        : "Unverified_btn"
                    }
                  >
                    <a onClick={() => this.handleOptionChange("Unverified")}>
                      Unverified ({this.state.UnverifiedCount1})
                    </a>
                  </li>
                </ul>
                <div className="showtxtnbtns_wrap">
                  <span className="showingtxt">
                    Showing{" "}
                    <strong>
                      &nbsp;
                      {this.state.count > 10 && (
                        <>
                          {(this.state.activePage - 1) * 10 + 1} -{" "}
                          {this.state.count < this.state.activePage * 10
                            ? this.state.count
                            : this.state.activePage * 10}{" "}
                          of{" "}
                        </>
                      )}
                      {this.state.count}&nbsp;
                    </strong>
                    Projects
                  </span>
                </div>
              </div>
              <div className="commontbl_wrap">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                >
                  <table className="commontbl_cont">
                    <thead>
                      <th>
                        Project Name
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("ProjectName")}
                        >
                          <i
                            className={
                              this.state.SortBy === "ProjectName"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "ProjectName"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th>
                        Project Location
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("Location")}
                        >
                          <i
                            className={
                              this.state.SortBy === "Location"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "Location"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      {/* <th>
                        Estimated Number of Trees
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("NumberOfTrees")}
                        >
                          <i
                            className={
                              this.state.SortBy === "NumberOfTrees"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "NumberOfTrees"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th>
                        Land area (in Acre)
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("ProjectType")}
                        >
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th> */}
                      <th>
                        New or Initiated Already?
                        <div
                          className="sortArrow"
                          onClick={() =>
                            this.handleSortClick("NewOrInitiatedAlready")
                          }
                        >
                          <i
                            className={
                              this.state.SortBy === "NewOrInitiatedAlready"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "NewOrInitiatedAlready"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th>
                        Estimated Project Period (In years)
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("ProjectType")}
                        >
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      {/* <th>
                        Status of Land
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("FundraiserGoal")}
                        >
                          <i
                            className={
                              this.state.SortBy === "FundraiserGoal"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "FundraiserGoal"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th> */}
                      <th>
                        Project Type
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("ProjectType")}
                        >
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th>
                        Project SubType
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("ProjectType")}
                        >
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "ProjectType"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      {/* <th>Amount Raised
                                                <div className="sortArrow" onClick={() => this.handleSortClick("AmountRaised")} >
                                                    <i className={this.state.SortBy === "AmountRaised" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                    <i className={this.state.SortBy === "AmountRaised" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                </div>
                                            </th>
                                            <th>Trees Planted
                                                <div className="sortArrow" onClick={() => this.handleSortClick("TreePlantedTillDate")} >
                                                    <i className={this.state.SortBy === "TreePlantedTillDate" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                    <i className={this.state.SortBy === "TreePlantedTillDate" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                </div>
                                            </th> */}
                      {/* <th className="status">
                        Status
                        <div
                          className="sortArrow"
                          onClick={() => this.handleSortClick("Status")}
                        >
                          <i
                            className={
                              this.state.SortBy === "Status"
                                ? `fas fa-sort-up ${this.state.Up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              this.state.SortBy === "Status"
                                ? `fas fa-sort-down ${this.state.Down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th> */}
                      <th className="action_cont">Action</th>
                    </thead>
                    <tbody id="myUL">
                      <tr className="tblsearch_cont">
                        <td colSpan="8">
                          <div className="tblsearch">
                            <i className="fa fa-search"></i>
                            <input
                              autoFocus
                              type="text"
                              id="SearchText"
                              placeholder="Search for Project Name, location…"
                              onKeyPress={this.handleSearchByEnter}
                              value={this.state.SearchText}
                              onChange={this.handleChange}
                            />
                          </div>
                        </td>
                      </tr>

                      {this.state.ProjectList.map((item, index) => {
                        return (
                          <tr className="tabel-data" key={index}>
                            <td className="projnametd">
                              {item.Status === "Verified" ? (
                                <a
                                  onClick={() => {
                                    this.projectdetailclick(item, index);
                                  }}
                                >
                                  {item.ProjectName}
                                </a>
                              ) : (
                                item.ProjectName
                              )}
                            </td>
                            <td>{item.Location}</td>
                            {/* <td className="tdnum">{item.NumberOfTrees}</td>
                            <td className="tdnum">
                              {(item.Landareasqm * 0.000247105).toFixed(2)}
                            </td>
                            <td className="tdnum">{item.Landstatus}</td> */}
                            <td className="tdnum">
                              {item.NewOrInitiatedAlready}
                            </td>
                            <td className="tdnum">
                              {item.EstimatedProjectPeriod}
                            </td>
                            {/* <td className="tdnum">{item.ProjectType}</td> */}
                            <td className="tdnum">
                              {commonMastersList
                                .filter(
                                  (x) =>
                                    x.mainGuId === item.ProjectTypeGuId &&
                                    x.type === "ProjectType"
                                )
                                .map((x) => x.description)}
                            </td>
                            <td className="tdnum">
                              {commonMastersList
                                .filter(
                                  (x) =>
                                    x.subGuId === item.ProjectSubTypeGuId &&
                                    x.type === "ProjectSubType"
                                )
                                .map((x) => x.description)}
                            </td>
                            {/* <td className="tdnum">{item.AmountRaised}</td>
                                                            <td className="tdnum">{item.TreePlantedTillDate}</td> */}
                            {/* <td className="tdnum">{item.Status}</td> */}
                            {item.Status === "Unverified" ? (
                              <td className="action_cont">
                                {/* <VerifyStatusPopup projectName={item.ProjectName} location={item.Location} noofTrees={item.NumberOfTrees} projectGoal={item.FundraiserGoal} projectId={item.ProjectId} documentName={item.ProjectFiles !== null && item.ProjectFiles[0].DocumentName} documentOriginalName={item.ProjectFiles !== null && item.ProjectFiles[0].DocumentOriginalName} handleBack={this.handleBack} /> */}
                                <VerifyStatusPopup
                                  data={item}
                                  handleBack={this.handleBack}
                                />
                              </td>
                            ) : (
                              <td className="action_cont"></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </CustomScrollbars>
              </div>
              <div className="wrapper">
                {this.state.isDataLoaded ? (
                  this.state.count > 0 ? (
                    this.state.count > this.state.pagesize && (
                      <div className="paginationDiv">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pagesize}
                          totalItemsCount={this.state.count}
                          pageRangeDisplayed={this.state.PageRange}
                          onChange={this.handlePageChange}
                          nextPageText={<i className="fa fa-angle-right"></i>}
                          prevPageText={<i className="fa fa-angle-left"></i>}
                          lastPageText={
                            <i className="fa fa-angle-double-right"></i>
                          }
                          firstPageText={
                            <i className="fa fa-angle-double-left"></i>
                          }
                        />
                      </div>
                    )
                  ) : (
                    <div>No Results Found</div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => dispatch({ type: "SET_USER_DETAILS", data: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
