import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
const tostConfig = {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
const ToastNotification = {
    ShowSuccess: (msg) => {
        toast.success(msg, tostConfig);
    },
    ShowError: (msg) => {
        toast.error(msg, tostConfig);
    },
    ShowWarning: (msg) => {
        toast.warn(msg, tostConfig)
    },
    ShowInfo: (msg) => {
        toast.info(msg, tostConfig)
    }
}

export default ToastNotification;
