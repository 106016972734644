import React, { Component } from 'react';
import { Modal } from '@patternfly/react-core';
import { Container } from "react-bootstrap";
class Sequestrationtreevalue extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        return (
            <div className="sequencewrapdiv">
                <div className="">
                    <div className="growalarm_content">
                        <div className="commontbl_wrap">
                            {/* <h2>Example calculation</h2> */}
                            <table className="commontbl_cont">
                                <thead>
                                    <th>A Cumulative carbon sequestration from lookup table (tCO2e/ha)</th>
                                    <th>B = 80% of A Cumulative Carbon Sequestrn Less 20% model precision (tCO2e/ha)</th>
                                    <th>C Risk buffer for permanence (15%) (tCO2e/ha)</th>
                                    <th>D= C-B Net carbon sequestration (tCO2e/ha)</th>
                                    <th>Trees planted in a ha with assumed spacing</th>
                                    <th className="carbonseqth">Approximate cumulative carbon sequestrn per tree over 50 years (tCO2e)</th>
                                </thead>
                                <tbody id="sequestrationtreevalueid">
                                    <tr className="tabel-data">
                                        <td className="tdnum">1028.8</td>
                                        <td className="tdnum">823.04</td>
                                        <td className="tdnum">123.456</td>
                                        <td className="tdnum">699.584</td>
                                        <td className="tdnum">4444<sup>1</sup></td>
                                        <td className="tdnum carbonseqtd">0.1574</td>
                                    </tr>

                                </tbody>
                            </table>
                            <span className="referlinktxt">Please refer to link URL <a href="https://ccsbestpractice.org.uk/wp-content/uploads/2017/04/Calculating-the-carbon-sequestration-value-of-trees.pdf" target="_blank"> Click here</a></span>

                        </div>
                    </div>

                </div>
            </div>

        )
    }
}
export default Sequestrationtreevalue
