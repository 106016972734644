import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@patternfly/react-core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LeafButton from "../../assets/leafbtn_img.png";

import Platform_1 from "../../assets/platform_1.svg";
import Platform_2 from "../../assets/platform_2.svg";
import Platform_3 from "../../assets/platform_3.svg";
import Platform_4 from "../../assets/platform_4.svg";
import Platform_5 from "../../assets/platform_5.svg";
import Platform_6 from "../../assets/platform_6.svg";
import Platform_7 from "../../assets/platform_7.svg";
import Platform_8 from "../../assets/platform_8.svg";
import Platform_9 from "../../assets/platform_9.svg";

import Ecosystem_1 from "../../assets/ecosystem_1.svg";
import Ecosystem_2 from "../../assets/ecosystem_2.svg";
import Ecosystem_3 from "../../assets/ecosystem_3.svg";
import Ecosystem_4 from "../../assets/ecosystem_4.svg";
import Ecosystem_5 from "../../assets/ecosystem_5.svg";

import Process_1 from "../../assets/process_1.svg";
import Process_2 from "../../assets/process_2.svg";
import Process_3 from "../../assets/process_3.svg";
import Process_4 from "../../assets/process_4.svg";
import Process_5 from "../../assets/process_5.svg";
import Process_6 from "../../assets/process_6.svg";
import Process_7 from "../../assets/process_7.svg";
import Process_8 from "../../assets/process_8.svg";
import ArrowProcess from "../../assets/arrow-process.svg";
import FooterLogo from "../../assets/footerlogo.png";
import IconShaping from "../../assets/icon_shaping.svg";
import backtbn from "../../assets/backbtn.png";

import ArrowProcessRight from "../../assets/arrow-process-right.svg";

import ConnectingImage from "../../assets/connecting-image.jpg";
import { Link } from "@reach/router";

class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isShowMSG: false,
      scrollCount: 0,
    };
    this.mounted = true;
    this.winScroll = this.winScroll.bind(this);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.body.classList.remove("platform_bg");
  }
  componentDidMount() {
    this.mounted = true;
    window.addEventListener("scroll", this.winScroll);
    document.body.classList.add("platform_bg");
  }
  OpenEnquiryDetail = (e) => {
    if (this.mounted) this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  CloseEnquiryData = () => {
    if (this.mounted) {
      this.setState({ isModalOpen: !this.state.isModalOpen, isShowMSG: false });
    }
  };
  ShowMSG = () => {
    if (this.mounted) this.setState({ isShowMSG: true });
  };

  winScroll = () => {
    this.setState({ scrollCount: window.scrollY });
  };

  goBack() {
    window.history.go(-2);
  }
  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
      backgroundPosition: "center center",
    };
    const insideStyles = {
      background: "transparent",
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-53%,-60%)",
      color: "#fff",
      textAlign: "center",
    };
    const insideStyles1 = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%,-50%)",
      texttransform: "capitliaze",
      color: "#fff",
      textAlign: "center",
      lineHeight: "40px",
    };
    const buttonStyle = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%, 130%)",
    };

    return (
      <>
        <Button className="backbtn" onClick={this.goBack}>
          <img src={backtbn} alt={backtbn} />
          <span>Back</span>
        </Button>
        <div className="morelrpadd_cont purpose_cont">
          <div className="treeties_container">
            <h1>
              Unleashing the{" "}
              <span className="greenunderline">Potential of Collective </span>
              <span className="greenunderline">
                Action With Transformative Tech
              </span>
            </h1>
            <br />
            <p>
              Treeties is your go-to digital platform for sustainable solutions.
              Our digital
              <br /> prowess fosters collaboration and innovation, giving you
              access to expert
              <br /> knowledge, resources, and a vibrant network of partners
              committed to
              <br /> shaping a greener tomorrow.
            </p>

            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Request Demo</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100{">
          <div className="treeties_container">
            <h1>
              Shaping <span className="greenunderline">the Future </span> of{" "}
              <br /> Restoration with{" "}
              <span className="greenunderline">Powerful</span>
              <br /> <span className="greenunderline ">Capabilities</span>{" "}
            </h1>
            <p>
              Treeties is your go-to digital platform for sustainable solutions.
              Our digital
              <br /> prowess fosters collaboration and innovation, giving you
              access to expert
              <br /> knowledge, resources, and a vibrant network of partners
              committed to
              <br /> shaping a greener tomorrow.
            </p>

            <div className="icons_list shapfutrepltfrm_list mt-5">
              <div className="row">
                <div className="col-sm-10">
                  <div className="row midmarginleft">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_1} />
                        <span>
                          Simplifying <br />
                          processes that <br />
                          are dynamic <br />
                          and complex
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_2} />
                        <span>
                          Digitizing <br />
                          Protocols <br />
                          based on <br />
                          science
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_3} />
                        <span>
                          Digitizing <br />
                          Expertise for <br />
                          universal <br />
                          access and use
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_4} />
                        <span>
                          Orchestrat
                          <br />
                          ion between
                          <br /> and within
                          <br /> partners
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row lessmarginleft">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_5} />
                        <span>
                          Digitized Project
                          <br /> Management and
                          <br /> Related Education/
                          <br />
                          Skill development
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_6} />
                        <span>
                          Computational
                          <br /> engine built to
                          <br /> deliver desired
                          <br /> outcomes
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_7} />
                        <span>
                          Digitized
                          <br /> transparency,
                          <br /> traceability,
                          <br /> and reporting
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                      <div className="list">
                        <img src={Platform_8} />
                        <span>
                          Plug-and-play
                          <br /> use for new
                          <br /> projects and
                          <br /> use cases
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 curlybacketimg">
                  <div className="list restorght_img">
                    <img src={Platform_9} />
                    <span>
                      Restoration
                      <br /> as a Service
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Book Demo</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100{">
          <div className="treeties_container">
            <h1>
              {" "}
              <span className="greenunderline">Connecting the Dots:</span>{" "}
              Treeties' Sustainable{" "}
              <span className="greenunderline">Digital Ecosystem </span>{" "}
            </h1>
            <p>
              Embark on a journey through Treeties' Sustainable Digital
              Ecosystem, where
              <br /> innovative minds, groundbreaking solutions, and boundless
              creativity
              <br /> converge. Together, we're weaving a tapestry of
              collaboration that
              <br /> transcends borders, igniting a global movement of
              ecological harmony and
              <br /> lasting change.
            </p>

            <div className="icons_list mt-5">
              <div className="row digiecoplatlst">
                <div className="col-lg-2 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Ecosystem_1} />
                    <span>Big Data</span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Ecosystem_2} />
                    <span>Protocols, Models & Algorithms</span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Ecosystem_3} />
                    <span>Precision, Restoration</span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Ecosystem_4} />
                    <span>Adoption</span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Ecosystem_5} />
                    <span>Partner Management</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <img className="w-100" src={ConnectingImage} alt="" />
            </div>
          </div>
        </div>

        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100{">
          <div className="treeties_container">
            <h1 className=" mb-4">
              {" "}
              <span className="greenunderline">
                Project Evaluation and{" "}
              </span>{" "}
              <span className="d-block" />{" "}
              <span className="greenunderline">Onboarding</span>{" "}
            </h1>
            <p>
              Take a closer look at our module for restoration, which includes
              project
              <br /> evaluation and onboarding features. Explore how Treeties
              streamlines the
              <br /> process of initiating and managing sustainable projects.
            </p>

            <div className="experience_list solution_list proevonplatlst">
              <div>
                <h1>1.</h1>
                <span>
                  Project <br /> Information
                </span>
              </div>
              <div>
                <h1>2.</h1>
                <span>
                  Initial <br /> Evaluation
                </span>
              </div>
              <div>
                <h1>3.</h1>
                <span>Desk Check</span>
              </div>
              <div>
                <h1>4.</h1>
                <span>Site Visit</span>
              </div>
            </div>
          </div>
        </div>
        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100{">
          <div className="treeties_container">
            <h1>
              TreeTies{" "}
              <span className="greenunderline"> Restoration Process</span>{" "}
            </h1>

            <div className="icons_list mt-5 treerestproplt">
              <div className="row justify-content-between lessmarginleft">
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_1} />
                    <span>
                      Digital <br />
                      Database
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_2} />
                    <span>
                      Assess &<br /> Design
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_3} />
                    <span>Plan</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_4} />
                    <span>Implement</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_5} />
                    <span>
                      Monitor,
                      <br /> Analyse & Adapt
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_6} />
                    <span>
                      Share &<br /> Report
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_7} />
                    <span>Maintenance</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mb-5">
                  <div className="list after-none">
                    <img src={Process_8} />
                    <span>
                      Carbon
                      <br /> Reduction
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100 ">
          <div className="treeties_container">
            <h1>
              TreeTies{" "}
              <span className="greenunderline"> Operation Process</span>
            </h1>
            <div className="scroller_container oprationpro_container">
              <div
                className="opration-process"
                style={{
                  backgroundImage: `url(${ArrowProcess}), url(${ArrowProcessRight})`,
                }}
              >
                <h4 className="process-title text-center">
                  Operational <br /> Actions
                </h4>
                <div className="process-inner">
                  <div className="process-box">
                    <p className="fs-6 mb-0">Identify Projects</p>
                  </div>
                  <div className="process-box">
                    <p className="fs-6 mb-0">Identify execution partner</p>
                  </div>
                  <div className="process-box">
                    <p className="fs-6 mb-0">Intervention Planning</p>
                  </div>
                  <div className="process-box">
                    <p className="fs-6 mb-0">Execution Oversight & Report</p>
                  </div>
                  <div className="process-box">
                    <p className="fs-6 mb-0">Carbon Credits</p>
                  </div>
                </div>
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Learn More</span>
              </Link>
            </Button>
          </div>
        </div>
        <div className="lesslrpadd_cont technology_cont paddingbotm pd-100 ">
          <div className="treeties_container">
            <h1>
              Engaging for <span className="greenunderline">end-to-end </span>{" "}
              ownership{" "}
            </h1>
            <div className="scroller_container">
              <div className="ownership">
                <div className="row">
                  <div className="col-2">
                    <div className="partner_box_first">
                      <p className="fs-6 mb-0">Right Objective</p>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="partner-box">
                      <p className="fs-6 mb-0">
                        Partner <span className="d-block" /> Logo
                      </p>
                    </div>
                    <div className="partner-content">
                      <div className="d-flex h-100">
                        <div className="content-row col-2 p-0">
                          <p className="fs-6 w-100 mb-0">
                            Right <span className="d-block" /> Partner
                          </p>
                        </div>
                        <div className="content-row col-2 p-0">
                          <p className="fs-6 w-100 mb-0 ">
                            Right <span className="d-block" /> Project
                          </p>
                        </div>
                        <div className="content-row col-2 p-0">
                          <p className="fs-6 w-100 mb-0 ">
                            Right <span className="d-block" /> Execution
                          </p>
                        </div>
                        <div className="content-row col-2 p-0">
                          <p className="fs-6 w-100 mb-0 ">
                            Right <span className="d-block" /> Team
                          </p>
                        </div>
                        <div className="content-row col-2 p-0">
                          <p className="fs-6 w-100 mb-0 ">
                            Right <span className="d-block" /> Impact
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="partner-box partner-image">
                      <img src={FooterLogo} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
