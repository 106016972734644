import React from "react";
import { Controller, useForm } from "react-hook-form";

export default function AddDashboardUrl() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log("Form Submitted", data);
  };

  const handleCancel = () => {
    // Handle form reset or cancellation logic here
    reset(); // This will reset the form fields
    console.log("Form Reset");
  };

  return (
    <>
      <div className="registartion_container adddashboardurl_cont">
        <div className="registartion_cont">
          <div className="registartioninner_wrap">
            <div className="section_heading">Add Dashboard URL</div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <ul className="register_form">
              <li className="form-group field">
                <Controller
                  control={control}
                  name="adminngoemail"
                  rules={{
                    required: "Please Enter Admin/NGO Email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address!",
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="adminngoemail"
                        id="adminngoemail"
                        autoComplete="off"
                        autoFill="off"
                        placeholder="Enter Admin/NGO Email"
                        onChange={(event) => {}}
                      />
                      <label htmlFor="adminngoemail">
                        <sup className="mandatory">*</sup>Admin/NGO Email
                      </label>
                      <div className="mandatory has-error">
                        {error?.message}
                      </div>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="dashboardurl"
                  rules={{
                    required: "Please Enter Dashboard Url",
                    pattern: {
                      value: /^[a-zA-Z]+$/,
                      message: "Only alphabets are allowed!",
                    },
                  }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="dashboardurl"
                        id="dashboardurl"
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (!/^[a-zA-Z]$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Enter Dashboard Url"
                        onChange={(event) => {}}
                      />
                      <label htmlFor="dashboardurl">
                        <sup className="mandatory">*</sup>Dashboard Url
                      </label>
                      <div className="mandatory has-error">
                        {error?.message}
                      </div>
                    </>
                  )}
                />
              </li>
              <li className="form-group submitbtn_wrap">
                <button
                  className="submit_btn"
                  type="submit"
                  name="Submit"
                  value="submit"
                >
                  Submit
                </button>
                <button
                  className="cancel_btn"
                  type="reset"
                  name="Cancel"
                  value="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
}
