import { useEffect, Component, PropTypes } from 'react';
import { Entity, useCesium, BillboardGraphics, PolygonGraphics } from 'resium';
import { Cartesian3, ArcType } from 'cesium';
import treeIcon from "../../../assets/treeIcon.png";

const EntityViewer = (props) => {

    const { viewer } = useCesium();
    useEffect(() => {
        props.setViewerToParent(props.isPlantPlot ? null : viewer);
    })
    return (props.polyArray ? <Entity onClick={props.Click}>
        <PolygonGraphics
            material={props.Colormaterial}
            className="treesArea"
            hierarchy={Cartesian3.fromDegreesArray(props.polyArray)}
            fill={true}
            height={0}
            extrudedHeight={0}
            outline={true}
            outlineColor={props.Coloroutline}
            show={true}
            arcType={ArcType.GEODESIC}
        />
    </Entity> : <Entity position={props.posn}><BillboardGraphics image={treeIcon} /></Entity>
    );
};
export default EntityViewer;