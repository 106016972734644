import React from "react";

export default function DeleteConfirmationPopup({
  onConfirm,
  onCancel,
  confirmMessage,
}) {
  return (
    <div className="delete-popup-overlay">
      <div className="delete-popup-container">
        <h5>{confirmMessage}</h5>
        <button className="deleteYes_btn" onClick={onConfirm}>
          Yes
        </button>
        <button className="deleteno_btn" onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
}
