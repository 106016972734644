import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { Multiselect } from "multiselect-react-dropdown";

class TreeTiesAddProjectForms1Par1 extends Component {
  constructor(props) {
    super(props);
    this.parRef = React.createRef();
    const { id } = this.props;
    this.state = {
      ngoUserList: [],
      instSponsorUserList: [],
      indSponsorUserList: [],
      farmerUserList: [],
      governEntitiesUserList: [],
      lspUserList: [],
      aspUserList: [],
      projectId: id?.substring(1),
      partnershipId: "",
      //Execution Partner
      added_ExecutionPartner: [],
      selected_EP_Personnel: [],
      ep_PersonnelDataSource: [],
      execution_PartnerId: "",
      ////////////////
      //InstSponsor
      added_InstSponsor: [],
      selected_IS_Personnel: [],
      is_PersonnelDataSource: [],
      institutional_SponsorId: "",
      associationTypeId: "",
      instSponsor_ExecPartnerId: "",
      associationTypeList: [],
      //////////////////

      //IndSponsor
      added_IndSponsor: [],
      individual_SponsorId: "",
      associationType_Ind_Id: "",
      indSponsor_ExecPartnerId: "",

      //Farmer

      added_Farmer: [],
      farmer_Id: "",
      associationType_farmer_Id: "",
      farmer_ExecPartnerId: "",

      //Government Entities
      added_GE: [],
      selected_GE_Personnel: [],
      ge_PersonnelDataSource: [],
      govern_EntitiesId: "",
      associationType_GE_Id: "",
      ge_ExecPartnerId: "",

      //Legal_service PRovider
      added_LSP: [],
      lsp_Id: "",
      selected_LSP_Personnel: [],
      lsp_PersonnelDataSource: [],
      associationType_LSP_Id: "",
      lsp_ExecPartnerId: "",

      //Legal_service PRovider
      added_ASP: [],
      asp_Id: "",
      selected_ASP_Personnel: [],
      asp_PersonnelDataSource: [],
      associationType_ASP_Id: "",
      asp_ExecPartnerId: "",
    };
    this.multiselectRef_EP_Personnel = React.createRef();
    this.multiselectRef_instSponsor_Personnel = React.createRef();
    this.multiselectRef_indSponsor = React.createRef();
    this.multiselectRef_farmer = React.createRef();
    this.multiselectRef_ge_Personnel = React.createRef();
    this.multiselectRef_lsp_Personnel = React.createRef();
    this.multiselectRef_asp_Personnel = React.createRef();
    this.PARTNERSHIP_TYPE = {
      EXECUTION_PARTNER: 1,
      INSTITUTIONAL_SPONSOR: 2,
      INDIVIDUAL_SPONSOR: 3,
      FARMER: 4,
      GOVERNMENT_ENTITIES: 5,
      LEGAL_SERVICE_PROVIDER: 6,
      AUDIT_SERVICE_PROVIDER: 7,
    };
    this.ASSOCIATED_WITH_EXECUTION_PARTNER =
      "c82a9c95-7152-4d51-83bf-4e0fef59b218";
  }

  componentDidMount() {
    this.GetNGOUserList();
  }
  gotoFar = () => {
    if (document.getElementById("farId")) {
      document.getElementById("farId").scrollIntoView({ behavior: "smooth" });
    }
  };
  gotoExe = () => {
    if (document.getElementById("exeId")) {
      document.getElementById("exeId").scrollIntoView({ behavior: "smooth" });
    }
  };

  gotoIns = () => {
    if (document.getElementById("insId")) {
      document.getElementById("insId").scrollIntoView({ behavior: "smooth" });
    }
  };
  gotoInd = () => {
    if (document.getElementById("indId")) {
      document.getElementById("indId").scrollIntoView({ behavior: "smooth" });
    }
  };
  gotoGov = () => {
    if (document.getElementById("govId")) {
      document.getElementById("govId").scrollIntoView({ behavior: "smooth" });
    }
  };
  gotoLeg = () => {
    if (document.getElementById("legId")) {
      document.getElementById("legId").scrollIntoView({ behavior: "smooth" });
    }
  };

  gotoAudit = () => {
    if (document.getElementById("auditId")) {
      document.getElementById("auditId").scrollIntoView({ behavior: "smooth" });
    }
  };
  GetPartnershipList = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetPartnership/${this.state.projectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null && data.length > 0) {
          this.setState({
            added_ExecutionPartner: data[0]?.execution_Partners,
            added_InstSponsor: data[0]?.institutional_Sponsors,
            added_IndSponsor: data[0]?.individual_Sponsors,
            added_Farmer: data[0]?.farmers,
            added_GE: data[0]?.government_Entities,
            added_LSP: data[0]?.legal_Service_Providers,
            added_ASP: data[0]?.audit_Service_Providers,
            partnershipId: data[0]?.partnershipId,
          });
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetCommonMasters = () => {
    let masterType = "AssociationType";
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/${masterType}`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          associationTypeList: data,
        });
        this.GetPartnershipList();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        this.setState({
          ngoUserList: responseData.filter(
            (x) =>
              x.TypeOfIncorporation === "Trust" ||
              x.TypeOfIncorporation === "Privatelimitedcompany" ||
              x.TypeOfIncorporation === "Others"
          ),
          instSponsorUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "InstitutionalSponsor"
          ),
          indSponsorUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "IndividualSponsor"
          ),
          farmerUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "Farmer"
          ),
          governEntitiesUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "GovernmentEntities"
          ),
          lspUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "LegalServiceProvider"
          ),
          aspUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "AuditServiceProvider"
          ),
        });

        this.GetCommonMasters();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  IsValidated = (type) => {
    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        if (this.state.selected_EP_Personnel.length === 0) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_ExecutionPartner.filter(
            (x) => x.execution_PartnerId === this.state.execution_PartnerId
          ).length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Execution Partner (NGO) already added."
          );
          return false;
        }
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        if (
          this.state.selected_IS_Personnel.length === 0 ||
          this.state.associationTypeId === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_InstSponsor.filter(
            (x) =>
              x.institutional_SponsorId === this.state.institutional_SponsorId
          ).length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Institutional Sponsor already added."
          );
          return false;
        }

        if (
          this.state.associationTypeId ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.instSponsor_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;
      case this.PARTNERSHIP_TYPE.INDIVIDUAL_SPONSOR:
        if (
          this.state.individual_SponsorId.length === 0 ||
          this.state.associationType_Ind_Id === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_IndSponsor.filter(
            (x) => x.individual_SponsorId === this.state.individual_SponsorId
          ).length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Individual Sponsor already added."
          );
          return false;
        }

        if (
          this.state.associationType_Ind_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.indSponsor_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;
      case this.PARTNERSHIP_TYPE.FARMER:
        if (
          this.state.farmer_Id.length === 0 ||
          this.state.associationType_farmer_Id === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_Farmer != null &&
          this.state.added_Farmer.filter(
            (x) => x.farmer_Id === this.state.farmer_Id
          ).length > 0
        ) {
          ToastNotification.ShowInfo("Selected Farmer already added.");
          return false;
        }

        if (
          this.state.associationType_farmer_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.farmer_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        if (
          this.state.selected_GE_Personnel.length === 0 ||
          this.state.associationType_GE_Id === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_GE.filter(
            (x) => x.govern_EntitiesId === this.state.govern_EntitiesId
          ).length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Governement Entities already added."
          );
          return false;
        }

        if (
          this.state.associationType_GE_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.ge_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;
      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        if (
          this.state.selected_LSP_Personnel.length === 0 ||
          this.state.associationType_LSP_Id === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_LSP.filter((x) => x.lsp_Id === this.state.lsp_Id)
            .length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Legal Service Provider already added."
          );
          return false;
        }

        if (
          this.state.associationType_LSP_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.lsp_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;

      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        if (
          this.state.selected_ASP_Personnel.length === 0 ||
          this.state.associationType_ASP_Id === ""
        ) {
          ToastNotification.ShowInfo("Please select values to continue.");
          return false;
        }

        if (
          this.state.added_ASP.filter((x) => x.asp_Id === this.state.asp_Id)
            .length > 0
        ) {
          ToastNotification.ShowInfo(
            "Selected Audit Service Provider already added."
          );
          return false;
        }

        if (
          this.state.associationType_ASP_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER &&
          this.state.asp_ExecPartnerId === ""
        ) {
          ToastNotification.ShowInfo("Please select Execution Partner (NGO).");
          return false;
        }
        break;
    }
    return true;
  };

  AddPartnership = (type) => {
    if (this.IsValidated(type) === false) {
      return;
    }

    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        let newExecutionPartner = {
          execution_PartnerId: this.state.execution_PartnerId,
          pS_EP_Personnel: this.state.selected_EP_Personnel,
        };

        this.setState({
          added_ExecutionPartner: [
            ...this.state.added_ExecutionPartner,
            newExecutionPartner,
          ],
          execution_PartnerId: "",
          ep_PersonnelDataSource: [],
          selected_EP_Personnel: [],
        });

        this.multiselectRef_EP_Personnel.current.resetSelectedValues();
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        let newInstSponsor = {
          institutional_SponsorId: this.state.institutional_SponsorId,
          pS_IS_Personnel: this.state.selected_IS_Personnel,
          associationTypeId: this.state.associationTypeId,
          execution_PartnerId:
            this.state.associationTypeId ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.instSponsor_ExecPartnerId
              : "",
        };

        this.setState({
          added_InstSponsor: [...this.state.added_InstSponsor, newInstSponsor],
          institutional_SponsorId: "",
          is_PersonnelDataSource: [],
          selected_IS_Personnel: [],
          associationTypeId: "",
          instSponsor_ExecPartnerId: "",
        });

        this.multiselectRef_instSponsor_Personnel.current.resetSelectedValues();
        break;

      case this.PARTNERSHIP_TYPE.INDIVIDUAL_SPONSOR:
        let newIndSponsor = {
          individual_SponsorId: this.state.individual_SponsorId,
          associationType_Ind_Id: this.state.associationType_Ind_Id,
          execution_PartnerId:
            this.state.associationType_Ind_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.indSponsor_ExecPartnerId
              : "",
        };

        this.setState({
          added_IndSponsor: [...this.state.added_IndSponsor, newIndSponsor],
          individual_SponsorId: "",
          associationType_Ind_Id: "",
          indSponsor_ExecPartnerId: "",
        });

        //this.multiselectRef_indSponsor.current.resetSelectedValues();
        break;
      case this.PARTNERSHIP_TYPE.FARMER:
        let newFarmer = {
          farmer_Id: this.state.farmer_Id,
          associationType_farmer_Id: this.state.associationType_farmer_Id,
          farmer_ExecPartnerId:
            this.state.associationType_farmer_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.farmer_ExecPartnerId
              : "",
        };

        this.setState({
          added_Farmer: [...this.state.added_Farmer, newFarmer],
          farmer_Id: "",
          associationType_farmer_Id: "",
          farmer_ExecPartnerId: "",
        });
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        let newGE = {
          govern_EntitiesId: this.state.govern_EntitiesId,
          pS_GE_Personnel: this.state.selected_GE_Personnel,
          associationType_GE_Id: this.state.associationType_GE_Id,
          ge_ExecPartnerId:
            this.state.associationType_GE_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.ge_ExecPartnerId
              : "",
        };

        this.setState({
          added_GE: [...this.state.added_GE, newGE],
          govern_EntitiesId: "",
          ge_PersonnelDataSource: [],
          selected_GE_Personnel: [],
          associationType_GE_Id: "",
          ge_ExecPartnerId: "",
        });

        this.multiselectRef_ge_Personnel.current.resetSelectedValues();
        break;

      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        let newLsp = {
          lsp_Id: this.state.lsp_Id,
          pS_LSP_Personnel: this.state.selected_LSP_Personnel,
          associationType_LSP_Id: this.state.associationType_LSP_Id,
          lsp_ExecPartnerId:
            this.state.associationType_LSP_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.lsp_ExecPartnerId
              : "",
        };
        this.setState({
          added_LSP: [...this.state.added_LSP, newLsp],
          lsp_Id: "",
          lsp_PersonnelDataSource: [],
          selected_LSP_Personnel: [],
          associationType_LSP_Id: "",
          lsp_ExecPartnerId: "",
        });

        this.multiselectRef_lsp_Personnel.current.resetSelectedValues();
        break;
      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        let newAsp = {
          asp_Id: this.state.asp_Id,
          pS_ASP_Personnel: this.state.selected_ASP_Personnel,
          associationType_ASP_Id: this.state.associationType_ASP_Id,
          asp_ExecPartnerId:
            this.state.associationType_ASP_Id ===
            this.ASSOCIATED_WITH_EXECUTION_PARTNER
              ? this.state.asp_ExecPartnerId
              : "",
        };
        this.setState({
          added_ASP: [...this.state.added_ASP, newAsp],
          asp_Id: "",
          asp_PersonnelDataSource: [],
          selected_ASP_Personnel: [],
          associationType_ASP_Id: "",
          asp_ExecPartnerId: "",
        });

        this.multiselectRef_asp_Personnel.current.resetSelectedValues();
        break;
    }
  };

  RemovePartnership(type, paramId) {
    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        let newEPList = this.state.added_ExecutionPartner.filter(
          (eachItem) => eachItem.execution_PartnerId !== paramId
        );
        this.setState({ added_ExecutionPartner: newEPList });
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        let newISList = this.state.added_InstSponsor.filter(
          (eachItem) => eachItem.institutional_SponsorId !== paramId
        );
        this.setState({ added_InstSponsor: newISList });
        break;
      case this.PARTNERSHIP_TYPE.INDIVIDUAL_SPONSOR:
        let newINDList = this.state.added_IndSponsor.filter(
          (eachItem) => eachItem.individual_SponsorId !== paramId
        );
        this.setState({ added_IndSponsor: newINDList });
        break;
      case this.PARTNERSHIP_TYPE.FARMER:
        let newFarmerList = this.state.added_Farmer.filter(
          (eachItem) => eachItem.farmer_Id !== paramId
        );
        this.setState({ added_Farmer: newFarmerList });
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        let newGEList = this.state.added_GE.filter(
          (eachItem) => eachItem.govern_EntitiesId !== paramId
        );
        this.setState({ added_GE: newGEList });
        break;
      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        let newLSPList = this.state.added_LSP.filter(
          (eachItem) => eachItem.lsp_Id !== paramId
        );
        this.setState({ added_LSP: newLSPList });
        break;
      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        let newASPList = this.state.added_ASP.filter(
          (eachItem) => eachItem.asp_Id !== paramId
        );
        this.setState({ added_ASP: newASPList });
        break;
    }
    ToastNotification.ShowError("Removed Successfully");
  }

  SavePartnership = (e) => {
    let data = {
      projectId: this.state.projectId,
      partnershipId: this.state.partnershipId,
      execution_Partners: this.state.added_ExecutionPartner,
      institutional_Sponsors: this.state.added_InstSponsor,
      individual_Sponsors: this.state.added_IndSponsor,
      farmers: this.state.added_Farmer,
      government_Entities: this.state.added_GE,
      legal_Service_Providers: this.state.added_LSP,
      audit_Service_Providers: this.state.added_ASP,
    };

    let url = `${config.API_HOST_URL}/api/Ecosystem/SavePartnership`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
          this.GetPartnershipList();
          ToastNotification.ShowSuccess(resp.message);
          this.Nextpage();
        } else {
          ToastNotification.ShowError(resp.message);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Colla/:" + this.state.projectId, {
      state: { activeMenu: 2 },
    });
  };

  handleSelect_Personnel = (event, type) => {
    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        this.setState({ selected_EP_Personnel: event });
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        this.setState({ selected_IS_Personnel: event });
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        this.setState({ selected_GE_Personnel: event });
        break;
      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        this.setState({ selected_LSP_Personnel: event });
        break;
      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        this.setState({ selected_ASP_Personnel: event });
        break;
    }
  };

  handleEvent = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange_EP = (event) => {
    this.setState({ execution_PartnerId: event.target.value });
    if (
      this.state.ngoUserList.filter(
        (x) => x.UserId === event.target.value && x.PointOfContact !== null
      ).length > 0
    ) {
      var personnel = this.state.ngoUserList
        .filter(
          (x) => x.UserId === event.target.value && x.PointOfContact !== null
        )
        .filter((x) => x.PointOfContact !== null)[0].PointOfContact;
      this.setState({ ep_PersonnelDataSource: personnel });
    } else {
      this.setState({
        ep_PersonnelDataSource: [],
        selected_EP_Personnel: [],
      });
    }

    this.multiselectRef_EP_Personnel.current.resetSelectedValues();
  };

  handleChange_InstSponsor = (event) => {
    this.setState({ institutional_SponsorId: event.target.value });
    if (
      this.state.instSponsorUserList.filter(
        (x) => x.UserId === event.target.value && x.PointOfContact !== null
      ).length > 0
    ) {
      var personnel = this.state.instSponsorUserList
        .filter(
          (x) => x.UserId === event.target.value && x.PointOfContact !== null
        )
        .filter((x) => x.PointOfContact !== null)[0].PointOfContact;
      this.setState({ is_PersonnelDataSource: personnel });
    } else {
      this.setState({
        is_PersonnelDataSource: [],
        selected_IS_Personnel: [],
      });
    }

    this.multiselectRef_instSponsor_Personnel.current.resetSelectedValues();
  };

  handleChange_GE = (event) => {
    this.setState({ govern_EntitiesId: event.target.value });
    if (
      this.state.governEntitiesUserList.filter(
        (x) => x.UserId === event.target.value && x.PointOfContact !== null
      ).length > 0
    ) {
      var personnel = this.state.governEntitiesUserList
        .filter(
          (x) => x.UserId === event.target.value && x.PointOfContact !== null
        )
        .filter((x) => x.PointOfContact !== null)[0].PointOfContact;
      this.setState({ ge_PersonnelDataSource: personnel });
    } else {
      this.setState({
        ge_PersonnelDataSource: [],
        selected_GE_Personnel: [],
      });
    }

    this.multiselectRef_ge_Personnel.current.resetSelectedValues();
  };

  handleChange_LSP = (event) => {
    this.setState({ lsp_Id: event.target.value });
    if (
      this.state.lspUserList.filter(
        (x) => x.UserId === event.target.value && x.PointOfContact !== null
      ).length > 0
    ) {
      var personnel = this.state.lspUserList
        .filter(
          (x) => x.UserId === event.target.value && x.PointOfContact !== null
        )
        .filter((x) => x.PointOfContact !== null)[0].PointOfContact;
      this.setState({ lsp_PersonnelDataSource: personnel });
    } else {
      this.setState({
        lsp_PersonnelDataSource: [],
        selected_LSP_Personnel: [],
      });
    }

    this.multiselectRef_lsp_Personnel.current.resetSelectedValues();
  };

  handleChange_ASP = (event) => {
    this.setState({ asp_Id: event.target.value });
    if (
      this.state.aspUserList.filter(
        (x) => x.UserId === event.target.value && x.PointOfContact !== null
      ).length > 0
    ) {
      var personnel = this.state.aspUserList
        .filter(
          (x) => x.UserId === event.target.value && x.PointOfContact !== null
        )
        .filter((x) => x.PointOfContact !== null)[0]?.PointOfContact;
      this.setState({ asp_PersonnelDataSource: personnel });
    } else {
      this.setState({
        asp_PersonnelDataSource: [],
        selected_ASP_Personnel: [],
      });
    }

    this.multiselectRef_asp_Personnel.current.resetSelectedValues();
  };

  handleChange_IndSponsor = (event) => {
    this.setState({ individual_SponsorId: event.target.value });
    //this.multiselectRef_indSponsor.current.resetSelectedValues();
  };

  handleGridTextChangeEvent = (event, type, parentIdValue, childIdValue) => {
    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        let filtered_EP = this.state.added_ExecutionPartner
          .filter((x) => x.execution_PartnerId === parentIdValue)[0]
          .pS_EP_Personnel.filter((x) => x.eP_PersonnelId === childIdValue)[0];
        filtered_EP[event.target.name] = event.target.value;
        this.setState({
          added_ExecutionPartner: this.state.added_ExecutionPartner,
        });
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        let filtered_IS = this.state.added_InstSponsor
          .filter((x) => x.institutional_SponsorId === parentIdValue)[0]
          .pS_IS_Personnel.filter((x) => x.iS_PersonnelId === childIdValue)[0];
        filtered_IS[event.target.name] = event.target.value;
        this.setState({ added_InstSponsor: this.state.added_InstSponsor });
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        let filtered_GE = this.state.added_GE
          .filter((x) => x.govern_EntitiesId === parentIdValue)[0]
          .pS_GE_Personnel.filter((x) => x.gE_PersonnelId === childIdValue)[0];
        filtered_GE[event.target.name] = event.target.value;
        this.setState({ added_GE: this.state.added_GE });
        break;
      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        let filtered_LSP = this.state.added_LSP
          .filter((x) => x.lsp_Id === parentIdValue)[0]
          .pS_LSP_Personnel.filter(
            (x) => x.lSP_PersonnelId === childIdValue
          )[0];
        filtered_LSP[event.target.name] = event.target.value;
        this.setState({ added_LSP: this.state.added_LSP });
        break;
      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        let filtered_ASP = this.state.added_ASP
          .filter((x) => x.asp_Id === parentIdValue)[0]
          .pS_ASP_Personnel.filter(
            (x) => x.aSP_PersonnelId === childIdValue
          )[0];
        filtered_ASP[event.target.name] = event.target.value;
        this.setState({ added_ASP: this.state.added_ASP });
        break;
    }
  };

  handleGridRadioChangeEvent = (event, type, parentIdValue, childIdValue) => {
    switch (type) {
      case this.PARTNERSHIP_TYPE.EXECUTION_PARTNER:
        let filtered_EP = this.state.added_ExecutionPartner.filter(
          (x) => x.execution_PartnerId === parentIdValue
        )[0].pS_EP_Personnel;
        filtered_EP.forEach((element) => {
          element.isSpoc =
            element.eP_PersonnelId === childIdValue ? true : false;
        });
        this.setState({
          added_ExecutionPartner: this.state.added_ExecutionPartner,
        });
        break;
      case this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR:
        let filtered_IS = this.state.added_InstSponsor.filter(
          (x) => x.institutional_SponsorId === parentIdValue
        )[0].pS_IS_Personnel;
        filtered_IS.forEach((element) => {
          element.isSpoc =
            element.iS_PersonnelId === childIdValue ? true : false;
        });
        this.setState({ added_InstSponsor: this.state.added_InstSponsor });
        break;
      case this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES:
        let filtered_GE = this.state.added_GE.filter(
          (x) => x.govern_EntitiesId === parentIdValue
        )[0].pS_GE_Personnel;
        filtered_GE.forEach((element) => {
          element.isSpoc =
            element.gE_PersonnelId === childIdValue ? true : false;
        });
        this.setState({ added_GE: this.state.added_GE });
        break;
      case this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER:
        let filtered_LSP = this.state.added_LSP.filter(
          (x) => x.lsp_Id === parentIdValue
        )[0].pS_LSP_Personnel;
        filtered_LSP.forEach((element) => {
          element.isSpoc =
            element.lSP_PersonnelId === childIdValue ? true : false;
        });
        this.setState({ added_LSP: this.state.added_LSP });
        break;
      case this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER:
        let filtered_ASP = this.state.added_ASP.filter(
          (x) => x.asp_Id === parentIdValue
        )[0].pS_ASP_Personnel;
        filtered_ASP.forEach((element) => {
          element.isSpoc =
            element.aSP_PersonnelId === childIdValue ? true : false;
        });
        this.setState({ added_ASP: this.state.added_ASP });
        break;
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div className="treeties-addproject-forms1-par ecosyscom_cont">
        {/* <Header/> */}
        <div className="main-content-area">
          <div className="middle-content-area">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont ecopartcont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
                activesubMenu={this.props.location.state?.activesubMenu}
                onExe={this.gotoExe}
                onIns={this.gotoIns}
                onInd={this.gotoInd}
                onGov={this.gotoGov}
                onLeg={this.gotoLeg}
                onAudit={this.gotoAudit}
                onFar={this.gotoFar}
              />
              <div className="ecosys_rightcont">
                {/**executionPartner */}
                <div ref={this.parRef} className="executionPartner" id="exeId">
                  <div className="hero-title20">
                    <b className="project-summary20">Execution Partner (NGO)</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Execution Partner (NGO)
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="execution_PartnerId"
                        value={this.state.execution_PartnerId}
                        onChange={this.handleChange_EP}
                      >
                        <option value="0">Select</option>
                        {this.state.ngoUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Personnel
                        </div>
                      </div>
                      <Multiselect
                        name="pS_EP_Personnel"
                        options={this.state.ep_PersonnelDataSource.map((e) => ({
                          name: e.ContactName,
                          design: e.ContactDesignation,
                          mobile: e.Mobile,
                          eP_PersonnelId: e.PointOfContactId,
                        }))}
                        placeholder="Select"
                        displayValue="name"
                        onSelect={(e) =>
                          this.handleSelect_Personnel(
                            e,
                            this.PARTNERSHIP_TYPE.EXECUTION_PARTNER
                          )
                        }
                        ref={this.multiselectRef_EP_Personnel}
                        className="multiselector_cont"
                      />
                    </div>
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.EXECUTION_PARTNER
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  {this.state.added_ExecutionPartner.map((eachPartner, i) => {
                    return (
                      <div className="form-group row pb-4" key={i}>
                        <div className="hero-title19 tblheadwithbtn">
                          <div className="project-summary19">
                            {
                              this.state.ngoUserList.filter(
                                (x) =>
                                  x.UserId === eachPartner.execution_PartnerId
                              )[0]?.NameOfNGO
                            }
                          </div>
                          <button
                            className="btn btn-outline-danger addrmvebtn btn-sm"
                            onClick={() =>
                              this.RemovePartnership(
                                this.PARTNERSHIP_TYPE.EXECUTION_PARTNER,
                                eachPartner.execution_PartnerId
                              )
                            }
                          >
                            {"Remove"}
                          </button>
                        </div>
                        <table className="table noflextable">
                          <thead>
                            <tr>
                              <th scope="col">Personnel</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Contact</th>
                              <th scope="col">SPOC for TreeTies</th>
                              <th scope="col">Project Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eachPartner.pS_EP_Personnel.map(
                              (eachPersonnel) => (
                                <tr>
                                  <td>{eachPersonnel.name}</td>
                                  <td>{eachPersonnel.design}</td>
                                  <td>{eachPersonnel.mobile}</td>
                                  <td>
                                    <div className="form-check form-switch pl-4">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        name={eachPartner.execution_PartnerId}
                                        checked={eachPersonnel.isSpoc}
                                        onChange={(e) =>
                                          this.handleGridRadioChangeEvent(
                                            e,
                                            this.PARTNERSHIP_TYPE
                                              .EXECUTION_PARTNER,
                                            eachPartner.execution_PartnerId,
                                            eachPersonnel.eP_PersonnelId
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={eachPersonnel.project_Role}
                                      name="project_Role"
                                      onChange={(e) =>
                                        this.handleGridTextChangeEvent(
                                          e,
                                          this.PARTNERSHIP_TYPE
                                            .EXECUTION_PARTNER,
                                          eachPartner.execution_PartnerId,
                                          eachPersonnel.eP_PersonnelId
                                        )
                                      }
                                    ></input>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>

                {/**institutionalSponsor */}
                <div
                  ref={this.parRef}
                  className="institutionalSponsor"
                  id="insId"
                >
                  <div className="hero-title20">
                    <b className="project-summary20">Institutional Sponsor</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Institutional Sponsor
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="institutional_SponsorId"
                        value={this.state.institutional_SponsorId}
                        onChange={this.handleChange_InstSponsor}
                      >
                        <option value="0">Select</option>
                        {this.state.instSponsorUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationTypeId"
                        value={this.state.associationTypeId}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationTypeId ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="instSponsor_ExecPartnerId"
                          value={this.state.instSponsor_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Personnel
                        </div>
                      </div>
                      <Multiselect
                        name="instSponsor_Personnel"
                        options={this.state.is_PersonnelDataSource.map((e) => ({
                          name: e.ContactName,
                          design: e.ContactDesignation,
                          mobile: e.Mobile,
                          iS_PersonnelId: e.PointOfContactId,
                        }))}
                        placeholder="Select"
                        displayValue="name"
                        onSelect={(e) =>
                          this.handleSelect_Personnel(
                            e,
                            this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR
                          )
                        }
                        ref={this.multiselectRef_instSponsor_Personnel}
                        className="multiselector_cont"
                      />
                    </div>
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>
                  {this.state.added_InstSponsor.map((eachInstSponsor, i) => {
                    return (
                      <div className="form-group row pb-4" key={i}>
                        <div className="hero-title19 tblheadwithbtn">
                          <div className="project-summary19">
                            {
                              this.state.instSponsorUserList.filter(
                                (x) =>
                                  x.UserId ===
                                  eachInstSponsor.institutional_SponsorId
                              )[0]?.NameOfNGO
                            }
                            <br />
                            Association Type:{" "}
                            {
                              this.state.associationTypeList.filter(
                                (x) =>
                                  x.mainGuId ===
                                  eachInstSponsor.associationTypeId
                              )[0]?.description
                            }
                            <br />
                            {eachInstSponsor.associationTypeId ===
                            this.ASSOCIATED_WITH_EXECUTION_PARTNER
                              ? `Execution Partner (NGO): ${
                                  this.state.ngoUserList.filter(
                                    (x) =>
                                      x.UserId ===
                                      eachInstSponsor.execution_PartnerId
                                  )[0]?.NameOfNGO
                                }`
                              : ""}
                          </div>
                          <button
                            className="btn btn-outline-danger addrmvebtn btn-sm"
                            onClick={() =>
                              this.RemovePartnership(
                                this.PARTNERSHIP_TYPE.INSTITUTIONAL_SPONSOR,
                                eachInstSponsor.institutional_SponsorId
                              )
                            }
                          >
                            {"Remove"}
                          </button>
                        </div>
                        <table className="table noflextable">
                          <thead>
                            <tr>
                              <th scope="col">Personnel</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Contact</th>
                              <th scope="col">SPOC for TreeTies</th>
                              <th scope="col">Project Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eachInstSponsor.pS_IS_Personnel.map(
                              (eachPersonnel) => (
                                <tr>
                                  <td>{eachPersonnel.name}</td>
                                  <td>{eachPersonnel.design}</td>
                                  <td>{eachPersonnel.mobile}</td>
                                  <td>
                                    <div className="form-check form-switch pl-4">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        name={
                                          eachInstSponsor.institutional_SponsorId
                                        }
                                        checked={eachPersonnel.isSpoc}
                                        onChange={(e) =>
                                          this.handleGridRadioChangeEvent(
                                            e,
                                            this.PARTNERSHIP_TYPE
                                              .INSTITUTIONAL_SPONSOR,
                                            eachInstSponsor.institutional_SponsorId,
                                            eachPersonnel.iS_PersonnelId
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={eachPersonnel.project_Role}
                                      name="project_Role"
                                      onChange={(e) =>
                                        this.handleGridTextChangeEvent(
                                          e,
                                          this.PARTNERSHIP_TYPE
                                            .INSTITUTIONAL_SPONSOR,
                                          eachInstSponsor.institutional_SponsorId,
                                          eachPersonnel.iS_PersonnelId
                                        )
                                      }
                                    ></input>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>

                {/* Individual Sponsor */}
                <div ref={this.parRef} className="individualSponsor" id="indId">
                  <div className="hero-title20">
                    <b className="project-summary20">Individual Sponsors</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Individual Sponsors
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="individual_SponsorId"
                        value={this.state.individual_SponsorId}
                        onChange={this.handleChange_IndSponsor}
                      >
                        <option value="0">Select</option>
                        {this.state.indSponsorUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationType_Ind_Id"
                        value={this.state.associationType_Ind_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationType_Ind_Id ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="indSponsor_ExecPartnerId"
                          value={this.state.indSponsor_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.INDIVIDUAL_SPONSOR
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  <div className="form-group row pb-4">
                    <div className="hero-title19 tblheadwithbtn"></div>
                    <table className="table noflextable">
                      <thead>
                        <tr>
                          <th scope="col">Individual Sponsor</th>
                          <th scope="col">Association Type</th>
                          <th scope="col">Execution Partner</th>
                          <th></th>
                        </tr>
                      </thead>
                      {this.state.added_IndSponsor.map((eachIndSponsor, i) => {
                        return (
                          <tbody>
                            <tr key={i}>
                              <td>
                                {
                                  this.state.indSponsorUserList.filter(
                                    (x) =>
                                      x.UserId ===
                                      eachIndSponsor.individual_SponsorId
                                  )[0]?.NameOfNGO
                                }
                              </td>
                              <td>
                                {
                                  this.state.associationTypeList.filter(
                                    (x) =>
                                      x.mainGuId ===
                                      eachIndSponsor.associationType_Ind_Id
                                  )[0]?.description
                                }
                              </td>
                              <td>
                                {this.state.ngoUserList.filter(
                                  (x) =>
                                    x.UserId ===
                                    eachIndSponsor.execution_PartnerId
                                )[0]?.NameOfNGO ?? "-"}
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-danger addrmvebtn btn-sm"
                                  onClick={() =>
                                    this.RemovePartnership(
                                      this.PARTNERSHIP_TYPE.INDIVIDUAL_SPONSOR,
                                      eachIndSponsor.individual_SponsorId
                                    )
                                  }
                                >
                                  {"Remove"}
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>

                {/* Farmer */}
                <div ref={this.parRef} className="farmer" id="farId">
                  <div className="hero-title20">
                    <b className="project-summary20">Farmer</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">Select Farmer</div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="farmer_Id"
                        value={this.state.farmer_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.farmerUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationType_farmer_Id"
                        value={this.state.associationType_farmer_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationType_farmer_Id ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="farmer_ExecPartnerId"
                          value={this.state.farmer_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(this.PARTNERSHIP_TYPE.FARMER)
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  <div className="form-group row pb-4">
                    <div className="hero-title19 position-relative">
                      {/* <div className="project-summary19">
                              {this.state.farmerUserList.filter(x=>x.UserId=== eachFarmer.farmer_Id)[0]?.NameOfNGO}
                              <br/>Association Type:  {this.state.associationTypeList.filter(x=>x.mainGuId=== eachFarmer.associationType_farmer_Id)[0]?.description}
                              <br/>{eachFarmer.associationType_farmer_Id === this.ASSOCIATED_WITH_EXECUTION_PARTNER ? `Execution Partner (NGO): ${this.state.ngoUserList.filter(x=>x.UserId=== eachFarmer.farmer_ExecPartnerId)[0]?.NameOfNGO}` : ""}
                            </div> */}
                      {/* <div className="position-absolute top-0 end-0"><button className="btn btn-outline-danger btn-sm" onClick={()=>this.RemovePartnership(this.PARTNERSHIP_TYPE.FARMER, eachFarmer.farmer_Id)}>{'Remove'}</button></div> */}
                    </div>
                    <table className="table noflextable">
                      <thead>
                        <tr>
                          <th scope="col">Farmer</th>
                          <th scope="col">Association Type</th>
                          <th scope="col">Execution Partner</th>
                          <th></th>
                        </tr>
                      </thead>
                      {this.state.added_Farmer.map((eachFarmer, i) => {
                        return (
                          <tbody>
                            <tr key={i}>
                              <td>
                                {
                                  this.state.farmerUserList.filter(
                                    (x) => x.UserId === eachFarmer.farmer_Id
                                  )[0]?.NameOfNGO
                                }
                              </td>
                              <td>
                                {
                                  this.state.associationTypeList.filter(
                                    (x) =>
                                      x.mainGuId ===
                                      eachFarmer.associationType_farmer_Id
                                  )[0]?.description
                                }
                              </td>
                              <td>
                                {this.state.ngoUserList.filter(
                                  (x) =>
                                    x.UserId === eachFarmer.farmer_ExecPartnerId
                                )[0]?.NameOfNGO ?? "-"}
                              </td>
                              <td>
                                <button
                                  className="btn btn-outline-danger addrmvebtn btn-sm"
                                  onClick={() =>
                                    this.RemovePartnership(
                                      this.PARTNERSHIP_TYPE.FARMER,
                                      eachFarmer.farmer_Id
                                    )
                                  }
                                >
                                  {"Remove"}
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>

                {/**Government Entities */}
                <div
                  ref={this.parRef}
                  className="governmentEntities"
                  id="govId"
                >
                  <div className="hero-title20">
                    <b className="project-summary20">Government Entities</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Government Entities
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="govern_EntitiesId"
                        value={this.state.govern_EntitiesId}
                        onChange={this.handleChange_GE}
                      >
                        <option value="0">Select</option>
                        {this.state.governEntitiesUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationType_GE_Id"
                        value={this.state.associationType_GE_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationType_GE_Id ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="ge_ExecPartnerId"
                          value={this.state.ge_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Personnel
                        </div>
                      </div>
                      <Multiselect
                        name="goverEntities_Personnel"
                        options={this.state.ge_PersonnelDataSource.map((e) => ({
                          name: e.ContactName,
                          design: e.ContactDesignation,
                          mobile: e.Mobile,
                          gE_PersonnelId: e.PointOfContactId,
                        }))}
                        placeholder="Select"
                        displayValue="name"
                        onSelect={(e) =>
                          this.handleSelect_Personnel(
                            e,
                            this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES
                          )
                        }
                        ref={this.multiselectRef_ge_Personnel}
                        className="multiselector_cont"
                      />
                    </div>
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  {this.state.added_GE.map((eachGE, i) => {
                    return (
                      <div className="form-group row pb-4" key={i}>
                        <div className="hero-title19 tblheadwithbtn">
                          <div className="project-summary19">
                            {
                              this.state.governEntitiesUserList.filter(
                                (x) => x.UserId === eachGE.govern_EntitiesId
                              )[0]?.NameOfNGO
                            }
                            <br />
                            Association Type:{" "}
                            {
                              this.state.associationTypeList.filter(
                                (x) =>
                                  x.mainGuId === eachGE.associationType_GE_Id
                              )[0]?.description
                            }
                            <br />
                            {eachGE.associationType_GE_Id ===
                            this.ASSOCIATED_WITH_EXECUTION_PARTNER
                              ? `Execution Partner (NGO): ${
                                  this.state.ngoUserList.filter(
                                    (x) => x.UserId === eachGE.ge_ExecPartnerId
                                  )[0]?.NameOfNGO
                                }`
                              : ""}
                          </div>
                          <button
                            className="btn btn-outline-danger addrmvebtn btn-sm"
                            onClick={() =>
                              this.RemovePartnership(
                                this.PARTNERSHIP_TYPE.GOVERNMENT_ENTITIES,
                                eachGE.govern_EntitiesId
                              )
                            }
                          >
                            {"Remove"}
                          </button>
                        </div>
                        <table className="table noflextable">
                          <thead>
                            <tr>
                              <th scope="col">Personnel</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Contact</th>
                              <th scope="col">SPOC for TreeTies</th>
                              <th scope="col">Project Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eachGE.pS_GE_Personnel.map((eachPersonnel) => (
                              <tr>
                                <td>{eachPersonnel.name}</td>
                                <td>{eachPersonnel.design}</td>
                                <td>{eachPersonnel.mobile}</td>
                                <td>
                                  <div className="form-check form-switch pl-4">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={eachGE.govern_EntitiesId}
                                      checked={eachPersonnel.isSpoc}
                                      onChange={(e) =>
                                        this.handleGridRadioChangeEvent(
                                          e,
                                          this.PARTNERSHIP_TYPE
                                            .GOVERNMENT_ENTITIES,
                                          eachGE.govern_EntitiesId,
                                          eachPersonnel.gE_PersonnelId
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={eachPersonnel.project_Role}
                                    name="project_Role"
                                    onChange={(e) =>
                                      this.handleGridTextChangeEvent(
                                        e,
                                        this.PARTNERSHIP_TYPE
                                          .GOVERNMENT_ENTITIES,
                                        eachGE.govern_EntitiesId,
                                        eachPersonnel.gE_PersonnelId
                                      )
                                    }
                                  ></input>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>

                {/** Legal Service Provider */}
                <div
                  ref={this.parRef}
                  className="institutionalSponsor"
                  id="legId"
                >
                  <div className="hero-title20">
                    <b className="project-summary20">Legal Service Provider</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Legal Service Provider
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="lsp_Id"
                        value={this.state.lsp_Id}
                        onChange={this.handleChange_LSP}
                      >
                        <option value="0">Select</option>
                        {this.state.lspUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationType_LSP_Id"
                        value={this.state.associationType_LSP_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationType_LSP_Id ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="lsp_ExecPartnerId"
                          value={this.state.lsp_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Personnel
                        </div>
                      </div>
                      <Multiselect
                        name="lsp_Personnel"
                        options={this.state.lsp_PersonnelDataSource.map(
                          (e) => ({
                            name: e.ContactName,
                            design: e.ContactDesignation,
                            mobile: e.Mobile,
                            lSP_PersonnelId: e.PointOfContactId,
                          })
                        )}
                        placeholder="Select"
                        displayValue="name"
                        onSelect={(e) =>
                          this.handleSelect_Personnel(
                            e,
                            this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER
                          )
                        }
                        ref={this.multiselectRef_lsp_Personnel}
                        className="multiselector_cont"
                      />
                    </div>
                    <div className="jstspcebtn pt-4">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  {this.state.added_LSP.map((eachLsp, i) => {
                    return (
                      <div className="form-group row pb-4" key={i}>
                        <div className="hero-title19 tblheadwithbtn">
                          <div className="project-summary19">
                            {
                              this.state.lspUserList.filter(
                                (x) => x.UserId === eachLsp.lsp_Id
                              )[0]?.NameOfNGO
                            }
                            <br />
                            Association Type:{" "}
                            {
                              this.state.associationTypeList.filter(
                                (x) =>
                                  x.mainGuId === eachLsp.associationType_lsp_Id
                              )[0]?.description
                            }
                            <br />
                            {eachLsp.associationType_lsp_Id ===
                            this.ASSOCIATED_WITH_EXECUTION_PARTNER
                              ? `Execution Partner (NGO): ${
                                  this.state.ngoUserList.filter(
                                    (x) =>
                                      x.UserId === eachLsp.lsp_ExecPartnerId
                                  )[0]?.NameOfNGO
                                }`
                              : ""}
                          </div>
                          <button
                            className="btn btn-outline-danger addrmvebtn btn-sm"
                            onClick={() =>
                              this.RemovePartnership(
                                this.PARTNERSHIP_TYPE.LEGAL_SERVICE_PROVIDER,
                                eachLsp.lsp_Id
                              )
                            }
                          >
                            {"Remove"}
                          </button>
                        </div>
                        <table className="table noflextable">
                          <thead>
                            <tr>
                              <th scope="col">Personnel</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Contact</th>
                              <th scope="col">SPOC for TreeTies</th>
                              <th scope="col">Project Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eachLsp.pS_LSP_Personnel.map((eachPersonnel) => (
                              <tr>
                                <td>{eachPersonnel.name}</td>
                                <td>{eachPersonnel.design}</td>
                                <td>{eachPersonnel.mobile}</td>
                                <td>
                                  <div className="form-check form-switch pl-4">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={eachLsp.lsp_Id}
                                      checked={eachPersonnel.isSpoc}
                                      onChange={(e) =>
                                        this.handleGridRadioChangeEvent(
                                          e,
                                          this.PARTNERSHIP_TYPE
                                            .LEGAL_SERVICE_PROVIDER,
                                          eachLsp.lsp_Id,
                                          eachPersonnel.lSP_PersonnelId
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    value={eachPersonnel.project_Role}
                                    name="project_Role"
                                    onChange={(e) =>
                                      this.handleGridTextChangeEvent(
                                        e,
                                        this.PARTNERSHIP_TYPE
                                          .LEGAL_SERVICE_PROVIDER,
                                        eachLsp.lsp_Id,
                                        eachPersonnel.lSP_PersonnelId
                                      )
                                    }
                                  ></input>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>

                {/** Audit Service Provider */}
                <div
                  ref={this.parRef}
                  className="auditServiceProvider"
                  id="auditId"
                >
                  <div className="hero-title20">
                    <b className="project-summary20">Audit Service Provider</b>
                  </div>
                  <div className="input-field-text31 pb-4">
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Audit Service Provider
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="institutional_SponsorId"
                        value={this.state.asp_Id}
                        onChange={this.handleChange_ASP}
                      >
                        <option value="0">Select</option>
                        {this.state.aspUserList.map(function (item) {
                          return (
                            <option value={item.UserId}>
                              {item.NameOfNGO}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Association Type
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="associationType_ASP_Id"
                        value={this.state.associationType_ASP_Id}
                        onChange={this.handleEvent}
                      >
                        <option value="0">Select</option>
                        {this.state.associationTypeList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text31 pb-4">
                    {this.state.associationType_ASP_Id ==
                      this.ASSOCIATED_WITH_EXECUTION_PARTNER && (
                      <div className="content-title-text150">
                        <div className="hero-title19">
                          <div className="project-summary19">
                            Select Execution Partner (NGO)
                          </div>
                        </div>
                        <select
                          className="dropdown-130"
                          name="asp_ExecPartnerId"
                          value={this.state.asp_ExecPartnerId}
                          onChange={this.handleEvent}
                        >
                          <option value="0">Select</option>
                          {this.state.added_ExecutionPartner.map((item) => {
                            let execPart = this.state.ngoUserList.filter(
                              (ngoItem) =>
                                ngoItem.UserId === item.execution_PartnerId
                            )[0];
                            return (
                              <option value={execPart.UserId}>
                                {execPart.NameOfNGO}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="content-title-text150">
                      <div className="hero-title19">
                        <div className="project-summary19">
                          Select Personnel
                        </div>
                      </div>
                      <Multiselect
                        name="asp_PersonnelDataSource"
                        options={this.state.asp_PersonnelDataSource.map(
                          (e) => ({
                            name: e.ContactName,
                            design: e.ContactDesignation,
                            mobile: e.Mobile,
                            aSP_PersonnelId: e.PointOfContactId,
                          })
                        )}
                        placeholder="Select"
                        displayValue="name"
                        onSelect={(e) =>
                          this.handleSelect_Personnel(
                            e,
                            this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER
                          )
                        }
                        ref={this.multiselectRef_asp_Personnel}
                        className="multiselector_cont"
                      />
                    </div>
                    <div className="jstspcebtn">
                      <button
                        className="btn btn-outline-primary addrmvebtn addbtnnewstyle btn-sm"
                        onClick={() =>
                          this.AddPartnership(
                            this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER
                          )
                        }
                      >
                        {"Add"}
                      </button>
                    </div>
                  </div>

                  {this.state.added_ASP &&
                    this.state.added_ASP.map((eachASP, a) => {
                      return (
                        <div className="form-group row pb-4" key={a}>
                          <div className="hero-title19 tblheadwithbtn">
                            <div className="project-summary19">
                              {
                                this.state.aspUserList.filter(
                                  (x) => x.UserId === eachASP.asp_Id
                                )[0]?.NameOfNGO
                              }
                              <br />
                              Association Type:{" "}
                              {
                                this.state.associationTypeList.filter(
                                  (x) =>
                                    x.mainGuId ===
                                    eachASP.associationType_asp_Id
                                )[0]?.description
                              }
                              <br />
                              {eachASP.associationType_asp_Id ===
                              this.ASSOCIATED_WITH_EXECUTION_PARTNER
                                ? `Execution Partner (NGO): ${
                                    this.state.ngoUserList.filter(
                                      (x) =>
                                        x.UserId === eachASP.asp_ExecPartnerId
                                    )[0]?.NameOfNGO
                                  }`
                                : ""}
                            </div>
                            <button
                              className="btn btn-outline-danger addrmvebtn btn-sm"
                              onClick={() =>
                                this.RemovePartnership(
                                  this.PARTNERSHIP_TYPE.AUDIT_SERVICE_PROVIDER,
                                  eachASP.asp_Id
                                )
                              }
                            >
                              {"Remove"}
                            </button>
                          </div>
                          <table className="table noflextable">
                            <thead>
                              <tr>
                                <th scope="col">Personnel</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Contact</th>
                                <th scope="col">SPOC for TreeTies</th>
                                <th scope="col">Project Role</th>
                              </tr>
                            </thead>
                            <tbody>
                              {eachASP.pS_ASP_Personnel.map((eachPersonnel) => (
                                <tr>
                                  <td>{eachPersonnel.name}</td>
                                  <td>{eachPersonnel.design}</td>
                                  <td>{eachPersonnel.mobile}</td>
                                  <td>
                                    <div className="form-check form-switch pl-4">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        name={eachASP.asp_Id}
                                        checked={eachPersonnel.isSpoc}
                                        onChange={(e) =>
                                          this.handleGridRadioChangeEvent(
                                            e,
                                            this.PARTNERSHIP_TYPE
                                              .AUDIT_SERVICE_PROVIDER,
                                            eachASP.asp_Id,
                                            eachPersonnel.aSP_PersonnelId
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      value={eachPersonnel.project_Role}
                                      name="project_Role"
                                      onChange={(e) =>
                                        this.handleGridTextChangeEvent(
                                          e,
                                          this.PARTNERSHIP_TYPE
                                            .AUDIT_SERVICE_PROVIDER,
                                          eachASP.asp_Id,
                                          eachPersonnel.aSP_PersonnelId
                                        )
                                      }
                                    ></input>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                </div>

                <button
                  className="cta14"
                  onClick={(e) => this.SavePartnership(e)}
                >
                  <div className="save-continue7">{`Save & Continue`}</div>
                </button>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar">
                  <button className="plus-circle">plus-circle</button>
                  <button className="notifications-bell">notifications-bell</button>
                  <button className="notifications-bell">email</button>
                </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Par1);
