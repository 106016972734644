import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from "../../../utils/config";
import ToastNotification from '../../Common/ToastNotification/ToastNotification';
//Comment added for re-deployment

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userDetails:{},
            isInfo: false,
            isDocuments:false,
        } 
   }

   componentDidMount() {
        this.GetProfile();
    }

   handleProfileImage=() =>{
     const fullName = document.getElementById('fullName').textContent;
     const intials = fullName.split(' ').map(name => name[0]).join('').toUpperCase();
     document.getElementById('profileImage').innerHTML = intials;
   }
   
     handleDownloadDocument = (FileName) => {
      let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/${this.state.userDetails.nameOfNGO}/${FileName}`;
         fetch(url)
           .then(response => {
            response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = FileName;
         a.click();
        });
     });
}

   GetProfile = () => {
     let url = `${config.API_HOST_URL}/api/Login/GetProfile/${this.props.userData.userId}`;
        fetch(url, {
           method: "Get",
           headers: {
               WebsiteGuid: this.props.websiteGUID,
                   LanguageGuid: this.props.languageGUID,
                   Authorization: `Bearer ${this.props.token}`,
                   "Content-Type": "application/json",
                   Accept: "application/json"
           }
       })
           .then(response =>  response.json())
           .then(data => { 
              this.setState({userDetails: data});
              this.handleProfileImage();
              this.handleInfo();
       })
           .catch(error => {
               console.log("Error in request", error);
       });
  };

     openInNewTab = (url) => {
       window.open(url);
  };
 

     handleInfo = () => {
          document.getElementById("Info").classList.add("active");
          document.getElementById("Documents").classList.remove("active");
                 this.setState({isInfo: true, isDocuments:false });
         };

     handleDocuments = () => {
          document.getElementById("Info").classList.remove("active");
          document.getElementById("Documents").classList.add("active");
                 this.setState({isDocuments: true, isInfo:false });
         }; 

     handleEdit(){
          ToastNotification.ShowInfo("Edit not allowed")
         }

 render() { 
    return (
         <>
         <div className="section_heading1 myProfile"><h3>My Profile</h3></div>
             <div className="profileBox profileNBox">
                 <div className="profileInfo">
                     <div className="profileImage">
           <div className='profileSection'>
           <div id="profileImage">
              <span id="fullName" >{this.state.userDetails.ngoHeadName}</span></div>
         <ul>
             <li>
                 <h6 className="nameTitle">{this.state.userDetails.ngoHeadName}</h6> 
             { this.state.userDetails.contactDesignation!=undefined && this.state.userDetails.contactDesignation.trim().length>0 ?<p className="info">{this.state.userDetails.contactDesignation} at <span>{this.state.userDetails.nameOfNGO}</span></p>: ''}
             </li>
         </ul>
         </div>
           <>
          {" "}
         <div className="Infocontainer">
            <button name="Info" id="Info"  className="Info active" onClick={this.handleInfo}>Info </button>
            <button name="Documents"  id="Documents" className="Documents"  onClick={this.handleDocuments}>Documents</button> </div>            
            </>
            { this.state.isInfo ?<div className='sectionAllData'>
        <div className='editDocument'><a><i class="fas fa-pen"  onClick={() => this.handleEdit()}></i></a></div>
            <div className="section_data generalData">
                <h3>General Details</h3>
                 <ul>
                    <li>
                        <p className="title">Name Of Ngo </p>
                        <p className="info">{this.state.userDetails?.nameOfNGO}</p>
                    </li>      
                    <li>
                        <p className="title">Base Location </p>
                        <p className="info">{this.state.userDetails.baseLocation}</p>
                    </li>  
                    <li>
                        <p className="title">Type Of Incorporation </p>
                        <p className="info">{this.state.userDetails.typeOfIncorporation}</p>
                    </li>
                    <li>
                        <p className="title">Head Of Organisation</p>
                        <p className="info">{this.state.userDetails.ngoHeadName}</p>
                    </li>
                    <li>
                        <p className="title">NGO Email Address</p>
                        <p className="info email">{this.state.userDetails.ngoheadEmailAddr}</p>
                    </li>
                </ul>
            </div>
     <div className="section_data">
        <h3> Point Of Contact</h3>
         <ul> 
             <li>
                 <p className="title">Name </p>
                 <p className="info">{this.state.userDetails?.contactName}</p>
             </li>      
             <li>
                 <p className="title">Mobile </p>
                 <p className="info mobile">{this.state.userDetails.mobile}</p>
             </li>  
             <li>
                 <p className="title">Email Id</p>
                 <p className="info email">{this.state.userDetails.email}</p>
             </li>
             <li>
                 <p className="title">Designation</p>
                 <p className="info">{this.state.userDetails.contactDesignation}</p>
             </li>
         </ul>
       </div>
     <div className="section_data profothrdet_cont"><h3> Other Details</h3>
         <ul>
             <li>
                  <p className="title">Quantum of Trees Planted </p>
                  <p className="info">{this.state.userDetails?.treePlantedBefore}</p>
             </li>      
             <li >
                 <p className="title">Website/Social Media Link/News Link</p>
                <a> <p className="info email" onClick={() => this.openInNewTab('https://'+ this.state.userDetails.website)}>{this.state.userDetails.website}</p></a>
             </li>  
         </ul>
       </div>
     </div>:""}                                
     { this.state.isDocuments?
    <div className='sectionAllData documentSectionData'>
     <div className='editDocument'><a><i class="fas fa-pen" onClick={() => this.handleEdit()}></i></a></div>
       <div className="section_data"><h3>Documents</h3>
        <ul>
            <li>
                {this.props.userData.typeOfIncorporation == "Trust"?
                <div><p className="title">Trust Deed </p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.trustdocument)}>{this.state.userDetails.trustdocument}</a></p></div>
                :this.props.userData.typeOfIncorporation == "Privatelimitedcompany"?
                <div><p className="title">ROC Registration </p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.rocdocument)}>{this.state.userDetails.rocdocument}</a></p></div>
                :this.props.userData.typeOfIncorporation =="Others"?
                <div> <p className="title">Company Incorporation Documents </p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.companyincarporationdocument)}>{this.state.userDetails.companyincarporationdocument}</a></p>
                </div>:""}
            </li>
            <li>
                <p className="title">Authorized Signatories </p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.authorsignatories)}>{this.state.userDetails.authorsignatories}</a></p>
            </li>
            <li>
                <p className="title">Blank Cancel Cheque</p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.blankcanchq)}>{this.state.userDetails.blankcanchq}</a></p>
            </li>
            <li>
                <p className="title">Additional Document </p>
                <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.additionaldocument)}>{this.state.userDetails.additionaldocument}</a></p>
            </li>
            <li>
                {this.props.userData.typeOfIncorporation == "Privatelimitedcompany"?
                 <div>
                    <p className="title">Memorandum Of Association (MoA) Of Company </p>
                    <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.moadocument)}>{this.state.userDetails.moadocument}</a></p>
                 </div>:""}
            </li>
            <li>
                {this.props.userData.typeOfIncorporation == "Privatelimitedcompany"?
                 <div>
                    <p className="title">Articles Of Association (AoA) Of Company </p>
                    <p><a className="projnametd" onClick={() => this.handleDownloadDocument(this.state.userDetails.aoadocument)}>{this.state.userDetails.aoadocument}</a></p>
                 </div>:""}
            </li>
        </ul>
    </div>
   </div>:""}
  </div> 
 </div>
    </div>
   </>
     )
  }
}

function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);