import React from "react";
import { connect } from "react-redux";
import "./header.css";
import { navigate } from "@reach/router";


class FrontEndHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false
        };
        this.mounted = true;
    }
    render() {
        return (
            <div className="headereco">
              <div className="header-left-content">
                <div className="logo">
                  <div className="logo-a">logo-A</div>
                </div>
                <div className="page-title">
                  <div className="logo-a">arrow-left</div>
                  <b className="aarey-forest-phase9">Aarey Forest Phase II</b>
                </div>
              </div>
              <div className="header-right-content">
                <div className="logo-a">search-circle</div>
                <img
                  className="header-right-content-child"
                  alt=""
                  src="/rectangle-1@2x.png"
                />
              </div>
            </div>
          );
    };
}


function mapStateToProps(state) {
    return {
        userData: state.user.userData,
        token: state.configuration.token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setReducer: (data, type) =>
            dispatch({
                type: type,
                data: data
            })

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontEndHeader);
