import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from "@patternfly/react-core";
import Loader from "./../../Common/Loader/Loader";
import config from "../../../utils/config";
import MainViewer from "../../Common/Map/MainViewer";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import { navigate } from "@reach/router";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Moment from "react-moment";
import moment from "moment";
class AddNewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,

      fields: {},
      errors: {},
      IsLoader: false,
      ProjectName: "",
      Location: "",
      Status: "Unverified",
      userData: [],
      files: [],
      Area: "",
      SiteUse: "",
      Ownername: "",
      regexp: /^[0-9\b]+$/,

      hide: false,

      projectType: [],
      projectSubType: [],
      selectedprojectType: "",
      selectedprojectSubType: "",
      initiatedAndNew: "",
      typeList: [],
      initiatedAndNew: "",
      percentageCompleted: "",
      commonMastersList: [],
      notificationMessage: "",

      dateOfInitiation: "",
      estimatedProjectPeriod: "",
    };
    this.handleProjectType = this.handleProjectType.bind(this);
    this.handleNewAndInitiated = this.handleNewAndInitiated.bind(this);
    this.handleProjectSubType = this.handleProjectSubType.bind(this);
    this.handleEstimatedProjectPeriod =
      this.handleEstimatedProjectPeriod.bind(this);
    this.handleDate = this.handleDate.bind(this);

    this.mounted = true;
    this.handleModalToggle = () => {
      this.clearFields();
      this.setState(({ isModalOpen }) => ({
        isModalOpen: !isModalOpen,
        errors: {},
      }));
    };
  }

  componentDidMount() {
    this.mounted = true;

    this.GetCommonMasters();
  }

  handleNewAndInitiated(event) {
    this.setState({ initiatedAndNew: event.target.value });
    this.setState({
      errors: { ...this.state.errors, initiatedAndNew: "" },
    });
  }
  handleDate(event) {
    if (this.mounted) this.setState({ dateOfInitiation: event.target.value });
    this.setState({
      errors: { ...this.state.errors, dateOfInitiation: "" },
    });
  }

  handleEstimatedProjectPeriod(event) {
    this.setState({ estimatedProjectPeriod: event.target.value });
    this.setState({
      errors: { ...this.state.errors, estimatedProjectPeriod: "" },
    });
  }
  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var listOfType = data
          .filter((x) => x.type === "ProjectType")
          .map((x) => x.description);

        this.setState({
          commonMastersList: data,
          typeList: listOfType,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  handleProjectType = (event) => {
    const value = event.target.value;
    // this.setState({projectSubType :this.state.commonMastersList.filter(x => x.type === event.target.value).map((item)});
    // this.setState({projectSubType :this.state.commonMastersList.filter(x=>x.type==="ProjectSubType", x=>x.mainGuId===event.target.value).find(x=>x.description)});

    if (event.target.name == "ddltype")
      this.setState({ selectedprojectType: event.target.value });
    this.setState({
      errors: { ...this.state.errors, selectedprojectType: "" },
    });
    //    {this.state.commonMastersList.filter(x => x.mainGuId === event.target.value).map((item) =>  this.setState({projectSubType :item}))}
    this.setState({
      projectSubType: this.state.commonMastersList.filter(
        (x) => x.mainGuId === event.target.value
      ),
    });
  };

  handleProjectSubType = (event) => {
    const value = event.target.value;
    if (event.target.name == "ddlsubtype")
      this.setState({ selectedprojectSubType: event.target.value });
    this.setState({
      errors: { ...this.state.errors, selectedprojectSubType: "" },
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  clearFields = (e) => {
    if (this.mounted) {
      this.setState({
        ProjectName: "",
        Location: "",
        Ownername: "",
        SiteUse: "",
        Area: "",
        ProjectType: "new",

        Status: "Unverified",
        files: [],

        typeList: "",
        initiatedAndNew: "",
        percentageCompleted: "",

        dateOfInitiation: "",
        estimatedProjectPeriod: "",
        selectedprojectType: "",
        selectedprojectSubType: "",
      });
    }
  };

  goBack = (e) => {
    window.history.back();
  };

  // This function do validate the Registration Form
  validateForm = (e) => {
    let fields;
    fields = {
      ProjectName: this.state.ProjectName,
      Location: this.state.Location,
      Area: this.state.Area,
      SiteUse: this.state.SiteUse,
      Ownername: this.state.Ownername,
      Status: "Unverified",

      estimatedProjectPeriod: this.state.estimatedProjectPeriod,
      selectedprojectType: this.state.selectedprojectType,
      selectedprojectSubType: this.state.selectedprojectSubType,
      percentageCompleted: this.state.percentageCompleted,

      initiatedAndNew: this.state.initiatedAndNew,
      dateOfInitiation: this.state.dateOfInitiation,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["ProjectName"]) {
      formIsValid = false;
      errors["ProjectName"] = "Enter Project Name.";
    }
    if (
      !fields["percentageCompleted"] &&
      this.state.initiatedAndNew == "Initiated Already"
    ) {
      formIsValid = false;
      errors["percentageCompleted"] = "Enter Percentage.";
    }
    if (
      !fields["dateOfInitiation"] &&
      this.state.initiatedAndNew == "Initiated Already"
    ) {
      formIsValid = false;
      errors["dateOfInitiation"] = "Enter Date.";
    }
    if (!fields["estimatedProjectPeriod"]) {
      formIsValid = false;
      errors["estimatedProjectPeriod"] = "Enter Estimated Project Period.";
    }

    if (!fields["selectedprojectType"]) {
      formIsValid = false;
      errors["selectedprojectType"] = "Enter Project Type.";
    }
    if (!fields["selectedprojectSubType"]) {
      formIsValid = false;
      errors["selectedprojectSubType"] = "Enter Project Sub-Type.";
    }
    if (!fields["initiatedAndNew"]) {
      formIsValid = false;
      errors["initiatedAndNew"] = "Enter  New or Initiated Already.";
    }
    if (!fields["Location"]) {
      formIsValid = false;
      errors["Location"] = "Enter Location.";
    }
    if (!fields["Area"]) {
      formIsValid = false;
      errors["Area"] = "Enter Area.";
    }
    if (!fields["Ownername"]) {
      formIsValid = false;
      errors["Ownername"] = "Enter Owner Name.";
    }
    if (!fields["SiteUse"]) {
      formIsValid = false;
      errors["SiteUse"] = "Enter Site Use.";
    }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };

  click = () => {
    this.props.parentMethod("All");
  };

  AddNewProject = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      if (this.mounted) {
        const formData = new FormData();

        formData.append("UserId", this.props.userData.userId);
        formData.append("ProjectName", this.state.ProjectName);

        formData.append("Status", "Unverified");
        formData.append("IsActive", true);
        formData.append("ProjectTypeGuId", this.state.selectedprojectType);
        formData.append(
          "ProjectSubTypeGuId",
          this.state.selectedprojectSubType
        );
        formData.append("NewOrInitiatedAlready", this.state.initiatedAndNew);
        formData.append("PercentageCompleted", this.state.percentageCompleted);
        formData.append(
          "EstimatedProjectPeriod",
          this.state.estimatedProjectPeriod
        );
        var dateofin = new Date(this.state.dateOfInitiation).toUTCString();
        formData.append("DateOfInitiation", dateofin);
        formData.append("Location", this.state.Location);
        formData.append("Area", this.state.Area);
        formData.append("SiteUse", this.state.SiteUse);
        formData.append("Ownername", this.state.Ownername);
        let uri = `${config.API_HOST_URL}/api/projects/AddNewProjectPI`;
        let fetchOptions = {
          method: "POST",
          //body: JSON.stringify(UserDetail),
          body: formData,
          headers: {
            Authorization: `Bearer ${this.props.token}`,
            Accept: "application/json",
            ContentType: "multipart/form-data",
          },
        };
        this.setState({ IsLoader: true });
        fetch(uri, fetchOptions)
          .then((response) => {
            return response.json().then((data) => {
              if (data.success) {
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                    IsLoader: false,
                  });
                }
                this.setState({ notificationMessage: data.message });
                this.click();
                this.clearFields();
                // this.handleModalToggle();
                //alert(data.message);
                ToastNotification.ShowSuccess(data.message);
              } else {
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                    IsLoader: false,
                  });
                }
                ToastNotification.ShowSuccess(data.message);
              }
            });
          })
          .catch((error) => {
            if (this.mounted) {
              console.log(error);
              this.setState({
                errorMsg: error,
                IsLoader: false,
              });
            }
          });
      }
    }
  };

  // This function will set the state of input fields and validate them when user enters the value in input boxes

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (this.mounted) {
      this.setState({ [name]: value });
      this.setState({
        errors: { ...this.state.errors, [e.target.name]: "" },
      });
    }
  };

  handlePercentInput = (e) => {
    if (
      e.metaKey ||
      e.ctrlKey ||
      e.key === "Backspace" ||
      e.key === "Delete" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "Tab" ||
      e.key === "Enter"
    ) {
      return;
    }

    // Prevent non-numeric, non-dot, and non-percentage characters
    if (/[^0-9.%]/.test(e.key)) {
      e.preventDefault();
    }
  };

  handlePercentageInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Check if the input value is valid
    if (/^\d*\.?\d*(%)?$/.test(value)) {
      if (this.mounted) {
        this.setState({ [name]: value });
      }
    }
  };
  handleAlphaInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Prevent multiple spaces
    value = value.replace(/\s{2,}/g, " ");

    if (this.mounted) {
      const regex = /^[a-z\d\-_\s]+$/i;
      if (value.match(regex) || value === "") {
        this.setState({ [name]: value });
        this.setState({
          errors: { ...this.state.errors, [e.target.name]: "" },
        });
      } else {
        e.target.value = value.replace(/\s{2,}/g, " ");
      }
    } else {
      this.setState({
        ProjectName: "",
      });
    }
  };

  handleOwnerInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (this.mounted) {
      const value = e.target.value.replace(/^ /, "").replace(/[^A-Za-z ]/g, ""); // remove leading spaces and non-alphabet characters, allow spaces
      const newValue = value.replace(/\s{2,}/g, " "); // replace multiple spaces with a single space

      this.setState({ Ownername: newValue });
      this.setState({
        errors: { ...this.state.errors, [e.target.name]: "" },
      });
    } else {
      this.setState({
        Ownername: "",
      });
    }
  };

  handleSiteInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (this.mounted) {
      const value = e.target.value.replace(/^ /, "").replace(/[^A-Za-z ]/g, ""); // remove leading spaces and non-alphabet characters, allow spaces
      const newValue = value.replace(/\s{2,}/g, " "); // replace multiple spaces with a single space

      this.setState({ SiteUse: newValue });
      this.setState({
        errors: { ...this.state.errors, [e.target.name]: "" },
      });
    } else {
      this.setState({
        SiteUse: "",
      });
    }
  };

  handleAreaInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Prevent multiple spaces
    //value = value.replace(/\s{2,}/g, " ");

    if (this.mounted) {
      if (name === "Area") {
        const regex = /^\d+$/; // only allow numbers
        if (value.match(regex) || value === "") {
          this.setState({ [name]: value });
          this.setState({
            errors: { ...this.state.errors, [e.target.name]: "" },
          });
        } else {
          e.target.value = value.replace(/[^\d]/g, ""); // remove non-numeric characters
        }
      }
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  onddlselectprorequidoc = (e) => {
    if (this.mounted) {
      this.setState({
        ProjectRequiredDocuments: e.target.value,
      });
    }
  };
  click = () => {
    this.props.parentMethod("All");
  };
  render() {
    const { typeList, isModalOpen, isModalMapOpen } = this.state;
    return (
      <div>
        {this.state.IsLoader ? (
          <Loader />
        ) : (
          <div className="addpro_btn">
            <Button onClick={this.handleModalToggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M12.4688 7C12.4688 7.17405 12.3996 7.34097 12.2765 7.46404C12.1535 7.58711 11.9865 7.65625 11.8125 7.65625H7.65625V11.8125C7.65625 11.9865 7.58711 12.1535 7.46404 12.2765C7.34097 12.3996 7.17405 12.4688 7 12.4688C6.82595 12.4688 6.65903 12.3996 6.53596 12.2765C6.41289 12.1535 6.34375 11.9865 6.34375 11.8125V7.65625H2.1875C2.01345 7.65625 1.84653 7.58711 1.72346 7.46404C1.60039 7.34097 1.53125 7.17405 1.53125 7C1.53125 6.82595 1.60039 6.65903 1.72346 6.53596C1.84653 6.41289 2.01345 6.34375 2.1875 6.34375H6.34375V2.1875C6.34375 2.01345 6.41289 1.84653 6.53596 1.72346C6.65903 1.60039 6.82595 1.53125 7 1.53125C7.17405 1.53125 7.34097 1.60039 7.46404 1.72346C7.58711 1.84653 7.65625 2.01345 7.65625 2.1875V6.34375H11.8125C11.9865 6.34375 12.1535 6.41289 12.2765 6.53596C12.3996 6.65903 12.4688 6.82595 12.4688 7Z"
                  fill="white"
                />
              </svg>
              Add Project
            </Button>
            <Modal
              title="Create Project"
              isOpen={isModalOpen}
              onClose={this.handleModalToggle}
              className="addproject_modal"
              actions={[
                <Button
                  key="submit"
                  className="modal_btn submit_btn"
                  onClick={this.AddNewProject}
                >
                  Submit
                </Button>,
                <Button
                  key="cancel"
                  className="modal_btn cancel_btn"
                  onClick={this.handleModalToggle}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div>
                <ul className="addproject_form">
                  <li className="form-group field addprobigfontlbl">
                    <input
                      required
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      id="proname"
                      placeholder="Enter Project Name"
                      name="ProjectName"
                      value={this.state.ProjectName}
                      onChange={(event) => this.handleAlphaInput(event)}
                    />
                    <label for="proname">
                      <sup className="mandatory">*</sup>Project Name
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.ProjectName}
                    </div>
                  </li>

                  <li className="form-group field">
                    <select
                      value={this.state.selectedprojectType}
                      onChange={this.handleProjectType}
                      ref="selectedGroup"
                      name="ddltype"
                    >
                      <option value="" selected="selected">
                        Select{" "}
                      </option>
                      {this.state.commonMastersList
                        .filter((x) => x.type === "ProjectType")
                        .map((item) => (
                          <option value={item.mainGuId}>
                            {item.description}
                          </option>
                        ))}
                    </select>
                    <label for="baselocation">
                      <sup className="mandatory">*</sup>
                      Project Type
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.selectedprojectType}
                    </div>
                  </li>
                  <li className="form-group field">
                    <select
                      value={this.state.selectedprojectSubType}
                      onChange={this.handleProjectSubType}
                      ref="selectedGroup"
                      name="ddlsubtype"
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      {this.state.projectSubType
                        .filter((x) => x.type === "ProjectSubType")
                        .map((item) => (
                          <option value={item.subGuId}>
                            {item.description}
                          </option>
                        ))}
                    </select>
                    <label for="nameofhodorg">
                      <sup className="mandatory">*</sup>
                      Project Sub-type
                    </label>

                    <div className="mandatory has-error">
                      {this.state.errors.selectedprojectSubType}
                    </div>
                  </li>

                  <li className="form-group field">
                    <select
                      value={this.state.initiatedAndNew}
                      onChange={this.handleNewAndInitiated}
                      name="epperiod"
                    >
                      <option value="" selected="selected">
                        Select{" "}
                      </option>
                      <option>New </option>
                      <option>Initiated Already </option>
                    </select>
                    <label for="NGOHeadEmailAddress">
                      <sup className="mandatory">*</sup>
                      New or Initiated Already?
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.initiatedAndNew}
                    </div>
                  </li>

                  {this.state.initiatedAndNew == "Initiated Already" && (
                    <div>
                      <li className="form-group field addprobigfontlbl">
                        <input
                          type="number"
                          min="0"
                          id="percentageCompleted"
                          name="percentageCompleted"
                          placeholder="Enter Percentage"
                          required
                          value={this.state.percentageCompleted}
                          onChange={this.handlePercentageInput}
                          onKeyDown={this.handlePercentInput}
                        />
                        <label for="percentageCompleted">
                          {" "}
                          <sup className="mandatory">*</sup> Enter Percentage{" "}
                        </label>
                        <div className="mandatory has-error">
                          {this.state.errors.percentageCompleted}
                        </div>
                      </li>
                      <li className="form-group field">
                        <input
                          type="date"
                          name="date"
                          value={this.state.dateOfInitiation}
                          onChange={(event) => this.handleDate(event)}
                          onKeyDown={(e) => e.preventDefault()}
                        />

                        <label for="NGOHeadEmailAddress">
                          <sup className="mandatory">*</sup>
                          Date of Initiation
                        </label>
                        <div className="mandatory has-error">
                          {this.state.errors.dateOfInitiation}
                        </div>
                      </li>
                    </div>
                  )}
                  <li className="form-group field">
                    <select
                      value={this.state.estimatedProjectPeriod}
                      onChange={this.handleEstimatedProjectPeriod}
                      ref="selectedEpPeriod"
                      name="epperiod"
                    >
                      <option value="" selected="selected">
                        Select
                      </option>
                      <option>1{">"}=2</option>
                      <option>2{">"}=5</option>
                      <option>5{">"}=7</option>
                      <option>{">"}7</option>
                    </select>
                    <label for="NGOHeadEmailAddress">
                      <sup className="mandatory">*</sup>
                      Estimated Project Period (In years)
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.estimatedProjectPeriod}
                    </div>
                  </li>
                  <li className="form-group field addprobigfontlbl">
                    <input
                      required
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      id="location"
                      placeholder="Enter Location"
                      name="Location"
                      value={this.state.Location}
                      onChange={(event) => this.handleAlphaInput(event)}
                    />
                    <label for="location">
                      <sup className="mandatory">*</sup>Location
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Location}
                    </div>
                  </li>
                  <li className="form-group field addprobigfontlbl">
                    <input
                      required
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      id="Area"
                      placeholder="Enter Area"
                      name="Area"
                      value={this.state.Area}
                      onChange={(event) => this.handleAreaInput(event)}
                    />
                    <label for="Area">
                      <sup className="mandatory">*</sup>Area (acre)
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Area}
                    </div>
                  </li>
                  <li className="form-group field addprobigfontlbl">
                    <input
                      required
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      id="SiteUse"
                      placeholder="Enter SiteUse"
                      name="SiteUse"
                      value={this.state.SiteUse}
                      onChange={(event) => this.handleSiteInput(event)}
                    />
                    <label for="SiteUse">
                      <sup className="mandatory">*</sup> Site Use
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.SiteUse}
                    </div>
                  </li>
                  <li className="form-group field addprobigfontlbl">
                    <input
                      required
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      id="Ownername"
                      placeholder="Enter Owner Name"
                      name="Ownername"
                      value={this.state.Ownername}
                      onChange={(event) => this.handleOwnerInput(event)}
                    />
                    <label for="Ownername">
                      <sup className="mandatory">*</sup> Owner Name
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Ownername}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="successmsgcont">
                {" "}
                <label> {this.state.notificationMessage} </label>{" "}
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(AddNewProject);
