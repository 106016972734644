import React, { Component } from "react";
import config from "../../utils/config";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Accordion, Card, Button, Carousel } from "react-bootstrap";
import { Link } from "@reach/router";
import LeafButton from "../../assets/leafbtn_img.png";
import backtbn from "../../assets/backbtn.png";
import CSEI from "../../assets/CSEI.png";
import atree from "../../assets/atree.png";
import DRDO from "../../assets/Defence_Research_and_Development_Organisation.png";
import Vimana from "../../assets/Vimana.png";
import GlobalVikas from "../../assets/Global vikas.png";
import JungleScapes from "../../assets/JungleScapes.png";
import Induslaw from "../../assets/Induslaw.png";
import Powerweave from "../../assets/Powerweave.png";
import SayTrees from "../../assets/Saytrees.png";
import CommaUp from "../../assets/comma_up.svg";
import CommaDown from "../../assets/comma_down.svg";
import Feedback_1 from "../../assets/feedback_1.png";
import LamaKonchok from "../../assets/LamaKonchokGyaltson_img.jpg";
import ChewangNorphel from "../../assets/chewangnorphel.jpg";
class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.mounted = true;
  }
  componentWillUnmount() {
    document.body.classList.remove("partners_bg");
  }
  componentDidMount() {
    document.body.classList.add("partners_bg");
  }
  goBack() {
    window.history.go(-2);
  }
  render() {
    return (
      <>
        <Button className="backbtn" onClick={this.goBack}>
          <img src={backtbn} alt={backtbn} />
          <span>Back</span>
        </Button>
        <div className="morelrpadd_cont commonfirstpadd_cont paddingbotm">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">
                Empowering Impact, Together
              </span>
            </h1>
            <p>
              Rooted in collaboration, our Partners and Advisors bring together
              the best in
              <br /> the field to propel Treeties' mission. We nurture their
              unique strengths to
              <br /> achieve your restoration goals, delivering impactful
              solutions for a healthier,
              <br /> greener planet.
            </p>

            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Partner with us</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="morelrpadd_cont keyachiev_cont paddingbotm">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">
                Evolving Partner Ecosystem:{" "}
              </span>
              <br /> Uniting for Change
            </h1>
            <p>
              As we continue to grow and evolve, TreeTies is assembling an
              esteemed
              <br /> Advisory Board of experts in the field. Their invaluable
              guidance and insights
              <br /> will help us navigate the complexities of our mission,
              ensuring that our
              <br /> efforts remain effective and impactful in this
              ever-changing landscape.
            </p>
            <div className="partners_list">
              <div className="partnerslist_left">
                <div className="partnerslistleft_top">
                  <div>
                    <img src={CSEI} alt="CSEI" />
                  </div>
                  <div>
                    <img src={atree} alt="atree" />
                  </div>
                  <div>
                    <img src={DRDO} alt="DRDO" />
                  </div>
                  <div>
                    <img src={Vimana} alt="Vimana" />
                  </div>
                  <div>
                    <img src={GlobalVikas} alt="GlobalVikas" />
                  </div>
                </div>
                <div className="partnerslistleft_bot">
                  <div>
                    <img src={JungleScapes} alt="JungleScapes" />
                  </div>

                  <div>
                    <img src={Powerweave} alt="Powerweave" />
                  </div>
                  <div>
                    <img src={SayTrees} alt="Saytrees" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="morelrpadd_cont keyachiev_cont paddingbotm">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">Voices of Impact - </span>{" "}
              Partners Speak
            </h1>
            <p>
              Client experiences speak volumes about the impact of our
              partnership. <br />
              Discover their stories and join us on the journey toward a
              sustainable future.
            </p>

            <div className="theme-feedback-slider mt-5">
              <Carousel controls={false} indicators={true}>
                <Carousel.Item>
                  <h2 className="treeties_heading">
                    <sup>
                      <img src={CommaUp} />
                    </sup>
                    I shall be a part of green Himalayas for as long as I live &
                    breathe. This work is my mission.1
                    <sub>
                      <img src={CommaDown} />
                    </sub>
                  </h2>
                  <div className="partner-profile d-flex align-items-center ">
                    <div className="partner-image rounded-circle me-4">
                      <img src={Feedback_1} />
                    </div>
                    <div className="about-partner">
                      <h4 className="greenunderline greentxt">
                        - Rigzin Motup
                      </h4>
                      <h4 className="treeties_subheading">
                        (Volunteer & Eco-Warrior Green Himalayas)
                      </h4>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <h2 className="treeties_heading">
                    <sup>
                      <img src={CommaUp} />
                    </sup>
                    I have lived amongst the mountains all my life I know that
                    my mission is to learn from them & listen to them, care for
                    them ever more deeply & help preserve their bounty & love
                    for generations that are yet to come.
                    <sub>
                      <img src={CommaDown} />
                    </sub>
                  </h2>
                  <div className="partner-profile d-flex align-items-center ">
                    <div className="partner-image rounded-circle me-4">
                      <img src={LamaKonchok} />
                    </div>
                    <div className="about-partner">
                      <h4 className="greenunderline greentxt">
                        - Lama Konchok Gyaltson (Kay Gee)
                      </h4>
                      <h4 className="treeties_subheading">
                        (Environmentalist & Eco-Warrior Green Himalayas )
                      </h4>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <h2 className="treeties_heading">
                    <sup>
                      <img src={CommaUp} />
                    </sup>
                    My message to the world is Act now. Before it's too late!
                    <sub>
                      <img src={CommaDown} />
                    </sub>
                  </h2>
                  <div className="partner-profile d-flex align-items-center ">
                    <div className="partner-image rounded-circle me-4">
                      <img src={ChewangNorphel} />
                    </div>
                    <div className="about-partner">
                      <h4 className="greenunderline greentxt">
                        - Chewang Norphel
                      </h4>
                      <h4 className="treeties_subheading">
                        (Advisor & Collaborator Green Himalayas )
                      </h4>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>

        <div className="morelrpadd_cont keyachiev_cont paddingbotm mt-5 mb-5">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">Our Impactful Initiatives</span>
            </h1>
            <p>
              Journey through the compelling story of TreeTies' projects in
              Ladakh, where
              <br /> we've championed environmental conservation and empowered
              <br /> communities. Witness the positive impact we've created and
              become a part
              <br /> of our ongoing efforts to nurture a sustainable world.
            </p>

            <Button className="leafbtn_cont">
              <Link to="/ourexperience">
                <img src={LeafButton} alt="leafbtn" />
                <span>Discover Impact </span>
              </Link>
            </Button>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
