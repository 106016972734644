import React, { useEffect, useState } from "react";
import { Polygon, InfoWindow, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";

export default function LandParcelAndWaterBodies({ locationCords }) {
  const reduxState = useSelector((state) => state.project);
  const [landParcels, setlandParcels] = useState([]);
  const [waterBodies, setwaterBodies] = useState([]);
  const [selectedLandID, setSelectedLandId] = useState(null);
  const [selectedWaterID, setSelectedWaterId] = useState(null);

  useEffect(() => {
    if (reduxState.waterBodiesData) {
      setwaterBodies(reduxState.waterBodiesData);
    }
  }, [reduxState.waterBodiesData]);

  useEffect(() => {
    if (reduxState.landParcelData) {
      setlandParcels(reduxState.landParcelData);
    }
  }, [reduxState.landParcelData]);

  const onPolygonMouseOver = (feature, id) => {
    console.log("sel id", feature, id);
    setSelectedLandId(id);
  };
  const onWaterPolygonMouseOver = (feature, id) => {
    console.log("sel id", feature, id);
    setSelectedWaterId(id);
  };

  return (
    <>
      {landParcels?.map((land, index) => {
        return (
          <div key={land?.id}>
            <Polygon
              key={land?.id}
              paths={land?.path}
              options={land?.options}
              landDetails={land?.landDetails}
              onMouseOver={(feature) => onPolygonMouseOver(feature, land?.id)}
              onMouseOut={() => setSelectedLandId(null)}
            />
            <Marker
              position={land?.path?.[0]}
              // icon={require("./yellow.svg").default}
              onMouseOver={(feature) => onPolygonMouseOver(feature, land?.id)}
              onMouseOut={() => setSelectedLandId(null)}
            >
              {selectedLandID == land?.id && (
                <InfoWindow position={land?.path?.[0]}>
                  <div>
                    <div className="infwdtree_head">
                      <span>Land Parcel</span>
                    </div>
                    <div className="treesinfowindowitem_wrap">
                      <div className="treesinfowindow_item">
                        <span>Title </span>
                        <span>: {land?.landDetails.title}</span>
                      </div>
                      <div className="treesinfowindow_item">
                        <span>Plot No </span>
                        <span>: {land?.landDetails.plotNo}</span>
                      </div>
                      <div className="treesinfowindow_item">
                        <span>IsFarmland </span>
                        <span>: {land?.landDetails.isFarmland}</span>
                      </div>
                      <div className="treesinfowindow_item">
                        <span>IsIrrigated </span>
                        <span>: {land?.landDetails.isIrrigated}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="LandParcel">
                    <p>Title: </p>
                    <p>Plot No: {land?.landDetails.plotNo}</p>
                    <p>IsFarmland: {land?.landDetails.isFarmland}</p>
                    <p>IsIrrigated: {land?.landDetails.isIrrigated}</p>
                  </div> */}
                </InfoWindow>
              )}
            </Marker>
          </div>
        );
      })}

      {waterBodies?.map((water) => {
        return (
          <div key={water?.id}>
            <Polygon
              key={water?.id}
              paths={water?.path}
              options={water?.options}
              waterDetails={water?.waterDetails}
              onMouseOver={(feature) =>
                onWaterPolygonMouseOver(feature, water?.id)
              }
              onMouseOut={() => setSelectedWaterId(null)}
            />
            <Marker
              position={water?.path?.[0]}
              onMouseOver={(feature) =>
                onWaterPolygonMouseOver(feature, water?.id)
              }
              onMouseOut={() => setSelectedWaterId(null)}
            >
              {selectedWaterID == water?.id && (
                <InfoWindow position={water?.path?.[0]}>
                  <div>
                    <div className="infwdtree_head">
                      <span>Water Bodies</span>
                    </div>
                    <div className="treesinfowindowitem_wrap">
                      <div className="treesinfowindow_item">
                        <span>Title </span>
                        <span>: {water?.waterDetails.title}</span>
                      </div>
                      <div className="treesinfowindow_item">
                        <span>Area In Hectares </span>
                        <span>: {water?.waterDetails.areaInHectares}</span>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </div>
        );
      })}
    </>
  );
}
