import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import growlogo from "../../assets/logo.png";
import loginbg from "../../assets/loginbg.jpg";
import FormErrors from "../Login/FormErrors";
import config from "../../utils/config";
class Forgetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Username: "",
      formErrors: { Username: "", error: "" },
      UsernameValid: false,
      formValid: false,
      fields: {},
      errors: {},
    };
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    this.mounted = true;
    document.getElementById("year").innerHTML = new Date().getFullYear();
  }
  // This function will enable or disable the login button based on form validation
  validateForgotPassForm() {
    let fields;
    fields = {
      Username: this.state.Username,
    };
    let errors = {};
    let formIsValid = true;

    if (!fields["Username"]) {
      formIsValid = false;
      errors["Username"] = "Enter email id";
    }

    if (this.mounted) {
      this.setState({ formValid: this.state.UsernameValid });
      this.setState({ errors: errors });
    }
    return formIsValid;
  }
  SendUsername = (e) => {
    e.preventDefault();
    if (this.validateForgotPassForm()) {
      if (this.mounted) this.setState({ IsLoader: true });
      //let cryptedp = sha512(this.state.Password);
      let UserDetail;
      UserDetail = {
        Email: this.state.Username,
      };
      let uri = `${config.API_HOST_URL}/api/login/ForgotPassword`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          let fieldValidationErrors = this.state.formErrors;
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                fieldValidationErrors.error = data.message;
                if (this.mounted) {
                  this.setState(
                    {
                      formErrors: fieldValidationErrors,
                      Username: "",
                    },
                    this.validateForm
                  );
                }
              } else {
                fieldValidationErrors.error = data.message;
                if (this.mounted) {
                  this.setState(
                    {
                      formErrors: fieldValidationErrors,
                    },
                    this.validateForm
                  );
                }
              }
            });
          } else {
            fieldValidationErrors.error = "something went wrong!";
            if (this.mounted) {
              this.setState(
                {
                  formErrors: fieldValidationErrors,
                },
                this.validateForm
              );
            }
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
          }
        });
    }
  };

  // This function will set the state of input fields and validate them when user enters the value in input boxes
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (this.mounted) {
      this.setState({ [name]: value });
    }
  }

  render() {
    return (
      <div className="login_container">
        <div className="loginleft_cont">
          <div className="loginform_cont">
            <div className="growlogocont">
              <div className="growlogo">
                {/* companyLogo.png */}
                <a href="/">
                  <img
                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/companyLogoWhiteBG.png`}
                    alt="growlogo"
                  />
                </a>
              </div>
            </div>
            <form className="loginfrm_wrap">
              <h1 className="section_heading">Sign in</h1>
              <ul className="login_form">
                <li>
                  <div className="panel panel-default">
                    <FormErrors
                      className=""
                      formErrors={this.state.formErrors}
                    />
                  </div>
                </li>
                <li className="field">
                  <input
                    type="text"
                    id="emailidlogin"
                    placeholder="Enter your email id"
                    name="Username"
                    value={this.state.Username}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <label for="emailidlogin">Email Id</label>
                  <div className="mandatory has-error">
                    {this.state.errors.Username}
                  </div>
                </li>
                <li>
                  <button
                    type="submit"
                    className="signin_btn"
                    name="Submit"
                    onClick={this.SendUsername}
                  >
                    Submit
                  </button>
                </li>
              </ul>
            </form>
            <div className="footertext">
              Copyright © <span id="year"></span> TreeTies. All rights reserved.
              Powered by Powerweave
            </div>
          </div>
        </div>
        <div className="loginright_cont">
          <img
            src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/login-bg-image.png`}
            alt="loginbg"
          />
          <div className="logrghtimgtxt">
            46% of the world’s forest are already destroyed
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}
export default connect(mapStateToProps)(Forgetpassword);
