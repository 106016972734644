import React, { Component } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
class VerifyStatusPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            Projectlist: props.data,
            commonMastersList: [],
        }
        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };
    }
    componentDidMount() {
        this.mounted = true;
    
        this.GetCommonMasters();
      }
      GetCommonMasters = () => {
        let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
        fetch(url, {
          method: "Get",
          headers: {
            WebsiteGuid: this.props.websiteGUID,
            LanguageGuid: this.props.languageGUID,
            Authorization: `Bearer ${this.props.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
       this.setState({commonMastersList : data});

          })
          .catch((error) => {
            console.log("Error in request", error);
          });
      };
    
    componentWillUnmount() {
        this.mounted = false;
    }

    VerifyApplication = (e) => {
        let Status = e.target.getAttribute('value');
        let url = `${config.API_HOST_URL}/api/projects/VerifyProject/${Status}/${this.state.Projectlist.ProjectId}`;

        fetch(url, {
            method: "POST",
            headers: {
                WebsiteGuid: this.props.websiteGUID,
                LanguageGuid: this.props.languageGUID,
                UserGuid: this.props.userData.UserGuid,
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/json"
            },
        })
            .then(res => res.text())
            .then(response => {
                if (JSON.parse(response) === "updated") {
                    if (this.mounted) {
                        ToastNotification.ShowSuccess(" Project Approved Sucessfully");
                        this.handleModalToggle();
                        setTimeout(() => {
                            this.setState({
                            }, this.props.handleBack)
                        }, 2000);
                    }

                } else if (JSON.parse(response) === "rejected") {
                    ToastNotification.ShowSuccess("Project Rejected Sucessfully");
                    this.handleModalToggle();
                    setTimeout(() => {
                        this.setState({
                        }, this.props.handleBack)
                    }, 2000);
                }

                else if (JSON.parse(response) === "Fail") {
                    ToastNotification.ShowError("Error While Updating");
                }

            })
            .catch(error => {
                console.log("Request failed", error);
            });
    }
    handleUrlOpen = (DocumentName) => {
        let Documenturl = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/${this.state.Projectlist.UserId}/${this.state.Projectlist.ProjectName}/${DocumentName}`;
        window.open(Documenturl, "_blank")
    }

    render() {
        const { isModalOpen, commonMastersList } = this.state;
        var ProjectFilesarr = [];
        if (this.state.Projectlist.ProjectFiles !== null) {
            var Projectfiles = this.state.Projectlist.ProjectFiles;
            for (let i = 0; i < Projectfiles.length; i++) {
                ProjectFilesarr.push(Projectfiles[i].DocumentOriginalName + "," + Projectfiles[i].DocumentName)
            }
        }

        return (

            <div class="makeappoint_btn">
                <i onClick={this.handleModalToggle} className="far fa-thumbs-up"></i>
                <Modal
                    title="Project Details"
                    isOpen={isModalOpen}
                    onClose={this.handleModalToggle}
                    className="addproject_modal makeapptment_modal"
                    actions={[
                        <Button key="submit" value="Verified" onClick={this.VerifyApplication} className="modal_btn submit_btn">Approve</Button>,
                        <Button key="reject" value="Rejected" onClick={this.VerifyApplication} className="modal_btn cancel_btn">Reject</Button>
                        // <Button key="cancel" value="Cancel" onClick={this.handleModalToggle} className="modal_btn cancel_btn">Cancel</Button>
                    ]}
                >
                    <div className="applistpopup_container">
                        <div className="applistpoptop">
                            <a className="backtolist" onClick={this.handleModalToggle}><i className="fa fa-angle-left"></i>Back to list</a>

                        </div>
                        <ul className="applistpopup_content">
                            <li>
                                <span className="applistpoplabel">Project Name</span>
                                <span className="applistpopinputvalue"> {this.state.Projectlist.ProjectName}</span>
                            </li>
                            <li>
                                <span className="applistpoplabel">Project Type</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.ProjectTypeGuId  ? commonMastersList.filter(x => x.mainGuId === this.state.Projectlist.ProjectTypeGuId && x.type === "ProjectType").map(x => x.description) : "-"}</span>
                            </li>
                            <li>
                                <span className="applistpoplabel">Project SubType</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.ProjectSubTypeGuId  ? commonMastersList.filter(x => x.subGuId === this.state.Projectlist.ProjectSubTypeGuId && x.type === "ProjectSubType").map(x => x.description) : "-"}</span>
                            </li>
                            <li>
                                <span className="applistpoplabel">Project Location</span>
                                <span className="applistpopinputvalue"> {this.state.Projectlist.Location ? this.state.Projectlist.Location :"-"}</span>
                            </li>
                            <li>
                                <span className="applistpoplabel">New or Initiated Already?</span>
                                <span className="applistpopinputvalue"> {this.state.Projectlist.NewOrInitiatedAlready ? this.state.Projectlist.NewOrInitiatedAlready : "-" }</span>
                            </li>
                            <li>
                                <span className="applistpoplabel">Estimated Project Period (In years)</span>
                                <span className="applistpopinputvalue"> {this.state.Projectlist.EstimatedProjectPeriod ? this.state.Projectlist.EstimatedProjectPeriod :"-"}</span>
                            </li>
                            {/* <li>
                                <span className="applistpoplabel">Estimated Number of Trees</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.NumberOfTrees ? this.state.Projectlist.NumberOfTrees :"0"}</span>

                            </li>
                            <li>
                                <span className="applistpoplabel">Land area (in square meter)</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.Landareasqm ? this.state.Projectlist.Landareasqm : "-"}</span>

                            </li> */}
                            {/* <li>
                                <span className="applistpoplabel">Status of Land</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.Landstatus ? this.state.Projectlist.Landstatus : "-"}</span>
                            </li>
                            {this.state.Projectlist.Landstatus === "Goverment and community land" && <li>
                                <span className="applistpoplabel">Amount Raised</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.AmountRaised ? this.state.Projectlist.AmountRaised : "-"}</span>
                            </li>}
                            {this.state.Projectlist.Landstatus === "Goverment and community land" && <li>
                                <span className="applistpoplabel">Tree planted till Date</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.TreePlantedTillDate ? this.state.Projectlist.TreePlantedTillDate : "-"}</span>
                            </li>}
                            {this.state.Projectlist.Ownername !== null && <li>
                                <span className="applistpoplabel">Land Owner Name</span>
                                <span className="applistpopinputvalue">{this.state.Projectlist.Ownername ? this.state.Projectlist.Ownername : "-"}</span>
                            </li>}
                            {this.state.Projectlist.TitleDeedDoc !== null && <li>
                                <span className="applistpoplabel">Download Title Deed Document</span>
                                <span className="docname"><a onClick={() => this.handleUrlOpen(this.state.Projectlist.TitleDeedDoc)}>{this.state.Projectlist.TitleDeedDoc}</a></span>
                            </li>}
                            {this.state.Projectlist.NOCGovAuthDoc !== null && <li>
                                <span className="applistpoplabel">Download Government NOC Document</span>
                                <span className="docname"><a onClick={() => this.handleUrlOpen(this.state.Projectlist.NOCGovAuthDoc)}>{this.state.Projectlist.NOCGovAuthDoc}</a></span>
                            </li>}
                            {this.state.Projectlist.LstDonorDoc !== null && <li>
                                <span className="applistpoplabel">Download List Of Donors Document</span>
                                <span className="docname"><a onClick={() => this.handleUrlOpen(this.state.Projectlist.LstDonorDoc)}>{this.state.Projectlist.LstDonorDoc}</a></span>
                            </li>}
                            {this.state.Projectlist.DonationAgreeDoc !== null && <li>
                                <span className="applistpoplabel">Download Donation Agreement Document</span>
                                <span className="docname"><a onClick={() => this.handleUrlOpen(this.state.Projectlist.DonationAgreeDoc)}>{this.state.Projectlist.DonationAgreeDoc}</a></span>
                            </li>}
                            {this.state.Projectlist.LatestProjDoc !== null && <li>
                                <span className="applistpoplabel">Download Latest Project Report Document</span>
                                <span className="docname"><a onClick={() => this.handleUrlOpen(this.state.Projectlist.LatestProjDoc)}>{this.state.Projectlist.LatestProjDoc}</a></span>
                            </li>} */}
                            {/* {this.state.Projectlist.ProjectFiles !== null && <li>
                                <span className="applistpoplabel">Project Document(s)</span>
                                {ProjectFilesarr.map((item, index) => (
                                    <tr key={item}>
                                        <td className="docname">
                                            <a onClick={() => this.handleUrlOpen(item.split(",")[1])}>{item.split(",")[0]}</a>
                                        </td>
                                    </tr>)

                                )}

                            </li>} */}

                        </ul>
                    </div>
                </Modal>

            </div>

        )
    }

}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData
    };
}

export default connect(
    mapStateToProps
)(VerifyStatusPopup);