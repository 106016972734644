import React, { useEffect, useState, useCallback } from "react";
import TreeSpeciesList from "./TreeSpeciesList";
import ZoneTypeList from "./ZoneTypeList";
import LifeFormList from "./LifeFormList";

export default function TreesSpeciesMasterData() {
  const [activeTab, setActiveTab] = useState("Trees Species");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="row prosumcordtabscont_wrap partnershipsmasterdata_cont">
        <div className="col-12">
          <div className="tab-buttons">
            <button
              className={activeTab === "Trees Species" ? "active" : ""}
              onClick={() => handleTabChange("Trees Species")}
            >
              Species
            </button>
            <button
              className={activeTab === "Zone Type" ? "active" : ""}
              onClick={() => handleTabChange("Zone Type")}
            >
              Zone Type
            </button>
            <button
              className={activeTab === "Life Form" ? "active" : ""}
              onClick={() => handleTabChange("Life Form")}
            >
              Life Form
            </button>
          </div>
        </div>

        <div className="col-12">
          {activeTab === "Trees Species" && (
            <div className="tabs-content">
              <TreeSpeciesList />
            </div>
          )}

          {activeTab === "Zone Type" && (
            <div className="tabs-content">
              <ZoneTypeList />
            </div>
          )}

          {activeTab === "Life Form" && (
            <div className="tabs-content">
              <LifeFormList />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
