import React from "react";
import { store } from "../../App";
import config from "../../utils/config";

export default function DashboardNew(props) {
  const loggedIn = store.getState().user.userData !== null;
  const userInfo = store.getState().user.userData;

  return (
    <div className="powebidashboard_cont">
      {loggedIn && userInfo && (
        <>
          <iframe
            title={
              userInfo.dashboardTitle ? userInfo.dashboardTitle : "NGO Page"
            }
            width="100%"
            height="100vh"
            src={
              userInfo.dashboardSource
                ? userInfo.dashboardSource
                : "https://app.powerbi.com/view?r=eyJrIjoiZjFlMDEwOTUtNGRjMC00Y2RmLThmYjktNWQ0NTljMjU5MTM3IiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
            }
            frameborder="0"
            allowFullScreen
          ></iframe>
          <div className="powerbitrial_hide"></div>
        </>
      )}
    </div>
  );
}

// {
//   !loggedIn &&
//     UserInfo.roleId == 1 &&
//     config.BASE_URL == "https://treeties.co.in" && (
//       <iframe
//         title="Power Bi Demo"
//         src="https://app.powerbi.com/view?r=eyJrIjoiNDU0ODFiMjktNDNmMy00MWUzLTlhMWYtZDg5ZmRiZDE2ZTBiIiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
//         frameborder="0"
//         allowFullScreen="true"
//         width="100%"
//         height="100vh"
//       ></iframe>
//     );
// }

// {
//   loggedIn &&
//     UserInfo.roleId == 1 &&
//     config.BASE_URL == "https://treeties.co.in" && (
//       <>
//         <iframe
//           title="Power Bi Demo"
//           src="https://app.powerbi.com/view?r=eyJrIjoiNDU0ODFiMjktNDNmMy00MWUzLTlhMWYtZDg5ZmRiZDE2ZTBiIiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
//           width="100%"
//           height="100vh"
//         ></iframe>
//       </>
//     );
// }

// {
//   !loggedIn && UserInfo.roleId == 2 && (
//     <>
//       <iframe
//         title="NGO Page"
//         src="https://app.powerbi.com/view?r=eyJrIjoiZWNhZTE2MWMtOTllZC00NDc4LThiM2QtOTAxYzMyN2FiOTdhIiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
//         frameborder="0"
//         allowFullScreen="true"
//         width="100%"
//         height="100vh"
//       ></iframe>
//     </>
//   );
// }
