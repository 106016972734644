import React from "react";
import { store } from "../../App";
import config from "../../utils/config";

export default function AdminDashboard(props) {
  const loggedIn = store.getState().user.userData !== null;
  const userInfo = store.getState().user.userData;

  return (
    <div className="powebidashboard_cont admindashboard_cont">
      {/* <h3 className="newdashboard_heading">Dashboard</h3> */}

      {loggedIn && userInfo && (
        <>
          <iframe
            title={
              userInfo.dashboardTitle ? userInfo.dashboardTitle : "NGO Page"
            }
            width="100%"
            height="100vh"
            src={
              userInfo.dashboardSource
                ? userInfo.dashboardSource
                : "https://app.powerbi.com/view?r=eyJrIjoiZjFlMDEwOTUtNGRjMC00Y2RmLThmYjktNWQ0NTljMjU5MTM3IiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
            }
            frameborder="0"
            allowFullScreen
          ></iframe>
          <div className="powerbitrial_hide"></div>
        </>
      )}
    </div>
  );
}
