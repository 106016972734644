import React, { Component } from "react";
import { navigate } from "@reach/router";
class FailureMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            WebSiteUrl: "",
            PrimaryColor: ""
        }
    }
    componentDidMount() {
        let Url = new URLSearchParams(window.location.search).get('Url');
        let Color = new URLSearchParams(window.location.href).get('Color');
        this.setState({ WebSiteUrl: Url, PrimaryColor: Color });

    }
    FailureClick = websitevalue => () => {
        let WebSiteUrl = websitevalue;
        window.location = WebSiteUrl;
    }
    render() {
        const websitebackgroundcolorcodestyle = {
            backgroundColor: this.state.PrimaryColor,
            borderColor: this.state.PrimaryColor
        };
        return (
            <div>
                <div className="paysuccesscont">
                    Payment Transaction failed. Please try again
                </div>
                <div>
                    <button type="button" style={websitebackgroundcolorcodestyle} onClick={this.FailureClick(this.state.WebSiteUrl)}>Back to calculator</button>
                </div>
            </div>
        )
    }
}
export default FailureMessage;