import { useState } from "react";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import NewProjectSummaryReport from "./NewProjectSummaryReport"; //D:\Treeties_New\grow_reactweb_microservice\src\Components\Common\NewMapDesign\Reports\NewProjectSummaryReport.jsx
import ReactDOM from "react-dom";

async function ProjectNewReport(props) {
  try {
    const component = (
      <NewProjectSummaryReport projectDetails={props.projectDetail} />
    ); // Render your React component
    const element = document.createElement("div"); // Create a temporary div element
    ReactDOM.render(component, element); // Render the component into the temporary div

    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Function to create a PDF using html2pdf and jsPDF
    html2pdf()
      .from(element)
      .set({
        margin: 1,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: pdf,
      })
      .save();
    // Clean up: unmount the component and remove the temporary div
    ReactDOM.unmountComponentAtNode(element);
    document.body.removeChild(element);
  } catch {}
}
export default ProjectNewReport;
