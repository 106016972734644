const initialConfiguration = {
    token: '',
    tokenTime: 0,
    languageGUID:'',
    currencyGUID:'',
    currencySymbol:''
}

export default function configuration(state = initialConfiguration, action) {
    switch (action.type) {
       case 'SET_TOKEN':
            return { ...state, token: action.token, tokenTime: new Date().getTime() }
        case 'SET_LANGUAGE_GUID':
            return { ...state, languageGUID: action.guid }
        case 'SET_CURRENCY_GUID':
            return {...state,currencyGUID:action.guid}
        case 'SET_CURRENCY_SYMBOL':
            return {...state,currencySymbol:action.currencySymbol}
        default:
            return state
    }
}