import { useState } from "react";
import config from "../../../../utils/config";

async function RegistryReport(
  projectId,
  projectName,
  location,
  isForestHealthy,
  forestHealthyPercentage
) {
  try {
    window.location = `${
      config.API_HOST_URL
    }/api/ProjectTreeTypes/ExportAllTreeDetails/${projectId}/${projectName}/${location}/${isForestHealthy}/${forestHealthyPercentage.toFixed(
      1
    )}`;
    return "Success";
  } catch (error) {
    console.error(error);
  }
}
export default RegistryReport;
