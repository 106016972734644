import React, { Component } from "react";
import config from "../../utils/config";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Button, Card } from "react-bootstrap";
import LeafButton from "../../assets/leafbtn_img.png";

import backtbn from "../../assets/backbtn.png";
import ContactFormImage from "../../assets/contactform_leftimg.png";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import ContactUsBgImage from "../../assets/contact-us.png";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      LastName: "",
      Email: "",
      CompanyName: "",
      Industry: "",
      Message: "",
      // errors: {},
      //isButtonDisabled: "",
      Purpose: "Contact Us",
      Type: "tree Ties",
      Mobile: null,
      ErrorMsg: {},
      isTouchedName: false,
      isTouchedLastName: false,
      isTouchedCompanyName: false,
      isTouchedIndustry: false,
      isTouchedMessage: false,
      isTouchedEmail: false,
      isValid: true,
      isMailValid: false,
    };
    this.mounted = true;
  }
  componentWillUnmount() {
    document.body.classList.remove("contact_us_bg");
  }
  componentDidMount() {
    document.body.classList.add("contact_us_bg");
    this.ref = React.createRef();
  }
  arrowposchange1 = () => {
    var arrow1 = document.getElementById("accord_arrow");
    arrow1.classList.toggle("downarrow1");
  };
  arrowposchange2 = () => {
    var arrow2 = document.getElementById("accord_arrow1");
    arrow2.classList.toggle("downarrow2");
  };
  arrowposchange3 = () => {
    var arrow3 = document.getElementById("accord_arrow2");
    arrow3.classList.toggle("downarrow3");
  };
  arrowposchange4 = () => {
    var arrow4 = document.getElementById("accord_arrow3");
    arrow4.classList.toggle("downarrow4");
  };
  goBack() {
    window.history.go(-2);
  }
  Save = () => {
    if (!this.handleValidation()) return;
    const data = {
      Name: this.state.Name,
      LastName: this.state.LastName,
      Email: this.state.Email,
      CompanyName: this.state.CompanyName,
      Industry: this.state.Industry,
      Message: this.state.Message,
      Mobile: this.state.Mobile,
      Purpose: this.state.Purpose,
      Type: this.state.Type,
      isTouchedName: false,
      isTouchedLastName: false,
      isTouchedEmail: false,
      isTouchedCompanyName: false,
      isTouchedIndustry: false,
      isTouchedMessage: false,
    };

    const url = `${config.API_HOST_URL}/api/EnquiryDetails/NewEnquiryDetails`;
    fetch(url, {
      method: "POST",

      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success == true) {
          //alert("Save Successfully");
          alert(resp.message);
          this.clearFields();
          //ToastNotification.ShowSuccess("Save Successfully");
        } else {
          alert("Failed");
          //ToastNotification.ShowError("Failed");
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  handleUserInput(e) {
    let ErrorMsg = this.state.ErrorMsg;
    this.setState({ [e.target.name]: e.target.value });
    // if (e.target.name === "Name") {
    //    const alphaSpace = /^[A-Za-z\s]+$/;
    //    const Space = /\S/g ;
    //   this.setState({ isTouchedName: true });
    //   let value = e.target?.value;
    //   if (value.match(alphaSpace) && value.match(Space)) {
    //     delete ErrorMsg?.Name;
    //     this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
    //   } else {
    //     ErrorMsg["Name"] = "Please enter First Name ";
    //     this.setState({
    //       ErrorMsg: ErrorMsg,
    //       isValid: false,
    //       Name: "",
    //     });
    //   }
    // }
    if (e.target.name === "Name") {
      this.setState({ isTouchedName: true });
      let value =
        e.target?.value.trim() && e.target?.value.replace(/[^a-z]/gi, "");

      if (value != "") {
        delete ErrorMsg?.Name;
        this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
      } else {
        ErrorMsg["Name"] = "Please enter First Name ";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          Name: "",
        });
      }
    }
    //  if (e.target.name === "LastName") {
    //    const alphaSpace = /^[A-Za-z\s]+$/;
    //    const Space = /\S/g;
    //    this.setState({ isTouchedLastName: true });
    //    let value = e.target?.value;
    //    if (value.match(alphaSpace) && value.match(Space)) {
    //      delete ErrorMsg?.LastName;
    //      this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
    //    } else {
    //      ErrorMsg["LastName"] = "Please enter Last Name";
    //      this.setState({
    //       ErrorMsg: ErrorMsg,
    //        isValid: false,
    //        LastName: "",
    //      });
    //    }
    //  }
    if (e.target.name === "LastName") {
      this.setState({ isTouchedLastName: true });

      let value =
        e.target?.value.trim() && e.target?.value.replace(/[^a-z]/gi, "");

      if (value != "") {
        delete ErrorMsg?.LastName;
        this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
      } else {
        ErrorMsg["LastName"] = "Please enter Last Name";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          LastName: "",
        });
      }
    }
    // if (e.target.name === "Email") {

    //   this.setState({
    //     isTouchedEmail: true,
    //     [e.target.name]: e.target.value,
    //   });
    //   let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    //   let value = e.target?.value;
    //   value = value.replace(/\s/g, "");
    //   if (e.target.value.length > 0) {
    //     if (value.match(mailformat) ) {
    //       delete ErrorMsg?.Email;
    //       this.setState({
    //         ErrorMsg: ErrorMsg,
    //         isValid: true,
    //         isMailValid: true,
    //       });
    //     } else {
    //       ErrorMsg["Email"] = "Please enter Valid Email";
    //       this.setState({
    //         ErrorMsg: ErrorMsg,
    //         isValid: false,
    //         isMailValid: false,
    //       });
    //     }
    //   } else {
    //     ErrorMsg["Email"] = "Please enter Valid Email";
    //     this.setState({
    //       ErrorMsg: ErrorMsg,
    //       isValid: false,
    //       isMailValid: false,
    //     });
    //   }
    // }
    if (e.target.name === "Email") {
      this.setState({ isTouchedEmail: true });
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let value = e.target?.value;
      value = value.replace(/\s/g, "");

      if (e.target.value.length > 0) {
        if (value.match(mailformat)) {
          delete ErrorMsg?.Email;
          this.setState({
            ErrorMsg: ErrorMsg,
            isValid: true,
            isMailValid: true,
          });
        } else {
          ErrorMsg["Email"] = "Please enter  Valid Email";
          this.setState({
            ErrorMsg: ErrorMsg,
            isValid: false,
            isMailValid: false,
          });
        }
      } else {
        ErrorMsg["Email"] = "Please enter valid Email";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          isMailValid: false,
        });
      }
    }

    if (e.target.name === "CompanyName") {
      const Space = /\S/g;
      this.setState({ isTouchedCompanyName: true });
      let value = e.target?.value;
      if (value.match(Space)) {
        delete ErrorMsg?.CompanyName;
        this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
      } else {
        ErrorMsg["CompanyName"] = "Please enter Company Name ";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          CompanyName: "",
        });
      }
    }

    if (e.target.name === "Industry") {
      const Space = /\S/g;
      this.setState({ isTouchedIndustry: true });
      let value = e.target?.value;
      if (value.match(Space)) {
        delete ErrorMsg?.Industry;
        this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
      } else {
        ErrorMsg["Industry"] = " Please enter Industry ";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          Industry: "",
        });
      }
    }

    if (e.target.name === "Message") {
      const Space = /\S/g;
      this.setState({ isTouchedMessage: true });
      let value = e.target?.value;
      if (value.match(Space)) {
        delete ErrorMsg?.Message;
        this.setState({ [e.target.name]: value, ErrorMsg: ErrorMsg });
      } else {
        ErrorMsg["Message"] = "Please enter Message ";
        this.setState({
          ErrorMsg: ErrorMsg,
          isValid: false,
          Message: "",
        });
      }
    }
  }

  handleValidation() {
    let { isValid } = this.state;
    this.setState({
      isButtonDisabled: true,
      Name: this.state.Name,
      LastName: this.state.LastName,
      Email: this.state.Email,
      CompanyName: this.state.CompanyName,
      Industry: this.state.Industry,
      Message: this.state.Message,
      isTouchedName: true,
      isTouchedLastName: true,
      isTouchedEmail: true,
      isTouchedCompanyName: true,
      isTouchedIndustry: true,
      isTouchedMessage: true,
    });
    let errorMsg = {};
    //let formIsValid = true;

    if (this.state.Name === null || this.state.Name.trim() === "") {
      isValid = false;
      errorMsg["Name"] = "Name Cannot be Empty";
    }

    if (this.state.LastName === null || this.state.LastName.trim() === "") {
      isValid = false;
      errorMsg["LastName"] = "Last Name Cannot be Empty";
    }

    // let regExEmailId =
    //    /^([a-z0-9_\-\.]+)@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;

    //  if (
    //    this.state.Email !== null &&
    //    this.state.Email?.length > 0 &&
    //    !regExEmailId.test(this.state.Email)
    //  ) {
    //    formIsValid = false;
    //    errorMsg["Email"] =
    //     "Please enter Email"
    //  } else {
    //    if (/[A-Z]/g.test(this.state.Email)) {
    //      this.state.Email = this.state.Email.toLowerCase();
    //    }
    //    if (
    //      !/^([a-z0-9_\-\.]+)@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/.test(
    //        this.state.Email
    //      )
    //    ) {
    //      formIsValid = false;
    //      errorMsg["Email"] = "Please enter Email";
    //    } else {
    //      let email = this.state.Email.split(".", 5);
    //      var countIn = 0;
    //      var countCo = 0;
    //      var countCom = 0;
    //      email.forEach((element) => {
    //        if (element === "in") {
    //          countIn++;
    //        } else if (element === "co") {
    //          countCo++;
    //        } else if (element === "com") {
    //          countCom++;
    //        }
    //      });
    //      if (countIn > 1 || countCo > 1 || countCom > 1) {
    //        formIsValid = false;
    //        errorMsg["Email"] = "Please enter Email";
    //      } else if (countIn === 1 && countCo === 1) {
    //        formIsValid = false;
    //        errorMsg["Email"] = "Please enter Email";
    //      } else if (countCo === 1 && countCom === 1) {
    //        formIsValid = false;
    //        errorMsg["Email"] = "Please enter Email";
    //      } else if (countCom === 1 && countIn === 1) {
    //        formIsValid = false;
    //        errorMsg["Email"] = "Please enter Email";
    //      }
    //    }
    //  }
    if (this.state.Email === null || this.state.Email.trim() === "") {
      isValid = false;
      errorMsg["Email"] = "Email Cannot be Empty";
    } else {
      if (/[A-Z]/g.test(this.state.Email)) {
        this.state.Email = this.state.Email.toLowerCase();
      }
      if (
        !/^([a-z0-9_\-\.]+)@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/.test(
          this.state.Email
        )
      ) {
        isValid = false;
        errorMsg["Email"] = "Please enter valid email";
      } else {
        let emailId = this.state.Email.split(".", 5);
        var countIn = 0;
        var countCo = 0;
        var countCom = 0;
        emailId.forEach((element) => {
          if (element === "in") {
            countIn++;
          } else if (element === "co") {
            countCo++;
          } else if (element === "com") {
            countCom++;
          }
        });
        if (countIn > 1 || countCo > 1 || countCom > 1) {
          isValid = false;
          errorMsg["Email"] = "Please enter  email";
        } else if (countIn === 1 && countCo === 1) {
          isValid = false;
          errorMsg["Email"] = "please  enter email";
        } else if (countCo === 1 && countCom === 1) {
          isValid = false;
          errorMsg["Email"] = "please  enter email";
        } else if (countCom === 1 && countIn === 1) {
          isValid = false;
          errorMsg["Email"] = "please  enter email";
        }
      }
    }

    if (
      this.state.CompanyName === null ||
      this.state.CompanyName.trim() === ""
    ) {
      isValid = false;
      errorMsg["CompanyName"] = "Company Name Cannot be Empty";
    }
    if (this.state.Industry === null || this.state.Industry.trim() === "") {
      isValid = false;
      errorMsg["Industry"] = "Industry Cannot be Empty";
    }

    if (this.state.Message === null || this.state.Message.trim() === "") {
      isValid = false;
      errorMsg["Message"] = "Message Cannot be Empty";
    } else {
    }
    if (isValid) {
      this.setState({
        ErrorMsg: {},
        isValid: true,
      });
    } else {
      this.setState({
        isButtonDisabled: false,
        ErrorMsg: errorMsg,
      });
    }
    this.setState({ error: errorMsg });
    return isValid;
  }
  clearFields = (e) => {
    this.setState({
      Name: "",
      LastName: "",
      Email: "",
      CompanyName: "",
      Industry: "",
      Message: "",
    });
  };

  render() {
    return (
      <>
        <Button className="backbtn" onClick={this.goBack}>
          <img src={backtbn} alt={backtbn} />
          <span>Back</span>
        </Button>
        <div className="morelrpadd_cont commonfirstpadd_cont paddingbotm pd-100">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">Connect with Treeties:</span>
              <br /> We're Here for You
            </h1>
            <p>
              Have questions or need assistance? Reach out to our dedicated team
              at
              <br /> Treeties, and we'll be happy to help.
            </p>
            <p className="mb-0">
              At Treeties, we value your input and are committed to providing
              exceptional support. Whether you have questions, suggestions, or
              simply want to share your
              <br /> experience, we're eager to help you grow and thrive.
            </p>
          </div>
        </div>
        <div className="morelrpadd_cont paddingbotm pd-100 ">
          <div className="treeties_container">
            <div className="contact_us_form">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-img"></div>
                </div>
                <div className="col-sm-8">
                  <form>
                    <div className="row">
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedName
                            ? "Name" in this.state.ErrorMsg
                              ? "ErrorMsg"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="Name" className="form-label">
                              First Name <sup class="mandatory">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control rounded-4"
                              id="Name"
                              name="Name"
                              value={this.state.Name}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter first name"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedName
                                  ? "Name" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.Name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedLastName
                            ? "LastName" in this.state.ErrorMsg
                              ? "error"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="LastName" className="form-label">
                              Last Name <sup class="mandatory">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="LastName"
                              name="LastName"
                              value={this.state.LastName}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter last name"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedLastName
                                  ? "LastName" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.LastName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedEmail
                            ? "Email" in this.state.ErrorMsg
                              ? "error"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-12">
                          <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                              Email <sup class="mandatory">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Email"
                              name="Email"
                              value={this.state.Email}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter email"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedEmail
                                  ? "Email" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.Email}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedCompanyName
                            ? "CompanyName" in this.state.ErrorMsg
                              ? "error"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-sm-12">
                          <div className="mb-3">
                            <label htmlFor="CompanyName" className="form-label">
                              Company Name <sup class="mandatory">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="CompanyName"
                              name="CompanyName"
                              value={this.state.CompanyName}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter company name"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedCompanyName
                                  ? "CompanyName" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.CompanyName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedIndustry
                            ? "Industry" in this.state.ErrorMsg
                              ? "error"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-sm-12">
                          <div className="mb-3">
                            <label htmlFor="Industry" className="form-label">
                              Industry <sup class="mandatory">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Industry"
                              name="Industry"
                              value={this.state.Industry}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter industry"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedIndustry
                                  ? "Industry" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.Industry}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "paddingDiv " +
                          (this.state.isTouchedMessage
                            ? "Message" in this.state.ErrorMsg
                              ? "error"
                              : "success"
                            : "")
                        }
                      >
                        <div className="col-12">
                          <div className="mb-0">
                            <label htmlFor="Message" className="form-label">
                              Send Us A Message <sup class="mandatory">*</sup>
                            </label>
                            <textarea
                              className="form-control"
                              id="Message"
                              name="Message"
                              type="Text"
                              rows={3}
                              //defaultValue={""}
                              value={this.state.Message}
                              onChange={(event) => this.handleUserInput(event)}
                              placeholder="Enter message"
                            />
                            <div
                              className={
                                "mandatory has-error " +
                                (this.state.isTouchedMessage
                                  ? "Message" in this.state.ErrorMsg
                                    ? "error"
                                    : "success"
                                  : "")
                              }
                            >
                              {this.state.ErrorMsg?.Message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Button className="leafbtn_cont">
                      <img src={LeafButton} alt="leafbtn" />
                      <span
                        type="submit"
                        onClick={this.Save}
                        name="Submit"
                        value="submit"
                      >
                        Submit
                      </span>
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="morelrpadd_cont  paddingbotm pd-100">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">Connecting the Dots: </span>
              <br />
              Treeties' Sustainable Digital Ecosystem
            </h1>
            <br />
            <p>
              Embark on a journey through Treeties' Sustainable Digital
              Ecosystem, where
              <br /> innovative minds, groundbreaking solutions, and boundless
              creativity
              <br /> converge. Together, we're weaving a tapestry of
              collaboration that
              <br /> transcends borders, igniting a global movement of
              ecological harmony and
              <br /> lasting change.
            </p>
          </div>
        </div>

        <div className="morelrpadd_cont  paddingbotm pd-100 contactfrmpgebot_cont">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">Contact Us: </span>
              <br />
            </h1>
            <div className="contactpgefoot_addcont">
              <h3>TREETIES NETWORK PRIVATE LIMITED</h3>
              <p>
                Flat S-01, Spenta, No. 69,
                <br />6<sup>th</sup> Main, 1<sup>st</sup> Cross, <br />
                Off 100 Feet, Defence Colony Road,
                <br />
                Indiranagar,
                <br />
                Bangalore - 560038,
                <br />
                Karnataka.
                <br />
                <span>Mobile : 9513955316</span>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="morelrpadd_cont  paddingbotm pd-100">
                    <div className="treeties_container">
                        <h1>Frequently Asked Questions</h1>
                        <div className="theme-accordion">
                        <Accordion>
                <AccordionItem header="What is the purpose of Treeties?">
                  This is first tab body
                </AccordionItem>

                <AccordionItem header="Who are the Eco-Warriors of Treeties?">
                  This is second tab body
                </AccordionItem>

                <AccordionItem header="What are TreeTies' solutions?">
                  This is third tab body
                </AccordionItem>

                <AccordionItem header="What are Treeties' ongoing and upcoming projects?">
                  This is fourth tab body
                </AccordionItem>
                    </Accordion>
              
                    </div>
                       
                    </div>
                </div> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
