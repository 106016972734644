import React, { Component } from "react";
import { connect } from 'react-redux';
import {  Button } from "react-bootstrap";
import backtbn from "../../assets/backbtn.png";

class RefundCancellationPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.mounted = true;
    }
    componentWillUnmount() {
        document.body.classList.remove('refundpage_bg');
    }
    componentDidMount() {
        document.body.classList.add('refundpage_bg');
    }
    goBack() {
        window.history.go(-2);
    }
    render() {
        return (
            <>
                <Button className="backbtn" onClick={this.goBack}>
                    <img src={backtbn} alt={backtbn} />
                    <span>Back</span>
                </Button>
                
                <div className="morelrpadd_cont purpose_cont policypage_cont">
                    <div className="treeties_container">
                        <h1>Refund & Cancellation Policy</h1>
                        <p>Since this is a Non-profitable organization, there is no Refund and cancellation policy applicable on website.</p>
                    </div>
                </div>

              
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        registrationData: state.registration.registrationData
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefundCancellationPolicy);

