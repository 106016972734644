import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import { Modal, Button } from "@patternfly/react-core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OurPurpose from "../../assets/OurPurpose.png";
import OurExperience from "../../assets/OurExperience.png";
import LeafButton from "../../assets/leafbtn_img.png";
import Restoration from "../../assets/Restoration.png";
import Afforestation from "../../assets/Afforestation.png";
import AgriculturalLandRestoration from "../../assets/AgriculturalLandRestoration.png";
import Agroforestry from "../../assets/Agroforestry.png";
import NonAgriculturalLand from "../../assets/NonAgriculturalLand.png";
import Reforestation from "../../assets/Reforestation.png";
import OurSolution from "../../assets/OurSolution.png";
import OurPlatform from "../../assets/OurPlatform.png";
import Partnermgmt from "../../assets/partnermgmt.png";
import protocols from "../../assets/protocols.png";
import Adoption from "../../assets/Adoption.png";
import Bigdata from "../../assets/Bigdata.png";
import Precision from "../../assets/Precision.png";
import platformlistimg from "../../assets/platformlistimg.png";
import CSEI from "../../assets/CSEI.png";
import atree from "../../assets/atree.png";
import DRDO from "../../assets/Defence_Research_and_Development_Organisation.png";
import Vimana from "../../assets/Vimana.png";
import GlobalVikas from "../../assets/Global vikas.png";
import JungleScapes from "../../assets/JungleScapes.png";
import Induslaw from "../../assets/Induslaw.png";
import Powerweave from "../../assets/Powerweave.png";
import SayTrees from "../../assets/Saytrees.png";
import OurPartners from "../../assets/OurPartners.png";
import OurMission from "../../assets/OurMission.png";
import list_mission from "../../assets/list_mission.png";
import missionCO2 from "../../assets/missionCO2.png";
import OurTechnology from "../../assets/OurTechnology.png";
import Preventive from "../../assets/Preventive.png";
import Predictive from "../../assets/Predictive.png";
import Anaytical from "../../assets/Anaytical.png";
import Monitoring from "../../assets/monitoring.png";
import Reporting from "../../assets/Reporting.png";
import OurPropostions from "../../assets/OurPropostions.png";
import Science from "../../assets/Science.png";
import Partnerships from "../../assets/Partnerships.png";
import Digitization from "../../assets/Digitization.png";
import Governance from "../../assets/Governance.png";
import Platform from "../../assets/Platform.png";
import Technology from "../../assets/Technology.png";
import Goldenmile from "../../assets/goldenmile.png";
import GoldenmileMob from "../../assets/goldenmilemob.png";

//Comment added for re-deployment
class HomePageNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isShowMSG: false,
      scrollCount: 0,
    };
    this.mounted = true;
    this.winScroll = this.winScroll.bind(this);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.body.classList.remove("home_bg");
  }
  componentDidMount() {
    document.body.classList.add("home_bg");

    this.mounted = true;
    window.addEventListener("scroll", this.winScroll);
  }
  OpenEnquiryDetail = (e) => {
    if (this.mounted) this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  CloseEnquiryData = () => {
    if (this.mounted) {
      this.setState({ isModalOpen: !this.state.isModalOpen, isShowMSG: false });
    }
  };
  ShowMSG = () => {
    if (this.mounted) this.setState({ isShowMSG: true });
  };

  winScroll = () => {
    this.setState({ scrollCount: window.scrollY });
  };
  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
      backgroundPosition: "center center",
    };
    const insideStyles = {
      background: "transparent",
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-53%,-60%)",
      color: "#fff",
      textAlign: "center",
    };
    const insideStyles1 = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%,-50%)",
      texttransform: "capitliaze",
      color: "#fff",
      textAlign: "center",
      lineHeight: "40px",
    };
    const buttonStyle = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%, 130%)",
    };

    return (
      <>
        <div className="morelrpadd_cont purpose_cont">
          <div className="treeties_container">
            <h5>
              Restore Ecosystems. Reduce Carbon. Rehabilitate Communities.
            </h5>
            <h1>
              Powering the Ecosystem
              <br /> Restoration{" "}
              <span className="greenunderline">the Right Way</span>
            </h1>
            <p>
              TreeTies - where next-gen science and technology join forces to{" "}
              <br /> breathe new life into our planet's ecosystems.
              Collaborating with
              <br /> governments, corporations, and NGOs, we're driving
              innovative
              <br /> restoration initiatives that regenerate the world the right
              way.
            </p>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Purpose</span>
              </span>
              <img src={OurPurpose} alt={OurPurpose} />
            </div>
            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Request Demo</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="morelrpadd_cont experience_cont paddingbotm">
          <div className="treeties_container">
            <h1>
              <span className="greenunderline">COMMITTED TO RESTORE</span>
              <br />
              ECOSYSTEMS, BY LEVERAGING
              <br />
              SCIENCE, TECHNOLOGY, AND
              <br /> PARTNERSHIP NETWORKS
            </h1>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Experience</span>
              </span>
              <img src={OurExperience} alt={OurExperience} />
            </div>
            <div className="experience_list">
              <div>
                <span className="explistnum">4,50,000+</span>
                <span>
                  trees planted <br />
                  and maintained
                </span>
              </div>
              <div>
                <span className="explistnum">10</span>
                <span>
                  years of project
                  <br /> experience
                </span>
              </div>
              <div>
                <span className="explistnum">2</span>
                <span>
                  NGO’s in active
                  <br /> operation
                </span>
              </div>
              <div>
                <span className="explistnum">11</span>
                <span>
                  Plantation
                  <br /> cycles
                </span>
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/aboutus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Know More</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="lesslrpadd_cont solution_cont paddingbotm">
          <div className="treeties_container">
            <h1 className="greenunderline">Science Based Approach</h1>
            <h1>
              for right outcome &<br /> impact at scale
            </h1>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Solution</span>
              </span>
              <img src={OurSolution} />
            </div>
            <div className="experience_list solution_list">
              <div>
                <img src={Restoration} />
                <span>
                  Restoration <br />
                  (invasive)
                </span>
              </div>
              <div>
                <img src={Afforestation} />
                <span>
                  Afforestation <br />
                  Projects
                </span>
              </div>
              <div>
                <img src={Agroforestry} />
                <span>
                  Agroforestry <br />
                  Projects
                </span>
              </div>
              <div>
                <img src={Reforestation} />
                <span>
                  Reforestation <br />
                  Projects
                </span>
              </div>
              <div>
                <img src={NonAgriculturalLand} />
                <span>
                  Non-Agricultural <br />
                  Land
                </span>
              </div>
              <div>
                <img src={AgriculturalLandRestoration} />
                <span>
                  Agricultural Land <br />
                  Restoration
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="lesslrpadd_cont platform_cont paddingbotm">
          <div className="treeties_container">
            <h1 className="greenunderline">One Platform, Unlimited</h1>
            <h1>
              <span className="greenunderline">Possibilties</span> : Management,
            </h1>
            <h1>Collaboration, and </h1>
            <h1>Governance for Success</h1>
            <p>
              Experience the TreeTies platform, a centralized hub designed to
              <br /> simplify ecosystem restoration management, foster
              collaboration,
              <br /> and streamline governance. Designed with efficiency in
              mind, our
              <br /> platform modernizes processes and brings people together,
              making
              <br /> it the ultimate catalyst for restoration success.
            </p>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Platform</span>
              </span>
              <img src={OurPlatform} />
            </div>
            <div className="experience_list platform_list">
              <div>
                <img src={platformlistimg} />
              </div>
              <div>
                <img src={Partnermgmt} />
                <span>
                  PARTNER <br /> MANAGEMENT
                </span>
              </div>
              <div>
                <img src={Precision} />
                <span>
                  PRECISION, <br /> RESTORATION
                </span>
              </div>
              <div>
                <img src={Bigdata} />
                <span>BIG DATA</span>
              </div>
              <div>
                <img src={Adoption} />
                <span>ADOPTION</span>
              </div>
              <div>
                <img src={protocols} />
                <span>
                  PROTOCOLS, MODELS <br /> & ALGORITHMS
                </span>
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/platform">
                <img src={LeafButton} alt="leafbtn" />
                <span>Learn More</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="morelrpadd_cont partners_cont paddingbotm">
          <div className="treeties_container">
            <h1 className="greenunderline">TreeTies Partners Collective:</h1>
            <h1>Conservationists, Researchers,</h1>
            <h1>and Tech Providers for</h1>
            <h1>Authentic Impact</h1>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Partners</span>
              </span>
              <img src={OurPartners} />
            </div>
            <div className="partners_list">
              <div className="partnerslist_left">
                <div className="partnerslistleft_top">
                  <div>
                    <img src={CSEI} alt="CSEI" />
                  </div>
                  <div>
                    <img src={atree} alt="atree" />
                  </div>
                  <div>
                    <img src={DRDO} alt="DRDO" />
                  </div>
                  <div>
                    <img src={Vimana} alt="Vimana" />
                  </div>
                  <div>
                    <img src={GlobalVikas} alt="GlobalVikas" />
                  </div>
                  <div className="golderlogoformob">
                    <img src={GoldenmileMob} alt="GOLDEN MILE LEARNING TRUST" />
                  </div>
                </div>
                <div className="partnerslistleft_bot">
                  <div>
                    <img src={JungleScapes} alt="JungleScapes" />
                  </div>
                  {/* <div>
                                        <img src={Induslaw} alt="Induslaw" />
                                    </div> */}
                  <div>
                    <img src={Powerweave} alt="Powerweave" />
                  </div>
                  <div>
                    <img src={SayTrees} alt="Saytrees" />
                  </div>
                </div>
              </div>
              <div className="partnerslist_right">
                <img src={Goldenmile} alt="GOLDEN MILE LEARNING TRUST" />
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/contactus">
                <img src={LeafButton} alt="leafbtn" />
                <span>Partner with us</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="morelrpadd_cont mission_cont paddingbotm">
          <div className="treeties_container">
            <h1 className="greenunderline">Be the Catalyst for Carbon-</h1>
            <h1>
              <span className="greenunderline">Neutrality </span> and Achieve
            </h1>
            <h1>Green Mission Goals</h1>
            <p>
              Dedicated to empowering carbon-neutral companies in realizing
              their green
              <br /> mission goals. By leveraging our expertise in carbon
              sequestration and
              <br /> providing valuable carbon credits, we pave the way for a
              greener tomorrow- <br />
              together creating a sustainable and thriving planet for
              generations to come.
            </p>
            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Mission</span>
              </span>
              <img src={OurMission} />
            </div>
            <div className="mission_list">
              <div>
                <div className="missionleftimg">
                  <img src={missionCO2} alt={missionCO2} />
                </div>
                <div className="missionrighttxt">
                  <span>India Current Net Emissions</span>
                  <span>2.9 Gt CO2</span>
                </div>
              </div>
              <div>
                <div className="missionleftimg">
                  <img src={list_mission} alt={list_mission} />
                </div>
                <div className="missionrighttxt">
                  <span>Net zero by</span>
                  <span>2070</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lesslrpadd_cont technology_cont paddingbotm">
          <div className="treeties_container">
            <h1>Intelligent Ecosystem Solutions</h1>
            <h1>
              With <span className="greenunderline">AI and ML</span> at the
              Forefront
            </h1>

            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Technology</span>
              </span>
              <img src={OurTechnology} />
            </div>
            <div className="technology_list">
              <div>
                <img src={Preventive} />
                <span>Preventive</span>
              </div>
              <div>
                <img src={Predictive} />
                <span>Predictive</span>
              </div>
              <div>
                <img src={Anaytical} />
                <span>Anaytical</span>
              </div>
              <div>
                <img src={Monitoring} />
                <span>Monitoring</span>
              </div>
              <div>
                <img src={Reporting} />
                <span>Reporting</span>
              </div>
            </div>
            <Button className="leafbtn_cont">
              <Link to="/ourexperience">
                <img src={LeafButton} alt="leafbtn" />
                <span>Success stories</span>
              </Link>
            </Button>
          </div>
        </div>

        <div className="lesslrpadd_cont propostions_cont">
          <div className="treeties_container">
            <h1>Uniting People, Planet, </h1>
            <h1>and Prosperity for a</h1>
            <h1 className="greenunderline">Purposeful Future</h1>

            <div className="comnright_txt">
              <span>
                Our <span className="greentxt">Proposition</span>
              </span>
              <img src={OurPropostions} />
            </div>
            <div className="propostions_list">
              <div>
                <img src={Science} />
                <span className="propolistheading">Science</span>
                <span>
                  Expert and <br />
                  Expertise Driven
                </span>
              </div>
              <div>
                <img src={Partnerships} />
                <span className="propolistheading">Partnerships</span>
                <span>
                  Stakeholder <br /> Connects as a core to
                  <br /> scale the intervention
                </span>
              </div>
              <div>
                <img src={Digitization} />
                <span className="propolistheading">Digitization</span>
                <span>
                  IoT across <br />
                  value chains
                </span>
              </div>
              <div>
                <img src={Governance} />
                <span className="propolistheading">Governance</span>
                <span>
                  Defined Structures at
                  <br /> Entity and Project level
                </span>
              </div>
              <div>
                <img src={Platform} />
                <span className="propolistheading">Platform</span>
                <span>
                  API led Network
                  <br /> on a Platform
                </span>
              </div>
              <div>
                <img src={Technology} />
                <span className="propolistheading">Technology</span>
                <span>
                  Best use of tech for
                  <br /> efficiency of scale
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageNew);
