import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import { globalHistory } from "@reach/router";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import GoogleMapDraw from "../Common/Map/GoogleMapDraw";

class TreeTiesAddProjectForms1Loc extends Component {
  constructor(props) {
    //console.log("Locprops", props.navigation.getParam('activeMenu'));
    super(props);
    this.state = {
      isModalMapOpen: false,
      selectedPlace: "",
      projectLocationId: "",
      place: "",
      projectId: "",
      noLandParcels: "",
      noWaterBodies: "",
      totAreaLandParcels: "",
      totAreaWaterBodies: "",

      locationCords: [],

      landParcelId: "",
      selectedGridNo: "",
      landTitle: "",
      locationOnMap: "",
      landLocAltitude: [],
      landAreaAltitude: [],
      landPlotNo: "",
      isFarmland: "",
      isIrrigated: "",
      landAreaInHectares: "",

      rowLandParcelList: [],

      waterBodyId: "",
      selectedTypeWater: "",
      waterTitle: "",
      waterGridNo: "",
      waterLocationOnMap: "",
      waterLocationAltitude: [],
      waterAreaAltitude: [],
      selectedTypeWater: "",
      waterAreaInHectares: "",

      rowWaterBodyList: [],
      // rowWaterBodyList: [{
      //   waterBodyId:"",
      //   grid_No:"",
      //   title:"",
      //   locationOnMap:"",
      //   locationAltitude:[],
      //   typeWaterBody:"",
      //   areaInHectares:""
      // }],

      commonMasterList: [],
      projectLocationList: [],
      placeDropdownList: [],
      typeWaterDropdownList: [],
      gridNoList: [],
      GoogleMapApiKeyLoc: "",
      CurrentLocation: "",
    };
    this.state.rowLandParcelList.splice(0, 1);
    this.state.rowWaterBodyList.splice(0, 1);
  }

  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ projectId: Id });
    console.log("id", Id);
    this.GetAllCommonMasters();
    this.GetAllGridNo();
    this.GetProjectLocation(Id);
    this.GetGlobalSettings("Google Map");
    this.GetProjectList(Id);
  }
  GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            this.setState({
              GoogleMapApiKeyLoc: this.MapKeyInArray(
                responseData,
                "GoogleMapApiKey"
              ),
            });
          } else {
            let errors = {};
            errors["message"] = data.message;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };

  // All Records from Common master
  GetAllCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        this.setState({ commonMasterList: responseData });

        if (this.state.commonMasterList.length > 0) {
          var PlaceList = this.state.commonMasterList.filter(
            (x) => x.type == "Place"
          );
          this.setState({ placeDropdownList: PlaceList });

          var typeWater = this.state.commonMasterList.filter(
            (x) => x.type == "Type of Water Body"
          );
          this.setState({ typeWaterDropdownList: typeWater });
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  // Records from ProjectTreeTypeGridno
  GetAllGridNo = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllGridNo`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        this.setState({ gridNoList: responseData });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  // All the data be taken from Project Location
  GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        console.log("prolist", responseData);
        //this.setState({projectLocationList: responseData});
        if (responseData !== null) {
          this.setState({
            projectId: responseData.projectId,
            projectLocationId: responseData.projectLocationId,
            selectedPlace: responseData.place,
            rowLandParcelList: responseData.landParcels,
            rowWaterBodyList: responseData.waterBodies,
            noLandParcels: responseData.noLandParcels,
            totAreaLandParcels: responseData.totAreaLandParcels,
            noWaterBodies: responseData.noWaterBodies,
            totAreaWaterBodies: responseData.totAreaWaterBodies,
          });
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  // Get location Name
  GetProjectList = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Projects/GetProjectSummaryByProjId`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        this.setState({ CurrentLocation: responseData?.location });
        this.setState({ projectId: responseData?.projectId });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  //Save and update the ProjectLocation
  AddProjectLocation = (e) => {
    let data = {
      ProjectId: this.state.projectId,
      ProjectLocationId: this.state.projectLocationId,
      Place: this.state.selectedPlace,
      LandParcels: this.state.rowLandParcelList,
      WaterBodies: this.state.rowWaterBodyList,
      NoLandParcels: this.state.noLandParcels,
      NoWaterBodies: this.state.noWaterBodies,
      TotAreaLandParcels: this.state.totAreaLandParcels,
      TotAreaWaterBodies: this.state.totAreaWaterBodies,
    };
    console.log("savedata", JSON.stringify(data));
    if (this.validateForm(e)) {
      //let data =this.state.projectLocation;
      let url = `${config.API_HOST_URL}/api/Ecosystem/AddProjectLocation`;
      fetch(url, {
        method: "POST",
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
        //body: data,
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            ToastNotification.ShowSuccess(resp.message);
            this.Nextpage();
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
  };

  uploadFileToAWSWebS3 = (file, projectId) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file);
      data.append("ProjectId", projectId);
      data.append("fileName", file.name);
      const url = `${config.API_HOST_URL}/api/Ecosystem/UploadLocationDoc`;

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((responseData) => {
          const { success, message, UploadFileName } = responseData;
          if (success) {
            resolve({ success, message, UploadedFileName: UploadFileName });
          } else {
            reject(new Error(message));
          }
        })
        .catch((error) => {
          reject(new Error("Request failed: " + error.message));
        });
    });
  };

  getStaticMapURLForlocImage = (coordinates) => {
    let polygonURL = "";
    if (coordinates.length > 0) {
      let locCords = coordinates;
      let center = locCords?.[0]?.["lat"] + "," + locCords?.[0]?.["lng"];
      let polycord = locCords?.map((cord) => {
        return "|" + cord?.["lat"] + "," + cord?.["lng"];
      });
      // repeatFirst coordinates to connect last polygon line
      let repeatFirst = "|" + center;
      polycord.push(repeatFirst);
      let cord = polycord.join("");
      polygonURL = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=16&scale=2&size=608x274&maptype=satellite&sensor=false&path=color%3awhite|weight:3|fillcolor%3awhite${cord}&key=${this.state.GoogleMapApiKeyLoc}`;
    }
    return polygonURL;
  };

  locImagePath = async (coordinates) => {
    let imageName = "";
    const imageUrl = this.getStaticMapURLForlocImage(coordinates);
    try {
      if (imageUrl == null) {
        throw new Error("Image URL is null or invalid.");
      }
      let response = await fetch(imageUrl);
      let file = await response.blob();
      let ProjectID = this.state.projectId;
      let uploadResponse = await this.uploadFileToAWSWebS3(file, ProjectID);
      if (uploadResponse.success === "true") {
        imageName = uploadResponse.UploadedFileName;
      } else {
        console.log("Upload failed or success is not true:", uploadResponse);
      }
    } catch (error) {
      console.error("Error fetching image or uploading to S3:", error);
    }

    return imageName;
  };

  WaterBodyImagePath = async (coordinates) => {
    let imageName = "";
    const imageUrl = this.getStaticMapURLForWaterBodyImage(coordinates);
    try {
      if (imageUrl == null) {
        throw new Error("Image URL is null or invalid.");
      }
      let response = await fetch(imageUrl);
      let file = await response.blob();
      let ProjectID = this.state.projectId;
      let uploadResponse = await this.uploadFileToAWSWebS3(file, ProjectID);
      if (uploadResponse.success === "true") {
        imageName = uploadResponse.UploadedFileName;
      } else {
        console.log("Upload failed or success is not true:", uploadResponse);
      }
    } catch (error) {
      console.error("Error fetching image or uploading to S3:", error);
    }

    return imageName;
  };

  getStaticMapURLForWaterBodyImage = (coordinates) => {
    let polygonURL = "";
    if (coordinates.length > 0) {
      let locCords = coordinates;
      let center = locCords?.[0]?.["lat"] + "," + locCords?.[0]?.["lng"];
      let polycord = locCords?.map((cord) => {
        return "|" + cord?.["lat"] + "," + cord?.["lng"];
      });
      // repeatFirst coordinates to connect last polygon line
      let repeatFirst = "|" + center;
      polycord.push(repeatFirst);
      let cord = polycord.join("");
      polygonURL = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=16&scale=2&size=608x274&maptype=satellite&sensor=false&path=color%3awhite|weight:3|fillcolor%3awhite${cord}&key=${this.state.GoogleMapApiKeyLoc}`;
    }
    return polygonURL;
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    //this.setState({ [name]: value }, () => { });
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (
      name === "landTitle" ||
      name === "waterTitle" ||
      name === "landPlotNo"
    ) {
      if (value === "" || alphanumericRegex.test(value)) {
        this.setState({ [name]: value });
      }
    }

    if (name === "landAreaInHectares") {
      if (isNaN(value)) {
        this.setState({ [name]: "" }, () => {});
        // ToastNotification.ShowError("Numeric fields only")
      } else if (value === "") {
        this.setState({ [name]: "" }, () => {});
      } else {
        let totAreaLandParcels = 0;
        totAreaLandParcels = parseFloat(value);

        this.state.rowLandParcelList.forEach((item) => {
          if (item.areaInHectares) {
            totAreaLandParcels =
              totAreaLandParcels + parseFloat(item.areaInHectares);
          }
        });
        this.setState({ [name]: value });
        this.setState({ totAreaLandParcels: totAreaLandParcels });
      }
    }

    if (name === "waterAreaInHectares") {
      if (isNaN(value)) {
        this.setState({ [name]: "" }, () => {});
        // ToastNotification.ShowError("Numeric fields only")
      } else if (value === "") {
        this.setState({ [name]: "" }, () => {});
      } else {
        let totAreaWaterBodies = 0;
        totAreaWaterBodies = parseFloat(value);

        this.state.rowWaterBodyList.forEach((item) => {
          if (item.areaInHectares) {
            totAreaWaterBodies =
              totAreaWaterBodies + parseFloat(item.areaInHectares);
          }
        });
        this.setState({ [name]: value });
        this.setState({ totAreaWaterBodies: totAreaWaterBodies });
      }
    }
  }

  // AddLandGrid = async () => {
  //   const imageUrl = await this.locImagePath(this.state.landLocAltitude);
  //   if (this.state.landTitle === "") {
  //     ToastNotification.ShowError("Enter Land Parcel Title");
  //   }
  //   // if (this.state.selectedGridNo === "") {
  //   //   ToastNotification.ShowError("Select Land Parcel Grid No.");
  //   // }
  //   else {
  //     if (this.state.landParcelId === "") {
  //       let newItem = {
  //         // landParcelId: this.state.landParcelId,
  //         grid_No: this.state.selectedGridNo,
  //         title: this.state.landTitle,
  //         locationOnMap: this.state.locationOnMap,
  //         locationAltitude: this.state.landLocAltitude,
  //         plotNo: this.state.landPlotNo,
  //         isFarmland: this.state.isFarmland,
  //         isIrrigated: this.state.isIrrigated,
  //         areaInHectares: this.state.landAreaInHectares,
  //         imageUrl: imageUrl,
  //       };
  //       let { rowLandParcelList } = this.state;
  //       rowLandParcelList.push(newItem);
  //       this.setState({ rowLandParcelList: rowLandParcelList });
  //     } else {
  //       const newState = this.state.rowLandParcelList.map((obj) => {
  //         if (obj.landParcelId === this.state.landParcelId) {
  //           return {
  //             ...obj,
  //             landParcelId: this.state.landParcelId,
  //             grid_No: this.state.selectedGridNo,
  //             title: this.state.landTitle,
  //             locationOnMap: this.state.locationOnMap,
  //             locationAltitude: this.state.landLocAltitude,
  //             plotNo: this.state.landPlotNo,
  //             isFarmland: this.state.isFarmland,
  //             isIrrigated: this.state.isIrrigated,
  //             areaInHectares: this.state.landAreaInHectares,
  //             imageUrl: imageUrl,
  //           };
  //         }
  //         return obj;
  //       });
  //       this.setState({ rowLandParcelList: newState });
  //     }

  //     //No. Land parcels count
  //     //  this.setState({ noLandParcels : this.state.rowLandParcelList.filter(x=>x.landParcelId !== null && x.landParcelId !== "").length})
  //     this.setState({
  //       noLandParcels: this.state.rowLandParcelList.filter(
  //         (x) => x.grid_No !== null && x.grid_No !== ""
  //       ).length,
  //     });
  //     // Clear land parcels
  //     this.setState({
  //       landParcelId: "",
  //       selectedGridNo: "",
  //       landTitle: "",
  //       landLocAltitude: [],
  //       landPlotNo: "",
  //       isFarmland: "",
  //       isIrrigated: "",
  //       landAreaInHectares: "",
  //     });
  //   }
  // };

  AddLandGrid = async () => {
    const imageUrl = await this.locImagePath(this.state.landLocAltitude);
    if (this.state.landTitle === "") {
      ToastNotification.ShowError("Enter Land Parcel Title");
    } else {
      let newItem = {
        // landParcelId: this.state.landParcelId,
        grid_No: this.state.selectedGridNo,
        title: this.state.landTitle,
        locationOnMap: this.state.locationOnMap,
        locationAltitude: this.state.landLocAltitude,
        plotNo: this.state.landPlotNo,
        isFarmland: this.state.isFarmland,
        isIrrigated: this.state.isIrrigated,
        areaInHectares: this.state.landAreaInHectares,
        imageUrl: imageUrl,
      };
      let { rowLandParcelList } = this.state;
      rowLandParcelList.push(newItem);
      this.setState({
        rowLandParcelList: rowLandParcelList,
        noLandParcels: rowLandParcelList.length,
        totAreaLandParcels:
          this.state.totAreaLandParcels +
          parseFloat(this.state.landAreaInHectares),
      });
      // Clear land parcels
      this.setState({
        landParcelId: "",
        selectedGridNo: "",
        landTitle: "",
        landLocAltitude: [],
        landPlotNo: "",
        isFarmland: "",
        isIrrigated: "",
        landAreaInHectares: "",
      });
    }
  };
  AddWaterBodyGrid = async () => {
    const imageUrl = await this.WaterBodyImagePath(
      this.state.waterLocationAltitude
    );
    if (this.state.waterTitle === "") {
      ToastNotification.ShowError("Enter Water body Title");
    }
    // if (this.state.waterGridNo === "") {
    //   ToastNotification.ShowError("Select Water Body Grid No.");
    // }
    else {
      if (this.state.waterBodyId === "") {
        let newItem = {
          // waterBodyId: this.state.waterBodyId,
          grid_No: this.state.waterGridNo,
          title: this.state.waterTitle,
          locationOnMap: this.state.waterLocationOnMap,
          locationAltitude: this.state.waterLocationAltitude,
          typeWaterBody: this.state.selectedTypeWater,
          areaInHectares: this.state.waterAreaInHectares,
          imageUrl: imageUrl,
        };
        let { rowWaterBodyList } = this.state;
        rowWaterBodyList.push(newItem);
        this.setState({ rowWaterBodyList: rowWaterBodyList });
      } else {
        const newState = this.state.rowWaterBodyList.map((obj) => {
          if (obj.waterBodyId === this.state.waterBodyId) {
            return {
              ...obj,
              waterBodyId: this.state.waterBodyId,
              grid_No: this.state.waterGridNo,
              title: this.state.waterTitle,
              locationOnMap: this.state.waterLocationOnMap,
              locationAltitude: this.state.waterLocationAltitude,
              //locationAltitude: waterLocationAltitude,
              typeWaterBody: this.state.selectedTypeWater,
              areaInHectares: this.state.waterAreaInHectares,
              imageUrl: imageUrl,
            };
          }
          return obj;
        });
        this.setState({ rowWaterBodyList: newState });
      }

      // No. water bodies count
      //  this.setState({ noWaterBodies : this.state.rowWaterBodyList.filter(x=>x.waterBodyId !== null && x.waterBodyId !== "").length})
      this.setState((prevState) => {
        return {
          noWaterBodies: prevState.rowWaterBodyList?.filter(
            (x) => x.waterTitle !== null && x.waterTitle !== ""
          ).length,
        };
      });

      // Clear water bodies
      this.setState({
        waterBodyId: "",
        waterGridNo: "",
        waterTitle: "",
        waterLocationOnMap: "",
        waterLocationAltitude: [],
        selectedTypeWater: "",
        isFarmland: "",
        isIrrigated: "",
        waterAreaInHectares: "",
      });
    }
  };

  removeLandParcel = (i) => {
    this.setState({
      totAreaLandParcels:
        this.state.totAreaLandParcels -
        parseFloat(this.state.rowLandParcelList[i].areaInHectares),
    });
    this.state.rowLandParcelList.splice(i, 1);
    this.setState({ noLandParcels: this.state.rowLandParcelList.length });
  };

  removeWaterBody = (i) => {
    this.setState({
      totAreaWaterBodies:
        this.state.totAreaWaterBodies -
        parseFloat(this.state.rowWaterBodyList[i].areaInHectares),
    });
    this.state.rowWaterBodyList.splice(i, 1);
    this.setState({ noWaterBodies: this.state.rowWaterBodyList.length });
  };

  editLandParcel = (i) => {
    this.setState({
      landParcelId: this.state.rowLandParcelList[i].landParcelId,
      selectedGridNo: this.state.rowLandParcelList[i].grid_No,
      landTitle: this.state.rowLandParcelList[i].title,
      landPlotNo: this.state.rowLandParcelList[i].plotNo,
      landAreaInHectares: this.state.rowLandParcelList[i].areaInHectares,
      landLocAltitude: this.state.rowLandParcelList[i].locationAltitude,
      landAreaAltitude: JSON.stringify(
        this.state.rowLandParcelList[i].locationAltitude
      ),
      isFarmland: this.state.rowLandParcelList[i].isFarmland,
      isIrrigated: this.state.rowLandParcelList[i].isIrrigated,
    });
  };

  editWaterBody = (i) => {
    this.setState({
      waterBodyId: this.state.rowWaterBodyList[i].waterBodyId,
      waterTitle: this.state.rowWaterBodyList[i].title,
      selectedTypeWater: this.state.rowWaterBodyList[i].typeWaterBody,
      waterLocationAltitude: this.state.rowWaterBodyList[i].locationAltitude,
      waterAreaAltitude: JSON.stringify(
        this.state.rowWaterBodyList[i].locationAltitude
      ),
      waterAreaInHectares: this.state.rowWaterBodyList[i].areaInHectares,
      waterGridNo: this.state.rowWaterBodyList[i].grid_No,
      waterBodyId: this.state.rowWaterBodyList[i].waterBodyId,
    });
  };

  // Validate the Project Location fields
  validateForm = (e) => {
    let fields = { place: this.state.selectedPlace };
    //let fields = { place : this.state.selectedPlace, selectedGridNo: this.state.selectedGridNo , landTitle: this.state.landTitle}
    // let landParcels =[{  grid_No: this.state.projectLocation.landParcels.grid_No, title : this.state.projectLocation.landParcels.title, locationOnMap : this.state.projectLocation.landParcels.locationOnMap }]
    //  let waterParcels =[{ grid_No: this.state.projectLocation.waterParcels.grid_No, title : this.state.projectLocation.waterParcels.title}]

    let errors = {};
    let formIsValid = true;
    debugger;
    // if (!fields["place"]) {
    //   formIsValid = false;
    //   ToastNotification.ShowError("Select Place");
    // }
    // if (fields["place"] === "text") {
    //   formIsValid = false;
    //   ToastNotification.ShowError("Select Place");
    // }
    // if (!fields["selectedGridNo"]) {
    //     formIsValid = false;
    //     ToastNotification.ShowError("Select Grid No");
    // }
    // if (!fields["landTitle"]) {
    //     formIsValid = false;
    //      ToastNotification.ShowError("Enter Title");
    //    // errors["title"] = "Enter Title";
    // }
    // if (!landParcels["locationOnMap"]) {
    //     formIsValid = false;
    //     ToastNotification.ShowError("Draw Location on Map");
    //     // errors["locationOnMap"] = "Draw Location on Map";
    // }
    // if (!waterParcels["grid_No"]) {
    //     formIsValid = false;
    //     ToastNotification.ShowError("Select Grid No");
    //    // errors["grid_No"] = "Select Grid No";
    // }
    // if (!waterParcels["title"]) {
    //     formIsValid = false;
    //     ToastNotification.ShowError("Enter Title");
    //     //errors["title"] = "Enter Title";
    // }
    return formIsValid;
  };
  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Own/:" + this.state.projectId, {
      state: { activeMenu: 5 },
    });
  };
  Back = () => {
    navigate("/TreeTiesAddProjectForms1Spo/:" + this.state.projectId, {
      state: { activeMenu: 3 },
    });
  };
  getdataFromChild = (data) => {
    this.setState({ landLocAltitude: data.newLocationCords });
    this.setState({
      landAreaAltitude: JSON.stringify(this.state.landLocAltitude),
    });
  };
  getdataFromWaterbody = (data) => {
    this.setState({ waterLocationAltitude: data.newLocationCords });
    this.setState({ waterAreaAltitude: JSON.stringify(data.newLocationCords) });
  };

  componentWillUnmount() {}

  render() {
    return (
      <div className="treeties-addproject-forms1-loc ecosyscom_cont">
        {/* <Header/> */}
        <div className="main-content-area6">
          {/* <LeftNavbar/>  */}
          <div className="middle-content-area6">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont exoloctab_cont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont addproformloc_cont">
                {/* <div className="container-17 pb-4">
                  <div className="input-field-text20">
                    <div className="content-title-text108">
                      <div className="hero-title13">
                        <div className="project-summary13">
                          <sup className="mandatory">*</sup>Select Place
                        </div>
                      </div>
                      <select
                        className="dropdown-130"
                        name="selectedPlace"
                        value={this.state.selectedPlace}
                        onChange={this.handleChange}
                      >
                        <option value="Select">Select</option>
                        {this.state.placeDropdownList.map(function (item) {
                          return (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div> */}

                <div className="container11">
                  {/* Landparcel Start */}
                  <div className="land-parcels-container1">
                    <div className="land-parecel-content-area1">
                      <div className="close7">close</div>
                      <div className="left-content1">
                        <div className="hero-title13">
                          <b className="logo-a6">Land Parcels</b>
                        </div>
                        <div className="container-26">
                          <div className="column-118">
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary13">Title</div>
                              </div>
                              <input
                                className="enter-input-text-1"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="landTitle"
                                value={this.state.landTitle}
                                placeholder="Enter Title"
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Is it a Farmland?
                                </div>
                              </div>
                              <div className="radio-button-input-text2">
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  id="yes"
                                  name="isFarmland"
                                  value="yes"
                                  checked={this.state.isFarmland === "yes"}
                                  //onChange={handleChange}
                                  onChange={this.handleChange}
                                />
                                Yes
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  name="isFarmland"
                                  value="no"
                                  checked={this.state.isFarmland === "no"}
                                  onChange={this.handleChange}
                                />
                                No
                              </div>
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Area (in hectares)
                                </div>
                              </div>
                              <input
                                className="text-input-field-319"
                                type="text"
                                placeholder="Area In Hectares"
                                name="landAreaInHectares"
                                value={this.state.landAreaInHectares}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>

                            {/* <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Grid Nos.
                                </div>
                              </div>
                              <select
                                className="dropdown-130"
                                name="selectedGridNo"
                                value={this.state.selectedGridNo}
                                onChange={this.handleChange}
                              >
                                <option value="text">Select</option>
                                {this.state.gridNoList.map(function (item) {
                                  return (
                                    <option value={item.gridGuid}>
                                      {item.gridNo}
                                    </option>
                                  );
                                })}
                              </select>
                            </div> */}
                          </div>

                          <div className="column-118">
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Plot Lat Long
                                </div>
                              </div>
                              {/* {this.state.landLocAltitude.map(x=>{
                                      <input className="text-input-field-319" type="text" name="landLocAltitude" value={x.landLocAltitude} />
                                    })}  */}
                              <input
                                className="text-input-field-319"
                                hidden
                                type="text"
                                readonly
                                name="landLocAltitude"
                                value={this.state.landLocAltitude}
                                placeholder="Draw a Plot"
                              />
                              <input
                                className="text-input-field-319"
                                type="text"
                                readonly
                                name="landAreaAltitude"
                                value={this.state.landAreaAltitude}
                                placeholder="Draw a Plot"
                              />
                            </div>
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Is it Irrigated?
                                </div>
                              </div>
                              <div className="radio-button-input-text2">
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  name="isIrrigated"
                                  value="yes"
                                  checked={this.state.isIrrigated === "yes"}
                                  onChange={this.handleChange}
                                />
                                Yes
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  name="isIrrigated"
                                  value="no"
                                  checked={this.state.isIrrigated === "no"}
                                  onChange={this.handleChange}
                                />
                                No
                              </div>
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Plot Nos.
                                </div>
                              </div>
                              <input
                                className="text-input-field-319"
                                type="text"
                                placeholder="Plot No"
                                name="landPlotNo"
                                value={this.state.landPlotNo}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.GoogleMapApiKeyLoc && (
                        <div className="ecolocpagemap_cont">
                          <GoogleMapDraw
                            onparentCallback={this.getdataFromChild}
                            defaultPolygon={this.state.landLocAltitude}
                            GoogleMapApiKeyLoc={this.state.GoogleMapApiKeyLoc}
                            GoogleMapLocation={this.state.CurrentLocation}
                          ></GoogleMapDraw>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="cta-add-more4 pb-3">
                    <div className="logo-a6">plus</div>
                    <div className="add-more4">
                      <a className="addbtnstyle" onClick={this.AddLandGrid}>
                        Add
                      </a>
                    </div>
                  </div>
                </div>

                {/* Land parcel grid start    */}
                <div className="table4 pb-4">
                  <div className="hero-title19">
                    {/* <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Grid No</div>
                      </div>
                    </div> */}
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Title</div>
                      </div>
                    </div>
                    {/* <div className="tb-col2">
                          <div className="table-left-title4">
                            <div className="project-summary19">Location Altitude</div>
                          </div>
                         </div>  */}
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Plot No's</div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">
                          Is it a Farmland
                        </div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Is it Irrigated</div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">
                          Area (In Hectares)
                        </div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Action</div>
                      </div>
                    </div>
                  </div>

                  {this.state.rowLandParcelList &&
                    this.state.rowLandParcelList.map((x, i) => {
                      return (
                        <div>
                          <div className="hero-title19" key={i}>
                            {/* <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {
                                    this.state.gridNoList.filter(
                                      (ss) => ss.gridGuid === x.grid_No
                                    )[0]?.gridNo
                                  }
                                </div>
                              </div>
                            </div> */}
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.title}
                                </div>
                              </div>
                            </div>
                            {/* <div className="tb-col2">
                              
                              <div className="table-row-content-a30">
                                    <div className="personnel-lorem-ipsum34">
                                    {JSON.stringify(x.locationAltitude)}
                                    </div>
                                </div>
                              </div>  */}
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.plotNo}
                                </div>
                              </div>
                            </div>
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.isFarmland}
                                </div>
                              </div>
                            </div>
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.isIrrigated}
                                </div>
                              </div>
                            </div>
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.areaInHectares}
                                </div>
                              </div>
                            </div>
                            {/* {x.grid_No ? ( */}
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editLandParcel(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.removeLandParcel(i)}
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* Land parcel grid end    */}
                <div className="container11">
                  <div className="container-17 fullwidthspon pt-4 pb-4">
                    <div className="input-field-text20 noofwaterinputs_wrap">
                      <div className="content-title-text108">
                        <div className="hero-title13">
                          <div className="project-summary13">
                            No. of Land Parcels Included
                          </div>
                        </div>
                        <input
                          className="text-input-field-319"
                          type="text"
                          readonly
                          name="noLandParcels"
                          value={this.state.noLandParcels}
                        />
                      </div>
                      <div className="content-title-text108">
                        <div className="hero-title13">
                          <div className="project-summary13">
                            Total Area of Land Parcels (in hectares)
                          </div>
                        </div>
                        <input
                          className="text-input-field-319"
                          type="text"
                          readonly
                          name="totAreaLandParcels"
                          value={this.state.totAreaLandParcels}
                        />
                      </div>
                    </div>
                  </div>
                  {/* LandParcel End */}

                  {/* Water Bodies Start */}
                  <div className="land-parcels-container2">
                    <div className="land-parecel-content-area1">
                      <div className="left-content2">
                        <div className="hero-title13">
                          <b className="logo-a6">Water Bodies</b>
                        </div>
                        <div className="container-26">
                          <div className="column-118">
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">Title</div>
                              </div>
                              <input
                                className="text-input-field-319"
                                required
                                type="text"
                                name="waterTitle"
                                value={this.state.waterTitle}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Location Lat Long
                                </div>
                              </div>
                              <input
                                className="text-input-field-319"
                                readonly
                                type="text"
                                hidden
                                name="waterLocationAltitude"
                                value={this.state.waterLocationAltitude}
                                //placeholder="Draw a Plot"
                              />
                              <input
                                className="text-input-field-319"
                                readonly
                                type="text"
                                name="waterAreaAltitude"
                                value={this.state.waterAreaAltitude}
                                //placeholder="Draw a Plot"
                              />
                            </div>

                            {/* <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Grid Nos.
                                </div>
                              </div>
                              <select
                                className="dropdown-130"
                                name="waterGridNo"
                                value={this.state.waterGridNo}
                                onChange={this.handleChange}
                              >
                                <option value="text">Select</option>
                                {this.state.gridNoList.map(function (item) {
                                  return (
                                    <option value={item.gridGuid}>
                                      {item.gridNo}
                                    </option>
                                  );
                                })}
                              </select>
                            </div> */}
                          </div>
                          <div className="column-118">
                            <div className="content-title-text121">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Type of Water Body
                                </div>
                              </div>
                              <select
                                className="dropdown-130"
                                name="selectedTypeWater"
                                value={this.state.selectedTypeWater}
                                onChange={this.handleChange}
                              >
                                <option value="text">Select</option>
                                {this.state.typeWaterDropdownList.map(function (
                                  item
                                ) {
                                  return (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Area (in hectares)
                                </div>
                              </div>
                              <input
                                className="text-input-field-319"
                                name="waterAreaInHectares"
                                value={this.state.waterAreaInHectares}
                                type="text"
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.GoogleMapApiKeyLoc && (
                        <div className="ecolocpagemap_cont">
                          <GoogleMapDraw
                            onparentCallback={this.getdataFromWaterbody}
                            defaultPolygon={this.state.waterLocationAltitude}
                            GoogleMapApiKeyLoc={this.state.GoogleMapApiKeyLoc}
                            GoogleMapLocation={this.state.CurrentLocation}
                          ></GoogleMapDraw>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Water Bodies End */}

                  <div className="cta-add-more4">
                    <div className="logo-a6">plus</div>
                    <div className="add-more4">
                      <a
                        className="addbtnstyle"
                        onClick={this.AddWaterBodyGrid}
                      >
                        Add
                      </a>
                    </div>
                  </div>

                  <div className="container-17 fullwidthspon pb-4">
                    <div className="input-field-text20 noofwaterinputs_wrap">
                      <div className="content-title-text108">
                        <div className="hero-title13">
                          <div className="project-summary13">
                            No. of Water Bodies Included
                          </div>
                        </div>
                        <input
                          className="text-input-field-319"
                          type="text"
                          readonly
                          name="noWaterBodies"
                          value={this.state.noWaterBodies}
                        />
                      </div>
                      <div className="content-title-text108">
                        <div className="hero-title13">
                          <div className="project-summary13">{`Total Area of Water Bodies (in hectares) `}</div>
                        </div>
                        <input
                          className="text-input-field-319"
                          type="text"
                          readonly
                          name="totAreaWaterBodies"
                          value={this.state.totAreaWaterBodies}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Water body grid start    */}
                <div className="table4">
                  <div className="hero-title19">
                    {/* <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Grid No</div>
                      </div>
                    </div> */}
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Title</div>
                      </div>
                    </div>
                    {/* <div className="tb-col2">
                          <div className="table-left-title4">
                            <div className="project-summary19">Location Altitude</div>
                          </div>
                         </div>  */}
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Type Water Body</div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">
                          Area (In Hectares)
                        </div>
                      </div>
                    </div>
                    <div className="tb-col2">
                      <div className="table-left-title4">
                        <div className="project-summary19">Action</div>
                      </div>
                    </div>
                  </div>

                  {this.state.rowWaterBodyList &&
                    this.state.rowWaterBodyList.map((x, i) => {
                      return (
                        <div>
                          <div className="hero-title19" key={i}>
                            {/* <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {
                                    this.state.gridNoList.filter(
                                      (ss) => ss.gridGuid === x.grid_No
                                    )[0]?.gridNo
                                  }
                                </div>
                              </div>
                            </div> */}

                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.title}
                                </div>
                              </div>
                            </div>

                            {/* <div className="tb-col2">
                                <div className="table-row-content-a30">
                                    <div className="personnel-lorem-ipsum34">
                                    {x.locationAltitude}
                                    </div>
                                </div>
                              </div>  */}

                            {/* <div className="tb-col2">
                                <div className="table-row-content-a30">
                                    <div className="personnel-lorem-ipsum34">
                                    {x.plotNo}
                                    </div>
                                </div>
                              </div>  */}

                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {
                                    this.state.typeWaterDropdownList.filter(
                                      (ss) => ss.mainGuId === x.typeWaterBody
                                    )[0]?.description
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  {x.areaInHectares}
                                </div>
                              </div>
                            </div>
                            {/* {x.grid_No ? ( */}
                            <div className="tb-col2">
                              <div className="table-row-content-a30">
                                <div className="personnel-lorem-ipsum34">
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editWaterBody(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.removeWaterBody(i)}
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* Water body grid End   */}

                <div className="action-buttons5 pt-4">
                  <button className="cta11">
                    <div className="back5" onClick={this.Back}>
                      Back
                    </div>
                  </button>
                  <button className="cta12">
                    <div
                      className="save-continue6"
                      onClick={() => this.AddProjectLocation()}
                    >{`Save & Continue`}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar6">
                  <button className="plus-circle6">plus-circle</button>
                  <button className="notifications-bell6">notifications-bell</button>
                  <button className="notifications-bell6">email</button>
                </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Loc);
