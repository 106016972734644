import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import Addlistpopup from "./ApplistPopup";
import AddMeetingNote from "./AddMeetingNote";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import { Modal, ModalVariant } from "@patternfly/react-core";
import "font-awesome/css/font-awesome.min.css";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import Loader from "../../Common/Loader/NewLoader";
class ApplicationList extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      All: 0,
      NGO: 0,
      Private: 0,
      Others: 0,
      TypeOfIncorporation: "All",
      List: [],
      count: 0,
      activePage: 1,
      pagesize: 10,
      SearchText: "",
      PageName: "Application",
      // SortBy: 'NGOName',
      SortBy: "DisplayOrder",
      SortType: true,
      Up: "active",
      Down: "",
      PageRange: 10,
      GroupDetails: [],
      GroupGuid: "",
      GroupName: "",
      userActivePage: 1,
      userTotalCount: 0,
      userPageSize: 7,
      userPageRange: 5,
      LastUpdatedOn: null,
      isDataLoaded: false,
      AlertShow: false,
      AlertPop: false,
      popGroupGuid: "",
      MenuGuid: "",
      MenuRights: "",
      MsgForAlert: "",
      startDate: null,
      endDate: null,
      isAdvance: false,
      IsPopupDataLoaded: false,
      GroupGuids: [],
      IsEditMergerGroup: false,
      IsShowpopup: false,
      SelectedCustomerGroup: [],
      formErrors: [],
      ApplicationInfo: [],
      calendlyToken: "",
      MeetingScheduled: {},
      MeetingScheduledModelIdx: false,
      IsPopUpStatus: false,
      Applicationlist: [],
      IsMeetingNotePopUpToggle: false,
      MeetingNote: "",
      errors: [],
      UserId: "",
      loading: true,
    };
    this.state = this.initialState;
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.GetApplicationList("All");
  }

  FilterApplications = (e) => {
    if (this.mounted) {
      let value = e.target.getAttribute("value");
      this.setState({ ProjectStatus: value }, () =>
        this.GetApplicationList(value)
      );
    }
  };

  GetApplicationList = (TypeOfIncorporation) => {
    this.setState({ loading: true });
    if (this.mounted) {
      this.setState({ TypeOfIncorporation: TypeOfIncorporation });
      let SearchText = "null";
      if (this.state.SearchText !== "") SearchText = this.state.SearchText;

      let uri = `${config.API_HOST_URL}/api/login/GetApplicationList/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}/${TypeOfIncorporation}`;
      let fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success && this.mounted) {
            this.setState({
              userTotalCount: data.count,
              All: data.all,
              NGO: data.ngo,
              Private: data.private,
              Others: data.others,
              List: data.list,
              count: data.count,
              pagesize: 10,
              IsPopupDataLoaded: true,
              isDataLoaded: true,
              calendlyToken: data.calendlyToken,
              loading: false,
            });
          } else {
            this.setState({ List: data.list, loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  };

  MakeAnAppointment = (e) => {
    e.preventDefault();
    if (this.mounted) {
      let value = e.target.getAttribute("value");
      let UserDetail;
      UserDetail = {
        UserId: value,
        Status: "Meeting Requested",
      };
      let uri = `${config.API_HOST_URL}/api/login/MakeAnAppointment`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          let fieldValidationErrors = this.state.formErrors;
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                fieldValidationErrors.error = data.message;
                if (this.mounted) {
                  this.setState(
                    {
                      formErrors: fieldValidationErrors,
                    },
                    this.validateForm
                  );
                }
                this.GetApplicationList("All");
              } else {
                fieldValidationErrors.error = data.message;
                if (this.mounted) {
                  this.setState(
                    {
                      formErrors: fieldValidationErrors,
                    },
                    this.validateForm
                  );
                }
              }
            });
          } else {
            fieldValidationErrors.error = "something went wrong!";
            if (this.mounted) {
              this.setState(
                {
                  formErrors: fieldValidationErrors,
                },
                this.validateForm
              );
            }
          }
        })
        .catch((error) => {
          if (this.mounted) {
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
          }
        });
    }
  };

  handleButtonClick = (GroupGuid, GroupName) => {
    this.setState({
      IsShowpopup: !this.state.IsShowpopup,
      GroupName: GroupName,
      GroupGuid: GroupGuid,
      IsPopupDataLoaded: false,
      userActivePage: 1,
    });
    this.GetApplicationList(this.state.TypeOfIncorporation);
  };

  handlePageChange = (pageNumber) => {
    if (this.mounted) {
      this.setState({ activePage: pageNumber }, function () {
        this.GetApplicationList(this.state.TypeOfIncorporation);
      });
    }
  };

  handlePopUpPageChange = (pageNumber) => {
    if (this.mounted) {
      this.setState(
        { userActivePage: pageNumber, IsPopupDataLoaded: false },
        function () {
          this.getUserNameBasedOnGroup(this.state.GroupGuid);
        }
      );
    }
  };

  handleSearchBySubmit = () => {
    if (this.mounted) {
      this.setState(
        {
          activePage: 1,
        },
        function () {
          this.GetApplicationList(this.state.TypeOfIncorporation);
        }
      );
    }
  };

  handleChange = (e) => {
    if (this.mounted) {
      this.setState({
        SearchText: e.target.value,
      });
    }
  };

  handleSortClick = (SortBy) => {
    let up = "";
    let down = "active";
    let sortType = this.state.SortType;

    if (SortBy === this.state.SortBy) sortType = !this.state.SortType;

    if (sortType) {
      up = "active";
      down = "";
    }

    this.setState(
      {
        SortBy: SortBy,
        SortType: sortType,
        Up: up,
        Down: down,
      },
      function () {
        this.GetApplicationList(this.state.TypeOfIncorporation);
      }
    );
  };

  handleSearchByEnter = (e) => {
    if (e.key === "Enter") {
      if (this.mounted) {
        this.setState(
          {
            SearchText: e.target.value,
            activePage: 1,
          },
          function () {
            this.GetApplicationList(this.state.TypeOfIncorporation);
          }
        );
      }
    }
  };

  // OpenApproveRejectPopup = param => (e) => {
  //     debugger
  //     this.props.setApplicationInfo(param);
  // };

  handleMeetingScheduledTgl = (idx) => {
    this.setState({
      MeetingScheduledModelIdx:
        idx === this.state.MeetingScheduledModelIdx ? null : idx,
    });
  };

  openMeetingScheduledPopup = (payload, idx) => {
    if (payload) {
      payload = JSON.parse(payload);
      fetch(payload.event.uri, {
        method: "GET",
        headers: { Authorization: `Bearer ${this.state.calendlyToken}` },
      })
        .then((resp) => {
          if (resp.ok) return resp.json();
          else return false;
        })
        .then((meeting) => {
          if (meeting) {
            meeting = meeting.resource;
            fetch(payload.invitee.uri, {
              method: "GET",
              headers: { Authorization: `Bearer ${this.state.calendlyToken}` },
            })
              .then((resp) => {
                if (resp.ok) return resp.json();
                else return false;
              })
              .then((invitee) => {
                meeting.invitee = invitee.resource;
                this.setState({ MeetingScheduled: meeting }, () =>
                  this.handleMeetingScheduledTgl(idx)
                );
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleparentMethod = () => {
    this.setState(this.initialState, function () {
      this.GetApplicationList("All");
    });
  };
  handleModalToggle = (item) => {
    let Applicationlist = [];
    if (this.mounted) {
      this.setState({
        Applicationlist: item,
        IsPopUpStatus: !this.state.IsPopUpStatus,
      });
    }
  };

  handleMeetingNoteToggle = (item) => {
    if (this.mounted) {
      if (item.meetingDetailUrl) {
        let payload = JSON.parse(item.meetingDetailUrl);
        fetch(payload.event.uri, {
          method: "GET",
          headers: { Authorization: `Bearer ${this.state.calendlyToken}` },
        })
          .then((resp) => {
            if (resp.ok) return resp.json();
            else return false;
          })
          .then((meeting) => {
            if (meeting) {
              meeting = meeting.resource;
              this.setState({
                MeetingScheduled: meeting,
                IsMeetingNotePopUpToggle: !this.state.IsMeetingNotePopUpToggle,
                UserId: item.userId,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.setState({ MeetingScheduled: null }, () =>
          ToastNotification.ShowInfo(
            "You can not add notes, because meeting not yet scheduled."
          )
        );
      }
    }
  };
  render() {
    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        innerWidth: 4,
        backgroundColor: "#50b041",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const CustomScrollbars = (props) => (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
      />
    );
    const { loading } = this.state;
    return (
      <>
        {loading && (
          <div className="loader-overlay listspages_cont">
            <Loader />
          </div>
        )}
        <div className="headbottom_cont">
          <ul className="headbottom_content">
            <li className="heading_cont">Applicants List</li>
            {/* <li className="export_btn">
                            <Button>Export Report <i className="fa fa-plus"></i></Button>
                        </li> */}
          </ul>
        </div>
        <div className="growalarm_container applist_cont">
          <Container>
            <div className="growalarm_content">
              <div className="commontblfltr_wrap">
                <ul className="filterlist">
                  <li
                    className={
                      this.state.TypeOfIncorporation === "All"
                        ? "all_btn active"
                        : "all_btn"
                    }
                  >
                    <a value="All" onClick={this.FilterApplications}>
                      All ({this.state.All})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.TypeOfIncorporation === "Trust"
                        ? "ngo_btn active"
                        : "ngo_btn"
                    }
                  >
                    <a value="Trust" onClick={this.FilterApplications}>
                      Trust ({this.state.NGO})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.TypeOfIncorporation === "Privatelimitedcompany"
                        ? "private_btn active"
                        : "private_btn"
                    }
                  >
                    <a value="Private" onClick={this.FilterApplications}>
                      Private limited company ({this.state.Private})
                    </a>
                  </li>
                  <li
                    className={
                      this.state.TypeOfIncorporation === "Others"
                        ? "others_btn active"
                        : "others_btn"
                    }
                  >
                    <a value="Others" onClick={this.FilterApplications}>
                      Others ({this.state.Others})
                    </a>
                  </li>
                </ul>
                <div className="showtxtnbtns_wrap">
                  <span className="showingtxt totalReport">
                    Showing{" "}
                    <strong>
                      &nbsp;
                      {this.state.count > 10 && (
                        <>
                          {(this.state.activePage - 1) * 10 + 1} -{" "}
                          {this.state.count < this.state.activePage * 10
                            ? this.state.count
                            : this.state.activePage * 10}{" "}
                          of{" "}
                        </>
                      )}
                      {this.state.count}{" "}
                    </strong>
                    &nbsp;
                    {this.state.PageName}
                  </span>
                  {/* <span className="totaoReset"><i onClick={() => this.reset()} className="fas fa-sync"></i> Last Updated: {this.state.LastUpdatedOn && <Moment format={config.DateFormat}>{this.state.LastUpdatedOn}</Moment>}</span> */}
                </div>
              </div>
              <div className="commontbl_wrap">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                >
                  <table className="commontbl_cont">
                    <thead>
                      <tr>
                        <th>
                          Id
                          {/* <div className="sortArrow" >
                                                        <i className="fas fa-sort-up"></i>
                                                        <i className="fas fa-sort-down"></i>
                                                    </div> */}
                        </th>
                        <th>
                          Registered On
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("CreatedOn")}
                          >
                            <i
                              className={
                                this.state.SortBy === "CreatedOn"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "CreatedOn"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th>
                          Name
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("NameOfNGO")}
                          >
                            <i
                              className={
                                this.state.SortBy === "NameOfNGO"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "NameOfNGO"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th>
                          Location
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("BaseLocation")}
                          >
                            <i
                              className={
                                this.state.SortBy === "BaseLocation"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "BaseLocation"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th>
                          Type
                          <div
                            className="sortArrow"
                            onClick={() =>
                              this.handleSortClick("TypeOfIncorporation")
                            }
                          >
                            <i
                              className={
                                this.state.SortBy === "TypeOfIncorporation"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "TypeOfIncorporation"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th className="status">
                          Head
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("NGOHeadName")}
                          >
                            <i
                              className={
                                this.state.SortBy === "NGOHeadName"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "NGOHeadName"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th>
                          Trees Planted
                          <div
                            className="sortArrow"
                            onClick={() =>
                              this.handleSortClick("TreePlantedBefore")
                            }
                          >
                            <i
                              className={
                                this.state.SortBy === "TreePlantedBefore"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "TreePlantedBefore"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th className="status statusth">
                          Status
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("DisplayOrder")}
                          >
                            <i
                              className={
                                this.state.SortBy === "DisplayOrder"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "DisplayOrder"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th className="actionth">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tblsearch_cont">
                        <td colSpan="9">
                          <div className="tblsearch">
                            <i className="fa fa-search"></i>
                            <input
                              autoFocus
                              type="text"
                              placeholder="Search by name, head name, location…"
                              onKeyPress={this.handleSearchByEnter}
                              value={this.state.SearchText}
                              onChange={this.handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                      {this.state.List &&
                        this.state.List?.map((item, index) => (
                          <tr key={item.email}>
                            <td className="idtd">{index + 1}</td>
                            <td className="datetd">
                              <Moment format="DD MMMM, YYYY">
                                {item.createdOn}
                              </Moment>
                            </td>
                            <td className="nametd">
                              <a
                                onClick={() => {
                                  this.handleModalToggle(item);
                                }}
                              >
                                {item?.nameOfNGO
                                  ? item?.nameOfNGO
                                  : item?.firstName + item?.lastName}
                              </a>
                            </td>
                            <td className="locationtd">{item.baseLocation}</td>
                            <td className="typetd">
                              {item.typeOfIncorporation ==
                              "Privatelimitedcompany"
                                ? "Private Limited Company"
                                : item.typeOfIncorporation}
                            </td>
                            <td className="headtd">{item.ngoHeadName}</td>
                            <td className="treeplantedtd">
                              {item.treePlantedBefore}
                            </td>
                            <td
                              className={
                                "statustwo " +
                                (item.status === "New Request" ||
                                item.status === null
                                  ? "newrequest"
                                  : item.status == "Approved"
                                  ? "resolved"
                                  : item.status == "Rejected"
                                  ? "rejected"
                                  : "newrequest")
                              }
                            >
                              <span
                                onClick={
                                  item.status === "Meeting Requested"
                                    ? () =>
                                        this.openMeetingScheduledPopup(
                                          item.meetingDetailUrl,
                                          index
                                        )
                                    : null
                                }
                              >
                                {item.status === null
                                  ? "New Request"
                                  : item.status}
                              </span>
                              {this.state.MeetingScheduledModelIdx ===
                                index && (
                                <Modal
                                  key={item.email}
                                  className="MeetingScheduledModel"
                                  variant={ModalVariant.small}
                                  title={this.state.MeetingScheduled.name}
                                  isOpen={
                                    this.state.MeetingScheduledModelIdx ===
                                    index
                                  }
                                  onClose={() =>
                                    this.handleMeetingScheduledTgl(index)
                                  }
                                >
                                  <div className="MeetingScheduledModelDiv">
                                    <strong>Meeting Date: </strong>{" "}
                                    <Moment
                                      className="infotag"
                                      format="DD MMMM, YYYY"
                                    >
                                      {this.state.MeetingScheduled.start_time}
                                    </Moment>
                                    <br />
                                    <strong>From: </strong>{" "}
                                    <Moment
                                      className="infotag"
                                      format="hh:mm A"
                                    >
                                      {this.state.MeetingScheduled.start_time}
                                    </Moment>
                                    <br />
                                    <strong>To: </strong>{" "}
                                    <Moment
                                      className="infotag"
                                      format="hh:mm A"
                                    >
                                      {this.state.MeetingScheduled.end_time}
                                    </Moment>
                                    <br />
                                    <strong>Invitee: </strong>{" "}
                                    <span className="infotag">
                                      {this.state.MeetingScheduled.invitee
                                        .name +
                                        " <" +
                                        this.state.MeetingScheduled.invitee
                                          .email +
                                        ">"}
                                    </span>
                                    {this.state.MeetingScheduled.event_guests
                                      .length > 0 && (
                                      <div className="meetingGuestsDiv">
                                        <strong>Guests: </strong>
                                        <ul className="meetingGuests">
                                          {this.state.MeetingScheduled.event_guests.map(
                                            (mt) => (
                                              <li key={mt.email}>{mt.email}</li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </Modal>
                              )}
                            </td>
                            <td className="action_cont">
                              {/* {item.status === "Meeting Requested" && <a onClick={this.OpenApproveRejectPopup(item)}> <Addlistpopup parentMethod={this.handleparentMethod} /></a>} */}
                              {item.status === "Meeting Requested" && (
                                <a
                                  className="meetingreqpopup"
                                  onClick={() => {
                                    this.handleModalToggle(item);
                                  }}
                                >
                                  <i className="far fa-thumbs-up"></i>
                                </a>
                              )}
                              {item.status === "Meeting Requested" && (
                                <div className="makeappoint_cont">
                                  <div className="makeappoint_btn">
                                    {" "}
                                    <a
                                      className="btn btn-sucess"
                                      onClick={() => {
                                        this.handleMeetingNoteToggle(item);
                                      }}
                                    >
                                      Add Meeting Note
                                    </a>
                                  </div>
                                </div>
                              )}
                              {(item.status === "New Request" ||
                                item.status === null) && (
                                <div className="makeappoint_cont">
                                  <div className="makeappoint_btn">
                                    <button
                                      value={item.userId}
                                      onClick={this.MakeAnAppointment}
                                    >
                                      Make Appointment
                                    </button>
                                    {/* <Addlistpopup /> */}
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </CustomScrollbars>
              </div>
              <div className="wrapper">
                {this.state.isDataLoaded ? (
                  this.state.count > 0 ? (
                    this.state.count > this.state.pagesize && (
                      <div className="paginationDiv">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pagesize}
                          totalItemsCount={this.state.count}
                          pageRangeDisplayed={this.state.PageRange}
                          onChange={this.handlePageChange}
                          nextPageText={<i className="fa fa-angle-right"></i>}
                          prevPageText={<i className="fa fa-angle-left"></i>}
                          lastPageText={
                            <i className="fa fa-angle-double-right"></i>
                          }
                          firstPageText={
                            <i className="fa fa-angle-double-left"></i>
                          }
                        />
                      </div>
                    )
                  ) : (
                    <div>No Results Found</div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
              {this.state.IsPopUpStatus && (
                <Addlistpopup
                  parentMethod={this.handleparentMethod}
                  data={this.state.Applicationlist}
                  status={true}
                />
              )}
              {this.state.IsMeetingNotePopUpToggle && (
                <AddMeetingNote
                  parentMethod={this.handleparentMethod}
                  UserId={this.state.UserId}
                  MeetingScheduled={this.state.MeetingScheduled}
                  IsOpen={true}
                />
              )}
            </div>
          </Container>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    //  ApplicationInfo: state.user.ApplicationInfo,
    calendlyToken: state.configuration.calendlyToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // setApplicationInfo: data => dispatch({ type: "SET_APPLICATION_DETAILS", data: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList);
