import React, { Component } from "react";
import HomePageHeader from "../../Home/HomePageHeader";
import HomePageFooter from "../../Home/HomePageFooter";
import { store } from "../../../App.js";
class PageNotFound extends Component {
  render() {
    let loggedIn = store.getState().user.userData !== null;
    return (
      <div className="pagenotfound_wrap">
        {!loggedIn && <HomePageHeader />}
        <div className="pagentfundcont">
          <h1>Page Not Found!</h1>
        </div>
        {!loggedIn && <HomePageFooter />}
      </div>
    );
  }
}
export default PageNotFound;
