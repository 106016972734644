import React, { useEffect, useState, useCallback } from "react";
import AddPointOfContact from "./AddPointOfContact";
import InstitutionalSponsors from "./InstitutionalSponsors";
import InvidualSponsors from "./InvidualSponsors";
import FarmersList from "./FarmersList";
import GovernmentEntities from "./GovernmentEntities";
import LegalServiceProvider from "./LegalServiceProvider";
import AuditServiceProvider from "./AuditServiceProvider";

export default function PartnershipsMasterData() {
  const [activeTab, setActiveTab] = useState("Execution Partner");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="row prosumcordtabscont_wrap partnershipsmasterdata_cont">
        <div className="col-12">
          <div className="tab-buttons">
            <button
              className={activeTab === "Execution Partner" ? "active" : ""}
              onClick={() => handleTabChange("Execution Partner")}
            >
             Point of Contact
            </button>
            <button
              className={activeTab === "Institutional Sponsor" ? "active" : ""}
              onClick={() => handleTabChange("Institutional Sponsor")}
            >
             Partners
            </button>
            {/* <button
              className={activeTab === "Individual Sponsors" ? "active" : ""}
              onClick={() => handleTabChange("Individual Sponsors")}
            >
              Individual Sponsors
            </button>
            <button
              className={activeTab === "Farmer" ? "active" : ""}
              onClick={() => handleTabChange("Farmer")}
            >
              Farmers
            </button>
            <button
              className={activeTab === "Government Entities" ? "active" : ""}
              onClick={() => handleTabChange("Government Entities")}
            >
              Government Entities
            </button>
            <button
              className={activeTab === "Legal Service Provider" ? "active" : ""}
              onClick={() => handleTabChange("Legal Service Provider")}
            >
              Legal Service Provider
            </button>
            <button
              className={activeTab === "Audit Service Provider" ? "active" : ""}
              onClick={() => handleTabChange("Audit Service Provider")}
            >
              Audit Service Provider
            </button> */}
          </div>
        </div>

        <div className="col-12">
          {activeTab === "Execution Partner" && (
            <div className="tabs-content">
              <AddPointOfContact />
            </div>
          )}

          {activeTab === "Institutional Sponsor" && (
            <div className="tabs-content">
              <InstitutionalSponsors />
            </div>
          )}

          {/* {activeTab === "Individual Sponsors" && (
            <div className="tabs-content">
              <InvidualSponsors />
            </div>
          )}

          {activeTab === "Farmer" && (
            <div className="tabs-content">
              <FarmersList />
            </div>
          )}

          {activeTab === "Government Entities" && (
            <div className="tabs-content">
              <GovernmentEntities />
            </div>
          )}

          {activeTab === "Legal Service Provider" && (
            <div className="tabs-content">
              <LegalServiceProvider />
            </div>
          )}

          {activeTab === "Audit Service Provider" && (
            <div className="tabs-content">
              <AuditServiceProvider />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
