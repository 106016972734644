
import React, { Component } from "react";
import { connect } from "react-redux";
import "./left-navbar.css";


class LeftNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  

  render() {
    
    return (
        <div className="left-navbar">
          <button className="dashboard">dashboard</button>
          <button className="tree">tree</button>
          <button className="dashboard">image</button>
          <button className="dashboard">download</button>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
   };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserData: () => dispatch({ type: "CLEAR_USER_DETAILS" })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftNavbar);
