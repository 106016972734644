import React, { Component } from "react";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import { Modal, Button } from "@patternfly/react-core";
import ScrollAnimation from "react-animate-on-scroll";
import EnquiryDetails from "../EnquiryDetails/EnquiryDetails";
import { connect } from "react-redux";
//import NavigationHelpButton from "cesium/Source/Widgets/NavigationHelpButton/NavigationHelpButton";
import MenuBg from "../../assets/menuBg.jpg";
import CloseIcon from "../../assets/close.svg";

class HomePageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      scrolltop: 0,
      isModalOpen: false,
      isShowMSG: false,
      isClassActive: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.mounted = true;
    this.toggleClass = this.toggleClass.bind(this);
  }

  toggleClass = () => {
    this.setState({ isClassActive: !this.state.isClassActive });
  };
  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidMount() {
    this.mounted = true;

    const heightscroll = this.divElement.clientHeight;

    this.setState({ heightscroll });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(prevProps, prevState) {
    const scrollTop2 = window.pageYOffset;

    if (scrollTop2 !== prevState.scrolltop) {
      this.setState({ scrolltop: scrollTop2 });
    }
  }
  handleScroll(event) {
    const scrollTop = window.scrollTo;

    this.setState({
      scrolltop: scrollTop,
    });
  }
  OpenEnquiryDetail = (e) => {
    if (this.mounted) this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  CloseEnquiryData = () => {
    if (this.mounted)
      this.setState({ isModalOpen: !this.state.isModalOpen, isShowMSG: false });
  };
  ShowMSG = () => {
    if (this.mounted) this.setState({ isShowMSG: true });
  };
  handleAtglance = () => {
    if (window.location.pathname === "/") {
      document.querySelector("#sustainability").scrollIntoView({
        behavior: "smooth",
      });
    } else {
      navigate("/");
      setTimeout(() => {
        document.querySelector("#sustainability").scrollIntoView({
          behavior: "smooth",
        });
      }, 500);
    }
  };

  isActiveLink = (link) => {
    return window.location.pathname === link ? "activecurtmenu" : "";
  };

  render() {
    return (
      <header
        ref={(divElement) => (this.divElement = divElement)}
        className={
          this.state.scrolltop > this.state.heightscroll
            ? "header main-nav main-nav-scrolled"
            : "header main-nav"
        }
      >
        <div className="container">
          <Modal
            title=""
            isOpen={this.state.isModalOpen}
            className="mapmodalviewpopup registerpopup"
            onClose={this.CloseEnquiryData}
          >
            {this.props.registrationData && !this.state.isShowMSG ? (
              <div>
                <h1>
                  Your interest is already captured, <br /> someone from
                  Treeties team will get back to you
                </h1>
              </div>
            ) : !this.state.isShowMSG ? (
              <EnquiryDetails
                ShowMSG={this.ShowMSG}
                onClick={this.hideEnquiryDetails}
              />
            ) : (
              <div>
                <h1>
                  Thank you for taking your first step to be Tree-Tied! <br />{" "}
                  We shall shortly connect with you on the way forward!
                </h1>
              </div>
            )}
          </Modal>
          <div className="header_cont">
            <div className="logo">
              <a href="/">
                <img
                  src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/companyLogoWhiteBG.png`}
                  className="img-fluid"
                />
              </a>
            </div>

            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <div className="right-wrapper">
              <a href="/login" className="menu-icon">
                <span className="navicon">Login</span>
              </a>
              <label
                className="menu-icon"
                for="menu-btn"
                onClick={this.state.toggle}
              >
                <span className="navicon" onClick={this.toggleClass}>
                  Menu
                </span>
              </label>
            </div>
            <div
              className={
                this.state.isClassActive ? "menu-links d-flex" : "menu-links"
              }
              style={{ backgroundImage: `url(${MenuBg})` }}
              onClick={() => this.setState({ check: true })}
            >
              <ul className="menu">
                <li
                  className="close-icon bg-clr border-rounded"
                  onClick={this.toggleClass}
                >
                  <img src={CloseIcon} />
                </li>
                <li className={this.isActiveLink("/")}>
                  <a href="/">Home Page</a>
                </li>
                <li className={this.isActiveLink("/aboutus")}>
                  <a href="/aboutus">About Us</a>
                </li>
                <li className={this.isActiveLink("/ourapproach")}>
                  <a href="/ourapproach">Our Approach</a>
                </li>
                <li className={this.isActiveLink("/platform")}>
                  <a href="/platform">Our Platform</a>
                </li>
                <li className={this.isActiveLink("/ourexperience")}>
                  <a href="/ourexperience">Our Experience</a>
                </li>
                <li className={this.isActiveLink("/partners")}>
                  <a href="/partners">Our Partners</a>
                </li>
                {/* <li>
                <a href="/repository">Knowledge Repository</a>
              </li> */}
                <li className={this.isActiveLink("/contactus")}>
                  <a href="/contactus">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageHeader);
