import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import Loader from "../../Common/Loader/Loader";
import { globalHistory } from "@reach/router";
import MainViewer from "../../Common/Map/MainViewer";
import moment from 'moment';
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

class AddTreeType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.IsOpen,
            fields: {},
            errors: {},
            ProjectId: "",
            PlotId: "",
            TreeType: "",
            PlantingDate: "",
            Status: "Alive",
            IsLoader: false,
            IsUpdate: props.AddOrUpdate === "Update" ? true : false,
            ProjectTreeTypeId: "",
            Applicationlist: props.data,
            DropList: [],
            isModalMapOpen: false,
            plotPlanted: props.plotPlanted ? props.plotPlanted : [],
            locationCords: []
        }

        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
            this.setState({
            }, this.props.parentMethod)
        };
    }

    getdataFromChild = (data) => {
        this.setState({ plotPlanted: data });
    }
    onsaveData = () => {
        if (this.state.plotPlanted.length > 0) {
            let plantPlot = this.state.plotPlanted.filter((fl) => fl.plotId == this.state.PlotId);
            this.setState({
                locationCords: plantPlot[0].location,
                isModalMapOpen: !this.state.isModalMapOpen
            }, this.validateForm);
        }
    }
    componentDidMount() {
        this.mounted = true;
        let currentPath = globalHistory.location.pathname;
        let Id = currentPath.split(":")[1].replace("/", "");
        let index = currentPath.split(":")[2].replace("/", "");
        this.setState({ ProjectId: Id, Index: index });
        if (this.state.IsUpdate === true) {
            this.setState({ ProjectTreeTypeId: this.state.Applicationlist.projectTreeTypeId, PlotId: this.state.Applicationlist.plotId, TreeType: this.state.Applicationlist.treeType, PlantingDate: this.state.Applicationlist.plantingDate.split("T")[0], Status: this.state.Applicationlist.status, IsUpdate: true });
        }
        this.GetTreeTypes();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    clearFields = (e) => {
        if (this.mounted) {
            this.setState({
                PlotId: "",
                TreeType: "Select TreeType",
                PlantingDate: "",
                Status: "Alive",
            });
        }
    }

    // this is for radio buttons
    onValueChange = (event) => {
        if (this.mounted) {
            this.setState({
                Status: event.target.value
            });
        }
    }

    mobileSidebarToggle = (e) => {
        document.documentElement.classList.toggle("nav-open");
        e.stopPropagation();
    };

    goBack = (e) => {
        window.history.back();
    }

    // This function do validate the Registration Form
    validateForm = (e) => {
        let fields;
        fields = {
            ProjectId: this.state.ProjectId,
            PlotId: this.state.PlotId,
            TreeType: this.state.TreeType,
            Status: this.state.Status,
            PlantingDate: this.state.PlantingDate
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["ProjectId"]) {
            formIsValid = false;
            errors["ProjectId"] = "Enter project id.";
        }
        if (!fields["PlotId"]) {
            formIsValid = false;
            errors["PlotId"] = "Enter PlotId.";
        }
        if (fields["TreeType"] === "Select Tree Type" || fields["TreeType"] === "") {
            formIsValid = false;
            errors["TreeType"] = "Enter Plot Type.";
        }
        if (!fields["PlantingDate"]) {
            formIsValid = false;
            errors["PlantingDate"] = "Select Planting Date.";
        }
        if (!this.state.locationCords || this.state.locationCords.length === 0) {
            formIsValid = false;
            errors["PlotId"] = "Please plant plot on map.";
        }
        if (this.state.plotPlanted.filter((fl) => fl.plotId == this.state.PlotId && !fl.isNew).length > 0) {
            formIsValid = false;
            errors["PlotId"] = "PlotId already used.";
        }
        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }

    AddTreeTypes = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            if (this.mounted) {
                let data = {
                    ProjectTreeTypeId: this.state.IsUpdate ? this.state.ProjectTreeTypeId : "",
                    ProjectId: this.state.ProjectId,
                    PlotId: this.state.PlotId,
                    TreeType: this.state.TreeType,
                    Status: this.state.Status,
                    PlantingDate: new Date(this.state.PlantingDate),
                    //PlantingDate: this.state.PlantingDate,
                    LocationCords: this.state.locationCords
                }
                let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/AddTreeType`;
                if (this.state.IsUpdate) {
                    uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/UpdateTreeType`;
                }
                let fetchOptions = {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                };
                this.setState({
                    IsLoader: true
                });
                fetch(uri, fetchOptions)
                    .then(response => {
                        return response.json().then(data => {
                            if (data.success) {
                                this.setState({
                                    IsLoader: false,
                                    IsOpen: false,
                                    Applicationlist: []
                                });
                                this.clearFields();
                                this.handleModalToggle();
                                setTimeout(() => {
                                    this.setState({
                                    }, this.props.parentMethod)
                                }, 500);
                            } else {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors,
                                        IsLoader: false
                                    });
                                }
                            }
                        });
                    })
                    .catch(error => {
                        if (this.mounted) {
                            console.log(error);
                            this.setState({
                                errorMsg: error,
                                IsLoader: false
                            })
                        }
                    });
            }
        }
    }

    // This function will set the state of input fields and validate them when user enters the value in input boxes
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (this.mounted) {
            this.setState({ [name]: value }, this.validateForm);
        }
    }
    handleModalMapToggle = () => {
        if (!this.state.PlotId) {
            ToastNotification.ShowWarning("Please enter PloatId first.")
            return;
        }
        this.setState({ isModalMapOpen: !this.state.isModalMapOpen });
    }

    GetTreeTypes = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/TreeType/GetTreeType`;
            let fetchOptions = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions).then((resp) => resp.json())
                .then(data => {
                    if (this.mounted) {
                        this.setState({
                            DropList: JSON.parse(data.List)
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    handleSelectChange = (e) => {
        this.setState({
            TreeType: e.target.value
        });
    }

    render() {
        const { isModalOpen, isModalMapOpen } = this.state;
        const isPlotIdFound = this.state.plotPlanted.filter((fl) => fl.plotId == this.state.PlotId && !fl.isNew).length > 0;
        return (
            <div>
                {this.state.IsLoader ? <Loader /> :
                    <div className="addpro_btn">
                        <div>
                            <Modal title="Add Plot Type"
                                isOpen={isModalOpen}
                                onClose={this.handleModalToggle}
                                className="addproject_modal"
                                actions={[
                                    <Button key="submit" className="modal_btn submit_btn" onClick={this.AddTreeTypes}>Submit</Button>,
                                    <Button key="cancel" className="modal_btn cancel_btn" onClick={this.handleModalToggle}>Cancel</Button>
                                ]}>
                                <div>
                                    <ul className="addproject_form">
                                        <li className="form-group field location_cont" >
                                            <span className="showmapsymbol" onClick={isPlotIdFound ? null : this.handleModalMapToggle}><i className="icon icon-location-pin"></i></span>
                                            <input type="text" id="nooftreesdet" placeholder="Enter Plot Id" name="PlotId" value={this.state.PlotId} onChange={(event) => this.handleUserInput(event)} />
                                            <label htmlFor="nooftreesdet" for="nooftreesdet">Plot Id</label>
                                            <div className="mandatory has-error">{this.state.errors.PlotId}</div>
                                        </li>
                                        <li className="form-group field treetypeselect">
                                            <select onChange={this.handleSelectChange} className="form-control selectdropdown">
                                                <option value="Select TreeType" selected={this.state.TreeType == "Select TreeType"}>Select Plot Type</option>
                                                {this.state.DropList != null && this.state.DropList.map((option) => (
                                                    <option value={option.TreeTypeName} selected={this.state.TreeType == option.TreeTypeName}>{option.TreeTypeName}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="treetype">Enter Plot Type</label>
                                            <div className="mandatory has-error">{this.state.errors.TreeType}</div>
                                        </li>
                                        <li className="form-group typeproject_cont">
                                            <label htmlFor="projecttype">
                                                <sup className="mandatory">*</sup>
                                                Status
                                            </label>
                                            <div className="input-group radiobtns_wrap">
                                                <div className="radio__input">
                                                    <div className="custom-radio">
                                                        <input value="Alive" type="radio" id="existing" name="Status" checked={this.state.Status === "Alive"} onChange={this.onValueChange} />
                                                    </div>
                                                    <label htmlFor="existing" className="radio__label">Alive</label>
                                                </div>
                                                <div className="radio__input">
                                                    <div className="custom-radio">
                                                        <input value="Dead" type="radio" id="new" name="Status" checked={this.state.Status === "Dead"} onChange={this.onValueChange} />
                                                    </div>
                                                    <label htmlFor="new" className="radio__label">Dead</label>
                                                </div>
                                                <div className="mandatory has-error">{this.state.errors.Status}</div>
                                            </div>
                                        </li>
                                        <li className="form-group field">
                                            <input type="date" id="plantingdate" placeholder="Select planting date" name="PlantingDate" value={this.state.PlantingDate} onChange={(event) => this.handleUserInput(event)} max={moment().format("YYYY-MM-DD")} onKeyDown={(e) => e.preventDefault()} />
                                            <label htmlFor="plantingdate">Planting Date</label>
                                            <div className="mandatory has-error">{this.state.errors.PlantingDate}</div>
                                        </li>
                                    </ul>
                                </div>
                            </Modal>
                        </div>
                        <div className="showmap_Popup">
                            <Modal
                                title=""
                                position="top"
                                isOpen={isModalMapOpen}
                                onClose={this.handleModalMapToggle}
                                className="addprojectmap_modal addtreetypemap_modal"
                            >
                                <div className="btn_mainviewer">
                                    <Button key="save" className="modal_btn submit_btn" variant="primary" onClick={this.onsaveData}>
                                        Save
                                        <div className="tooltipdata">Save</div>
                                    </Button>
                                    <MainViewer key="addTreePageView" locationCords={this.props.polycords} cameraPosition={this.props.cameraPosition} isPlantPlot={true} onparentCallbackPlot={this.getdataFromChild} plotId={this.state.PlotId} plotPlanted={this.state.plotPlanted} />
                                </div>
                            </Modal>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

export default connect(
    mapStateToProps
)(AddTreeType);