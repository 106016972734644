import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import config from "../../../utils/config";
import Pagination from "react-js-pagination";
import AddZoneType from "./AddZoneType";
import Loader from "../../Common/Loader/NewLoader";

let PageSize = 5;

class ZoneTypeList extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      List: [],
      count: 0,
      activePage: 1,
      pagesize: 10,
      SearchText: "",
      PageName: "",
      SortBy: "ZoneName",
      SortType: true,
      Up: "active",
      Down: "",
      PageRange: 10,
      userActivePage: 1,
      userTotalCount: 0,
      userPageSize: 7,
      userPageRange: 5,
      isDataLoaded: false,
      formErrors: [],
      IsPopUpStatus: false,
      Applicationlist: [],
      AddOrUpdate: "Add",
      loading: true,
    };
    this.state = this.initialState;
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.Get();
  }

  Get = () => {
    this.setState({ loading: true });
    if (this.mounted) {
      let SearchText = "null";
      if (this.state.SearchText !== "") SearchText = this.state.SearchText;

      let uri = `${config.API_HOST_URL}/api/Ecosystem/GetAllZonetypeData/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}`;
      let fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            this.setState({
              userTotalCount: data.Count,
              List: JSON.parse(data.List),
              count: data.Count,
              PageSize: 2,
              IsPopupDataLoaded: true,
              isDataLoaded: true,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    }
  };

  handlePageChange = (pageNumber) => {
    if (this.mounted) {
      this.setState({ activePage: pageNumber }, function () {
        this.Get();
      });
    }
  };

  handleSearchBySubmit = () => {
    if (this.mounted) {
      this.setState(
        {
          activePage: 1,
        },
        function () {
          this.Get();
        }
      );
    }
  };

  handleChange = (e) => {
    if (this.mounted) {
      this.setState({
        SearchText: e.target.value,
      });
    }
  };

  handleSortClick = (SortBy) => {
    let up = "";
    let down = "active";
    let sortType = this.state.SortType;

    if (SortBy === this.state.SortBy) sortType = !this.state.SortType;

    if (sortType) {
      up = "active";
      down = "";
    }

    this.setState(
      {
        SortBy: SortBy,
        SortType: sortType,
        Up: up,
        Down: down,
      },
      function () {
        this.Get();
      }
    );
  };

  handleSearchByEnter = (e) => {
    if (e.key === "Enter") {
      if (this.mounted) {
        this.setState(
          {
            SearchText: e.target.value,
            activePage: 1,
          },
          function () {
            this.Get();
          }
        );
      }
    }
  };

  handleparentMethod = () => {
    this.setState(this.initialState, function () {
      this.Get();
    });
  };

  handleModalToggle = (item) => {
    if (this.mounted) {
      this.setState(
        {
          IsPopUpStatus: false,
        },
        () => {
          this.setState({
            Applicationlist: item,
            IsPopUpStatus: true,
            AddOrUpdate: item !== null ? "Update" : "Add",
          });
        }
      );
    }
  };

  render() {
    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        innerWidth: 4,
        backgroundColor: "#50b041",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const CustomScrollbars = (props) => (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
      />
    );
    console.log("List", this.state.List);
    const { loading } = this.state;
    return (
      <>
        {loading && (
          <div className="loader-overlay listspages_cont">
            <Loader />
          </div>
        )}
        <div className="headbottom_cont treespeslisthead_cont">
          <ul className="headbottom_content">
            <li className="heading_cont">Zone Type List</li>
            <li className="addpro_btn">
              <a
                onClick={() => {
                  this.handleModalToggle(null);
                }}
              >
                Add Zone Type Details <i className="fa fa-plus"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="growalarm_container applist_cont treetypelist_cont">
          <Container>
            <div className="growalarm_content">
              <div className="commontbl_wrap">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                >
                  <table className="commontbl_cont">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>
                          Zone Name
                          <div
                            className="sortArrow"
                            onClick={() => this.handleSortClick("ZoneName")}
                          >
                            <i
                              className={
                                this.state.SortBy === "ZoneName"
                                  ? `fas fa-sort-up ${this.state.Up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                this.state.SortBy === "ZoneName"
                                  ? `fas fa-sort-down ${this.state.Down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                        <th className="actionth">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tblsearch_cont">
                        <td colSpan="9">
                          <div className="tblsearch">
                            <i className="fa fa-search"></i>
                            <input
                              autoFocus
                              type="text"
                              placeholder="Search by tree species name"
                              onKeyPress={this.handleSearchByEnter}
                              value={this.state.SearchText}
                              onChange={this.handleChange}
                            />
                          </div>
                        </td>
                      </tr>
                      {this.state.List.map((item, index) => (
                        <tr key={item.email}>
                          <td className="idtd">{index + 1}</td>
                          <td className="locationtd treespeciednametd">
                            {item.ZoneName}
                          </td>

                          <td className="action_cont">
                            <a
                              onClick={() => {
                                this.handleModalToggle(item);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CustomScrollbars>
              </div>
              <div className="wrapper">
                {this.state.isDataLoaded ? (
                  this.state.count > 0 ? (
                    this.state.count > this.state.pagesize && (
                      <div className="paginationDiv">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.pagesize}
                          totalItemsCount={this.state.count}
                          pageRangeDisplayed={this.state.PageRange}
                          onChange={this.handlePageChange}
                          nextPageText={<i className="fa fa-angle-right"></i>}
                          prevPageText={<i className="fa fa-angle-left"></i>}
                          lastPageText={
                            <i className="fa fa-angle-double-right"></i>
                          }
                          firstPageText={
                            <i className="fa fa-angle-double-left"></i>
                          }
                        />
                      </div>
                    )
                  ) : (
                    <div>No Results Found</div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
              {this.state.IsPopUpStatus && (
                <AddZoneType
                  parentMethod={this.handleparentMethod}
                  data={this.state.Applicationlist}
                  IsOpen={true}
                  AddOrUpdate={this.state.AddOrUpdate}
                />
              )}
            </div>
          </Container>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    //  ApplicationInfo: state.user.ApplicationInfo,
    calendlyToken: state.configuration.calendlyToken,
  };
}

export default connect(mapStateToProps)(ZoneTypeList);
