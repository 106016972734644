import React, { Component, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { useEffect } from "react";

export default function GroundTruthSampling({
  markerArray,
  center,
  selectedTree,
}) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const getDateInFormat = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    const capitalizedMonth = month.toUpperCase();

    return `${day} ${capitalizedMonth} ${year}`;
  };

  useEffect(() => {
    console.log("selectedTree...", selectedTree);
  }, [selectedTree]);

  return (
    <>
      {markerArray?.length > 0 &&
        markerArray?.map((eachMarker, index) => {
          let cord = {
            lat: eachMarker.geometry?.coordinates?.[1],
            lng: eachMarker.geometry?.coordinates?.[0],
          };
          let selectedTreeData = eachMarker?.properties;
          return (
            <div key={index}>
              <Marker
                position={cord}
                icon={
                  cord.lat === center.lat && cord.lng === center.lng
                    ? require("./markerPin.png")
                    : require("./tree.svg").default
                }
                onMouseOver={() => setSelectedIndex(index)}
                onMouseOut={() => setSelectedIndex(null)}
              >
                {selectedIndex == index && (
                  <InfoWindow position={cord}>
                    <div>
                      <p>
                        Tree Name:{" "}
                        {selectedTreeData?.Madhura_co
                          ? selectedTreeData?.Madhura_co
                          : "NA"}
                      </p>
                      <p>
                        Species:{" "}
                        {selectedTreeData?._5_Species
                          ? selectedTreeData?._5_Species
                          : "NA"}{" "}
                      </p>
                      <p>
                        Height:{" "}
                        {selectedTreeData._16_Height
                          ? Number(selectedTreeData?._16_Height).toFixed(1)
                          : "NA"}
                      </p>
                      <p>
                        Girth:{" "}
                        {selectedTreeData?.["_9_Girth_G"] &&
                        selectedTreeData?.["_9_Girth_G"] != "NaN"
                          ? selectedTreeData?.["_9_Girth_G"]
                          : "NA"}
                      </p>
                      <p>Latitude: {cord.lat}</p>
                      <p>Longitude: {cord.lng}</p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </div>
          );
        })}
    </>
  );
}
