import React, { useEffect, useState, useRef } from "react";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import { globalHistory } from "@reach/router";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import "../Test/tree-ties-add-project-forms1-img.css";
import ToastNotification from "../Common/ToastNotification/ToastNotification";

function TreeTiesAddProjectForms1PDFUpload(props) {
  const [fileNamesControl, setFileNamesControl] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadfile, setUploadfile] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [fileName, setFileName] = useState("");
  //const [activePage, setActivePage] = useState(1);
  //const [pagesize, setPagesize] = useState(6);
  //const [pageRange, setPageRange] = useState(6);

  const initialState = {
    fileName: [],
  };

  const [totalCount, setTotalCount] = useState(0);
  const [layerData, setLayerData] = useState(initialState);

  const [state, setState] = useState({
    uploadedImages: [],
  });

  useEffect(() => {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    setProjectId(Id);
    getFileFromS3(Id);
  }, [projectId]);

  const getFileFromS3 = async (id) => {
    const url = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetFileFromS3/${id}`;
    console.log("url", url);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        // No need for `mode: "no-cors"` if your backend allows CORS
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("FileData", data);

      if (data.success) {
        setFileName(data.fileName); // Store the filename in state
        // You may want to handle the file content (e.g., download it)
      } else {
        console.error("Error fetching file:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    let validFiles = [];
    let invalidFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileType = file.type;
      const acceptedTypes = ["application/pdf"]; // Only accept PDF files

      if (acceptedTypes.includes(fileType) && file.size <= 2 * 1024 * 1024) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    }

    if (invalidFiles.length > 0) {
      ToastNotification.ShowError(
        `File(s) ${invalidFiles.join(
          ", "
        )} is not a valid file type or larger than 2MB`
      );
    }

    if (validFiles.length > 0) {
      setUploadfile((prevFiles = []) => [...prevFiles, ...validFiles]);
      setLayerData((prevData) => ({
        ...prevData,
        fileName: [
          ...prevData.fileName,
          ...validFiles.map((file) => file.name),
        ],
      }));
      setFileNamesControl((prevFileNames = []) => [
        ...prevFileNames,
        ...validFiles.map((file) => file.name),
      ]);
    }
  };

  const UploadProjectImages = async () => {
    const formData = new FormData();
    uploadfile.forEach((file) => {
      formData.append("FormFiles", file);
    });

    const uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/UploadSinglePDFDocument/${projectId}`;
    const fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        setUploadfile([]);
        setFileNamesControl([]);
        ToastNotification.ShowSuccess("File(s) uploaded successfully");
        window.location.reload();
      } else {
        ToastNotification.ShowError(data.message);
      }
    } catch (error) {
      console.log("Request failed", error);
      ToastNotification.ShowError("Failed to upload file(s)");
    }
  };

  const handleSubmitClick = (event) => {
    event.preventDefault();
    if (uploadfile.length === 0) {
      ToastNotification.ShowError("Please select an image to upload");
      return;
    }
    UploadProjectImages();
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/Projects/DeleteOtherReportsFromS3/${projectId}`,
        {
          method: "POST",
          headers: {
            WebsiteGuid: props.websiteGUID,
            LanguageGuid: props.languageGUID,
            Authorization: `Bearer ${props.token}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        setFileName("");
        ToastNotification.ShowSuccess("File(s) deleted successfully");
      }
    } catch (error) {
      console.log("Request failed", error);
    }
  };

  const handleRemoveFile = (index) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFileNamesControl((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames.splice(index, 1);
      return newFileNames;
    });

    setUploadfile((prevUploadfile) => {
      const newUploadfile = [...prevUploadfile];
      newUploadfile.splice(index, 1);
      return newUploadfile;
    });

    setLayerData((prevData) => {
      const newFileName = [...prevData.fileName];
      newFileName.splice(index, 1);
      return { ...prevData, fileName: newFileName };
    });
  };

  // const GetProjectImages = async (projectId) => {
  //   let url = `${config.API_HOST_URL}/api/Ecosystem/GetFilterProjectsImages/${projectId}/${activePage}/${pagesize}`;
  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     timeout: 5000,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       return response.text();
  //     })
  //     .then((text) => {
  //       if (!text) {
  //         throw new Error("Response body is empty");
  //       }
  //       return JSON.parse(text);
  //     })
  //     .then((data) => {
  //       let responseData = data?.data;
  //       let ImagesNames = responseData?.uploadedImages;
  //       let totalCount = responseData?.totalCount;
  //       setTotalCount(totalCount);
  //       setUploadedImages(ImagesNames);
  //       setState({ uploadedImages: ImagesNames });
  //     })
  //     .catch((error) => {
  //       console.log("Error in request", error);
  //     });
  // };

  // const deleteImage = async (imageId) => {
  //   try {
  //     const response = await fetch(
  //       `${config.API_HOST_URL}/api/Projects/DeleteProjectImage/${projectId}/${imageId}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           WebsiteGuid: props.websiteGUID,
  //           LanguageGuid: props.languageGUID,
  //           Authorization: `Bearer ${props.token}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();
  //     if (data.success) {
  //       ToastNotification.ShowSuccess("File(s) deleted successfully");

  //       const index = uploadedImages.findIndex((image) => image.id === imageId);
  //       setUploadedImages((prevImages) =>
  //         prevImages.filter((image, i) => i !== index)
  //       );
  //       GetProjectImages(projectId);
  //     }
  //   } catch (error) {
  //     console.log("Request failed", error);
  //   }
  // };

  const Back = () => {
    navigate("/TreeTiesAddProjectForms1Lay/:" + projectId, {
      state: { activeMenu: 10 },
    });
  };

  return (
    <>
      <div className="treeties-addproject-forms1-loc ecosyscom_cont">
        {/* <Header/> */}
        <div className="main-content-area6">
          {/* <LeftNavbar/>  */}
          <div className="middle-content-area6">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont exoloctab_cont">
              <LeftTabMenuContainer
                activeMenu={props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont addproformloc_cont">
                <div class="ecosysprosimguplds_contianer">
                  <ul className="addproject_form">
                    <form>
                      <li className="form-group">
                        <label for="forinventory">Other Report Upload</label>
                      </li>
                      {fileName && (
                        <button
                          type="button"
                          className="submit_btn removeBtn"
                          onClick={handleDelete}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24px"
                            height="24px"
                          >
                            <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                          </svg>
                          {fileName}
                        </button>
                      )}
                      <li className="form-group inventory_cont">
                        <div className="input-group divUploadFile">
                          <div className="qq-upload-drop-area">
                            <span>Drag and drop files here or Upload</span>
                          </div>
                          <div className="qq-upload-button">
                            <div className="uploadfield_icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                              >
                                <path
                                  d="M22.9632 34.1135V17.1344L17.5465 22.551L14.6299 19.5302L25.0465 9.11353L35.4632 19.5302L32.5465 22.551L27.1299 17.1344V34.1135H22.9632ZM12.5465 42.4469C11.4007 42.4469 10.4202 42.0392 9.60488 41.2239C8.7896 40.4087 8.38127 39.4274 8.37988 38.2802V32.0302H12.5465V38.2802H37.5465V32.0302H41.7132V38.2802C41.7132 39.426 41.3056 40.4073 40.4903 41.2239C39.675 42.0406 38.6938 42.4482 37.5465 42.4469H12.5465Z"
                                  fill="#727272"
                                />
                              </svg>
                            </div>
                            <div className="prolvlimgs_updtxt">
                              Drag and drop files here or Upload
                              <span className="redMandat">
                                File: PDF | Size: 2 MB
                              </span>
                            </div>
                            <div className="prolvlimgs_uploadbtn">Upload</div>
                          </div>
                          <input
                            className="text-input-field-319"
                            type="file"
                            id="fileInput"
                            name="fileName"
                            accept=".jpg, .jpeg, .png"
                            multiple
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="proimgsupld_list">
                          {fileNamesControl.map((name, index) => (
                            <div key={index} className="file-name-container">
                              <div className="file-name">
                                <div className="uploadedfile_icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                  >
                                    <path
                                      d="M20 23.3147H4V2.3147H15L20 7.3147V23.3147Z"
                                      fill="#90CAF9"
                                    />
                                    <path
                                      d="M19.25 7.8147H14.5V3.0647L19.25 7.8147Z"
                                      fill="#E1F5FE"
                                    />
                                    <path
                                      d="M10.5 12.3147L7 17.3147H14L10.5 12.3147Z"
                                      fill="#1565C0"
                                    />
                                    <path
                                      d="M14 14.0146L11.5 17.3146H16.5L14 14.0146Z"
                                      fill="#1976D2"
                                    />
                                    <path
                                      d="M15 13.0647C15 13.2636 15.079 13.4544 15.2197 13.595C15.3603 13.7357 15.5511 13.8147 15.75 13.8147C15.9489 13.8147 16.1397 13.7357 16.2803 13.595C16.421 13.4544 16.5 13.2636 16.5 13.0647C16.5 12.8658 16.421 12.675 16.2803 12.5344C16.1397 12.3937 15.9489 12.3147 15.75 12.3147C15.5511 12.3147 15.3603 12.3937 15.2197 12.5344C15.079 12.675 15 12.8658 15 13.0647Z"
                                      fill="#1976D2"
                                    />
                                  </svg>
                                </div>
                                {name}
                              </div>
                              <button
                                className="remove-file-button"
                                onClick={(event) =>
                                  handleRemoveFile(index)(event)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M9.99967 10.256L5.83301 6.08936M9.99967 10.256L14.1663 14.4227M9.99967 10.256L14.1663 6.08936M9.99967 10.256L5.83301 14.4227"
                                    stroke="#7E7E7E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </li>
                      <li className="form-group addpro_btn">
                        <div className="prolvlimgsbtn_wrap">
                          <button
                            type="button"
                            className="submit_btn"
                            onClick={handleSubmitClick}
                          >
                            Add
                          </button>
                        </div>
                      </li>
                    </form>
                  </ul>

                  {/* //new design  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeTiesAddProjectForms1PDFUpload;
