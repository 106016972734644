import React, { Component } from "react";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import { Modal, ModalVariant, Button } from "@patternfly/react-core";
import Payment from "../Payment/Payment";
import Select from "react-select";
import { globalHistory } from "@reach/router";
import Sequestration from "../Common/Sequestrationtreevalue.js";
import { connect } from "react-redux";
import _ from "lodash";
import Calcao_Treeties from "../../assets/calao_treeties.png";
import Calcao_Goldenmile from "../../assets/calcao_goldenmile.png";
import PowerweaveCalcoAo from "../../assets/Powerweave_calcaofooter.png";

class Co2CalculatorAO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      WebsiteGuid: this.props.WebsiteGuid,
      WebsiteInfo: this.props.WebsiteInfo,
      errors: [],
      Make: "",
      Model: "",
      Series: "",
      FuelType: "",
      MileageByMakeModel: 0,
      MakeModelDistance: "",
      Mileage: "",
      Distance: "",
      MakeList: [],
      ModelList: [],
      FuelTypeList: [],
      SerieslList: [],
      SpecificationList: [],
      MyDistanceList: [],
      ShowSeriesDropdown: false,
      ShowDistanceType: false,
      Co2Emission: 0,
      CarbonEmissionInTons: 0,
      NuetrilizationCost: 0,
      FuelProtectionConstant: 0,
      ManufacturingConstant: 0,
      DistanceType: "",
      Year5Co2Emission: 15.4,
      PerTreePrice: 0,
      ShowChild: true,
      selectedOption: null,
      selectedNgoOption: null,
      NgoId: "",
      WebsiteGuid: "",
      WebsiteInfo: {},
      WebsiteImagePath: "",
      NgoName: "",
      isModalOpen: false,
      VehicleType: null,
      VehicleList: [],
      PayLoadInKG: "",
      Manufacturing_Year: "",
      MakeModelDestination: "",
      MakeModelPackageWeight: "",
      MakeModelSource: "",
      GoogleMapApi: 0,
      Enginetype: "",
      EngineFuelType: "",
      EnginetypeList: [],
      Enginetypedistance: "",
      MileageByMakeEngineType: "",
      TreeCountPlant: 0,
      Co2EmissionPerLiterEngineType: "",
      name: "",
      emailid: "",
      mobileno: "",
      TotalDistance: "", //Total distance paas in props
      pancard: "",
      address: "",
      DdlAmtandNooftree: "",
      TxtAmtNooftree: "",
      Type: "",
      IsdisableVehicleType: false,
      IsdisableEngineType: false,
      IsdisableJourney: false,
      Isdisableother: false,
      CountryDetailList: [],
      countryPhoneCode: "+91",
      showCountryCodedll: "hide",
      searchcountyrcode: "",
      CCEngineValue: "",
      CarModel: "",
      GHGEmission: 0,
      CCEngineList: [],
      CCEngineUniqList: [],
      EngineFuelList: [],
      CarEmissionByCCList: [],
      CarEmissionByCCUniq: [],
      NoPassengerIncar: 5,
      NoPassengerCar: 5,
      CCEngineVal: "",
      activecalcaotab: 2,
      ShareAmount: 0,
      paymentShareOption: 1,
    };

    this.mounted = true;
    this.handleModalToggle = () => {
      this.setState(({ isModalOpen }) => ({
        isModalOpen: !isModalOpen,
      }));
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (this.props.WebsiteInfo !== prevProps.WebsiteInfo) {
      this.setState({ WebsiteInfo: this.props.WebsiteInfo });
    }
    if (this.props.WebsiteGuid !== prevProps.WebsiteGuid) {
      this.setState({ WebsiteGuid: this.props.WebsiteGuid });
    }
  }
  componentDidMount() {
    this.setState({ WebsiteGuid: this.props.WebsiteGuid }, () =>
      this.CallAllMethods()
    );
    this.updateYear();
  }

  updateYear() {
    if (this.mounted) {
      this.setState({ year: new Date().getFullYear() });
    }
  }

  CountryCodetextclick = (e) => {
    this.setState({ countryPhoneCode: e.target.value });
  };

  GetCountryCode = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetCountryCode`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            if (data != null) {
              let Records = JSON.parse(data.CountryCode);
              this.setState({
                CountryDetailList: Records,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  OnChangeVehicleShare = (e) => {
    if (e.target.value !== "Select") {
      this.setState({ paymentShareOption: e.target.value });
      let Final_Cost = this.state.NuetrilizationCost * e.target.value;
      this.setState({ ShareAmount: Final_Cost });
    }
  };

  uniqueArray = (objects, uniqueBy, keepFirst = true) => {
    return Array.from(
      objects
        .reduce((map, e) => {
          let key = uniqueBy
            .map((key) => [e[key], typeof e[key]])
            .flat()
            .join("-");
          if (keepFirst && map.has(key)) return map;
          return map.set(key, e);
        }, new Map())
        .values()
    );
  };

  setMilageValue = (item) => {
    if (item?.MixedDrivingFuelConsumption === null) {
      if (item?.CityDrivingFuelConsumption === null) {
        if (item?.HighwayDrivingFuelConsumption !== null) {
          this.setState({
            MileageByMakeModel: item?.HighwayDrivingFuelConsumption,
          });
        } else {
          this.setState({
            MileageByMakeModel: 8, //Default value
          });
        }
      } else {
        this.setState({
          MileageByMakeModel: item?.CityDrivingFuelConsumption,
        });
      }
    } else {
      this.setState({
        MileageByMakeModel: item?.MixedDrivingFuelConsumption,
      });
    }
  };

  handleCCValueUsingCarModels = (CarModel) => {
    if (CarModel !== null) {
      if (CarModel.MakeModelSeries.length > 0) {
        this.setMilageValue(CarModel.MakeModelSeries?.[0]);
      }
    }
  };

  // This function will set the state of input fields and validate them when user enters the value in input boxes
  handleModelDdlChange = (e) => {
    if (this.mounted) {
      if (e.target.value === "Select Model") {
        this.setState(
          {
            Model: "",
            SerieslList: [],
            MyDistanceList: [],
            DistanceType: "",
            NuetrilizationCost: 0,
            CarbonEmissionInTons: 0,
            // ShowChild: false,
            MakeModelSource: "",
            MakeModelDestination: "",
            MakeModelPackageWeight: "",
            MakeModelDistance: "",
          },
          () => this.setState({ Series: "Select Series", DistanceType: "" })
        );
      } else {
        const value = JSON.parse(e.target.value);
        if (value.MakeModelSeries.length > 0) {
          this.setState(
            {
              SerieslList: [],
              Model: value.Model,
              MyDistanceList: [],
              NuetrilizationCost: 0,
              CarbonEmissionInTons: 0,
              // ShowChild: false,
              MakeModelSource: "",
              MakeModelDestination: "",
              MakeModelPackageWeight: "",
              MakeModelDistance: "",
            },
            () =>
              this.setState({
                SerieslList: value.MakeModelSeries,
                Series: "Select Series",
                DistanceType: "",
              }),
            this.ClearOutputValue(),
            this.handleCCValueUsingCarModels(value)
          );
        } else {
          this.setState({
            SerieslList: [],
            ShowSeriesDropdown: false,
            Series: "Select Series",
            DistanceType: "",
            NuetrilizationCost: 0,
            CarbonEmissionInTons: 0,
            //ShowChild: false,
          });
        }
      }
    }
  };

  handleFuelTypeChange = (e) => {
    if (this.mounted) {
      if (e.target.value === "Select Fuel") {
        this.setState({ Co2EmissionPerLiter: 0, FuelType: "" });
      } else {
        const value = JSON.parse(e.target.value);
        if (this.mounted) {
          this.setState({
            Co2EmissionPerLiter: parseFloat(value.Co2EmissionPerLiter),
            FuelType: value.FuelType,
          });
        }
      }
    }
  };

  DdlAmtandNooftree = (e) => {
    if (this.mounted) {
      this.setState({ DdlAmtandNooftree: e.target.value, TxtAmtNooftree: "" });
    }
  };

  handleCarCCEngine = (e) => {
    if (e.target.value !== "Select CC Engine") {
      this.setState({ CCEngineValue: e.target.value });
      let carEmissionByCCList = this.state.CarEmissionByCCList;
      carEmissionByCCList.map((val, index) => {
        if (
          val.EngineCC === e.target.value &&
          val.Fuel === this.state.EngineFuelType
        ) {
          this.setState({ Co2Emission: val.EmissionFactor });
        }
      });
    }
  };

  handleEngineTypeChange = (e) => {
    if (this.mounted) {
      if (e.target.value === "Select Engine Type") {
        this.setState({
          Enginetype: "",
          MileageByMakeEngineType: "",
        });
      } else {
        const value = JSON.parse(e.target.value);
        if (this.mounted) {
          this.setState({
            Enginetype: value.EngineType,
            MileageByMakeEngineType: value.Mileage,
          });
        }
      }
    }
  };

  checkFuelTypeMatchValue = (EngineType) => {
    let SingleArrValue = this.state.FuelTypeList.filter((e) =>
      e.MappingValues.split(",").find((element) => element === EngineType)
    );
    if (SingleArrValue.length > 0) {
      this.setState({
        Co2EmissionPerLiter: SingleArrValue[0].Co2EmissionPerLiter,
      });
    }
  };

  handleSeriesDdlChange = (e) => {
    if (e.target.value === "Select Series") {
      this.setState({
        Series: "",
        MyDistanceList: [],
        DistanceType: "",
        NuetrilizationCost: 0,
        CarbonEmissionInTons: 0,
        // ShowChild: false,
        MakeModelSource: "",
        MakeModelDestination: "",
        MakeModelPackageWeight: "",
        MakeModelDistance: "",
      });
    } else {
      if (this.mounted) {
        const seriesOption = JSON.parse(e.target.value);
        if (this.state.VehicleType !== "1") {
          this.setState(
            {
              NuetrilizationCost: 0,
              CarbonEmissionInTons: 0,
              // ShowChild: false,
            },
            () =>
              this.setState({
                MileageByMakeModel: seriesOption.MixedDrivingFuelConsumption,
              })
          );
        }
        if (seriesOption.EngineType !== null) {
          this.setState(
            {
              MyDistanceList: [],
              Series: seriesOption.SeariesName,
              EngineType: seriesOption.EngineType,
              DistanceType: "",
              MileageByMakeModel: 0,
              NuetrilizationCost: 0,
              CarbonEmissionInTons: 0,
              //ShowChild: false,
              PayLoadInKG: seriesOption.PayLoadInKG,
              Manufacturing_Year: seriesOption.Manufacturing_Year,
            },
            () => this.GenerateDropdown(seriesOption)
          );
        }
      }
    }
  };

  GenerateDropdown = (seriesOption) => {
    let MyDistanceList = [];
    if (
      seriesOption.CityDrivingFuelConsumption !== null ||
      seriesOption.HighwayDrivingFuelConsumption !== null ||
      seriesOption.MixedDrivingFuelConsumption !== null
    ) {
      if (seriesOption.CityDrivingFuelConsumption !== null) {
        MyDistanceList.push({
          name: "City driving",
          value: seriesOption.CityDrivingFuelConsumption,
        });
      }
      if (seriesOption.HighwayDrivingFuelConsumption !== null) {
        MyDistanceList.push({
          name: "Highway driving",
          value: seriesOption.HighwayDrivingFuelConsumption,
        });
      }
      if (seriesOption.MixedDrivingFuelConsumption !== null) {
        MyDistanceList.push({
          name: "City and Highway (Mixed) driving",
          value: seriesOption.MixedDrivingFuelConsumption,
        });
      }
      this.setState({ ShowDistanceType: true, MyDistanceList: MyDistanceList });
    }
    this.checkFuelTypeMatchValue(seriesOption.EngineType);
  };

  CallAllMethods = () => {
    this.GetWebsiteData();
    this.GetFuelTypes();
    this.GetEngineType();
    this.GetGlobalSettings("CO2 emissions calculation");
    this.GetEngineCC();
    this.GetCountryCode();
  };

  GetWebsiteData = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Websites/GetWebsiteData`;
      let param = {
        WebsiteGuid: this.state.WebsiteGuid,
      };
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(param),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            let Records = JSON.parse(data.WebsiteInfo);
            if (Records) {
              this.setState(
                {
                  WebsiteInfo: Records,
                  PrimaryColor: Records.PrimaryColor,
                  WebsiteImagePath: Records.ImagePath,
                },
                function () {
                  // Active radio button according to feature
                  if (this.state.WebsiteInfo.IsShowVehicleType === true) {
                    this.setState({ IsdisableVehicleType: true });
                    this.GetVehicleType();
                  } else if (this.state.WebsiteInfo.IsShowEngineType === true) {
                    this.setState({ IsdisableEngineType: true });
                  } else if (this.state.WebsiteInfo.IsShowJourney === true) {
                    this.setState({ IsdisableJourney: true });
                  } else {
                    this.setState({ Isdisableother: true });
                  }
                }
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  GetEngineCC = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetEngineCC`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            let Records = JSON.parse(data.CarEmissionByCC);
            let uniqCC = _.uniq(_.map(Records, "EngineCC"));
            this.setState({
              CarEmissionByCCList: Records,
              CarEmissionByCCUniq: uniqCC,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleJourneyCCEngine = (e) => {
    if (e.target.value !== "Select CC Engine") {
      this.setState({ CCEngineVal: e.target.value });
      let carEmissionByCCList = this.state.CarEmissionByCCList;
      carEmissionByCCList.map((val, index) => {
        if (
          val.EngineCC === e.target.value &&
          val.Fuel === this.state.FuelType
        ) {
          this.setState({ Co2Emission: val.EmissionFactor });
        }
      });
    }
  };

  handleJourneyFuelType = (e) => {
    if (e.target.value !== "") {
      this.setState({ FuelType: e.target.value });
      let carEmissionByCCList = this.state.CarEmissionByCCList;
      carEmissionByCCList.map((val, index) => {
        if (
          val.EngineCC === this.state.CCEngineVal &&
          val.Fuel === e.target.value
        ) {
          this.setState({ Co2Emission: val.EmissionFactor });
        }
      });
    }
  };

  handleCarFuelTypeChange = (e) => {
    if (e.target.value !== "Select Fuel") {
      this.setState({ EngineFuelType: e.target.value });
      let carEmissionByCCList = this.state.CarEmissionByCCList;
      carEmissionByCCList.map((val, index) => {
        if (
          val.EngineCC === this.state.CCEngineValue &&
          val.Fuel === e.target.value
        ) {
          this.setState({ Co2Emission: val.EmissionFactor });
        }
      });
    }
  };

  GetFuelTypes = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetFuelTypes`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            let Records = JSON.parse(data.FuelTypeList);
            this.setState({
              FuelTypeList: Records,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  GetEngineType = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetEngineType`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            let Records = JSON.parse(data.EnginetypeList);
            this.setState({
              EnginetypeList: Records,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  GetVehicalTypeMakes = () => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetVehicalTypeMakes`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ VehicleType: this.state.VehicleType }),
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            this.setState({
              MakeList: JSON.parse(data.MakeList),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  GetVehicleType = () => {
    if (this.mounted) {
      let param = {
        SupportedVehicleType: this.state.WebsiteInfo.SupportedVehicleType,
      };
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetVehicleMaster`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(param),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            this.setState(
              {
                VehicleList: JSON.parse(data.vehiclelist),
              },
              () => {
                if (
                  this.state.VehicleList &&
                  this.state.VehicleList.length == 1
                ) {
                  this.handleVehicleTypeChange(this.state.VehicleList[0].value);
                }
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  GetModelsUsingMake = (MakeName) => {
    if (this.mounted) {
      let param = {
        Make: MakeName,
        VehicleType: this.state.VehicleType,
      };
      let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetModelsUsingMake`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(param),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          if (this.mounted) {
            let Model = JSON.parse(data.ModelList);
            this.setState({
              ModelList: Model,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // This function will set the state of input fields and validate them when user enters the value in input boxes
  handleUserInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const regx = /^[0-9]*(\.[0-9]{0,2})?$/;
    const regmobno = /^[0-9\b]+$/;
    if (this.mounted) {
      if (e.target.value === "" || regx.test(e.target.value)) {
        //this.setState({ [name]: e.target.value });
        if (name === "Mileage") {
          this.setState({
            [name]: e.target.value,
            MileageByMakeModel: e.target.value,
          });
        }
        if (name == "Enginetypedistance") {
          this.setState({
            [name]: e.target.value,
            Enginetypedistance: e.target.value,
          });
        }
        if (name == "mobileno") {
          if (e.target.value === "" || regmobno.test(e.target.value))
            this.setState({ [name]: e.target.value, mobileno: e.target.value });
        }
        if (name == "TxtAmtNooftree") {
          this.setState({
            [name]: Math.round(e.target.value),
            TxtAmtNooftree: Math.round(e.target.value),
          });
        }
        if (name == "MakeModelPackageWeight") {
          this.setState({
            [name]: e.target.value,
            MakeModelPackageWeight: e.target.value,
          });
        }
        if (name == "MakeModelDistance") {
          this.setState({
            [name]: e.target.value,
            MakeModelDistance: e.target.value,
          });
        }
        if (name == "Distance") {
          this.setState({ [name]: e.target.value, Distance: e.target.value });
        }
      }
      if (name === "MakeModelSource") {
        this.setState({ MakeModelSource: e.target.value });
      }
      if (name == "MakeModelDestination") {
        this.setState({ MakeModelDestination: e.target.value });
      }
      if (name == "name") {
        this.setState({ name: e.target.value });
      }
      if (name == "emailid") {
        this.setState({ emailid: e.target.value });
      }
      if (name == "pancard") {
        this.setState({ pancard: e.target.value });
      }
      if (name == "address") {
        this.setState({ address: e.target.value });
      }
    }
  };

  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return parseFloat(myArray.value);
    }
    //alert(this.state.GoogleMapApi)
  };

  GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            if (this.mounted) {
              this.setState({
                FuelProtectionConstant: this.MapKeyInArray(
                  responseData,
                  "Fuel Protection Constant"
                ),
              });
              this.setState({
                ManufacturingConstant: this.MapKeyInArray(
                  responseData,
                  "Manufacturing constant"
                ),
              });
              this.setState({
                Year5Co2Emission: this.MapKeyInArray(
                  responseData,
                  "5 Year Co2 Emission"
                ),
              });
              this.setState({
                GoogleMapApi: this.MapKeyInArray(
                  responseData,
                  "Google Map Api"
                ),
              });
            }
          } else {
            let errors = {};
            errors["message"] = data.message;
            if (this.mounted) {
              this.setState({ errors: errors });
            }
          }
        });
      })
      .catch((error) => {
        if (this.mounted) {
          console.log(error);
          this.setState({
            errorMsg: error,
            IsLoader: false,
          });
        }
      });
  };

  calculateCo2Footprint = (e) => {
    try {
      e.preventDefault();
      if (this.validateForm()) {
        this.setState({ ShareAmount: 0 });
        this.setState({ paymentShareOption: 1 });
        if (this.state.IsdisableVehicleType) {
          if (this.state.MakeModelDistance !== "") {
            var Final_GHG_Emission =
              this.state.Co2Emission *
              (this.state.MakeModelDistance / this.state.MileageByMakeModel);

            Final_GHG_Emission = Final_GHG_Emission.toFixed(2);
            this.setState({ GHGEmission: Final_GHG_Emission });

            var TotNoTreesPlantedCarbonQSques = parseFloat(
              (Final_GHG_Emission / 2.7).toFixed(2)
            );

            TotNoTreesPlantedCarbonQSques = Math.ceil(
              TotNoTreesPlantedCarbonQSques
            );

            this.setState({ TreeCountPlant: TotNoTreesPlantedCarbonQSques });

            var TotAmtDonatedPlantingTrees =
              TotNoTreesPlantedCarbonQSques * 200;
            this.setState({ NuetrilizationCost: TotAmtDonatedPlantingTrees });
          }
        }

        if (this.state.Isdisableother) {
          if (this.state.DdlAmtandNooftree == "Number of trees to plant") {
            let Price = parseInt(
              parseFloat(this.state.TxtAmtNooftree) *
                parseFloat(this.state.WebsiteInfo.PerTreePrice)
            );
            let CarbonEmissionInTons = parseInt(
              parseFloat(this.state.TxtAmtNooftree) *
                parseFloat(this.state.Year5Co2Emission)
            );
            this.setState({
              CarbonEmissionInTons: CarbonEmissionInTons,
              NuetrilizationCost: Price,
              // ShowChild: false,
              TreeCountPlant: this.state.TxtAmtNooftree,
            });
          }
          if (this.state.DdlAmtandNooftree == "Donation amount") {
            let TreeCounts = parseInt(
              parseFloat(this.state.TxtAmtNooftree) /
                parseFloat(this.state.WebsiteInfo.PerTreePrice)
            );
            let CarbonEmissionInTons =
              TreeCounts * parseInt(parseFloat(this.state.Year5Co2Emission));

            this.setState({
              CarbonEmissionInTons: CarbonEmissionInTons,
              NuetrilizationCost: this.state.TxtAmtNooftree,
              //ShowChild: false,
              TreeCountPlant: TreeCounts,
            });
          }
        }

        if (this.state.IsdisableJourney) {
          if (this.state.Distance !== "") {
            var Final_GHG_Emission = parseFloat(
              (
                this.state.Co2Emission *
                (this.state.Distance / this.state.Mileage)
              ).toFixed(2)
            );
            this.setState({ GHGEmission: Final_GHG_Emission });
            var TotNoTreesPlantedCarbonQSques = parseFloat(
              (Final_GHG_Emission / 2.7).toFixed(2)
            );
            TotNoTreesPlantedCarbonQSques = Math.ceil(
              TotNoTreesPlantedCarbonQSques
            );
            this.setState({ TreeCountPlant: TotNoTreesPlantedCarbonQSques });
            var TotAmtDonatedPlantingTrees =
              TotNoTreesPlantedCarbonQSques * 200;
            this.setState({ NuetrilizationCost: TotAmtDonatedPlantingTrees });
          }
        }
        setTimeout(() => {
          this.setState({ ShowChild: true });
        }, 100);
      }
      // var delayInMilliseconds = 500;
      // setTimeout(function () {
      //   window.scroll({
      //     top: 700,
      //     behavior: "smooth",
      //   });
      // }, delayInMilliseconds);
    } catch (err) {}
  };

  // This function do validate the Registration Form
  validateForm = (e) => {
    let fields;
    fields = {
      VehicleType: this.state.VehicleTypesss,
      Make: this.state.Make,
      Model: this.state.Model,
      Series: this.state.Series,
      Distance: this.state.Distance,
      MakeModelDistance: this.state.MakeModelDistance,
      Mileage: this.state.Mileage,
      FuelType: this.state.FuelType,
      DistanceType: this.state.DistanceType,
      MileageByMakeModel: this.state.MileageByMakeModel,
      MakeModelDestination: this.state.MakeModelDestination,
      MakeModelSource: this.state.MakeModelSource,
      Enginetype: this.state.Enginetype,
      Enginetypedistance: this.state.Enginetypedistance,
      EngineFuelType: this.state.EngineFuelType,
      MileageByMakeEngineType: this.state.MileageByMakeEngineType,
      DdlAmtandNooftree: this.state.DdlAmtandNooftree,
      TxtAmtNooftree: this.state.TxtAmtNooftree,
      MakeModelPackageWeight: this.state.MakeModelPackageWeight,
      CCEngineValue: this.state.CCEngineValue,
      CCEngineVal: this.state.CCEngineVal,
    };
    let errors = {};
    let formIsValid = true;

    if (this.state.IsdisableVehicleType) {
      if (fields["Make"] === "Select Make" || fields["Make"] === "") {
        formIsValid = false;
        errors["Make"] = "Please select make.";
      }
      if (fields["Model"] === "Select Model" || fields["Model"] === "") {
        formIsValid = false;
        errors["Model"] = "Please select model.";
      }
      if (!fields["MakeModelDistance"] || fields["MakeModelDistance"] == ".") {
        formIsValid = false;
        errors["MakeModelDistance"] = "Please enter a distance.";
      }
      if (fields["CCEngineValue"] == "") {
        formIsValid = false;
        errors["CCEngineValue"] = "Please select CC engine value.";
      }
      if (fields["EngineFuelType"] == "") {
        formIsValid = false;
        errors["EngineFuelType"] = "Please select fuel type.";
      }
    }
    if (this.state.IsdisableJourney === true) {
      if (this.state.WebsiteInfo.IsShowJourney) {
        if (!fields["Mileage"]) {
          formIsValid = false;
          errors["Mileage"] = "Please enter mileage.";
        }
        if (
          parseInt(fields["Mileage"]) <= 0 ||
          parseInt(fields["Mileage"]) > 15
        ) {
          formIsValid = false;
          errors["Mileage"] = "Please enter mileage value between 1 and 15";
        }
        if (fields["Mileage"] == ".") {
          formIsValid = false;
          errors["Mileage"] = "Please enter valid mileage.";
        }
        if (!fields["FuelType"]) {
          formIsValid = false;
          errors["FuelType"] = "Please select fuel type.";
        }
        if (!fields["Distance"]) {
          formIsValid = false;
          errors["Distance"] = "Please enter a distance.";
        }
        if (fields["Distance"] == ".") {
          formIsValid = false;
          errors["Distance"] = "Please enter valid distance.";
        }
        if (fields["CCEngineVal"] == "") {
          formIsValid = false;
          errors["CCEngineVal"] = "Please select CC engine value.";
        }
      }
    }
    if (this.state.Isdisableother) {
      if (this.state.WebsiteInfo.DonationAmtNooftreeType) {
        if (!fields["DdlAmtandNooftree"]) {
          formIsValid = false;
          errors["DdlAmtandNooftree"] = "Please select.";
        }
        if (!fields["TxtAmtNooftree"]) {
          formIsValid = false;
          errors["TxtAmtNooftree"] = "Please enter value.";
        }
        if (fields["TxtAmtNooftree"] == ".") {
          formIsValid = false;
          errors["TxtAmtNooftree"] = "Please enter valid value.";
        }
      }
    }
    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };

  ClearForm = () => {
    if (this.state.VehicleList && this.state.VehicleList.length > 1) {
      this.setState({
        VehicleType: "",
      });
    }
    this.setState(
      {
        Make: "",
        Model: "",
        Series: "",
        DistanceType: "",
        MakeModelDistance: "",
        MakeModelSource: "",
        MakeModelDestination: "",
        MakeModelPackageWeight: "",
        PayLoadInKG: "",
        Mileage: "",
        FuelType: "",
        Distance: "",
        DdlAmtandNooftree: "",
        TxtAmtNooftree: "",
        Enginetype: "",
        Enginetypedistance: "",
        EngineFuelType: "",
        MileageByMakeModel: 0,
        ShowSeriesDropdown: false,
        ShowDistanceType: false,
        Co2Emission: 0,
        CarbonEmissionInTons: 0,
        NuetrilizationCost: 0,
        //ShowChild: false,
        selectedOption: "",
        ModelList: [],
        FuelTypeList: [],
        SerieslList: [],
        MyDistanceList: [],
        errors: [],
        Manufacturing_Year: "",
        EnginetypeList: [],
        MileageByMakeEngineType: "",
        TreeCountPlant: 0,
        DdlAmtandNooftree: "",
        TxtAmtNooftree: "",
        Type: "",
        showCountryCodedll: "hide",
        searchcountyrcode: "",
        countryPhoneCode: "+91",
        CCEngineValue: "",
        MakeList: [],
        CCEngineList: [],
        CCEngineUniqList: [],
        EngineFuelList: [],
        CCEngineVal: "",
        ShareAmount: 0,
        paymentShareOption: 1,
        name: "",
        emailid: "",
        mobileno: "",
        pancard: "",
        address: "",
      },
      () => this.GetFuelTypes(),
      this.GetEngineType(),
      this.GetVehicalTypeMakes(),
      this.ClearOutputValue()
    );
  };

  ClearOutputValue = () => {
    this.setState({
      Co2Emission: 0,
      GHGEmission: 0,
      TreeCountPlant: 0,
      NuetrilizationCost: 0,
      ShareAmount: 0,
      paymentShareOption: 1,
    });
  };

  handleMakeChange = (e) => {
    this.setState(
      { Make: e.target.value },
      this.handleSetState(),
      this.GetModelsUsingMake(e.target.value)
    );
    this.ClearOutputValue();
  };

  handleChange = (selectedOption) => {
    this.setState(
      {
        selectedOption,
        Make: selectedOption.value,
      },
      this.handleSetState(),
      this.GetModelsUsingMake(selectedOption.value)
    );
  };

  handleSetState = () => {
    this.setState({
      ModelList: [],
      SerieslList: [],
      MyDistanceList: [],
      Series: "",
      Model: "",
      DistanceType: "",
      PayLoadInKG: "",
      Manufacturing_Year: "",
      MakeModelSource: "",
      MakeModelDestination: "",
      MakeModelPackageWeight: "",
      MakeModelDistance: "",
      CCEngineUniqList: [],
      EngineFuelList: [],
      CCEngineValue: "",
      EngineFuelType: "",
      Co2Emission: 0,
      GHGEmission: 0,
      TreeCountPlant: 0,
      NuetrilizationCost: 0,
    });
  };

  handleVehicleTypeChange = (vType) => {
    this.setState(
      {
        VehicleType: vType,
        MakeList: [],
        selectedOption: null,
        Make: "",
        ModelList: [],
        SerieslList: [],
        MyDistanceList: [],
        Series: "",
        Model: "",
        DistanceType: "",
        PayLoadInKG: "",
        Manufacturing_Year: "",
        MakeModelSource: "",
        MakeModelDestination: "",
        MakeModelPackageWeight: "",
        MakeModelDistance: "",
      },
      this.GetVehicalTypeMakes
    );
  };

  handleNgoChange = (selectedNgoOption) => {
    this.setState(
      {
        selectedNgoOption,
        //ShowChild: false,
        NgoId: selectedNgoOption.value,
        NgoName: selectedNgoOption.label,
      },
      () => this.setState({ ShowChild: true })
    );
    // var delayInMilliseconds = 500;
    // setTimeout(function () {
    //   window.scroll({
    //     top: 700,
    //     behavior: "smooth",
    //   });
    // }, delayInMilliseconds);
  };

  handleCallback = (error) => {
    let formIsValid = true;
    if (Object.keys(error).length > 0) {
      formIsValid = false;
    }
    if (this.mounted) {
      this.setState({ errors: error });
    }
    return formIsValid;
  };

  handleSectionChange = (tabName, tabNumber) => {
    this.setState({ activecalcaotab: tabNumber });
    let mList = this.state.MakeList;

    if (tabName === "vehicletype") {
      this.setState(
        {
          IsdisableVehicleType: true,
          IsdisableEngineType: false,
          IsdisableJourney: false,
          Isdisableother: false,
        },
        () => this.ClearForm()
      );
      this.GetVehicalTypeMakes();
    }
    if (tabName === "enginetype") {
      this.setState(
        {
          IsdisableVehicleType: false,
          IsdisableEngineType: true,
          IsdisableJourney: false,
          Isdisableother: false,
          MakeList: mList,
        },
        () => this.ClearForm()
      );
    }
    if (tabName === "Journey") {
      this.setState(
        {
          IsdisableVehicleType: false,
          IsdisableEngineType: false,
          IsdisableJourney: true,
          Isdisableother: false,
          MakeList: mList,
          // CCEngineValue:""
        },
        () => this.ClearForm()
      );
    }
    if (tabName === "Other") {
      this.setState(
        {
          IsdisableVehicleType: false,
          IsdisableEngineType: false,
          IsdisableJourney: false,
          Isdisableother: true,
          MakeList: mList,
        },
        () => this.ClearForm()
      );
    }
  };

  handlecalcaotabClick(tabNumber) {
    this.setState({ activecalcaotab: tabNumber });
  }

  render() {
    const { selectedOption } = this.state;
    const { selectedNgoOption } = this.state;
    const { isModalOpen } = this.state;
    const { activecalcaotab } = this.state;
    const { year } = this.state;
    const websitecolorcodestyle = {
      color: this.state.PrimaryColor,
      borderColor: this.state.PrimaryColor,
    };

    const selectStyles = {
      option: (provided, state) => ({
        ...provided,
        LineHeight: 18,
        paddingTop: 2,
        paddingBottom: 2,
        borderBottom: "none",
        fontSize: 14,
        backgroundColor: state.isSelected ? "#0d6efd" : "",
        selectedOptionfontSize: 14,
        textAlign: "left",
        cursor: "pointer",
      }),
    };

    return (
      <div
        className={
          this.state.ShowChild == true
            ? "co2emicalcwrap_cont selectngoshowcont"
            : "co2emicalcwrap_cont"
        }
      >
        <div className="registartion_container co2emicalc_container co2calcforao_cont">
          <div className="registartion_cont">
            <ul className="register_form">
              <li className="form-group calcco2heading">
                <div className="calcao_heading">
                  <div className="calcaohead_left">
                    <h1> Carbon Clear Calculator</h1>
                    <p>
                      Calculate your Carbon Footprint and offset it by selecting
                      any one of the two options.
                    </p>
                  </div>
                  <div className="calcaohead_right">
                    <img src={Calcao_Treeties} alt="Treeties" />
                    <img
                      src={Calcao_Goldenmile}
                      alt="GOLDEN MILE LEARNING TRUST"
                    />
                  </div>

                  {/* <img
                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/${this.state.WebsiteImagePath}`}
                    alt=""
                  /> */}

                  {this.state.WebsiteInfo &&
                    this.state.WebsiteInfo.IsShowSource && (
                      <div>
                        <Button
                          className="sourcebtn"
                          onClick={this.handleModalToggle}
                        >
                          Source
                        </Button>
                        <Modal
                          variant={ModalVariant.medium}
                          isOpen={isModalOpen}
                          className="sourcemodal_cont"
                          onClose={this.handleModalToggle}
                        >
                          <div>
                            <Sequestration />
                          </div>
                        </Modal>
                      </div>
                    )}
                </div>
              </li>
              <div className="halfwidth_cont">
                <div className="calcaotab_wrap">
                  {/* <button
                    onClick={(e) => this.handleSectionChange("vehicletype", 1)}
                    className={
                      activecalcaotab === 1 ? "activecalcaotab_btn" : ""
                    }
                  >
                    Car Type
                  </button> */}
                  <button
                    onClick={(e) => this.handleSectionChange("Journey", 2)}
                    className={
                      activecalcaotab === 2 ? "activecalcaotab_btn" : ""
                    }
                  >
                    Journey
                  </button>
                  <button
                    onClick={(e) => this.handleSectionChange("Other", 3)}
                    className={
                      activecalcaotab === 3 ? "activecalcaotab_btn" : ""
                    }
                  >
                    Other
                  </button>
                </div>

                <div className="calcaotab_content">
                  {/* {activecalcaotab === 1 && (
                    <div className="calcao_vehicaletyprcalc">
                      <p>
                        Please provide your car make, model and engine details
                        along with fuel and distance travelled.
                      </p>
                      <div className="fields_wrap">
                        <div className="field searchmakecont">
                          <label htmlFor="nameofngo">
                            Make<sup className="mandatory">*</sup>
                          </label>
                          {this.state.MakeList != null && (
                            <select
                              onChange={this.handleMakeChange}
                              className="form-control selectdropdown"
                              disabled={
                                this.state.IsdisableJourney ||
                                this.state.Isdisableother
                              }
                            >
                              <option value="Select Make">Select Make</option>
                              {this.state.MakeList != null &&
                                this.state.MakeList.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.value}
                                  </option>
                                ))}
                            </select>
                          )}
                          <div className="mandatory has-error">
                            {this.state.errors.Make}
                          </div>
                        </div>

                        <div className="field searchmakecont">
                          <label htmlFor="nameofngo">
                            Model<sup className="mandatory">*</sup>
                          </label>
                          <select
                            onChange={this.handleModelDdlChange}
                            className="form-control selectdropdown"
                            defaultValue={this.state.Model}
                            disabled={!this.state.IsdisableVehicleType}
                          >
                            <option value="Select Model">Select Model</option>
                            {this.state.ModelList != null &&
                              this.state.ModelList.map((option) => (
                                <option value={JSON.stringify(option)}>
                                  {option.Model}
                                </option>
                              ))}
                          </select>
                          <div className="mandatory has-error">
                            {this.state.errors.Model}
                          </div>
                        </div>

                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            Engine<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <div>
                              <select
                                value={this.state.CCEngineValue}
                                onChange={this.handleCarCCEngine}
                                className="form-control selectdropdown"
                              >
                                <option value="Select CC Engine">
                                  Select CC Engine
                                </option>
                                {this.state.CarEmissionByCCUniq != null &&
                                  this.state.CarEmissionByCCUniq.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                              </select>
                              <div className="mandatory has-error">
                                {this.state.errors.CCEngineValue}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            Fuel<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <div>
                              <select
                                onChange={this.handleCarFuelTypeChange}
                                className="form-control selectdropdown"
                                defaultValue={this.state.EngineFuelType}
                                value={this.state.EngineFuelType}
                              >
                                <option value="Select Fuel">Select Fuel</option>
                                {this.state.FuelTypeList != null &&
                                  this.state.FuelTypeList.map(
                                    (option, index) => (
                                      <option
                                        key={index}
                                        value={option.FuelType}
                                      >
                                        {option.FuelType}
                                      </option>
                                    )
                                  )}
                              </select>
                              <div className="mandatory has-error">
                                {this.state.errors.EngineFuelType}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div>
                            <label htmlFor="nameofngo">
                              Distance (kms)<sup className="mandatory">*</sup>
                            </label>
                            <div>
                              <input
                                type="number"
                                id="MakeModelDistance"
                                name="MakeModelDistance"
                                value={this.state.MakeModelDistance}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value?.length <= 10 &&
                                    e.target.value >= 0
                                  )
                                    this.handleUserInput(e);
                                }}
                                disabled={!this.state.IsdisableVehicleType}
                              />
                              {/* <span className="calcmeasur">kms</span> */}
                  {/* <div className="mandatory has-error">
                                {this.state.errors.MakeModelDistance}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.CCEngineList.length > 0 &&
                        this.state.CCEngineUniqList.length === 0 && (
                          <div className="mandatory has-error nodata_error">
                            {"No data present for selected criteria"}
                          </div>
                        )}
                      {this.state.Make !== "" &&
                        this.state.ModelList !== null &&
                        this.state.ModelList.length === 0 && (
                          <div className="mandatory has-error nodata_error">
                            {"No data present for selected criteria"}
                          </div>
                        )}
                    </div>
                  )} */}

                  {activecalcaotab === 2 && (
                    <div className="calcao_journeycont">
                      <p>
                        Please provide the journey facts such as distance,
                        miles, CC engine and fuel.
                      </p>
                      <div className="fields_wrap">
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            Distance (km)<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <input
                              type="number"
                              id="Distance"
                              name="Distance"
                              value={this.state.Distance}
                              onKeyDown={(e) => {
                                ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault();
                              }}
                              onChange={(e) => {
                                if (
                                  e.target.value?.length <= 10 &&
                                  e.target.value >= 0
                                )
                                  this.handleUserInput(e);
                              }}
                              disabled={!this.state.IsdisableJourney}
                            />
                            {/* <span className="calcmeasur">kms</span> */}
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.Distance}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            Mileage (km/l)<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <input
                              type="number"
                              id="Mileage"
                              name="Mileage"
                              value={this.state.Mileage}
                              onKeyDown={(e) => {
                                ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault();
                              }}
                              onChange={(e) => {
                                if (
                                  e.target.value?.length <= 10 &&
                                  e.target.value >= 0
                                )
                                  this.handleUserInput(e);
                              }}
                              disabled={!this.state.IsdisableJourney}
                            />
                            {/* <span className="calcmeasur">km/l</span> */}
                          </div>

                          <div className="mandatory has-error">
                            {this.state.errors.Mileage}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            CC Engine<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <select
                              onChange={this.handleJourneyCCEngine}
                              className="form-control selectdropdown"
                              value={this.state.CCEngineVal}
                              disabled={!this.state.IsdisableJourney}
                            >
                              <option value="Select CC Engine">
                                Select CC Engine
                              </option>
                              {this.state.CarEmissionByCCUniq != null &&
                                this.state.CarEmissionByCCUniq.map(
                                  (option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.CCEngineVal}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            Fuel<sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <select
                              onChange={this.handleJourneyFuelType}
                              className="form-control selectdropdown"
                              defaultValue={this.state.FuelType}
                              disabled={!this.state.IsdisableJourney}
                            >
                              <option value="Select Fuel">Select Fuel</option>
                              {this.state.FuelTypeList != null &&
                                this.state.FuelTypeList.map((option, index) => (
                                  <option key={index} value={option.FuelType}>
                                    {option.FuelType}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.FuelType}
                          </div>
                        </div>
                      </div>
                      {this.state.CCEngineVal !== "" &&
                        this.state.FuelType !== "" &&
                        this.state.Co2Emission === 0 && (
                          <div className="mandatory has-error nodata_error">
                            {"No Data present for selected criteria"}
                          </div>
                        )}
                    </div>
                  )}

                  {activecalcaotab === 3 && (
                    <div className="calcao_othercont">
                      <p>
                        Please provide the number of trees you want to plant or
                        donation amount.
                      </p>
                      <div className="fields_wrap">
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            <sup className="mandatory">*</sup>
                            Select Type
                          </label>
                          <div>
                            <select
                              onChange={this.DdlAmtandNooftree}
                              className="form-control selectdropdown"
                              value={this.state.DdlAmtandNooftree}
                              disabled={!this.state.Isdisableother}
                            >
                              <option value="">Please Select</option>
                              <option value="Number of trees to plant">
                                Number of trees to plant
                              </option>
                              <option value="Donation amount">
                                Donation amount
                              </option>
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.DdlAmtandNooftree}
                          </div>
                        </div>
                        <div className="field">
                          <label htmlFor="nameofhodorg">
                            {this.state.DdlAmtandNooftree == "" && (
                              <span>Donation amount/no of trees </span>
                            )}
                            {this.state.DdlAmtandNooftree ==
                              "Number of trees to plant" && (
                              <span>Enter number of trees </span>
                            )}
                            {this.state.DdlAmtandNooftree ==
                              "Donation amount" && (
                              <span>Enter donation amount (₹) </span>
                            )}
                            <sup className="mandatory">*</sup>
                          </label>
                          <div>
                            <input
                              type="text"
                              id="TxtAmtNooftree"
                              name="TxtAmtNooftree"
                              value={this.state.TxtAmtNooftree}
                              onChange={(e) => {
                                if (
                                  (e.target.value?.length <= 10 &&
                                    e.target.value >= 0) ||
                                  e.target.value == ""
                                )
                                  this.handleUserInput(e);
                              }}
                              disabled={!this.state.Isdisableother}
                            />
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.TxtAmtNooftree}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <li className="calcbtnerror">
                  <div className="mandatory has-error">
                    {this.state.errors.allselected}
                  </div>
                </li>
                <li className="form-group calco2ftprintbtn">
                  <button
                    className="calco2footprint calcresetbtn"
                    style={websitecolorcodestyle}
                    onClick={this.ClearForm}
                    type="reset"
                    name="Reset"
                    value="reset"
                  >
                    Reset
                  </button>
                  <button
                    className="calco2footprint calcsubmitbtn"
                    style={websitecolorcodestyle}
                    type="submit"
                    onClick={this.calculateCo2Footprint}
                    name="Cancel"
                    value="Calculate Carbon Footprint"
                  >
                    Calculate Carbon Footprint
                  </button>
                </li>
              </div>

              <div className="halfwidth_cont calculationinfo_cont">
                <li className="form-group calcfoottextwrap">
                  {this.state.IsdisableVehicleType && (
                    <div className="calcfoottext" style={{ display: "none" }}>
                      Emission Factor : <span> {this.state.Co2Emission}</span>
                    </div>
                  )}
                  {this.state.IsdisableVehicleType && (
                    <div className="calcfoottext" style={{ display: "none" }}>
                      Emission per trip : <span>{this.state.GHGEmission}</span>
                    </div>
                  )}
                  {this.state.IsdisableVehicleType && (
                    <div className="calcfoottext" style={{ display: "none" }}>
                      Emission Per Passenger:{" "}
                      <span>
                        {this.state.GHGEmission
                          ? (
                              parseFloat(this.state.GHGEmission) /
                              this.state.NoPassengerCar
                            ).toFixed(2)
                          : 0}
                      </span>
                    </div>
                  )}
                  {!this.state.Isdisableother &&
                    this.state.TreeCountPlant >= 4 && (
                      <div className="field addressfield carbofsetforveh_field">
                        <label htmlFor="nameofngo">
                          Carbon Offset for Vehicle
                        </label>
                        <div>
                          <select
                            className="dropdown-13"
                            id="vehicleShare"
                            onChange={this.OnChangeVehicleShare}
                            value={this.state.paymentShareOption}
                          >
                            <option value="1">Full</option>
                            <option value="0.75">Three-Quarter</option>
                            <option value="0.5">Half</option>
                            <option value="0.25">One-Quarter</option>
                          </select>
                        </div>
                      </div>
                    )}

                  {this.state.WebsiteInfo.TreeToPlant && (
                    <div className="calcfoottext">
                      Carbon Offset (in kgs){" "}
                      <span>
                        {(
                          this.state.TreeCountPlant *
                          2.7 *
                          this.state.paymentShareOption
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}
                  <div className="calcfoottext">
                    Donation for Carbon Offset{" "}
                    <span>
                      {this.state?.ShareAmount
                        ? this.state.ShareAmount
                        : this.state.NuetrilizationCost}
                    </span>
                  </div>
                  {this.state.WebsiteInfo.TreeToPlant && (
                    <div className="calcfoottext calcaodonatefield_cont">
                      Trees to be planted for Carbon Offset{" "}
                      <span>
                        {this.state.paymentShareOption
                          ? Math.round(
                              this.state.paymentShareOption *
                                this.state.TreeCountPlant
                            )
                          : this.state.TreeCountPlant}
                      </span>
                    </div>
                  )}
                </li>

                {this.state.ShowChild == true && (
                  <>
                    <li className="calccondetcont">
                      <h3>Contact Details</h3>
                      <p>
                        Furnish PAN Card, address, and contact details for 80G
                        tax exemption receipt on your donation.
                      </p>
                    </li>

                    <li className="form-group paybtnerror">
                      <div className="field">
                        <label htmlFor="nameofngo">
                          Name<sup class="mandatory">*</sup>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.name}
                            placeholder="Enter Name"
                            onChange={this.handleUserInput}
                          />
                          <div className="mandatory has-error">
                            {this.state.errors.name}
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <label htmlFor="nameofngo">
                          Email Id<sup class="mandatory">*</sup>
                        </label>
                        <div>
                          <input
                            type="text"
                            id="emailid"
                            name="emailid"
                            placeholder="Enter Email Id"
                            value={this.state.emailid}
                            onChange={this.handleUserInput}
                          />
                          <div className="mandatory has-error">
                            {this.state.errors.emailid}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "field Divcountryphonecode " +
                          this.state.showCountryCodedll
                        }
                      >
                        <label htmlFor="nameofngo">
                          Mobile No<sup class="mandatory">*</sup>
                        </label>
                        <div>
                          <select
                            id="countryphonecode"
                            onChange={this.CountryCodetextclick}
                            className="form-control selectdropdown"
                            value={this.state.countryPhoneCode}
                          >
                            {/* <option value="Select Country Code">
                              Select Country Code
                            </option> */}
                            {this.state.CountryDetailList != null &&
                              this.state.CountryDetailList.map(
                                (option, index) => (
                                  <option key={index} value={option.dial_code}>
                                    {option.dial_code}
                                  </option>
                                )
                              )}
                          </select>
                          <div>
                            <input
                              type="text"
                              id="mobileno"
                              name="mobileno"
                              value={this.state.mobileno}
                              onChange={this.handleUserInput}
                              maxLength={15}
                            />
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={this.closeCountryCodePopop}
                            ></i>
                            <div className="mandatory has-error">
                              {this.state.errors.mobileno}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field panfield">
                        <label htmlFor="nameofngo">PAN Card</label>
                        <div>
                          <input
                            type="text"
                            id="pancard"
                            name="pancard"
                            placeholder="Enter PAN number"
                            value={this.state.pancard}
                            onChange={this.handleUserInput}
                            maxLength={10}
                          />
                          <div className="mandatory has-error">
                            {this.state.errors.pancard}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="form-group panaddfieldscont">
                      <div className="field addressfield">
                        <label htmlFor="nameofngo">Address</label>
                        <div>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Write address here"
                            value={this.state.address}
                            onChange={this.handleUserInput}
                          />
                        </div>
                      </div>
                    </li>

                    <li className="form-group paybtnerror seletngovalid pymntgloberr_cont">
                      <div className="mandatory has-error">
                        {this.state.errors.nutritioncost}
                      </div>
                    </li>

                    <li className="form-group calcco2paynowbtn" id="paymentbtn">
                      <Payment
                        Amount={this.state.NuetrilizationCost}
                        parentCallback={this.handleCallback}
                        WebsiteGuid={this.state.WebsiteGuid}
                        Color={this.state.PrimaryColor}
                        CarbonEmissionInTons={(
                          this.state.TreeCountPlant *
                          2.7 *
                          this.state.paymentShareOption
                        ).toFixed(2)}
                        WebsiteName={this.state.WebsiteInfo.WebsiteName}
                        name={this.state.name}
                        emailid={this.state.emailid}
                        countryPhoneCode={this.state.countryPhoneCode}
                        mobileno={this.state.mobileno}
                        Treecount={
                          this.state.paymentShareOption
                            ? Math.round(
                                this.state.paymentShareOption *
                                  this.state.TreeCountPlant
                              )
                            : this.state.TreeCountPlant
                        }
                        TotalDistance={this.state.TotalDistance}
                        pancard={this.state.pancard}
                        address={this.state.address}
                        WebSiteLogoName={this.state.WebsiteInfo.WebSiteLogoName}
                        AOLogoName={this.state.WebsiteInfo.AOLogoName}
                        Type={this.state.Type}
                        Make={this.state.Make}
                        Model={this.state.Model}
                        Series={this.state.Series}
                        DrivingMode={this.state.DistanceType}
                        Mileage={this.state.Mileage}
                        FuelType={this.state.FuelType}
                        EngineFuelType={this.state.EngineFuelType}
                        DonationAmountorNoOfTrees={this.state.DdlAmtandNooftree}
                        VehicleType={this.state.VehicleType}
                        EngineType={this.state.Enginetype}
                        ReturnUrl={this.state.WebsiteInfo.ReturnUrl}
                        ShareAmount={
                          this.state.ShareAmount
                            ? this.state.ShareAmount
                            : this.state.NuetrilizationCost
                        }
                      />
                    </li>
                  </>
                )}
              </div>
              <div className="calcaofooter_copyright">
                <p>
                  © <span id="year">{year}</span>, Powerweave Software Services
                  Pvt. Ltd. All rights reserved.
                  <img src={PowerweaveCalcoAo} alt="powerweave" />
                </p>
              </div>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Co2CalculatorAO);
