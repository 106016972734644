import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container} from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import { Button } from '@patternfly/react-core';
import Searchcomp from '../../Common/Search/Search';

class Growalarm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    
    render() {
        const renderThumb = ({ style, ...props }) => {
            const thumbStyle = {
              borderRadius: 6,
              innerWidth: 4,
              backgroundColor: 'rgba(51, 51, 51, 1)'
            };
            return <div style={{ ...style, ...thumbStyle }} {...props} />;
        };
        
        const CustomScrollbars = props => (
            <Scrollbars
              renderThumbHorizontal={renderThumb}
              renderThumbVertical={renderThumb}
              {...props}
            />
        );
        return (
        <>
            <div className="headbottom_cont">
                <ul className="headbottom_content">
                    <li className="heading_cont">Grow Alarms</li>
                    <li className="updatetxt_cont">Last updated 3 May, 2021 9:00:00 AM IST<i className="fa fa-repeat"></i></li>
                    <li className="search_container">
                        <Searchcomp />
                    </li>
                    <li className="export_btn">
                        <Button>Export Report <i className="fa fa-plus"></i></Button>
                    </li>
                </ul>
            </div>
            <div className="growalarm_container">
                <Container>
                
                    <div className="growalarm_content">
                        <div className="commontblfltr_wrap">
                            <ul className="filterlist">
                                <li><a>All 25</a></li>
                                <li><a>High 18</a></li>
                                <li><a>Medium 05</a></li>
                                <li><a>Low 02</a></li>
                            </ul>
                            <div className="showtxtnbtns_wrap">
                                <div className="showingtxt">Showing 12 of 25 High Alarms</div>
                                <div className="showbtn_cont">
                                    <a className="lineicon"><i className="icon icon-menu-of-three-lines"></i></a>
                                    <a className="pinicon"><i className="icon icon-location-pin"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="commontbl_wrap">
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                                <table className="commontbl_cont">
                                    <thead>
                                        <th>ZONE ID
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th>ALARM INFO
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th>LOCATION
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th className="status">STATUS
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th>REPORTED BY
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th>REPORTED ON
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th className="status">STATUS
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                        <th className="action_cont">ACTIONS
                                            <div className="sortArrow">
                                                <i className="fas fa-sort-up"></i>
                                                <i className="fas fa-sort-down"></i>
                                            </div>
                                        </th>
                                    </thead>
                                    
                                    <tbody>
                                        <tr className="tblsearch_cont">
                                            <td colSpan="8">
                                                <div className="tblsearch">
                                                    <i className="fa fa-search"></i><input type="text" placeholder="Search for tree type, zone, location…" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PL 001</td>
                                            <td>Tree organic fertilizers needed</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy">
                                                <span>Healthy</span>
                                            </td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo resolved">
                                                <span>Resolved</span>
                                            </td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PL 002</td>
                                            <td>Animal intrusion found</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy">
                                                <span>Healthy</span>
                                            </td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo resolved">
                                                <span>Resolved</span>
                                            </td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr className="unhealthytr">
                                            <td>PL 003</td>
                                            <td>Animal intrusion found</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone unhealthy">
                                                <span>Unhealthy</span>
                                            </td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo inprogress">
                                                <span>In Progress</span>
                                            </td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PL 004</td>
                                            <td>Mango trees missing</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy"><span>Healthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>15 Apr 2021</td>
                                            <td className="statustwo inprogress"><span>In Progress</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>DL 001</td>
                                            <td>Tree organic fertilizers needed</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy"><span>Healthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo resolved"><span>Resolved</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Ak 002</td>
                                            <td>Tree Inspection required</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy"><span>Healthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo unassigned"><span>Unassigned</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr className="unhealthytr">
                                            <td>PL 021</td>
                                            <td>Tree Inspection required</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone unhealthy"><span>Unhealthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo unassigned"><span>Unassigned</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr className="unhealthytr">
                                            <td>PL 032</td>
                                            <td>Animal intrusion found</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone unhealthy"><span>Unhealthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo inprogress"><span>In Progress</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr className="unhealthytr">
                                            <td>PL 211</td>
                                            <td>Flood prone area detected</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone unhealthy"><span>Unhealthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo inprogress"><span>In Progress</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PL 112</td>
                                            <td>Flood prone area detected</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy"><span>Healthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo inprogress"><span>In Progress</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>PL 061</td>
                                            <td>Tree organic fertilizers needed</td>
                                            <td>@19.15247359,72.87521491</td>
                                            <td className="statusone healthy"><span>Healthy</span></td>
                                            <td>Satellite 235</td>
                                            <td>12 Apr 2021</td>
                                            <td className="statustwo inprogress"><span>In Progress</span></td>
                                            <td className="action_cont">
                                                <a><i className="fas fa-edit"></i></a>
                                                <a><i className="fas fa-satellite"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </CustomScrollbars>
                            </div>
                        </div>
                </Container>
            </div>
        </>
        )
    }
    
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: data => dispatch({ type: "SET_USER_DETAILS", data: data }),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Growalarm);