import { useEffect, useState } from "react";
import config from "../../../utils/config";
import { ReactComponent as SelectDropdown } from "./icons/Select_Dropdown.svg";
import OverviewReportUrl from "./Reports/OverviewDownload";
import Pagination from "react-js-pagination";

export default function OwnershipView(props) {
  const [allData, setAllData] = useState([]);
  const [ownerproofdDropdownList, setOwnerProofdDropdownList] = useState([]);
  const [activeTab, setActiveTab] = useState("Government");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [pageRange, setPageRange] = useState(10);

  useEffect(() => {
    if (props?.projectId) {
      GetOwnership(props.projectId);
      setPagesize(1);
      setPageRange(5);
      GetCommonMasters();
    }
  }, [props?.projectId, currentPage]);

  const GetOwnership = (ProjectId) => {
    let proj = {
      ProjectId: ProjectId,
      currentPage: currentPage,
    };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetOwnership`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(proj),
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response?.json())
      .then((data) => {
        let responseData = data?.data;
        if (responseData !== null) {
          setAllData(responseData);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/Ownership`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response?.json())
      .then((data) => {
        if (data?.length > 0) {
          var OwnerOwnerproof = data?.filter(
            (x) => x?.type === "Proof of Ownership"
          );
          setOwnerProofdDropdownList(OwnerOwnerproof);
        }
      })

      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const handleDownloadDocument = (FileName) => {
    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/ProjectFiles/Project/${props.projectId}/${FileName}`;
    fetch(url).then((response) => {
      response?.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderTabContent = (currentTab) => {
    let dataForCurrentTab = allData?.ownershipList?.filter(
      (ownership) => ownership?.ownershipType === currentTab
    );

    return (
      <div className="tabs-content">
        {dataForCurrentTab?.length > 0 ? (
          <>
            {dataForCurrentTab
              ?.slice((currentPage - 1) * pagesize, currentPage * pagesize)
              .map((ownership, index) => (
                <div key={index} className="ownershiptab_content">
                  <>
                    <div className="owershiptabtop_cont">
                      <div className="ownertb_ownrnme">
                        {ownership?.ownerName ? ownership?.ownerName : "-"}
                      </div>
                      <div className="ownertbtypmobile_wrap">
                        <div className="ownertb_ownertype">
                          {ownership?.ownershipType
                            ? ownership?.ownershipType
                            : "-"}{" "}
                          |
                        </div>
                        <div className="ownertb_ownermobile">
                          <span>&nbsp;Contact : </span>
                          <span>
                            {ownership?.contactNo ? ownership.contactNo : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="prosumacordinertbl_wrap">
                      <table className="prosumacord_inertbl">
                        <thead>
                          <tr>
                            <th>Proof of Ownership</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ownerproofdDropdownList &&
                            ownerproofdDropdownList.map((eachOwnerProof, i) => (
                              <tr key={i}>
                                <td>
                                  {eachOwnerProof?.description ? (
                                    <div>{eachOwnerProof?.description}</div>
                                  ) : (
                                    <div>NA</div>
                                  )}
                                </td>
                                <td>
                                  {ownership?.ownershipFiles &&
                                    ownership?.ownershipFiles.some(
                                      (file) =>
                                        file?.documentId ===
                                        eachOwnerProof.mainGuId
                                    ) && (
                                      <button
                                        onClick={() => {
                                          const matchingFile =
                                            ownership?.ownershipFiles?.find(
                                              (file) =>
                                                file?.documentId ===
                                                eachOwnerProof.mainGuId
                                            );
                                          if (matchingFile) {
                                            handleDownloadDocument(
                                              matchingFile.documentName
                                            );
                                          }
                                        }}
                                      >
                                        <SelectDropdown />
                                      </button>
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                </div>
              ))}
            {dataForCurrentTab?.length > 1 && (
              <div className="paginationDiv">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={pagesize}
                  totalItemsCount={dataForCurrentTab.length}
                  pageRangeDisplayed={pageRange}
                  nextPageText={<i className="fa fa-angle-right"></i>}
                  prevPageText={<i className="fa fa-angle-left"></i>}
                  onChange={(pageNumber) => handlePageChange(pageNumber)}
                />
              </div>
            )}
          </>
        ) : (
          <div className="norecodfoundprosum_cont">No Record Found</div>
        )}
      </div>
    );
  };

  const Tabs =
    props?.projectId === "ea23fffa-7e2a-4217-acdd-6515f46b6b05"
      ? ["Trust"]
      : [
          "Government",
          "Private - Individuals",
          "Corporations/Businesses",
          "Trust",
          "Community Land",
          "NGO",
        ];

  return (
    <div className="row prosumcordtabscont_wrap">
      <div className="col-5">
        <div className="tab-buttons">
          {Tabs?.map((tab, index) => (
            <button
              key={index}
              className={activeTab === tab ? "active" : ""}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="col-7">
        {activeTab !== null && renderTabContent(activeTab)}
      </div>
    </div>
  );
}
