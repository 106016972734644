const initialState = {
    userData: null
}
export default function user(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER_DETAILS':
            return { ...state, userData: action.data }
        case 'SET_USERMENU_DETAILS':
            return { ...state, userMenuData: action.data }
        case 'SET_APPLICATION_DETAILS':
            return { ...state, ApplicationInfo: action.data }
        case 'CLEAR_USER_DETAILS':
            return { ...state, userData: null, userMenuData: null, userGlobalSettingData: null }
        default:
            return state
    }
};