import React, { useEffect, useState } from "react";
import ProListviewmediaImg from "./mediaImages/listviewpopupimg.jpg";
import { Carousel } from "react-responsive-carousel";
import config from "../../../utils/config";

export default function ProjectListMediaPopup(props) {
  const [images, setImages] = useState({});
  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    setTreeData(props.eachTreeData);
  }, [props.eachTreeData]);

  return (
    <div className="treedetails_cont">
      <div className="treedetimg">
        <Carousel>
          {treeData?.photos?.map((imageName, i) => (
            <img
              key={i}
              src={
                `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${treeData?.projectID}/${treeData?.ec5_uuid}/` +
                imageName
              }
              alt="ProListviewmediaImg"
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
