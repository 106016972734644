import React, { useEffect, useState } from "react";
import config from "../../../utils/config";

export default function CostDetailsView(props) {
  const [costData, setCostData] = useState({});

  const GetCostDetails = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCostDetails`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "Post",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        if (data != null) {
          setCostData(data?.data);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  useEffect(() => {
    if (props?.projectId) GetCostDetails(props.projectId);
  }, [props?.projectId, props]);

  return (
    <div className="row partshprosumpacrd_content">
      <div className="row">
        {/* cost view start */}
        <div className="hero-title20">
          <b className="project-summary20">Costs</b>
        </div>

        <div className="prosumacordtbls_wrap">
          {costData?.costDetailsList?.length > 0 && (
            <table className="prosumacord_inertbl">
              <thead>
                <tr>
                  <th>Cost Type</th>
                  <th>Cost (in Rs.)</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {costData?.costDetailsList?.map((costDetails, i) => (
                  <tr key={i}>
                    <td>{costDetails?.costType}</td>
                    <td>{costDetails?.cost}</td>
                    <td>{costDetails?.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="prosummarylist">
          {costData?.totalCost && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Total Cost : &nbsp;</div>
              <div className="produmitem_content">
                {"₹ " + costData?.totalCost}
              </div>
            </div>
          )}
        </div>
        {/* cost view end   */}
      </div>
    </div>
  );
}
