import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OurStoryOne from "../../assets/our-story-one.png";
import OurStoryTwo from "../../assets/our-story-two.png";
import ProggressionOne from "../../assets/proggression-arrow-1.svg";
import Leader_1 from "../../assets/leader_1.png";
import Leader_2 from "../../assets/leader_2.png";
import Leader_3 from "../../assets/leader_3.png";
import Leader_4 from "../../assets/leader_4.png";
import Leader_5 from "../../assets/leader_5.png";
import Leader_6 from "../../assets/leader_6.png";
import Leader_7 from "../../assets/leader_7.png";
import Leader_8 from "../../assets/leader_8.png";
import Leader_9 from "../../assets/leader_9.png";
import Platform_9 from "../../assets/platform_9.svg";
import IconShaping from "../../assets/icon_shaping.svg";
import StackholderlistIcon from "../../assets/OurstackholdersImg.png";
import backtbn from "../../assets/backbtn.png";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isShowMSG: false,
      scrollCount: 0,
      selectedTeamMember: null, // State to hold selected team member's information
    };
    this.mounted = true;
    this.winScroll = this.winScroll.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
    document.body.classList.remove("about_us_bg");
  }
  componentDidMount() {
    this.mounted = true;
    window.addEventListener("scroll", this.winScroll);
    document.body.classList.add("about_us_bg");
  }
  OpenEnquiryDetail = (e) => {
    if (this.mounted) this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  CloseEnquiryData = () => {
    if (this.mounted) {
      this.setState({ isModalOpen: !this.state.isModalOpen, isShowMSG: false });
    }
  };
  ShowMSG = () => {
    if (this.mounted) this.setState({ isShowMSG: true });
  };

  winScroll = () => {
    this.setState({ scrollCount: window.scrollY });
  };
  goBack() {
    window.history.go(-2);
  }
  // Function to handle click event on team member images
  handleTeamMemberClick = (teamMemberInfo) => {
    this.setState({ isModalOpen: true, selectedTeamMember: teamMemberInfo });
  };
  render() {
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
      backgroundPosition: "center center",
    };
    const insideStyles = {
      background: "transparent",
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-53%,-60%)",
      color: "#fff",
      textAlign: "center",
    };
    const insideStyles1 = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%,-50%)",
      texttransform: "capitliaze",
      color: "#fff",
      textAlign: "center",
      lineHeight: "40px",
    };
    const buttonStyle = {
      background: "transparent",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-53%, 130%)",
    };
    const teamMembers = [
      {
        image: Leader_1,
        name: "Srini Srinivasan",
        role: "Business Development, Sponsor & Network Management, Governance",
        info: "Srini has 37 years’ experience in leadership roles and CFO in Corporates of which 27 years in HUL/Unilever. Retiring in March 2022 as Jt MD of Bosch, Srini now focuses on social impact, building on a decade of experience in the social sector incubating and scaling two NGOs in EdTech and Climate Action. Srini is responsible for business and network development, sponsor network, awareness and advocacy and overall governance.",
      },
      {
        image: Leader_2,
        name: "Rajesh Patel",
        role: "Climate Science, Technology Platform Mgt.",
        info: "Rajesh Patel is a serial entrepreneur with over four decades of experience in incubating and scaling digital business. With deep roots in Science and Technology and its application to business solutions. Rajesh brings in the experience of running a collaborative platform focused on sustainable commerce that works with a community of buyers, suppliers, subject matter experts, compliance, certification, finance partners and many more to deliver the net zero goal for businesses.",
      },
      {
        image: Leader_3,
        name: "Swapnili Tewari",
        role: "Operations, Finance, HR",
        info: "Swapnili Tewari is a Chartered Accountant with wide ranging experience across different sectors including Unit Finance head at FMCG giant Coca Cola,  before moving to the Middle East and having multinational exposure in sectors Real Estate, Investment Banking and logistics industries. She moved back to India for a  purpose driven career shift. She has worked in a new age education sector as the COO of a renowned education institution. Her passion for environment and climate action has brought her to TreeTies. As COO, Swapnili leads all aspects of our operations.",
      },
      {
        image: Leader_4,
        name: "Prakash N",
        role: "Designation Field Officer",
        info: "Prakash N is an environmental activist with extensive field experience in restoration projects in Karnataka. He holds a bachelor's degree in agriculture and a master's degree in environmental science. Prakash has dedicated his expertise to conducting field research in ecology, environment, and allied agricultural domains. He has spearheaded numerous restoration projects, leading baseline assessments and equalization efforts across the state. His work delves into various taxa of vegetation, insects, birds, and soils, reflecting his commitment to understanding and preserving our natural world.",
      },
      {
        image: Leader_5,
        name: "Venkatesh Bhat",
        role: "NGO & Partner Network, Process & Project Mgt, Operations.",
        info: "Venkatesh has, in a career spanning three decades, worked in large corporations such as HUL and GE as well as holding CXO positions in ACT Fibernet and Zinka Logistics. Currently he is the managing partner of a start up consulting firm. Apart from contributions to the unicorn success story in two startups, he has held positions in national level task forces of Government ministries and industry bodies. Being part of the founding team of TreeTies, he focuses on partner network building and project implementation strategies.",
      },
      {
        image: Leader_6,
        name: "Namgyal Durbuk",
        role: "Operations Head Gov Relations, Mobilisation & Partner Network Mgt.",
        info: "Namgyal Durbuk, Leads Operations, Government Relations, Mobilization, and Partner Network Management for Green Himalaya's project. This initiative is dedicated to preserving the fragile Himalayan environment while promoting traditional practices",
      },
      {
        image: Leader_7,
        name: "Lama Gyalsten Kinchok",
        role: "Operations Lead, Partner Network Mgt, Local community onboarding, Social Impact",
        info: "Lama Gyalsten Kinchok, Operations Lead for Green Himalayas' s project,  spearheads local community onboarding, and ensures impactful social outcomes, liaise with communities, uphold cultural sensitivity, and facilitate project execution to drive positive change.",
      },
      {
        image: Leader_8,
        name: "Sohini Mitra",
        role: "Awareness and Advocacy",
        info: "Sohini is a former corporate sector professional, She pivoted from the world of market research to focus on her interest in the climate crisis. She now reports ESG/ sustainability performance for corporates and works with TreeTies on awareness and advocacy  of ecological restoration in the corporate sector. She loves riding a bicycle for pleasure and fitness and enjoys long rides amidst nature.",
      },
      {
        image: Leader_9,
        name: "Rohit Lodhi",
        role: "Social Media, Marketing, Communication",
        info: "25+ years’ experience in  marketing leadership roles in WPP, Omnicom, IPG group agencies, Rohit’s  last corporate role was as a Global Marketing Head for iGate-Patni (now part of Capgemini). Last 10 years has been an entrepreneurial journey with Synclariti (previously  3pointlight) , and as a consultant with Momentum-ITSMA for APAC. With keen interest in social + sustainability impact, he is an advisor with TreeTies for strategic marketing, brand advisory and stakeholder communications.",
      },
    ];

    const teamMemberItems = teamMembers.map((member, index) => (
      <div
        className="col-sm-4"
        key={index}
        onClick={() => this.handleTeamMemberClick(member)}
      >
        <div className="leaders-box">
          <div className="landimg">
            <img src={member.image} alt={member.name} />
          </div>
          <div className="text-center">
            <h5 className="greentxt me-0 pb-0">{member.name}</h5>
            <span>{member.role}</span>
          </div>
        </div>
      </div>
    ));
    return (
      <>
        <Button className="backbtn" onClick={this.goBack}>
          <img src={backtbn} alt={backtbn} />
          <span>Back</span>
        </Button>

        <div className="about-us-page">
          <div className="morelrpadd_cont aboutourstory_cont">
            <div className="treeties_container">
              <h1>Our Story</h1>
              <div className="row aboutourstory_content">
                <div className="col-sm-8">
                  <h5>
                    <span className="greentxt me-0">#</span>restore
                    <span className="greentxt me-0">ecosystems</span>reduce
                    <span className="greentxt me-0">carbon</span>
                  </h5>
                  <p>
                    TreeTies is a social enterprise start-up
                    <br /> committed to restore degenerated
                    <br /> land and ecosystems, by leveraging
                    <br /> science, technology, and partnership
                    <br /> networks through the TreeTies holistic
                    <br /> digital platform to bring systemic
                    <br /> solutions at scale.
                  </p>
                  <p>
                    To achieve this goal, TreeTies will
                    <br /> build awareness-for-action amongst
                    <br /> corporates, communities and citizens
                    <br /> for collaboration and partner with
                    <br /> government in policy advocacy.
                  </p>
                </div>
                <div className="col-sm-4">
                  <div className="our-story-img">
                    <div className="landimg">
                      <img src={OurStoryOne} alt="" />
                    </div>
                    <div className="landimg">
                      <img src={OurStoryTwo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="morelrpadd_cont  paddingbotm">
            <div className="treeties_container">
              <h1 className="mb-5">Our Progression</h1>
              <div className="progression_list">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2013</span>
                      <h5 className="pt-2">
                        Founders begin working in
                        <br /> Ladakh with the GoldenMile
                        <br /> Trust, focusing on e-Learning
                        <br /> opportunities for local schools.
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="img-arrow">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-7">
                    <div className="img-arrow left-img">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2015</span>
                      <h5 className="pt-2">
                        First successful reforestation
                        <br /> project was completed in Ladakh,
                        <br /> sparking the idea for Treeties.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2019</span>
                      <h5 className="pt-2">
                        Successful 3rd phase for
                        <br /> Ladakh Reforestation
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="img-arrow">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="img-arrow left-img">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2021</span>
                      <h5 className="pt-2">
                        Treeties gets created with
                        <br /> vision of holistic view to
                        <br /> ecosystem restoration
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2022</span>
                      <h5 className="pt-2">
                        Treeties establishes
                        <br /> partnerships with corporate
                        <br /> entities and local communities.
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="img-arrow">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-7">
                    <div className="img-arrow left-img">
                      <div className="pointer">
                        <img src={ProggressionOne} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="text-center">
                      <span className="treeties_heading mb-0 mt-2">2023</span>
                      <h5 className="pt-2">
                        The development of Treeties'
                        <br /> online platform, which offers
                        <br /> ecosystem restoration as a service,
                        <br /> promotes ecosystem change.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lesslrpadd_cont paddingbotm">
            <div className="treeties_container">
              <div className="eco-heading">
                <h1>
                  Eco-Warriors: Treeties' Inspiring
                  <br /> Leadership and Dedicated Team
                </h1>
                <h5>
                  Our Eco-Warriors at Treeties are a formidable force in the
                  battle for
                  <br /> a greener planet. With an inspiring leadership team and
                  a dedicated
                  <br /> group of professionals, we combine passion, expertise,
                  and a<br /> relentless drive to create a world where
                  environmental stewardship
                  <br /> is second nature.
                </h5>
              </div>

              <div className="leaders_list solution_list">
                <div className="row col-extra-padding">
                  {teamMemberItems}
                  <Modal
                    title={
                      this.state.selectedTeamMember
                        ? this.state.selectedTeamMember.name
                        : ""
                    }
                    isOpen={this.state.isModalOpen}
                    variant={ModalVariant.small}
                    onClose={() => this.setState({ isModalOpen: false })}
                    className="modalforteammeb_infocont"
                  >
                    <div>
                      {this.state.selectedTeamMember && (
                        <p>{this.state.selectedTeamMember.info}</p>
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
