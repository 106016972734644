import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import ApplicationList from "./ApplicationList";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
class ApplistPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.status === true ? true : false,
            // isModalOpen: false,
            // ApplicationInfo: [],
            Applicationlist: props.data,
            files: [],
            FilesList: [],
            isUploaded: false,
            DocumentName: "",
            fields: {},
            errors: {},
            flag: false,

        }
        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };
    }
    componentDidMount() {
        this.mounted = true;
        // if (this.props.ApplicationInfo) {
        //     this.setState({ ApplicationInfo: this.props.ApplicationInfo });
        // }
        {
            this.GetUploaddDocumentDetails();
        }

    }
    componentWillUnmount() {
        this.mounted = false;
    }
    // click = () => {
    //     debugger
    //     //this.props.parentMethod("All");
    //     this.props.parentMethod;
    // }

    ApproveRejectApplication = (e) => {
        if (this.mounted) {
            let Status = e.target.getAttribute('value');
            let UserDetail = {
                Status: Status,
                //  UserId: this.state.ApplicationInfo.userId,
                UserId: this.state.Applicationlist.userId,
            }
            let uri = `${config.API_HOST_URL}/api/login/ApproveRejectApplication`;
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    WebsiteGuid: this.props.websiteGUID,
                    LanguageGuid: this.props.languageGUID,
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (data.success) {
                                if (this.mounted) {
                                    //   this.click();

                                    ToastNotification.ShowSuccess(data.message)
                                    this.handleModalToggle();
                                    setTimeout(() => {
                                        this.setState({
                                        }, this.props.parentMethod)
                                    }, 2000);
                                    this.setState({ files: [] });
                                }
                            } else {

                            }
                        });
                    } else {
                        return response.json().then(data => {

                        });
                    }
                })
                .catch(error => {
                    if (this.mounted) {
                        console.log(error);
                    }
                });
        }
    }

    onCheckboxhandle = () => {

    }
    handleUrlOpen = (DocumentName) => {
        let Documenturl = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/${DocumentName}`;
        window.open(Documenturl, "_blank")
    }
    setFile = (e, id) => {
        let filesArr = e.target.files;
        for (var i = 0; i < filesArr.length; ++i) {
            let arraylength = [...this.state.files];
            arraylength.push(filesArr[i]);
            if (arraylength.length == 1) {

                if (filesArr[i].name.match(/\.(pdf)$/)) {
                    if ((parseInt(filesArr[0].size) / 1024) <= (parseInt(5) * 1024)) {
                        if (id === "docbeforeappr") {
                            if (this.state.DocumentName.length === 0) {
                                this.validateForm();
                            }
                            else {
                                // for clear files array on upload
                                let projFilesList = [...this.state.files];
                                projFilesList.push(filesArr[i]);
                                this.setState({
                                    files: projFilesList
                                });

                            }
                        }
                        if (id === "docafterappr") {
                            let projFilesList = this.state.files;
                            projFilesList.push(filesArr[i]);
                            this.setState({ files: projFilesList });
                            let file = this.state.files;
                            const formData = new FormData();
                            formData.append('FormFiles', file[0]);
                            formData.append('Status', "Approved");
                            formData.append('UserId', this.state.Applicationlist.userId);
                            let uri = `${config.API_HOST_URL}/api/login/UploadProjectAgreementFile`;
                            let fetchOptions = {
                                method: "POST",
                                body: formData,
                                headers: {
                                    WebsiteGuid: this.props.websiteGUID,
                                    LanguageGuid: this.props.languageGUID,
                                    Authorization: `Bearer ${this.props.token}`,
                                    Accept: "application/json",
                                    ContentType: 'multipart/form-data'
                                }
                            };
                            fetch(uri, fetchOptions)
                                .then(response => {
                                    const contentType = response.headers.get("content-type");
                                    if (contentType && contentType.indexOf("application/json") !== -1) {
                                        return response.json().then(data => {
                                            if (data.success) {
                                                let ApplicationList = this.props.data;
                                                ApplicationList["agreementFile"] = data.UploadFileName;
                                                this.setState({ isUploaded: data.success, ApplicationList: data.ApplicationList });
                                                alert(data.message);
                                            } else {
                                                this.setState({ isUploaded: false });
                                                alert(data.message);
                                            }
                                        });
                                    } else {
                                        return response.json().then(data => {
                                            this.setState({ isUploaded: false });
                                            alert(data.message);
                                        });
                                    }
                                })
                                .catch(error => {
                                    if (this.mounted) {
                                        alert(error)
                                    }
                                });
                        }
                    } else {
                        alert("Maximum files size allowed is 5 MB");
                    }
                } else {
                    alert('Allowed extension are .pdf');
                }
            }
            else { alert("Please select only one file") }
        }
    }
    handleDownloadFile = (DocumentName) => {
        let Documenturl = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/${this.state.Applicationlist.nameOfNGO}/${DocumentName}`;
        window.open(Documenturl, "_blank")
    }
    removeFileFromList = (e,) => {
        if (this.mounted) {

            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ files: array, files: [] });
        }
    }
    UploadDocumentBeforeNgoApproved = (e) => {
        if (this.mounted) {
            if (this.validateForm()) {
                let file = this.state.files;
                const formData = new FormData();
                for (var index = 0; index < file.length; index++) {
                    var element = file[index];
                    formData.append('FormFiles', element);
                }
                formData.append('DocumentName', this.state.DocumentName);
                formData.append('Status', "Meeting Requested");
                formData.append('UserId', this.state.Applicationlist.userId);
                let uri = `${config.API_HOST_URL}/api/login/UploadDocumentBeforeNgoApproved`;
                let fetchOptions = {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        ContentType: 'multipart/form-data'
                    }
                };
                fetch(uri, fetchOptions)
                    .then(response => {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            return response.json().then(data => {
                                if (data.success) {
                                    alert(data.message);
                                    this.GetUploaddDocumentDetails();
                                    this.clearFields();
                                } else {
                                    alert(data.message);
                                }

                            });
                        } else {
                            return response.json().then(data => {
                                alert(data.message);
                            });
                        }
                    })
                    .catch(error => {
                        if (this.mounted) {
                            alert(error)
                        }
                    });
            }
        }
    }
    handleUserInput(e) {
        if (this.mounted) {
            const name = e.target.name;
            const value = e.target.value;
            if (this.mounted) {
                this.setState({ [name]: value });
            }
        }
    }
    clearFields = (e) => {
        if (this.mounted) {
            if (this.mounted) {
                this.setState({
                    files: [],
                    DocumentName: ""
                })
            }
        }
    }
    validateForm(e) {
        if (this.mounted) {
            let fields;
            fields = {
                DocumentName: this.state.DocumentName,
                files: this.state.files
            }
            let errors = {};
            let formIsValid = true;
            if (!fields["DocumentName"]) {
                formIsValid = false;
                errors["DocumentName"] = "Please enter document name";
            }
            if (fields["DocumentName"] !== "") {
                if (this.state.files.length === 0) {
                    formIsValid = false;
                    errors["files"] = "Please select file";
                }
            }
            if (this.mounted) {
                this.setState({ errors: errors });
            }
            return formIsValid;
        }
    }
    GetUploaddDocumentDetails = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/login/GetUploaddDocumentDetails/${this.state.Applicationlist.userId}`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    WebsiteGuid: this.props.websiteGUID,
                    LanguageGuid: this.props.languageGUID,
                    Authorization: `Bearer ${this.props.token}`,
                    Accept: "application/json",
                    ContentType: 'multipart/form-data'
                }
            };
            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (data.success) {
                                this.setState({ FilesList: data.list })
                            } else {
                                alert(data.message);
                            }
                            if (this.state.FilesList[0].userFiles.length > 0) {
                                this.setState({ flag: true });
                            }
                        });
                    } else {
                        return response.json().then(data => {
                            alert(data.message);
                        });
                    }
                })
                .catch(error => {
                    if (this.mounted) {
                    }
                });
        }



    }
    render() {
        const { isModalOpen } = this.state;

        var UserFilesarr = [];
        if (this.state.Applicationlist.userFiles !== null) {
            var Userfiles = this.state.Applicationlist.userFiles;
            for (let i = 0; i < Userfiles.length; i++) {
                UserFilesarr.push(Userfiles[i].documentOriginalName + "," + Userfiles[i].documentName)
            }

        }
        return (

            <div className="makeappoint_btn">
                {/* <i onClick={this.handleModalToggle} className="far fa-thumbs-up"></i> */}
                {/* <Button onClick={this.handleModalToggle}>Make Appointment</Button> */}
                {this.state.Applicationlist != [] && this.state.Applicationlist != undefined &&
                    <Modal
                        title="Application Details"
                        isOpen={isModalOpen}
                        onClose={this.handleModalToggle}
                        className="addproject_modal makeapptment_modal"
                        actions={[

                            <button value="Approved" className={this.state.Applicationlist.status === "Meeting Requested" ? "modal_btn submit_btn" : "nomeetreqbtn"} onClick={this.ApproveRejectApplication}>Approve</button>,
                            <button value="Rejected" className={this.state.Applicationlist.status === "Meeting Requested" ? "modal_btn cancel_btn" : "nomeetreqbtn"} onClick={this.ApproveRejectApplication}>Reject</button>
                            //<Button key="submit" value="Approved" onClick={this.ApproveRejectApplication} className={this.state.Applicationlist.status === "Meeting Requested" ? "modal_btn submit_btn":"hide_btn"}>Approve</Button>,
                            //<Button key="cancel" value="Rejected" onClick={this.ApproveRejectApplication} className={this.state.Applicationlist.status === "Meeting Requested" ? "modal_btn cancel_btn":"hide_btn"}>Reject</Button>
                        ]}

                    >
                        <div className="applistpopup_container">
                            <div className="applistpoptop">
                                <a className="backtolist" onClick={this.handleModalToggle}><i className="fa fa-angle-left"></i>Back to list</a>
                                <div className="page_heading">{this.state.Applicationlist.nameOfNGO}</div>
                                <div className="applistpoploc">{this.state.Applicationlist.typeOfIncorporation === "Others" ? this.state.Applicationlist.typeOfIncorporation + "[" + this.state.Applicationlist.otherData + "]" : this.state.Applicationlist.typeOfIncorporation === "Privatelimitedcompany" ? "Private Limited Company" : this.state.Applicationlist.typeOfIncorporation} | {this.state.Applicationlist.baseLocation}</div>
                                <div className="applistpopbtn">
                                    <div className={"statustwo " + (this.state.Applicationlist.status === null || this.state.Applicationlist.status === "New Request" ? 'newrequest' : (this.state.Applicationlist.status === "Approved" ? 'resolved' : 'rejected'))}>
                                        <span>{this.state.Applicationlist.status}</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="applistpopup_content">
                                <li>
                                    <span className="applistpoplabel">Name Of The Head Of Organisation</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.ngoHeadName}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">NGO Head Email ID</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.ngoheadEmailAddr}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">Name</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.contactName}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">Designation</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.contactDesignation}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">Mobile</span>
                                    <span className="applistpopinputvalue">+91 {this.state.Applicationlist.mobile}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">Email ID</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.email}</span>
                                </li>
                                <li>
                                    <span className="applistpoplabel">Website</span>
                                    <span className="applistpopinputvalue">{(this.state.Applicationlist.website === null || this.state.Applicationlist.website === "") ? "No website specified." : this.state.Applicationlist.website}</span>
                                </li>
                                {this.state.Applicationlist.treePlantedBefore !== null && <li>
                                    <span className="applistpoplabel">Quantum of Trees planted</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.treePlantedBefore}</span>
                                </li>}
                                {this.state.Applicationlist.meetingDate !== null && <li>
                                    <span className="applistpoplabel">Meeting Date</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.meetingDate}</span>
                                </li>}
                                {this.state.Applicationlist.meetingTime !== null && <li>
                                    <span className="applistpoplabel">Meeting Time</span>
                                    <span className="applistpopinputvalue">{this.state.Applicationlist.meetingTime}</span>
                                </li>}
                                {this.state.Applicationlist.meetingNotes !== null && <li>
                                    <span className="applistpoplabel">Meeting Note(s)</span>
                                    <ul className="meetingnoteslist">
                                        {this.state.Applicationlist.meetingNotes.map((item, index) => (
                                            <li>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>

                                </li>}
                                {this.state.Applicationlist.trustdocument !== null && <li>
                                    <span className="applistpoplabel">Download Trust Document</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.trustdocument)}>{this.state.Applicationlist.trustdocument}</a></span>
                                </li>}
                                {this.state.Applicationlist.authorsignatories !== null && <li>
                                    <span className="applistpoplabel">Download Authorized Signatories Document</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.authorsignatories)}>{this.state.Applicationlist.authorsignatories}</a></span>
                                </li>}
                                {this.state.Applicationlist.blankcanchq !== null && <li>
                                    <span className="applistpoplabel">Download Blank Cancel Cheque Document</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.blankcanchq)}>{this.state.Applicationlist.blankcanchq}</a></span>
                                </li>}
                                {this.state.Applicationlist.additionaldocument !== null && <li>
                                    <span className="applistpoplabel">Download Additional Document</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.additionaldocument)}>{this.state.Applicationlist.additionaldocument}</a></span>
                                </li>}
                                {this.state.Applicationlist.companyincarporationdocument !== null && <li>
                                    <span className="applistpoplabel">Download Company Incorporation Documents</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.companyincarporationdocument)}>{this.state.Applicationlist.companyincarporationdocument}</a></span>
                                </li>}
                                {this.state.Applicationlist.rocdocument !== null && <li>
                                    <span className="applistpoplabel">Download ROC Registration Documents</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.rocdocument)}>{this.state.Applicationlist.rocdocument}</a></span>
                                </li>}
                                {this.state.Applicationlist.moadocument !== null && <li>
                                    <span className="applistpoplabel">Memorandum of association (MoA) of Company Document</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.moadocument)}>{this.state.Applicationlist.moadocument}</a></span>
                                </li>}
                                {this.state.Applicationlist.aoadocument !== null && <li>
                                    <span className="applistpoplabel">Download Articles of Association (AoA) of company Documents</span>
                                    <span className="docname"><a onClick={() => this.handleDownloadFile(this.state.Applicationlist.aoadocument)}>{this.state.Applicationlist.aoadocument}</a></span>
                                </li>}
                                {(this.state.Applicationlist.status === "Approved" || this.state.Applicationlist.status === "Rejected") && this.state.Applicationlist.userFiles !== null && this.state.Applicationlist.userFiles.map((item, index) => (
                                    <div>
                                        <li >
                                            <span className="applistpoplabel">Download {item.documentName}</span>
                                            <span className="docname" ><a onClick={() => this.handleDownloadFile(item.documentOriginalName)}>{item.documentOriginalName}</a></span>
                                        </li>
                                    </div>
                                ))}
                                {this.state.Applicationlist.agreementFile === null && this.state.Applicationlist.status === "Approved" && this.state.isUploaded !== true &&
                                    <li className="upload_projAgremnt">
                                        <label for="forinventory">
                                            Upload Project Agreement
                                        </label>
                                        <div className="input-group divUploadFile">
                                            <div className="qq-upload-drop-area">
                                                <span>Drop files here to upload</span>
                                            </div>
                                            <div className="qq-upload-button">
                                                Drag and Drop Files
                                                <span className="redMandat">
                                                    Upload file type .pdf File size limit 5MB
                                                </span>
                                            </div>
                                            <input id="choose" type="file" name="files" onChange={e => this.setFile(e, "docafterappr")} />
                                        </div>
                                    </li>}

                                {this.state.Applicationlist.agreementFile !== null && this.state.Applicationlist.status === "Approved" && <li>
                                    <span className="applistpoplabel">Download Project Agreement</span>
                                    <tr>
                                        <td className="docname">
                                            <a onClick={() => this.handleDownloadFile(this.state.Applicationlist.agreementFile)}>{this.state.Applicationlist.agreementFile}</a>
                                        </td>
                                    </tr>
                                </li>}

                                {this.state.Applicationlist.status === "Meeting Requested" && this.state.flag && this.state.FilesList[0].userFiles.map((item, index) => (
                                    <div>
                                        <li >
                                            <span className="applistpoplabel">Download {item.documentName}</span>
                                            <span className="docname" ><a onClick={() => this.handleDownloadFile(item.documentOriginalName)}>{item.documentOriginalName}</a></span>
                                        </li>
                                    </div>
                                ))}
                                {this.state.Applicationlist.status === "Meeting Requested" &&
                                    <li >
                                        <div className="page_heading">Upload Additional Documents</div>
                                    </li>}
                                {this.state.Applicationlist.status === "Meeting Requested" &&
                                    <div className="addinfobox">
                                        < li className="form-group field docnamefield">
                                            <label for="FileName">
                                                Enter Document Name
                                            </label>
                                            <input type="text" id="DocumentName" name="DocumentName" placeholder="Enter your document name" value={this.state.DocumentName} onChange={(event) => this.handleUserInput(event)} />
                                            <div className="mandatory has-error">{this.state.errors.DocumentName}</div>

                                        </li>
                                        <li className="upload_projAgremnt">
                                            <label for="forinventorydoc">
                                                Upload Document
                                            </label>
                                            <div className="input-group divUploadFile">
                                                <div className="qq-upload-drop-area">
                                                    <span>Drop files here to upload</span>
                                                </div>
                                                <div className="qq-upload-button">
                                                    Drag and Drop Files
                                                    <span className="redMandat">
                                                        Upload file type .pdf File size limit 5MB
                                                    </span>
                                                </div>
                                                <input id="choose" type="file" name="files" multiple onChange={e => this.setFile(e, "docbeforeappr")} onClick={(event) => { event.target.value = null }} />
                                                {this.state.files.length == 0 &&
                                                    <div className="mandatory has-error">{this.state.errors.files}</div>}

                                            </div>
                                            <ul id="fileList" className="ul-project-files">
                                                {this.state.files !== null && this.state.files.map((item, index) => (
                                                    <li class="file-list-li">
                                                        <i class="fa fa-check"></i>{item.name}
                                                        <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e)}>Remove</a></li>
                                                ))}
                                            </ul>
                                            <div className="input-group divUploadFile uploadfldbtn">
                                                <div className="uploadfldbtn">
                                                    <button className="modal_btn cancel_btn" onClick={e => this.UploadDocumentBeforeNgoApproved(e)}>Upload</button>
                                                </div>
                                            </div>

                                        </li>

                                    </div>
                                }


                            </ul>
                        </div>
                    </Modal>
                }
            </div>

        )
    }

}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        //  ApplicationInfo: state.user.ApplicationInfo,
    };
}

export default connect(
    mapStateToProps
)(ApplistPopup);