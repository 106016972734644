import React, { Component } from "react";
import HomePageHeader from "../../Home/HomePageHeader";
import HomePageFooter from "../../Home/HomePageFooter";
import { navigate } from "@reach/router";
import Routes from "../../Routes";
class HomePageLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAbout: false
        }
        this.mounted = true;
    }
    render() {
        return (
            <>
                <div className={this.state.homeblank == true ? "treetiesfont": "treetiesfont homepagebgcont" && this.state.privacypolicy == true ? "treetiesfont": "treetiesfont homepagebgcont" && this.state.ourexperience == true ? "treetiesfont": "treetiesfont experiencepagebgcont" && this.state.aboutus == true ? "treetiesfont": "treetiesfont aboutusbg" } style={{ cursor: 'url(mouse_cursor.png),auto' }}>
                    <HomePageHeader />
                    <Routes />
                    <HomePageFooter />
                </div>
            </>
        );
    }
}

export default HomePageLayout;