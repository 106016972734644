import React, { Component, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function TreeDetailMarker(props) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const reduxState = useSelector((state) => state.project);
  const [treeData, setTreeData] = useState({});

  const getDateInFormat = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    const capitalizedMonth = month.toUpperCase();

    return `${day} ${capitalizedMonth} ${year}`;
  };

  useEffect(() => {
    if (reduxState?.treeData) {
      setTreeData(reduxState.treeData);
    } else {
      setTreeData({});
    }
  }, [reduxState.treeData]);

  return (
    <>
      return (
      <div>
        <Marker
          position={{
            lat: treeData?.coordinates?.geometry?.coordinates?.[1],
            lng: treeData?.coordinates?.geometry?.coordinates?.[0],
          }}
          icon={require("./orangeMarker1.png")}
          onMouseOver={() => setSelectedIndex(true)}
          onMouseOut={() => setSelectedIndex(null)}
        >
          {selectedIndex ? (
            <InfoWindow
              position={{
                lat: treeData?.coordinates?.geometry?.coordinates?.[1],
                lng: treeData?.coordinates?.geometry?.coordinates?.[0],
              }}
              className="treesinfowindow_cont"
            >
              <div>
                <div className="infwdtree_head">
                  <span>
                    Source: {treeData?.source ? treeData?.source : "NA"}
                  </span>
                </div>
                <div className="treesinfowindowitem_wrap">
                  <div className="treesinfowindow_item">
                    <span>Species Name</span>
                    <span>
                      :{" "}
                      {treeData?.common_name
                        ? treeData?.common_name
                        : treeData?.species_name || "NA"}
                    </span>
                  </div>
                  <div className="treesinfowindow_item">
                    <span>Height</span>
                    <span>
                      :{" "}
                      {treeData?.tree_height
                        ? Number(treeData?.tree_height).toFixed(1)
                        : "NA"}
                    </span>
                  </div>
                  <div className="treesinfowindow_item">
                    <span>Girth</span>
                    <span>
                      :{" "}
                      {treeData?.tree_girth && treeData?.tree_girth !== "NaN"
                        ? treeData?.tree_girth
                        : "NA"}
                    </span>
                  </div>
                  <div className="treesinfowindow_item">
                    <span>Latitude </span>
                    <span>
                      : {treeData?.coordinates?.geometry?.coordinates?.[1]}{" "}
                    </span>
                  </div>
                  <div className="treesinfowindow_item">
                    <span>Longitude </span>
                    <span>
                      :{treeData?.coordinates?.geometry?.coordinates?.[0]}
                    </span>
                  </div>
                  {/* <div className="treesinfowindow_item">
                    <span>Carbon Sequestered</span>
                    <span>: 5.70 Kg</span>
                  </div> */}
                </div>
              </div>
            </InfoWindow>
          ) : (
            ""
          )}
        </Marker>
      </div>
      );
    </>
  );
}
