import { useEffect, useState } from "react";
import config from "../../../utils/config";
import MangotreeImg from "./mediaImages/Mangotree.png";
import WhaleImg from "./mediaImages/Bluewhale.png";
import BacteriaImg from "./mediaImages/Bacteria.png";
import TextureSoilImg from "./mediaImages/Texture_soil.png";
import ColorSoilImg from "./mediaImages/Color_soil.png";
import PorocitySoilImg from "./mediaImages/Porocity_soil.png";
import StructureSoilImg from "./mediaImages/Structure_soil.png";
import FamilyImg from "./mediaImages/Family.jpg";
import MaleImg from "./mediaImages/male.png";
import FemaleImg from "./mediaImages/Female.png";

export default function ObjectivesView(props) {
  const [objectiveList, setObjectiveList] = useState([]);
  const [commonMastersList, setCommonMastersList] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [projectSubType, setProjectSubType] = useState([]);
  const [preDisturbanceDetails, setPreDisturbanceDetails] = useState([]);
  const [ListofMicrobes, setListofMicrobes] = useState([]);
  const [Flora, setFlora] = useState([]);
  const [FloraSpecies, setFloraSpecies] = useState([]);
  const [Fauna, setFauna] = useState([]);
  const [FaunaSpecies, setFaunaSpecies] = useState([]);
  const [Microbes, setMicrobes] = useState([]);
  const [MicrobesSpecies, setMicrobesSpecies] = useState([]);

  useEffect(() => {
    if (props?.projectId) {
      GetProjectObjective(props.projectId);
      GetCommonMasters();
    }
  }, [props?.projectId]);

  const GetProjectObjective = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectObjectives`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        let responseData = data?.data;
        setObjectiveList(responseData);
        setPreDisturbanceDetails(responseData?.pre_DisturbanceDetails);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response?.json())
      .then((data) => {
        let responseData = data;
        var listOfMicrobes = data
          ?.filter((x) => x?.type === "Microbes")
          .map((x) => x?.description);
        setCommonMastersList(data);
        setListofMicrobes(listOfMicrobes);
        if (responseData?.length > 0) {
          var projectType = responseData?.filter(
            (x) => x?.type === "ProjectType"
          );
          var projectSubType = responseData?.filter(
            (x) => x?.type === "ProjectSubType"
          );
          var flora = responseData?.filter((x) => x?.type === "Flora");
          var floraSpecies = responseData?.filter(
            (x) => x?.type === "FloraSubType"
          );
          var fauna = responseData?.filter((x) => x?.type === "Fauna");
          var faunaSpecies = responseData?.filter(
            (x) => x?.type === "FaunaSubType"
          );
          var microbes = responseData?.filter((x) => x?.type === "Microbes");
          var microbesSpecies = responseData?.filter(
            (x) => x?.type === "MicrobesSubType"
          );
          setFlora(flora);
          setFloraSpecies(floraSpecies);
          setFauna(fauna);
          setFaunaSpecies(faunaSpecies);
          setMicrobes(microbes);
          setMicrobesSpecies(microbesSpecies);
          setProjectType(projectType);
          setProjectSubType(projectSubType);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  return (
    <>
      <div className="sitedetailsaccord_wrap objectivedetailsaccord_wrap">
        <div className="sitedetailsaccord_heading">
          <h3>General</h3>
        </div>
        <div className="prosummarylist">
          {objectiveList?.project_Goal && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Project Goal</div>
              <div className="produmitem_content">
                :&nbsp;{" "}
                {objectiveList?.project_Goal
                  ? objectiveList?.project_Goal
                  : "-"}
              </div>
            </div>
          )}
          {objectiveList?.goal_Type && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Goal Type</div>
              <div className="produmitem_content">
                :&nbsp;{" "}
                {objectiveList?.goal_Type ? objectiveList?.goal_Type : "-"}
              </div>
            </div>
          )}
          {objectiveList?.scope_Restoration && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Scope Restoration</div>
              <div className="produmitem_content">
                :&nbsp;{" "}
                {objectiveList?.scope_Restoration
                  ? objectiveList?.scope_Restoration
                  : "-"}
              </div>
            </div>
          )}
          {projectType?.filter(
            (ss) => ss?.mainGuId === objectiveList?.target_Ecosystem_Type
          )[0]?.description ? (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Target Ecosystem Type</div>
              <div className="produmitem_content">
                :&nbsp;{" "}
                {
                  projectType?.filter(
                    (ss) =>
                      ss?.mainGuId === objectiveList?.target_Ecosystem_Type
                  )[0]?.description
                }
              </div>
            </div>
          ) : null}
          {projectSubType?.filter(
            (ss) => ss?.subGuId === objectiveList?.target_Ecosystem_Sub_Type
          )[0]?.description ? (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Target Ecosystem Sub Type</div>
              <div className="produmitem_content">
                :&nbsp;{" "}
                {
                  projectSubType?.filter(
                    (ss) =>
                      ss?.subGuId === objectiveList?.target_Ecosystem_Sub_Type
                  )[0]?.description
                }
              </div>
            </div>
          ) : null}
          {objectiveList?.scope_for_TreeTies ? (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Scope for Treeties</div>
              <div className="produmitem_content">
                :&nbsp; {objectiveList?.scope_for_TreeTies}
              </div>
            </div>
          ) : null}
        </div>
        <div className="sitedetailstabs_cont">
          <div className="tabs-content">
            <div className="yearsninfo_wrap">
              {preDisturbanceDetails?.pre_Dis_Ref_YearOfReference && (
                <div>
                  <span>Year of Reference </span>
                  <span>
                    :&nbsp;
                    {new Date(preDisturbanceDetails.pre_Dis_Ref_YearOfReference)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .toUpperCase()}
                  </span>
                </div>
              )}

              {preDisturbanceDetails?.pre_Dis_Ref_Sources && (
                <div>
                  <span>Sources</span>
                  <span>
                    :&nbsp; {preDisturbanceDetails?.pre_Dis_Ref_Sources}
                  </span>
                </div>
              )}
              {preDisturbanceDetails?.pre_Dis_Ref_Remarks && (
                <div>
                  <span>Remarks</span>
                  <span>
                    :&nbsp; {preDisturbanceDetails?.pre_Dis_Ref_Remarks}
                  </span>
                </div>
              )}
            </div>
            <div className="faflmicTypes_cont">
              <div className="floratype_cont">
                <div className="faflmicTypes_heading">Flora Details</div>
                <div className="floratypelist_wrap">
                  {preDisturbanceDetails?.pre_Dis_Flora &&
                  preDisturbanceDetails.pre_Dis_Flora.length > 0 ? (
                    preDisturbanceDetails.pre_Dis_Flora.map((item, i) => {
                      return (
                        <>
                          {FloraSpecies?.filter(
                            (x) => x?.subGuId === item?.floraSpecies
                          )?.map((flora) => {
                            return (
                              <div className="floratypelist_item">
                                <div className="floratypelist_img">
                                  <img
                                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Flora/${flora?.imageName}`}
                                    alt="Mangotree"
                                  />
                                </div>
                                <div className="floratypelist_content">
                                  <div>
                                    <span>
                                      Trees <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>{flora?.description}</span>
                                  </div>
                                  <div>
                                    <span>
                                      Population <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item?.floraNumber_Population
                                        ? item?.floraNumber_Population
                                        : "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      Invasive <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item?.floraInvasive_Specie
                                        ? item?.floraInvasive_Specie
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })
                  ) : (
                    <div className="norecodfoundprosum_cont">
                      No record found
                    </div>
                  )}
                </div>
              </div>

              <div className="floratype_cont">
                <div className="faflmicTypes_heading">Fauna Details</div>
                <div className="floratypelist_wrap">
                  {preDisturbanceDetails?.pre_Dis_Fauna &&
                  preDisturbanceDetails.pre_Dis_Fauna.length > 0 ? (
                    preDisturbanceDetails.pre_Dis_Fauna.map((item2, i2) => {
                      return (
                        <>
                          {FaunaSpecies?.filter(
                            (x) => x?.subGuId === item2?.faunaSpecies
                          )?.map((fauna) => {
                            return (
                              <div className="floratypelist_item">
                                <div className="floratypelist_img">
                                  <img
                                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Fauna/${fauna?.imageName}`}
                                    alt="Mangotree"
                                  />
                                </div>
                                <div className="floratypelist_content">
                                  <div>
                                    <span>
                                      Trees <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>{fauna?.description} </span>
                                  </div>
                                  <div>
                                    <span>
                                      Population <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item2?.faunaNumber_Population
                                        ? item2?.faunaNumber_Population
                                        : "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      Invasive <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item2?.faunaInvasive_Specie
                                        ? item2?.faunaInvasive_Specie
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })
                  ) : (
                    <div className="norecodfoundprosum_cont">
                      No record found
                    </div>
                  )}
                </div>
              </div>

              <div className="floratype_cont microbestype_cont">
                <div className="faflmicTypes_heading">Microbes Details</div>
                <div className="floratypelist_wrap">
                  {preDisturbanceDetails?.pre_Dis_Microbes &&
                  preDisturbanceDetails.pre_Dis_Microbes.length > 0 ? (
                    preDisturbanceDetails.pre_Dis_Microbes.map((item3, i3) => {
                      return (
                        <>
                          {MicrobesSpecies?.filter(
                            (x) => x?.subGuId === item3?.microbesSpecies
                          )?.map((microbes) => {
                            return (
                              <div className="floratypelist_item">
                                <div className="floratypelist_img">
                                  <img
                                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Microbes/${microbes?.imageName}`}
                                    alt="Mangotree"
                                  />
                                </div>
                                <div className="floratypelist_content">
                                  <div>
                                    <span>
                                      Trees <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>{microbes?.description} </span>
                                  </div>
                                  <div>
                                    <span>
                                      Population <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item3?.microbesNumber_Population
                                        ? item3?.microbesNumber_Population
                                        : "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      Invasive <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {item3?.microbesInvasive_Specie
                                        ? item3?.microbesInvasive_Specie
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })
                  ) : (
                    <div className="norecodfoundprosum_cont">
                      No record found
                    </div>
                  )}
                </div>
              </div>

              <div className="floratype_cont climatewatercontent_wrap">
                <div className="faflmicTypes_heading">Climate</div>
                <div className="climatedetails_cont">
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Climate</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl prosumartbltemp sitedet_climatetbl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.climateType ===
                                "Equable"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Equable
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.climateType ===
                                "Neither Equable Nor Extreme"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Neither Equable Nor Extreme
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.climateType ===
                                "Extreme"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Extreme
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************average temperature*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Average Temperature (°C)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                        <tbody>
                          <tr>
                            <td>°C</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= -20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              -20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > -20 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= -10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              -10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > -10 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= 0
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 0 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 10 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= 20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 20 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= 30
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              30
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 30 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer <= 40
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              40
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 40 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Temp_Summer > 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Average Yearly Rainfall (in mm)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Average Yearly Rainfall (in mm)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_avgrainfalltbl">
                        <tbody>
                          <tr>
                            <td>mm</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 0 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 10 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 30
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              30
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 30 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 50 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 90
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              90
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 90 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 120
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              120
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 120 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 150
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              150
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 150 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall <= 200
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              200
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall > 200 &&
                                preDisturbanceDetails?.climate
                                  ?.avg_Yearly_Rainfall >= 300
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              300
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Humidity (%)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Humidity (%)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_humiditytbl">
                        <tbody>
                          <tr>
                            <td>%</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 0 &&
                                preDisturbanceDetails?.climate?.humidity <= 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 10 &&
                                preDisturbanceDetails?.climate?.humidity <= 20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 20 &&
                                preDisturbanceDetails?.climate?.humidity <= 30
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              30
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 30 &&
                                preDisturbanceDetails?.climate?.humidity <= 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 50 &&
                                preDisturbanceDetails?.climate?.humidity <= 70
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              70
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 70 &&
                                preDisturbanceDetails?.climate?.humidity <= 80
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              80
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 80 &&
                                preDisturbanceDetails?.climate?.humidity <= 90
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              90
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate?.humidity > 90 &&
                                preDisturbanceDetails?.climate?.humidity <= 100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              100
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Wind (km/h)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Wind (km/h)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_windkmhpbl">
                        <tbody>
                          <tr>
                            <td>km/h</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 0 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 10 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 20 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  30
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 30 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  40
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              30
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 40 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              40
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 50 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  70
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 70 &&
                                preDisturbanceDetails?.climate?.avg_Wind_Speed <
                                  100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              70
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_Wind_Speed >= 100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              100
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Air Quality Index*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Air Quality Index</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_aqibl">
                        <tbody>
                          <tr>
                            <td>AQI</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index >= 0 &&
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index <= 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0-50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index >= 51 &&
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index <= 100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              51-100
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index >= 101 &&
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index <= 200
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              101-200
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index >= 201 &&
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index <= 300
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              201-300
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.air_Quality_Index >= 300
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Beyond 300
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Avg. No of Hrs. of Sunlight Availability in Summer*****************/}
                  <div className="climatedetails_item lasttwoheading_maxwidth">
                    <div className="climatedetails_heading">
                      <h3>
                        Avg. No of Hrs. of Sunlight Availability in Summer
                      </h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_avghrsslightinsummerbl">
                        <tbody>
                          <tr>
                            <td>Hrs.</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 1
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 2
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              2
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 3
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              3
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 4
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              4
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 5
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              5
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 6
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              6
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 7
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              7
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 8
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              8
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 9
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              9
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 11
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              11
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Summer === 12
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              12
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Avg. No of Hrs. of Sunlight Availability in Winter*****************/}
                  <div className="climatedetails_item lasttwoheading_maxwidth">
                    <div className="climatedetails_heading">
                      <h3>
                        Avg. No of Hrs. of Sunlight Availability in Winter
                      </h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_avghrsslightinwinterbl">
                        <tbody>
                          <tr>
                            <td>Hrs.</td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 1
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 2
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              2
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 3
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              3
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 4
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              4
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 5
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              5
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 6
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              6
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 7
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              7
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 8
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              8
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 9
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              9
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 11
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              11
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.climate
                                  ?.avg_No_Hours_Sunlight_Avail_Winter === 12
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              12
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="floratype_cont climatewatercontent_wrap">
                <div className="faflmicTypes_heading">Water</div>
                <div className="climatedetails_cont waterdetails_cont">
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Turbidity</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl water_turbiditytbl">
                        <tbody>
                          <tr>
                            <td>0%</td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.turbidity ===
                                "High"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              25%
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.turbidity ===
                                "Medium"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50%
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.turbidity ===
                                "Low"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              75%
                            </td>
                            <td>0%</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont">
                        <span>High</span>
                        <span>Medium</span>
                        <span>Low</span>
                      </div>
                    </div>
                  </div>
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Taste and Odor</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl water_tastenodertbl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "High Purity"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "Relatively High Purity"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50-100
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "General Purity"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              200
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "Lime Scale"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              300
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "Rough Taste"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              400
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.taste_Odor ===
                                "Harmfull"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              500+
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>
                          High <br />
                          Purity
                        </span>
                        <span>
                          Relatively <br />
                          High <br />
                          Purity
                        </span>
                        <span>
                          General <br />
                          Purity
                        </span>
                        <span>
                          Lime <br />
                          Scale
                        </span>
                        <span>
                          Rough <br />
                          Taste
                        </span>
                        <span>Harmful</span>
                      </div>
                    </div>
                  </div>
                  {/*****************Hardness******************* */}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Hardness</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <div className="waterhardnesstbls_wrap">
                        <div>mg/L & ppm</div>
                        <table class="prosumarytbl water_hardnesstbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Soft"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0 -75
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Slightly Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                75-150
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                150-300
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Very Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                300+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="waterhardnesstbls_wrap">
                        <div>mg/L & ppm</div>
                        <table class="prosumarytbl water_hardnesstbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Soft"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0 - 4
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Slightly Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                4 - 8.5
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                8.5 - 17
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.hardness ===
                                  "Very Hard"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                17+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="watertblunits_cont water_hardnesstblfoot">
                        <span>Soft</span>
                        <span>Slightly Hard</span>
                        <span>Hard</span>
                        <span>Very Hard</span>
                      </div>
                    </div>
                  </div>
                  {/*****************pH Scale******************* */}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>pH Scale</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl water_phscaletbl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 0
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 1
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 2
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              2
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 3
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              3
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 4
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              4
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 5
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              5
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 6
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              6
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 7
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              7
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 8
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              8
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 9
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              9
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 11
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              11
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 12
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              12
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 13
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              13
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.ph === 14
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              14
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>Acidic</span>
                        <span>Neutral</span>
                        <span>Alkaline</span>
                      </div>
                    </div>
                  </div>
                  {/**************average temperature*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Temperature (°C)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                        <tbody>
                          <tr>
                            <td>°C</td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  -20 &&
                                preDisturbanceDetails?.water?.temperature < -10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              -20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  -10 &&
                                preDisturbanceDetails?.water?.temperature < 0
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              -10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  0 &&
                                preDisturbanceDetails?.water?.temperature < 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  10 &&
                                preDisturbanceDetails?.water?.temperature < 20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  20 &&
                                preDisturbanceDetails?.water?.temperature < 30
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              20
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  30 &&
                                preDisturbanceDetails?.water?.temperature < 40
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              30
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >=
                                  40 &&
                                preDisturbanceDetails?.water?.temperature < 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              40
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water?.temperature >= 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Biochemical Oxygen Demand (BOD) (in mgL)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Biochemical Oxygen Demand (BOD) (in mgL)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl water_hardnesstbl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand >= 1 &&
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand <= 2
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1 - 2
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand >= 3 &&
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand <= 5
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              3 - 5
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand >= 6 &&
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand <= 9
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              6 - 9
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.biochem_Oxygen_Demand >= 100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              100 or more
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/**************Dissolved Oxygen (DO) (in mgL)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Dissolved Oxygen (DO) (in mgL)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_aqibl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand >= 81 &&
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand <= 100
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              81-100
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand >= 61 &&
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand <= 80
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              61-80
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand >= 41 &&
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand <= 60
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              41-60
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand >= 21 &&
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand <= 40
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              21-40
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand >= 1 &&
                                preDisturbanceDetails?.water
                                  ?.chem_Oxygen_Demand <= 20
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1-20
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>Excellent</span>
                        <span>Fair</span>
                        <span>Very Poor</span>
                      </div>
                    </div>
                  </div>
                  {/**************Electrical Conductivity*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Electrical Conductivity</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_aqibl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.electrical_Conductivity === "Low"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Low
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.electrical_Conductivity === "Medium"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              Medium
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.water
                                  ?.electrical_Conductivity === "High"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              High
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="floratype_cont climatewatercontent_wrap">
                <div className="faflmicTypes_heading">
                  Water Toxic Substances
                </div>
                <div className="watertoxicsubstance_wrap">
                  <div className="watertoxicsubstance_cont">
                    <div className="watertoxicsubstance_left">Organic</div>
                    <div className="watertoxicsubstance_right">
                      <h3>Dioxins and furans</h3>
                      <p>
                        Most common form of water pollution. Originate from
                        domestic sewage, industrial effluents and farm wastes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="floratype_cont climatewatercontent_wrap soildetailscontent_wrap">
                <div className="faflmicTypes_heading">Soil</div>
                <div className="soilswrap">
                  <div>
                    <img src={TextureSoilImg} alt="Texture" />
                    Texture{" "}
                    <b>
                      {preDisturbanceDetails?.soil?.soil_Textures
                        ? preDisturbanceDetails?.soil?.soil_Textures
                        : "-"}
                    </b>
                  </div>
                  <div>
                    <img src={StructureSoilImg} alt="Structure" />
                    Structure{" "}
                    <b>
                      {" "}
                      {preDisturbanceDetails?.soil?.soil_Structure
                        ? preDisturbanceDetails?.soil?.soil_Structure
                        : "-"}
                    </b>
                  </div>
                  <div>
                    <img src={PorocitySoilImg} alt="Porocity" />
                    Porocity{" "}
                    <b>
                      {preDisturbanceDetails?.soil?.soil_Porosity
                        ? preDisturbanceDetails?.soil?.soil_Porosity
                        : "-"}
                    </b>
                  </div>
                  <div>
                    <img src={ColorSoilImg} alt="Color" />
                    Color{" "}
                    <b>
                      {" "}
                      {preDisturbanceDetails?.soil?.soil_Color
                        ? preDisturbanceDetails?.soil?.soil_Color
                        : "-"}
                    </b>
                  </div>
                </div>
                <div className="climatedetails_cont waterdetails_cont">
                  {/*****************Soil pH******************* */}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Soil pH</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl water_phscaletbl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === "0"
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 1
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              1
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 2
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              2
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 3
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              3
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 4
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              4
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 5
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              5
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 6
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              6
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 7
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              7
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 8
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              8
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 9
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              9
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 10
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              10
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 11
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              11
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 12
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              12
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 13
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              13
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.soil_pH === 14
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              14
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>Acidic</span>
                        <span>Neutral</span>
                        <span>Alkaline</span>
                      </div>
                    </div>
                  </div>
                  {/**************Nitrate (mg/kg)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Nitrate (mg/kg)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_aqibl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.nitrate >= 0 &&
                                preDisturbanceDetails?.soil?.nitrate <= 15
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0-15
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.nitrate >= 16 &&
                                preDisturbanceDetails?.soil?.nitrate <= 40
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              16-40
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.nitrate > 40 &&
                                preDisturbanceDetails?.soil?.nitrate <= 15
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              40-50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.nitrate >= 60
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              60+
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>Low</span>
                        <span>Medium</span>
                        <span>High</span>
                        <span>Very High</span>
                      </div>
                    </div>
                  </div>
                  {/**************Phosphate (PPA)*****************/}
                  <div className="climatedetails_item">
                    <div className="climatedetails_heading">
                      <h3>Phosphate (PPA)</h3>
                    </div>
                    <div className="climatedetails_tblcontent">
                      <table class="prosumarytbl sitedet_aqibl">
                        <tbody>
                          <tr>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.phosphate >= 0 &&
                                preDisturbanceDetails?.soil?.phosphate <= 35
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              0-35
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.phosphate > 35 &&
                                preDisturbanceDetails?.soil?.phosphate <= 50
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              35-50
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.phosphate > 50 &&
                                preDisturbanceDetails?.soil?.phosphate <= 80
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              50-80
                            </td>
                            <td
                              className={
                                preDisturbanceDetails?.soil?.phosphate > 80
                                  ? "activetype_td"
                                  : ""
                              }
                            >
                              80+
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="watertblunits_cont water_tastenodertblfoot">
                        <span>Low</span>
                        <span>Medium</span>
                        <span>High</span>
                        <span>Very High</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="floratype_cont climatewatercontent_wrap">
                <div className="faflmicTypes_heading">Social</div>
                <div className="socialwrap">
                  <div className="socialtop_cont">
                    <div className="socialtop_left">
                      <div>
                        <span className="social_counts">
                          {preDisturbanceDetails?.social?.no_Families
                            ? preDisturbanceDetails?.social?.no_Families
                            : "-"}
                        </span>
                        <span className="social_content">No. of Families</span>
                      </div>
                      <div>
                        <span className="social_counts">
                          {preDisturbanceDetails?.social?.avg_Family_Size
                            ? preDisturbanceDetails?.social?.avg_Family_Size
                            : "-"}
                        </span>
                        <span className="social_content">
                          Average Family Size
                        </span>
                      </div>
                      <div>
                        <span className="social_counts">
                          {" "}
                          {preDisturbanceDetails?.social
                            ?.avg_Annual_Inc_Family_Farming
                            ? preDisturbanceDetails?.social
                                ?.avg_Annual_Inc_Family_Farming
                            : "-"}
                        </span>
                        <span className="social_content">
                          Average Annual Income per Family by Farming
                          <abbr>
                            (for the land parcels included in this project)
                          </abbr>
                        </span>
                      </div>
                      <div>
                        <span className="social_counts">
                          {preDisturbanceDetails?.social
                            ?.avg_Annual_Inc_Family_Other_Sources
                            ? preDisturbanceDetails?.social
                                ?.avg_Annual_Inc_Family_Other_Sources
                            : "-"}
                        </span>
                        <span className="social_content">
                          Average Annual Income per Family by Other Sources
                        </span>
                      </div>
                    </div>
                    <div className="socialtop_right">
                      <img src={FamilyImg} alt="FamilyImg" />
                    </div>
                  </div>
                  <div className="socialbot_cont">
                    <div className="socialbot_left">
                      <div className="socialbot_heading">Gender Ratio</div>
                      <div className="socialbotiner_left">
                        <img src={FemaleImg} alt="FamilyImg" />
                        <span className="socialbot_contenthead">Female</span>
                        <span className="socialbot_count">
                          {preDisturbanceDetails?.social?.gender
                            ? preDisturbanceDetails?.social?.gender
                            : "-"}
                        </span>
                      </div>
                      <div className="socialbotiner_right">
                        <img src={MaleImg} alt="FamilyImg" />
                        <span className="socialbot_contenthead">Male</span>
                        <span className="socialbot_count">
                          {preDisturbanceDetails?.social?.gender
                            ? preDisturbanceDetails?.social?.gender
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="socialbot_right">
                      <div className="socialbot_heading">Literacy Rate</div>
                      <div className="socialbotiner_left">
                        <span className="socialbot_contenthead">Overall</span>
                        <span className="socialbot_count">
                          {preDisturbanceDetails?.social?.literacy_Rate_Overall
                            ? preDisturbanceDetails?.social
                                ?.literacy_Rate_Overall
                            : "-"}
                        </span>
                      </div>
                      <div className="socialbotiner_right">
                        <img src={FemaleImg} alt="FamilyImg" />
                        <span className="socialbot_contenthead">Female</span>
                        <span className="socialbot_count">
                          {preDisturbanceDetails?.social?.literacy_Rate_Female
                            ? preDisturbanceDetails?.social
                                ?.literacy_Rate_Female
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
