import React from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
//import projects from "../Views/NGOManager/projects";
import { globalHistory } from "@reach/router";

class LeftTabMenuContainer extends React.Component {
  constructor(props) {
    //alert(props.activeMenu);
    super(props);
    this.state = {
      isModalOpen: false,
      ProjectId: "",
      activeButton: props.activeMenu,
      subMenuPar: props.activesubMenu,
      subMenuSite: props.activesubSite,
    };
    this.mounted = true;
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    console.log("currentPath", currentPath);
    let Id = currentPath.split(":")[1];
    this.setState({ ProjectId: Id });
    /// console.log("ProjectId", this.state.ProjectId);
  }

  handleClick = (buttonId) => {
    // this.setState((prevState) => ({
    //   activeButton: prevState.activeButton === buttonId ? null : buttonId
    // }));
    this.setState({ activeButton: buttonId });
    // Call the respective function based on the clicked buttonId
    switch (buttonId) {
      case 1:
        this.onLeftTabsTitleClick();
        break;
      case 2:
        this.onLeftTabsTitle1Click();
        break;
      case 3:
        this.onLeftTabsTitle2Click();
        break;
      case 4:
        this.onLeftTabsTitle3Click();
        break;
      case 5:
        this.onLeftTabsTitle4Click();
        break;
      case 6:
        this.onLeftTabsTitle5Click();
        break;
      case 7:
        this.onLeftTabsTitle6Click();
        break;
      case 8:
        this.onLeftTabsTitle7Click();
        break;
      case 9:
        this.onLeftTabsTitle8Click();
        break;
      case 10:
        this.onLeftTabsTitle9Click();
        break;
      case 11:
        this.onLeftTabsTitle10Click();
        break;
      case 12:
        this.onLeftTabsTitle11Click();
        break;
      case 13:
        this.onLeftTabsTitle12Click();
        break;
      // Add more cases for other buttons
      default:
        break;
    }
  };

  onLeftTabsTitleClick = () => {
    navigate("/TreeTiesAddProjectForms1Par1/:" + this.state.ProjectId, {
      state: { activeMenu: 1, activesubMenu: true },
    });
    this.setState({ subMenuPar: true });
  };

  onLeftTabsTitle1Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1Colla/:" + this.state.ProjectId,
      { state: { activeMenu: 2 } },
      this.setState({ subMenu: false })
    );
  };

  onLeftTabsTitle2Click = () => {
    navigate("/TreeTiesAddProjectForms1Spo/:" + this.state.ProjectId, {
      state: { activeMenu: 3 },
    });
  };

  onLeftTabsTitle3Click = () => {
    navigate("/TreeTiesAddProjectForms1Loc/:" + this.state.ProjectId, {
      state: { activeMenu: 4 },
    });
  };

  onLeftTabsTitle4Click = () => {
    navigate("/TreeTiesAddProjectForms1Own/:" + this.state.ProjectId, {
      state: { activeMenu: 5 },
    });
  };
  onLeftTabsTitle5Click = () => {
    navigate("/TreeTiesAddProjectForms1Sit/:" + this.state.ProjectId, {
      state: { activeMenu: 6, activesubSite: true },
    });

    this.setState({ subMenuSite: true });
  };

  onLeftTabsTitle6Click = () => {
    navigate("/TreeTiesAddProjectForms1Obj/:" + this.state.ProjectId, {
      state: { activeMenu: 7 },
    });
  };

  // onLeftTabsTitle5Click = () => {
  //   navigate("/TreeTiesAddProjectForms1Obj/:" +this.state.ProjectId);
  //   document.getElementById("hideSubmenu").style.display = "none";
  //   document.getElementById("hideSubmenu1").style.display = "none";
  // };

  onLeftTabsTitle7Click = () => {
    navigate("/TreeTiesAddProjectForms1Ris/:" + this.state.ProjectId, {
      state: { activeMenu: 8 },
    });
  };

  onLeftTabsTitle8Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1Cos/:" + this.state.ProjectId,
      { state: { activeMenu: 9 } },
      this.setState({ subMenu: false })
    );
  };

  onLeftTabsTitle9Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1Lay/:" + this.state.ProjectId,
      { state: { activeMenu: 10 } },
      this.setState({ subMenu: false })
    );
  };

  onLeftTabsTitle10Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1ImageUpload/:" + this.state.ProjectId,
      { state: { activeMenu: 11 } },
      this.setState({ subMenu: false })
    );
  };

  onLeftTabsTitle11Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1WaterParameter/:" + this.state.ProjectId,
      { state: { activeMenu: 12 } },
      this.setState({ subMenu: false })
    );
  };

  onLeftTabsTitle12Click = () => {
    navigate(
      "/TreeTiesAddProjectForms1PDFUpload/:" + this.state.ProjectId,
      { state: { activeMenu: 13 } },
      this.setState({ subMenu: false })
    );
  };

  render() {
    const { activeButton } = this.state;

    console.log("activeButton", activeButton);
    return (
      <div className="ecosys_leftcont">
        <button
          className={
            activeButton === 1
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(1)}
        >
          <div className="content1">
            <div className="title">
              <div className="handshake">handshake</div>
              <div className="partnerships-5">Partnerships </div>
            </div>
            <div className="labels" style={{ display: "none" }}>
              <img className="tick-icon" alt="" src="/tick.svg" />
              <div className="verified">Verified</div>
            </div>
          </div>
        </button>
        {this.state.subMenuPar === true && (
          <div className="submenu" id="hideSubmenu">
            <a className="submenu1" onClick={this.props.onExe}>
              <div className="institutional-sponsors">
                Execution Partner (NGO)
              </div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onIns}>
              <div className="institutional-sponsors">
                Institutional Sponsors
              </div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onInd}>
              <div className="institutional-sponsors">Individual Sponsors</div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onFar}>
              <div className="institutional-sponsors">Farmers</div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onGov}>
              <div className="institutional-sponsors">Government Entities</div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onLeg}>
              <div className="institutional-sponsors">
                Legal Service Providers
              </div>
              <div className="div"></div>
            </a>
            <a className="submenu3" onClick={this.props.onAudit}>
              <div className="institutional-sponsors">
                Audit Service Providers
              </div>
              <div className="div"></div>
            </a>
          </div>
        )}

        <button
          className={
            activeButton === 2
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(2)}
        >
          <div className="content1">
            <div className="title">
              <div className="ecocollab_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="26"
                  viewBox="0 0 24 26"
                  fill="none"
                >
                  {" "}
                  <path
                    d="M22.9826 12.0887C22.8053 12.4546 22.5138 12.5632 22.1136 12.5632C19.9411 12.5517 17.7687 12.5575 15.5962 12.5575C15.0417 12.5575 14.8073 12.3174 14.8015 11.7628C14.7901 11.0367 14.813 10.3164 15.1103 9.64179C15.7791 8.12679 16.9568 7.29782 18.6491 7.09201C17.8944 6.96052 17.2884 6.6518 16.8997 6.02293C16.5109 5.39406 16.4194 4.71945 16.6253 4.01054C16.9797 2.76995 18.1288 2.25542 19.1179 2.34117C20.2498 2.43836 21.1589 3.35308 21.2732 4.43932C21.4047 5.71993 20.5357 6.8862 19.278 7.08057C19.6153 7.17205 20.0269 7.23493 20.3928 7.38929C21.8449 7.99529 22.7024 9.08724 22.9483 10.6423C22.954 10.6823 22.9711 10.7223 22.9883 10.7623C22.9826 11.2025 22.9826 11.6427 22.9826 12.0887Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M8.40396 24.2434C8.03235 24.0891 7.90658 23.8089 7.91229 23.4145C7.92373 22.7513 7.91229 22.0881 8.16956 21.4592C8.8213 19.8528 10.0276 18.9895 11.7656 18.7837C11.0681 18.6751 10.4849 18.3949 10.079 17.8289C9.67313 17.2687 9.54164 16.6341 9.67313 15.9595C9.8961 14.7703 10.8966 13.9757 12.0857 14.0214C13.2405 14.0614 14.2353 14.959 14.3668 16.0795C14.5269 17.4173 13.8008 18.3663 12.2801 18.7951C13.4121 18.898 14.3496 19.3554 15.0871 20.1958C15.716 20.9161 16.0476 21.7508 16.0819 22.7055C16.0876 22.9399 16.0705 23.1743 16.0933 23.4087C16.1276 23.8032 15.979 24.0833 15.6074 24.2377C13.2062 24.2434 10.8051 24.2434 8.40396 24.2434Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M1.02915 10.8884C1.08061 10.6312 1.12063 10.3739 1.18351 10.1166C1.62944 8.3958 3.04154 7.23525 4.81953 7.12091C7.15779 6.97227 8.99295 8.56731 9.17589 10.8999C9.19876 11.22 9.20448 11.5402 9.19876 11.8603C9.19304 12.2777 8.92434 12.5464 8.51272 12.5464C6.24878 12.5464 3.98485 12.5464 1.72091 12.5521C1.37789 12.5521 1.17208 12.3634 1.02344 12.0833C1.02915 11.6888 1.02915 11.2886 1.02915 10.8884Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M5.15111 7.09271C3.84191 7.12129 2.76711 6.09795 2.74425 4.79447C2.71566 3.40524 3.72185 2.35331 5.09394 2.33044C6.38598 2.30757 7.48365 3.36522 7.50651 4.66298C7.52367 5.98933 6.48317 7.06984 5.15111 7.09271Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M7.80372 19.4866C7.57504 19.8525 7.35207 20.2069 7.12911 20.5557C5.22535 19.4351 3.52168 16.0049 3.80181 13.8496C4.20772 13.8496 4.61934 13.8496 5.05955 13.8496C5.08242 16.1593 5.99714 18.0459 7.80372 19.4866Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M16.8826 20.5559C16.6654 20.2072 16.4424 19.8585 16.208 19.4869C17.0598 18.8237 17.7516 18.0004 18.2375 17.0171C18.7235 16.0281 18.9522 14.9761 18.9465 13.8613C19.3695 13.8613 19.7869 13.8613 20.2042 13.8613C20.6044 15.7537 18.7063 19.5726 16.8826 20.5559Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                  <path
                    d="M8.18115 6.73816C10.2107 5.47471 13.7552 5.45184 15.8305 6.73245C15.5218 7.0183 15.2131 7.30987 14.8929 7.59572C14.8586 7.6243 14.7729 7.6243 14.7271 7.60143C13.6752 7.14979 12.5775 7.00115 11.4398 7.08118C10.7138 7.13264 10.0163 7.287 9.34742 7.58428C9.21593 7.64145 9.14161 7.63573 9.03299 7.52711C8.76429 7.2527 8.467 6.99543 8.18115 6.73816Z"
                    fill="#D9D9D9"
                  ></path>{" "}
                </svg>
              </div>
              <div className="sponsorship">Collaborations</div>
            </div>
            <div className="labels1" style={{ display: "none" }}>
              <div className="verified">70%</div>
            </div>
          </div>
        </button>

        <button
          className={
            activeButton === 3
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(3)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">money-bag</div>
              <div className="sponsorship">Sponsorship</div>
            </div>
            <div className="labels1" style={{ display: "none" }}>
              <div className="verified">70%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 4
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(4)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">location-map</div>
              <div className="sponsorship">Location</div>
            </div>
            <div className="labels1" style={{ display: "none" }}>
              <div className="verified">70%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 5
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(5)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">ownership</div>
              <div className="sponsorship">Ownership</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 6
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(6)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">site-details</div>
              <div className="sponsorship">Site Details</div>
            </div>
            <div className="labels4" style={{ display: "none" }}>
              <div className="not-started">Not started</div>
            </div>
          </div>
        </button>
        {this.state.subMenuSite === true && (
          <div className="submenu8" id="hideSubmenu1">
            <a className="submenu10" onClick={this.props.onPre}>
              <div className="institutional-sponsors6">
                Pre-Disturbance Details
              </div>
              <div className="div30"></div>
            </a>
            {/* <div className="submenu-child">
            <a className="submenu-child1">
              <div className="fauna">Fauna</div>
              <div className="div31"></div>
            </a>
            <a className="submenu-child1">
              <div className="fauna">Fauna</div>
              <div className="div31"></div>
            </a>
          </div> */}
            <a className="submenu10" onClick={this.props.onDis}>
              <div className="institutional-sponsors6">Disturbance Details</div>
              <div className="div33"></div>
            </a>
            <a className="submenu10" onClick={this.props.onPost}>
              <div className="institutional-sponsors6">
                Post-Disturbance Details
              </div>
              <div className="div33"></div>
            </a>
          </div>
        )}

        <button
          className={
            activeButton === 7
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(7)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">objectives</div>
              <div className="sponsorship">Objectives</div>
            </div>
            <div className="labels4" style={{ display: "none" }}>
              <div className="not-started">Not started</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 8
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(8)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">warning-risk</div>
              <div className="sponsorship">Risks</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 9
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(9)}
        >
          <div className="content1">
            <div className="title">
              <div className="money-bag">cost-chart</div>
              <div className="sponsorship">Cost</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 10
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(10)}
        >
          <div className="content1">
            <div className="title">
              <div className="ecolayers_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 2L2 7L12 12L22 7L12 2Z"
                    stroke="#d9d9d9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M2 17L12 22L22 17"
                    stroke="#d9d9d9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M2 12L12 17L22 12"
                    stroke="#d9d9d9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <div className="sponsorship">Layers</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
        <button
          className={
            activeButton === 11
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(11)}
        >
          <div className="content1">
            <div className="title">
              <div className="ecoprolvlimg_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M21 17.3689H7V3.3689H21M21 1.3689H7C6.46957 1.3689 5.96086 1.57961 5.58579 1.95468C5.21071 2.32976 5 2.83846 5 3.3689V17.3689C5 17.8993 5.21071 18.408 5.58579 18.7831C5.96086 19.1582 6.46957 19.3689 7 19.3689H21C21.5304 19.3689 22.0391 19.1582 22.4142 18.7831C22.7893 18.408 23 17.8993 23 17.3689V3.3689C23 2.83846 22.7893 2.32976 22.4142 1.95468C22.0391 1.57961 21.5304 1.3689 21 1.3689ZM3 5.3689H1V21.3689C1 21.8993 1.21071 22.408 1.58579 22.7831C1.96086 23.1582 2.46957 23.3689 3 23.3689H19V21.3689H3M15.96 10.6589L13.21 14.1989L11.25 11.8389L8.5 15.3689H19.5L15.96 10.6589Z"
                    fill="#d9d9d9"
                  />
                </svg>
              </div>
              <div className="sponsorship">Project Level Images</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
        {/* <button
          className={
            activeButton === 12
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(12)}
        >
          <div className="content1">
            <div className="title">
              <div className="ecoprowatrparm_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g id="Environment / Water_Drop">
                    <path
                      id="Vector"
                      d="M16.0001 13.3848C16.0001 14.6088 15.526 15.7828 14.6821 16.6483C14.203 17.1397 13.6269 17.5091 13 17.7364M19 13.6923C19 7.11538 12 2 12 2C12 2 5 7.11538 5 13.6923C5 15.6304 5.7375 17.4893 7.05025 18.8598C8.36301 20.2302 10.1436 20.9994 12.0001 20.9994C13.8566 20.9994 15.637 20.2298 16.9497 18.8594C18.2625 17.4889 19 15.6304 19 13.6923Z"
                      stroke="#d9d9d9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="sponsorship">Water Parameter</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button> */}
        <button
          className={
            activeButton === 13
              ? "left-tabs-title active_tab"
              : "left-tabs-title"
          }
          onClick={() => this.handleClick(13)}
        >
          <div className="content1">
            <div className="title">
              <div className="ecoprowatrparm_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g id="Environment / Water_Drop">
                    <path
                      id="Vector"
                      d="M16.0001 13.3848C16.0001 14.6088 15.526 15.7828 14.6821 16.6483C14.203 17.1397 13.6269 17.5091 13 17.7364M19 13.6923C19 7.11538 12 2 12 2C12 2 5 7.11538 5 13.6923C5 15.6304 5.7375 17.4893 7.05025 18.8598C8.36301 20.2302 10.1436 20.9994 12.0001 20.9994C13.8566 20.9994 15.637 20.2298 16.9497 18.8594C18.2625 17.4889 19 15.6304 19 13.6923Z"
                      stroke="#d9d9d9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="sponsorship">Report</div>
            </div>
            <div className="labels3" style={{ display: "none" }}>
              <div className="verified">10%</div>
            </div>
          </div>
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
    token: state.configuration.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReducer: (data, type) =>
      dispatch({
        type: type,
        data: data,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftTabMenuContainer);
